import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NotificationComponent } from './notification.component';
import { NotifierComponent } from './notifier/notifier.component';
import { VerteraCommonModule } from '@vertera-common';
import { ButtonsModule } from '../buttons/buttons.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';


@NgModule({
  imports: [
    CommonModule,
    VerteraCommonModule,
    SvgIconModule,
    ButtonsModule,
    RouterModule,
  ],
  exports: [NotificationComponent, NotifierComponent],
  declarations: [NotificationComponent, NotifierComponent]
})
export class NotificationModule { }

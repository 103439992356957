<ng-container *ngIf="currentLanguage">
  <div class="placeholder--image" *ngIf="!noChangeSelect" [class.open]="opened">
    <span class="lang--img" appStatic="/assets/icons/countries/flag-{{currentLanguage.code}}.svg"></span>
  </div>
  <ng-select bindValue="code"
             bindLabel="name"
             [searchable]="false"
             [clearable]="false"
             [(ngModel)]="selectedLanguage"
             [placeholder]="currentLanguage.name"
             (open)="onOpenLanguage()"
             (close)="onOpenLanguage()"
             (change)="onSelectLanguage()"
             class="select--language">
    <ng-option [value]="item.code" *ngFor="let item of languages;">
      <span class="lang--img" appStatic="/assets/icons/countries/flag-{{item.code}}.svg"></span>
      <span class="lang--name">
        {{item.name}}
      </span>
    </ng-option>
  </ng-select>
</ng-container>


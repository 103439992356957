import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CartService } from '@core/services/cart/cart.service';
import { CartViewService } from '../../../cart/services/cart-view.service';
import { prevent } from '@core/lib/functions/ui';
import { NotificationService } from '@core/services';
import { validationErrorText } from '@core/lib/functions/errors';
import { CartMessagesService } from '../services/cart-messages.service';
import { Cart, CartCreateInput, CartUpdatePayload, Product, User } from '@generated';
import { GQL_CART_PREVIEW_FIELDS, IEntityUpdateResponse } from '@core/models';
import { UserHelperService } from '@core/services/user-helper.service';
import { AuthService } from '@core/services/auth/auth.service';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss'],
})
export class AddToCartComponent implements OnInit, OnDestroy {
  readonly sub$: Subscription = new Subscription();
  @Input() product: Product;
  isLoading: boolean;
  inCart = false;
  inCartCount = 0;
  cartId?= 0;
  user: User | null;

  constructor(
    private cd: ChangeDetectorRef,
    private cartService: CartService,
    private cartViewService: CartViewService,
    private notify: NotificationService,
    private cartMessagesService: CartMessagesService,
    private userHelperService: UserHelperService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.subscribeToUser();
    if (this.product.cart?.count) {
      this.inCartCount = this.product.cart.count;
      this.inCart = true;
      this.cartId = this.product.cart.cartId;
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  prevent(event: Event) {
    prevent(event);
  }

  addToCart(event: Event) {
    prevent(event);
    if (!this.user) {
      this.authService.externalReg();
      return;
    }
    this.cartMessagesService.showProductMessage(this.product.slug);
    this.cartMessagesService.showGeoMessage();
    this.isLoading = true;

    const input: CartCreateInput = {
      productSlug: this.product.slug,
      amount: this.product.cart?.count || 1,
    };
    const sub = this.cartService.addToCart(input).subscribe(
      (res: CartUpdatePayload) => {
        this.isLoading = false;
        if (res.isSuccessful) {
          this.inCart = true;
          this.inCartCount = input.amount;
          this.cartId = res.cart?.id;
          this.notify.openNotification({ type: 'to-cart', data: this.product, entityType: 'product' });
          // VERTERA_REFACTOR
          // this.cartViewService.getStockConditions();
          this.cd.detectChanges();
          this.subscribeToCart();
        }

        if (res.errors && res.errors.length > 0) {
          this.notify.openNotification({ data: { description: res.errors[0].message } });
        }
      },
      (err) => {
        this.isLoading = false;
        if (err && err.error) {
          this.notify.openNotification({ data: { description: validationErrorText(err.error) } });
        }
        this.cd.detectChanges();
      },
    );
    this.sub$.add(sub);
  }

  increment(event: Event) {
    prevent(event);
    if (this.cartId) {
      this.isLoading = true;
      const sub = this.cartViewService.increment(this.cartId).subscribe(
        (res: IEntityUpdateResponse) => {
          this.isLoading = false;
          if (res.isSuccessful) {
            this.inCartCount++;
          }
          this.cd.detectChanges();
        },
        () => (this.isLoading = false),
      );
      this.sub$.add(sub);
    }
  }

  decrement(event: Event) {
    prevent(event);
    this.isLoading = true;
    if (this.cartId) {
      if (this.inCartCount > 1) {
        const sub = this.cartViewService.decrement(this.cartId).subscribe(
          (res: IEntityUpdateResponse) => {
            this.isLoading = false;
            if (res.isSuccessful) {
              this.inCartCount--;
            }
            this.cd.detectChanges();
          },
          () => {
            this.isLoading = false;
            this.cd.detectChanges();
          },
        );
        this.sub$.add(sub);
      } else {
        this.removeFromCart();
      }
    }
  }

  changeAmount(event) {
    this.isLoading = true;
    if (this.cartId && this.inCartCount > 0) {
      this.inCartCount = parseFloat(event.target.value);
      const sub = this.cartViewService.changeAmount(this.cartId, this.inCartCount).subscribe(
        () => {
          this.isLoading = false;
          this.cd.detectChanges();
        },
        () => {
          this.isLoading = false;
          this.cd.detectChanges();
        },
      );
      this.sub$.add(sub);
    } else {
      this.removeFromCart();
    }
  }

  subscribeToCart() {
    const sub = this.cartService.getCart(GQL_CART_PREVIEW_FIELDS).subscribe((data) => {
      this.cartViewService.cartItems$.next(data.carts as Cart[]);
      this.cartViewService.cartItems = data.carts as Cart[];
      this.cartViewService.calculateCartTotalAmount();
      this.cartViewService.cartItemAmountChanged$.next(1);
      this.cd.detectChanges();
    });
    this.sub$.add(sub);
  }

  subscribeToUser() {
    const sub = this.userHelperService.user$.subscribe((user: User | null) => {
      this.user = user;
    });
    this.sub$.add(sub);
  }

  removeFromCart() {
    if (this.cartId) {
      this.isLoading = true;
      const sub = this.cartViewService.deleteCartItem(this.cartId).subscribe(
        () => {
          this.isLoading = false;
          this.inCart = false;
          this.inCartCount = 0;
          this.cd.detectChanges();
        },
        () => {
          this.isLoading = false;
          this.cd.detectChanges();
        },
      );
      this.sub$.add(sub);
    }
  }
}

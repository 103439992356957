import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  User,
  UserQueryFilter,
} from '@generated';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class UserApiService {
  constructor(private apollo: Apollo) {}

  getUser(filter: UserQueryFilter, fields: string): Observable<User> {
    return this.apollo
      .query({
        query: gql`
        query User($filter: UserQueryFilter) {
          UserGroup {
            User(filter: $filter) {
              user {
                ${fields}
              }
            }
          }
        }`,
        variables: { filter },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res: any) => res.data.UserGroup.User.user));
  }

}

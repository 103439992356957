import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { WrStorage } from './wr-storage.interface';

@Injectable()
export class CookieStorage implements WrStorage {
  [index: number]: string;
  [key: string]: any;
  length!: number;
  yearMs = 24 * 60 * 60 * 1000;
  constructor(private cookieService: CookieService) {
  }

  public clear(): void {
    this.cookieService.deleteAll();
  }

  public getItem(key: string): string {
    return this.cookieService.get(key);
  }

  public key(index: number): string {
    return this.cookieService.getAll()[index];
  }

  public removeItem(key: string, path?: string, domain?: string): void {
    // Fix problem ngx-cookie-service https://github.com/7leads/ngx-cookie-service/issues/5
    if (document && document.cookie) {
      document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      if (domain) {
        document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${domain}`;
      }
    }
    this.cookieService.delete(key, path, domain);
  }

  public setItem(key: string, data: string): void {
    const date = new Date(new Date().getTime() + this.yearMs);
    this.cookieService.set(key, data, date, '/');
  }

  public setItemWithDomain(key: string, value: string, domain: any): void {
    const date = new Date(new Date().getTime() + this.yearMs);
    this.cookieService.set(key, value, date, '/', domain);
  }

  public setItemWithPeriod(key: string, data: string, period: number): void {
    const date = new Date(new Date().getTime() + period);
    this.cookieService.set(key, data, date, '/');
  }
}

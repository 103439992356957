import { Injectable, Injector, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AuthTokenService } from '@core/services/auth/auth-token.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@core/services/auth/auth.service';
import { UserHelperService } from '@core/services/user-helper.service';
import { UserService } from '@core/services';


@Injectable({
  providedIn: 'root'
})
class PermissionsService {
  token: boolean;
  constructor(private userService: UserService, private userHelper: UserHelperService,
    private auth: AuthService, private injector: Injector) {
    this.token = !!this.injector.get(AuthTokenService).getAuthHeader();
  }

  canActivate(): Observable<boolean> | boolean {
    if (!this.userService.user) {
      return this.userService.getUser().pipe(
        map(user => this.userGuardMap(user))
      );
    } else {
      return this.userHelper.user$.pipe(
        map(user => this.userGuardMap(user))
      );
    }
  }

  userGuardMap(user) {
    let isCanctivate = true;
    if (!this.token) {
      isCanctivate = false;
      this.auth.externalLogin();
    }
    if (!user) {
      isCanctivate = false;
      this.auth.externalLogin();
    }
    return isCanctivate;
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean => {
  return inject(PermissionsService).canActivate();
}
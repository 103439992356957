import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareButtonComponent } from './share-button.component';
import { SvgIconModule } from '@ui';

@NgModule({
  declarations: [ShareButtonComponent],
  imports: [CommonModule, SvgIconModule],
  exports: [ShareButtonComponent],
})
export class ShareButtonModule {}

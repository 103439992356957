import { Pipe, PipeTransform } from '@angular/core';
import { ProductProductType, ProductTypeAttributes } from '@generated';

@Pipe({ name: 'productTypeAttributesProduct' })
export class ProductTypeAttributesPipe implements PipeTransform {
  transform(typeAttributes?: ProductTypeAttributes): ProductProductType {
    if (!typeAttributes) {
      return {} as ProductProductType;
    }
    if ('product' in typeAttributes) {
      return typeAttributes.product as ProductProductType;
    }
    return typeAttributes as ProductProductType;
  }
}

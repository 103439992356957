import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserEnvironmentApiService } from '../../api/UserGroup/user-environment-api.service';
import { UserEnvironmentQueryFactoryService } from './user-environment-factory.service';
import { UserEnvironmentUpdatePayload } from '@generated';
import { AppStorage } from '@core/storage';
import { MatDialog } from '@angular/material/dialog';
import { TextDialogComponent } from '@core/dialogs/text-dialog/text-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UserEnvironmentService {
  private readonly key = `${environment.appName}_userEnvironmentUuid`;
  private userEnvironmentUuid$$ = new BehaviorSubject<string>('');
  private isLoading$$ = new BehaviorSubject<boolean>(false);

  public userEnvironmentUuid$: Observable<string> = this.userEnvironmentUuid$$.asObservable();
  public isLoading$: Observable<boolean> = this.isLoading$$.asObservable();

  constructor(
    @Inject(AppStorage) private appStorage: Storage,
    private userEnvironmentApiService: UserEnvironmentApiService,
    private userEnvironmentQueryFactoryService: UserEnvironmentQueryFactoryService,
    private dialog: MatDialog,
  ) {}

  public get(): string {
    return this.appStorage.getItem(this.key) || '';
  }

  public set(userEnvironmentUuid: string): void {
    this.appStorage.removeItem(this.key);
    this.appStorage.setItem(this.key, userEnvironmentUuid);
    this.userEnvironmentUuid$$.next(userEnvironmentUuid);
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading$$.next(isLoading);
  }

  public updateUserEnvironment(): Observable<UserEnvironmentUpdatePayload> {
    this.isLoading$$.next(true);
    return this.userEnvironmentApiService.mutate(this.userEnvironmentQueryFactoryService.getQuery()).pipe(
      tap((res: UserEnvironmentUpdatePayload) => {
        if (res.isSuccessful && res.userEnvironment?.uuid) {
          this.set(res.userEnvironment.uuid);
        }

        if (res.errors && res.errors.length > 0) {
          this.dialog.open(TextDialogComponent, {
            width: '300px',
            panelClass: 'modal-form-error',
            data: res.errors[0].message,
          });
        }
        this.isLoading$$.next(false);
      }),
      take(1),
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VerteraAnalyticsDirective } from './directives/vertera-analytics.directive';
import { IfLangDirective } from './directives/if-lang.directive';
import { CutWordsPipe } from '@core/pipes/cut-words.pipe';
import { InputNumberDirective } from '@vertera-common/directives/input-number.directive';
import { MaxCharsDirective } from '@vertera-common/directives/max-chars.directive';
import { StaticDirective } from './directives/static.directive';
import { VrTabFocusDirective } from './directives/vr-tab-focus.directive';
import { StaticPipe } from '@core/pipes/static.pipe';
import { VrGoBackDirective } from './directives/vr-go-back.directive';
import { VrSafePipe } from '@core/pipes/vrSafe.pipe';
import { VRCurrencyPipe, VRCurrencySymbolPipe } from '@core/pipes/currency.pipe';
import { IfAuthDirective } from '@vertera-common/directives/If-auth.directive';
import { NgxTelInputCustomPlaceholderDirective } from './directives/ngx-tel-input-custom-placeholder';
import { PointsPipe } from '@core/pipes/points.pipe';
import { ProductTypeAttributesPipe } from '@core/pipes/product-type-attributes.pipe';
import { AddressPipe } from '@core/pipes/address.pipe';
import { OrderStatusPipe } from '../lk/pipes/order-status.pipe';

const commonDeclarations = [
  // directives
  StaticDirective,
  VerteraAnalyticsDirective,
  IfLangDirective,
  InputNumberDirective,
  MaxCharsDirective,
  VrTabFocusDirective,
  VrGoBackDirective,
  IfAuthDirective,
  NgxTelInputCustomPlaceholderDirective,

  // pipes
  CutWordsPipe,
  StaticPipe,
  VrSafePipe,
  VRCurrencyPipe,
  VRCurrencySymbolPipe,
  PointsPipe,
  ProductTypeAttributesPipe,
  AddressPipe,
  OrderStatusPipe
];


@NgModule({
  declarations: commonDeclarations,
  imports: [
    CommonModule
  ],
  exports: commonDeclarations
})
export class VerteraCommonModule { }

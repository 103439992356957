import { City, Country, CountryEnum } from '@generated';

export interface GeoData {
  country: Country;
  city: City;
}
export const GeoDataDefault: GeoData = {
  country: {
    code: CountryEnum.Ru,
    title: 'Россия',
  },
  city: {
    id: 4442,
    title: 'Москва',
  },
};

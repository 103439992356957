import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WrapperComponent } from './layout/wrapper/wrapper.component';
import { AuthGuard } from '@core/guards/auth-guard';

const routes: Routes = [
  {
    path: '',
    component: WrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/page/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'catalog',
        loadChildren: () => import('src/app/catalog/catalog.module').then(m => m.CatalogModule),
      },
      {
        path: 'product',
        loadChildren: () => import('src/app/page/product/product.module').then(m => m.ProductModule),
      },
      {
        path: 'geo-lang',
        loadChildren: () => import('src/app/page/geo-lang/geo-lang.module').then((m) => m.GeoLangModule),
      },
      {
        path: 'cart',
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/cart/cart.module').then(m => m.CartModule),
      },
      {
        path: 'payment',
        loadChildren: () => import('src/app/page/payment-result/payment-result.module').then(m => m.PaymentResultModule),
      },
      //   {
      //     path: 'confirm-phone',
      //     loadChildren: () => import('src/app/page/confirm-phone/confirm-phone.module').then(m => m.ConfirmPhoneModule),
      //   },
      //   {
      //     path: 'callback',
      //     loadChildren: () => import('src/app/page/callback/callback.module').then(m => m.CallbackModule),
      //   },
      {
        path: 'terms',
        loadChildren: () => import('src/app/page/simple/terms/terms.module').then((m) => m.TermsModule),
      },
      //   {
      //     path: 'returns',
      //     loadChildren: () => import('src/app/page/simple/retuns/retuns.module').then(m => m.RetunsModule),
      //   },
      {
        path: 'policy',
        loadChildren: () => import('src/app/page/simple/policy/policy.module').then((m) => m.PolicyModule),
      },
      {
        path: 'delivery',
        loadChildren: () => import('src/app/page/simple/delivery/delivery.module').then(m => m.DeliveryModule),
      },
      {
        path: 'order-created',
        loadChildren: () => import('src/app/page/order-created/order-created.module').then(m => m.OrderCreatedModule),
      },
      {
        path: 'faq',
        loadChildren: () => import('src/app/page/faq/faq.module').then(m => m.FaqModule),
      },
    ],
  },
  // {
  //   path: 'production',
  //   loadChildren: () => import('src/app/page/production/production.module').then(m => m.ProductionModule),
  // },
  {
    path: 'about',
    component: WrapperComponent,
    loadChildren: () => import('src/app/page/about-new/about-new.module').then(m => m.AboutNewModule),
  },
  // {
  //   path: 'external',
  //   component: WrapperComponent,
  //   loadChildren: () => import('src/app/page/auth/auth.module').then(m => m.AuthModule),
  // },
  {
    path: 'account',
    canActivate: [AuthGuard],
    component: WrapperComponent,
    loadChildren: () => import('src/app/lk/lk.module').then(m => m.LkModule),
  },
  {
    path: 'logout',
    component: WrapperComponent,
    loadChildren: () => import('src/app/page/auth/logout/logout.module').then(m => m.LogoutModule),
  },
  {
    path: 'blog',
    component: WrapperComponent,
    loadChildren: () => import('src/app/page/blog/blog.module').then(m => m.BlogModule),
  },
  {
    path: '**',
    component: WrapperComponent,
    loadChildren: () => import('src/app/page/not-found/not-found.module').then(m => m.NotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

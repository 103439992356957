import { gql } from 'apollo-angular';

export const GQL_FILTERS_QUERY = (fields: string) => gql`
    query getFilters($commonFilter: ProductsQueryFilter, $userEnvironmentUuid: UUID!) {
        ProductGroup {
            Filters(commonFilter: $commonFilter, userEnvironmentUuid: $userEnvironmentUuid) {
                filters {
                    ${fields}
                }
            }
        }
    }
`;

export const GQL_FILTERS_FIELDS = `
    tags {
        id
        title
        count
    }
    storages {
        id
        title
    }
    price {
        min
        max
    }
    pointBpv {
        min
        max
    }
    selectedStorages {
        id
        title
        address {
            country {
                title
            }
            city {
                title
            }
            street
            houseNumber
            houseBlock
            apartNumber
        }
    }
    categories {
        id
        title
        count
        depth
    }
`;

export const GQL_CLIENT_STORAGE_FIELDS = `
    selectedStorages {
        id
        title
        address {
            country {
                title
            }
            city {
                title
            }
            street
            houseNumber
            houseBlock
            apartNumber
        }
    }
`;

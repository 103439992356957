<div class="range-with-inputs__inputs">
  <input #inputMin appInputNumber="true" class="input with-padding text-center"
                [(ngModel)]="rangeOutput.min" type="number" name="price" [disabled]="disabled">
  <input #inputMax appInputNumber="true" class="input with-padding text-center"
              [(ngModel)]="rangeOutput.max" type="number" name="price" [disabled]="disabled">
</div>

<div class="range-with-inputs__range">
  <nouislider [connect]="true"
              [min]="min"
              [max]="max"
              (ngModelChange)="onChangeSlider($event)"
              [step]="1"
              (change)="onChangeEmit()"
              [(ngModel)]="range"
              [disabled]="disabled">
  </nouislider>
</div>

import { animate, query, style, transition, trigger } from '@angular/animations';

export const fade = trigger('fade', [
  transition('* => *', [
    query(
      ':leave',
      [
        style({ opacity: 1, position: 'fixed', zIndex: 9999, top: '24px', right: '24px',
        backgroundColor: 'white',
       }),
        animate('0.3s',
          style({ opacity: 0, position: 'fixed', zIndex: 9999, top: '24px', right: '24px',
          backgroundColor: 'white', }))],
      { optional: true }
    ),
    query(
      ':enter',
      [style({ opacity: 0, position: 'fixed', zIndex: 9999, top: '24px', right: '24px',
      backgroundColor: 'white', }),
        animate('0.3s',
          style({ opacity: 1, position: 'fixed', zIndex: 9999, top: '24px', right: '24px',
          backgroundColor: 'white', }))],
      { optional: true }
    )
  ])
]);

export const fadeBlock = trigger('fadeBlock', [
  transition('* => *', [
    query(
      ':enter',
      [
        style({ opacity: 0, position: 'relative' })],
      { optional: true }
    ),
    query(
      ':leave',
      [
        style({ opacity: 1, position: 'relative' }),
        animate('0.3s',
          style({ opacity: 0, position: 'relative' }))],
      { optional: true }
    ),
    query(
      ':enter',
      [style({ opacity: 0, position: 'relative' }),
        animate('0.3s',
          style({ opacity: 1, position: 'relative' }))],
      { optional: true }
    )
  ])
]);

export interface ErrorValueModel {
  name: string;
  value: string;
}

export interface ErrorModel {
  errors?: ErrorValueModel[];
  form_errors?: { [key: string]: ErrorValueModel }[];
}

export function validationErrorText(error: { data: ErrorModel }): string {
  const e = error.data;
  if (e.errors) {
    return e.errors.map((err) => err.value).join('.<br>');
  } else if (e.form_errors) {
    const formErrors = Object.keys(e.form_errors).map((key) => e.form_errors![key]);
    return formErrors.map((err: ErrorValueModel[]) => err.map((_err) => _err.value)).join('\n');
  }
  return '';
}

export function shortValidationErrorText(error: { data: any }): string {
  const fieldKey = Object.keys(error.data)[0];
  return error.data[fieldKey][0].value;
}

import { Injectable } from '@angular/core';
import { FilterCount, GQL_FILTERS_FIELDS, GQL_PRODUCT_CARD_QUERY_BODY, GQL_PRODUCT_CATALOG_FIELDS, PaginationResponse, ProductQuery, ProductsQuery } from '@core/models';
import { CurrencyEnum, Product, ProductFilters, ProductsQueryFilter } from '@generated';
import { Observable, Subject, catchError, map, throwError } from 'rxjs';
import { UserEnvironmentService } from '../core/user-environment/user-environment.service';
import { ProductApiService } from '../api/ProductGroup/product-api.service';
import { CurrencyService } from '../currency.service';
import { FiltersApiService } from '../api/ProductGroup/filters-api.service';
import { BaseFiltersService } from 'src/app/catalog/abstract/base-filters.service';

@Injectable()
export class ProductService {
  public products$ = new Subject<void>();
  constructor(
    private userEnvironmentService: UserEnvironmentService,
    private productApiService: ProductApiService,
    private filtersApiService: FiltersApiService,
    private currencyService: CurrencyService,
    private baseFiltersService: BaseFiltersService<ProductsQueryFilter>
  ) { }

  getInstancesWithPagination(query: ProductsQuery): Observable<PaginationResponse<Product>> {
    const q = Object.assign({}, query);
    return this.productApiService.getProducts(q, GQL_PRODUCT_CATALOG_FIELDS).pipe(
      map((res) => {
        this.products$.next();
        this.currencyService.setCurrency(res.currencyCode as CurrencyEnum);
        return res.products as PaginationResponse<Product>;
      }),
      catchError((err) => throwError(err)),
    );
  }

  getFilters(commonFilter: ProductsQueryFilter): Observable<ProductFilters> {

    const filterQuery = {
      commonFilter: this.baseFiltersService.getFilter(commonFilter),
      userEnvironmentUuid: this.userEnvironmentService.get(),
    };

    return this.filtersApiService.getFilters(filterQuery, GQL_FILTERS_FIELDS);
  }

  getCount(commonFilter: ProductsQueryFilter): Observable<FilterCount> {
    const filterQuery = {
      commonFilter: this.baseFiltersService.getFilter(commonFilter),
      userEnvironmentUuid: this.userEnvironmentService.get(),
    };

    return this.filtersApiService.getFilters(filterQuery, 'count') as Observable<FilterCount>;
  }

  getProduct(id: number): Observable<Product | undefined> {
    const query = new ProductQuery();
    query.userEnvironmentUuid = this.userEnvironmentService.get(),
      query.filter = {
        id
      }
    return this.productApiService.getProduct(query, GQL_PRODUCT_CARD_QUERY_BODY).pipe(map(res => res.product));
  }
}

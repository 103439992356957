import { GQL_ERROR_FIELDS, GQL_IMAGE } from '../common.gql';
import { gql } from 'apollo-angular';

export const GQL_PRODUCT_CATALOG_FIELDS = `
    id
    title
    slug
    isMyFavorite
    isAvailableToOrder
    mainImage {
      image {
        ${GQL_IMAGE}
      }
    }
    prices {
      pricePartner
      priceGuest
      price
    }
    points {
      code
      value
    }
    flags {
      isNew
      isHit
      isKosher
      isPromax
      isHalal
      isVegan
    }
    cart {
      count
      cartId
    }
    couponInfo {
      currentCoupon {
        title
      }
    }
`;

export const GQL_PRODUCT_QUERY = (fields: string) => gql`
  query product($filter: ProductQueryFilter!, $userEnvironmentUuid: UUID!) {
    ProductGroup {
      Product(filter: $filter, userEnvironmentUuid:$userEnvironmentUuid) {
        product {
          ${fields}
        }
        currencyCode
        ${GQL_ERROR_FIELDS}
      }
    }
  }
`;

export const GQL_PRODUCT_FLAGS_BODY = `
  flags {
    isNew
    isHit
    isKosher
    isPromax
    isHalal
    isVegan
  }
`;

export const GQL_PRODUCT_PRICES_BODY = `
  prices {
    price
    pricePartner
    priceGuest
    priceClmnTitle
  }
`;

export const GQL_PRODUCT_IMAGE_BODY = `
  id
  title
  image {
    original
    size150
    size300
    size600
  }
`;

export const GQL_PRODUCT_DOCUMENTS_BODY = `
  documents {
    id
    url
    title
  }
`;

export const GQL_PRODUCT_POINTS_BODY = `
  points {
    code
    value
  }
`;

export const GQL_PRODUCT_SEARCH_FIELDS = `
  id
  title
`;

export const GQL_PRODUCT_STATS_FIELDS = `
  productStats {
    reviewsPublishedCount
    reviewsPublishedAvgRating
  }
`;

export const GQL_PRODUCT_CART_FIELDS = `
  cart {
    count
    cartId
  }
`;

export const GQL_PRODUCT_CARD_QUERY_BODY = `
  id
  title
  shortDescription
  description
  statusConst
  slug
  isMyFavorite
  isAvailableToOrder
  unitConst
  vendorCode

  categories {
    id
    title
  }

  couponInfo {
    currentCoupon {
      title
    }
  }

  ${GQL_PRODUCT_CART_FIELDS}
  ${GQL_PRODUCT_FLAGS_BODY}
  ${GQL_PRODUCT_PRICES_BODY}
  ${GQL_PRODUCT_DOCUMENTS_BODY}
  ${GQL_PRODUCT_POINTS_BODY}
  ${GQL_PRODUCT_STATS_FIELDS}

  images { ${GQL_PRODUCT_IMAGE_BODY} }
  mainImage { ${GQL_PRODUCT_IMAGE_BODY} }

  productsRelated {
    id
    title
    slug
    isMyFavorite
    isAvailableToOrder

    ${GQL_PRODUCT_CART_FIELDS}
    ${GQL_PRODUCT_FLAGS_BODY}
    ${GQL_PRODUCT_PRICES_BODY}
    ${GQL_PRODUCT_POINTS_BODY}

    images { ${GQL_PRODUCT_IMAGE_BODY} }
    mainImage { ${GQL_PRODUCT_IMAGE_BODY} }
  }

  productStats {
    reviewsPublishedCount
    reviewsPublishedAvgRating
  }

  typeAttributes {
    ...on ProductProductType {
      netWeight
      grossWeight
      composition
      using
    }
  }
 `;

import { BaseDirective } from '@abstract';
import { Component } from '@angular/core';
import { copyright } from '@core/lib/functions/copyright';
import { NgAnimateScrollService } from '@core/services';
import { AuthService } from '@core/services/auth/auth.service';
import { LanguageService } from '@core/services/language.service';
import { UserHelperService } from '@core/services/user-helper.service';
import { User } from '@generated';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends BaseDirective
 {
  verteraHost!: string;
  scroller!: boolean;
  lang!: string;
  copyright = copyright(true);
  user: User | null;

  constructor(
    private userHelperSevice: UserHelperService,
    private authService: AuthService,
    private languageService: LanguageService,
    public scrollService: NgAnimateScrollService,
  ) {
    super();
   }

  ngOnInit() {
    this.getLanguage();
    this.subscribeToUser();
    const lang = this.languageService.getLanguage();
    this.verteraHost = environment.vertera.url.replace(/\.org/, `.org${ lang === 'en' ?  '' : '/' + lang }`);
  }

  subscribeToUser() {
   this.userHelperSevice.user$.pipe(this.takeUntilDestroyed()).subscribe((user: User| null) => this.user = user);
  }

  register() {
    this.authService.externalReg();
  }
  login() {
    this.authService.externalLogin();
  }
  goToUserCabinet() {
    this.authService.goToUserCabinet();
  }


  scrollToTop() {
    this.scrollService.scrollToElement('main__header', 750);
    this.scroller = true;
    setTimeout(() => {
      this.scroller = false;
    }, 1500);
  }

  getLanguage() {
    this.lang = this.languageService.getLanguage();
  }

}

import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserProductFavoriteApiService } from '../api/UserGroup/user-product-favorite-api.service';
import { IEntityUpdateResponse, PaginationResponse, UserProductFavoritesQuery } from '@core/models';
import { UserProductFavoriteCreateInput, UserProductFavoriteType } from '@generated';

@Injectable()
export class FavoritesService {
  favoritesChange$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private favoritesApiService: UserProductFavoriteApiService) { }

  getFavorites(query: UserProductFavoritesQuery): Observable<PaginationResponse<UserProductFavoriteType>> {
    return this.favoritesApiService.get(query).pipe(map(res => res.userProductFavorites as PaginationResponse<UserProductFavoriteType>));
  }

  addToFavorites(input: UserProductFavoriteCreateInput): Observable<IEntityUpdateResponse> {
    return this.favoritesApiService.create(input).pipe(tap(() => {
      this.favoritesChange$.next(null);
    }));
  }

  removeFromFavorites(id: number): Observable<IEntityUpdateResponse> {
    return this.favoritesApiService.delete(id).pipe(tap(() => {
      this.favoritesChange$.next(null);
    }));
  }
}

import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AppStorage } from '../../storage';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService {
  private tokenKey = `${environment.appName}_token`;
  private _tokenSubject: BehaviorSubject<string> = new BehaviorSubject(this.getAuthHeader());

  constructor(@Inject(AppStorage) private _appStorage: Storage) { }

  getAuthorizationHeaderValue(): Observable<string> {
    const token = this.getAuthHeader();
    if (token) {
      return of(`Bearer ${this.getAuthHeader()}`);
    }
    return of(``);
  }

  clearAuthHeaderValue(): void {
    if (document && document.cookie) {
      document.cookie.split(';').forEach((c) => {
        // keep a language and city cookies
        if (!c.includes(`${environment.appName}_lang`)) {
          document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        }
      });
    }
    this._appStorage.removeItem(this.tokenKey); // null, domain
  }

  setAuthHeader(token: string): void {
    this._appStorage.removeItem(this.tokenKey);
    this._appStorage.setItem(this.tokenKey, token);
    this._tokenSubject.next(token);
  }

  getAuthHeader(): string {
    const token = this._appStorage.getItem(this.tokenKey) as string;
    return token;
  }

  getToken(): Observable<string> {
    return this._tokenSubject;
  }
}

import { gql } from 'apollo-angular';
import { GQL_PAGE_INFO_MODEL } from '../common.gql';

export const GQL_COUNTRIES_QUERY = (fields: string) => gql`
    query getCountries($limit: Int!, $page: Int!, $filter: CountriesQueryFilter) {
        GeoGroup {
          Countries(limit: $limit, page: $page, filter: $filter) {
            countries {
                data {
                  ${fields}
                }
                pageInfo { ${GQL_PAGE_INFO_MODEL} }
             }
           }   
        }
    }
`;

export const GQL_COUNTRIES_FIELDS = `
    code
    title
    alpha3
`;

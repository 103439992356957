import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import {
  GQL_CART_QUERY,
  GQL_CART_CREATE_MUTATION,
  GQL_CART_UPDATE_MUTATION,
  GQL_CART_DELETE_MUTATION,
  GQL_CART_INCREMENT_MUTATION,
  GQL_CART_DECREMENT_MUTATION,
  IEntityUpdateResponse,
  GQL_CART_MULTIPLE_UPDATE,
} from '@core/models';

import { CartCreateInput, CartMultipleUpdateInput, CartsQueryPayload, CartUpdateInput, CartUpdatePayload } from '@generated';

@Injectable({
  providedIn: 'root'
})
export class CartsApiService {
  constructor(private apollo: Apollo) {}

  get(fields: string, userEnvironmentUuid: string): Observable<CartsQueryPayload> {
    return this.apollo
      .query({
        query: GQL_CART_QUERY(fields),
        fetchPolicy: 'no-cache',
        variables: { userEnvironmentUuid },
      })
      .pipe(map((res: any) => res.data.CartGroup.Carts));
  }

  getOrderInfo(fields: string, userEnvironmentUuid: string, storageDeliveryId?: number): Observable<CartsQueryPayload> {
    return this.apollo
      .query({
        query: GQL_CART_QUERY(fields),
        fetchPolicy: 'no-cache',
        variables: { filter: { storageDeliveryId }, userEnvironmentUuid },
      })
      .pipe(map((res: any) => res.data.CartGroup.Carts));
  }

  create(cartCreateInput: CartCreateInput, userEnvironmentUuid: string): Observable<CartUpdatePayload> {
    return this.apollo
      .mutate({
        mutation: GQL_CART_CREATE_MUTATION,
        variables: { input: cartCreateInput, userEnvironmentUuid },
      })
      .pipe(map((res: any) => res.data.Cart.Create));
  }

  update(id: number, cartUpdateInput: CartUpdateInput, userEnvironmentUuid: string): Observable<IEntityUpdateResponse> {
    return this.apollo
      .mutate({
        mutation: GQL_CART_UPDATE_MUTATION,
        variables: { id, input: cartUpdateInput, userEnvironmentUuid },
      })
      .pipe(map((res: any) => res.data.Cart.Update));
  }

  delete(ids: number[], userEnvironmentUuid: string): Observable<IEntityUpdateResponse> {
    return this.apollo
      .mutate({
        mutation: GQL_CART_DELETE_MUTATION,
        variables: { ids, userEnvironmentUuid },
      })
      .pipe(map((res: any) => res.data.Cart.Delete));
  }

  increment(id: number, userEnvironmentUuid: string): Observable<IEntityUpdateResponse> {
    return this.apollo
      .mutate({
        mutation: GQL_CART_INCREMENT_MUTATION,
        variables: { id, userEnvironmentUuid },
      })
      .pipe(map((res: any) => res.data.Cart.Inc));
  }

  decrement(id: number, userEnvironmentUuid: string): Observable<IEntityUpdateResponse> {
    return this.apollo
      .mutate({
        mutation: GQL_CART_DECREMENT_MUTATION,
        variables: { id, userEnvironmentUuid },
      })
      .pipe(map((res: any) => res.data.Cart.Dec));
  }

  multipleUpdate(ids: number[], userEnvironmentUuid: string, input?: CartMultipleUpdateInput): Observable<IEntityUpdateResponse> {
    return this.apollo
      .mutate({
        mutation: GQL_CART_MULTIPLE_UPDATE,
        variables: { ids, input, userEnvironmentUuid },
      })
      .pipe(map((res: any) => res.data.Cart.MultipleUpdate));
  }
}

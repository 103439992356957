import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '@core/services/language.service';

@Component({
  selector: 'app-geo-select',
  templateUrl: './geo-select.component.html',
  styleUrls: ['./geo-select.component.scss'],
})
export class GeoSelectComponent implements OnInit {
  constructor(
    protected formBuilder: FormBuilder,
    protected changeDetector: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected router: Router,
    protected languageService: LanguageService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  openGeoMenu!: boolean;
  showWidget!: boolean;

  ngOnInit() {
    this.showWidget = isPlatformBrowser(this.platformId);
  }

  toggleGeoMenu() {
    this.openGeoMenu = !this.openGeoMenu;
  }
}

import { Component, Input, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { FAV_DELETE_MESSAGE } from '@core/lib/const/messages';
import { LanguageService } from '@core/services/language.service';
import { prevent } from '@core/lib/functions/ui';
import { AuthService } from '@core/services/auth/auth.service';
import { Product } from '@generated';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@core/dialogs/confirm-dialog/confirm-dialog.component';
import { FavoritesService } from '@core/services';
import { IServerError } from '@core/models';

@Component({
  selector: 'app-to-fav',
  templateUrl: './to-fav.component.html',
  styleUrls: ['./to-fav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToFavComponent implements OnDestroy {
  @Input() item: Product;
  @Input() withText: boolean;
  sub: Subscription = new Subscription();

  constructor(
    private favoritesService: FavoritesService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private languageService: LanguageService,
    private authService: AuthService,
  ) {}

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onClick(event: Event) {
    prevent(event);
    if (this.item.isMyFavorite) {
      this.removeFromFavorites();
    } else {
      this.addToFavorites();
    }
  }

  addToFavorites() {
    const sub = this.favoritesService.addToFavorites({ productId: this.item.id }).subscribe(
      (res) => {
        if (res.isSuccessful) {
          this.item.isMyFavorite = true;
          this.cd.detectChanges();
        } else {
          if (res.errors?.length > 0) {
            const err = res.errors.find((error: IServerError) => {
              error.message === 'Unauthorized';
            });
            if (err) {
              this.authService.externalLogin();
            }
          }
        }
      },
      (err) => {
        console.log(err);
        this.authService.externalLogin();
      },
    );
    this.sub.add(sub);
  }

  removeFromFavorites() {
    const sub = this.openConfirmDialog()
      .afterClosed()
      .pipe(
        switchMap((isConfirmed: boolean) => {
          if (isConfirmed) {
            return this.favoritesService.removeFromFavorites(this.item.id).pipe(map(() => true));
          } else {
            return of(false);
          }
        }),
      )
      .subscribe((isDeleted: boolean) => {
        if (isDeleted) {
          this.item.isMyFavorite = false;
          this.cd.detectChanges();
        }
      });
    this.sub.add(sub);
  }

  private openConfirmDialog(): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
      panelClass: 'modal-form-error',
      data: { question: FAV_DELETE_MESSAGE[this.languageService.getLanguage()] },
    });
  }
}

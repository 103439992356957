import { LabelComponent} from './label.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  imports: [CommonModule],
  declarations: [ LabelComponent],
  exports: [ LabelComponent ]
})
export class LabelModule { }

import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '@core/services/language.service';
import { LanguageSwitcherBaseDirective } from '@core/components/languageSwitcherBase';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent extends LanguageSwitcherBaseDirective {
  _opened: boolean;

  constructor(
    protected override route: ActivatedRoute,
    protected override router: Router,
    protected override languageService: LanguageService,
    protected cd: ChangeDetectorRef,
  ) {
    super(route, router, languageService);
  }

  toggleLang() {
    this._opened = !this._opened;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToFavComponent } from './to-fav.component';
import { ButtonsModule } from '../../buttons/buttons.module';
import { SvgIconModule } from '../../svg-icon/svg-icon.module';
import { ConfirmDialogModule } from '@core/dialogs/confirm-dialog/confirm-dialog.module';


@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
    ConfirmDialogModule,
    ButtonsModule,
  ],
  declarations: [ToFavComponent],
  exports: [ToFavComponent]
})
export class ToFavModule {}

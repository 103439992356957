import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { ButtonsModule, LanguageSwitcherModule, SvgIconModule } from '@ui';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { WrapperComponent } from './wrapper/wrapper.component';
import { SearchFormComponent } from './header/components/search-form/search-form.component';
import { GeoSelectComponent } from './header/components/geo-select/geo-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookiesComponent } from './footer/components/cookies/cookies.component';
import { VerteraCommonModule } from '@vertera-common';
import { ConfirmDialogModule } from '@core/dialogs/confirm-dialog/confirm-dialog.module';
import { BreadcrumbsComponent } from './header/components/breadcrumbs/breadcrumbs.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, WrapperComponent, SearchFormComponent, GeoSelectComponent, CookiesComponent, BreadcrumbsComponent],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    SvgIconModule,
    ButtonsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    VerteraCommonModule,
    ConfirmDialogModule,
    LanguageSwitcherModule
  ],
})
export class LayoutModule {}

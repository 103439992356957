import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { VerteraCommonModule } from '@vertera-common';
import { LanguageSwitcherComponent } from './language-switcher.component';
import { LanguageSwitcherBlackComponent } from './language-switcher-black/language-switcher-black.component';
import { LanguageSwitcherSelectComponent } from './language-switcher-select/language-switcher-select.component';



@NgModule({
  declarations: [LanguageSwitcherComponent, LanguageSwitcherBlackComponent, LanguageSwitcherSelectComponent],
  imports: [CommonModule, SvgIconModule, RouterModule, NgSelectModule, FormsModule, ReactiveFormsModule, VerteraCommonModule],
  exports: [
    LanguageSwitcherComponent, LanguageSwitcherSelectComponent
  ]
})
export class LanguageSwitcherModule {}

<div class="container">
  <div class="cookies__close">
    <!-- <svg-icon name="control--close" [size]="12" (click)="close()"></svg-icon> -->
    <!-- ^^^ Было так ^^^ -->
    <svg-icon name="control--close" [size]="12"></svg-icon>
  </div>
  <div class="cookies">
    <div class="cookies--icon">
      <svg-icon name="control--info" [size]="30"></svg-icon>
    </div>
    <div class="cookies--text">
      <p>
        <span i18n="@@cookies_text">
          We use cookies to ensure the proper functioning of our website and for quality improvement purposes. For more information, please read our
        </span>
        <a routerLink="/policy" target="_blank" class="cookies--link" i18n="@@cookies_policy">Company Privacy</a>
      </p>
    </div>
    <div class="cookies__button" >
      <!-- <app-green-button (click)="accept()"> -->
      <!-- ^^^ Было так ^^^ -->
      <app-green-button>
        <span i18n="@@cookies_accept" >Accept</span>
      </app-green-button>
    </div>
  </div>
</div>


import { gql } from 'apollo-angular';
import { GQL_PAGE_INFO_MODEL } from '../common.gql';

export const GQL_CITIES_QUERY = (fields: string) => gql`
    query getCities($limit: Int!, $page: Int!, $filter: CitiesQueryFilter) {
        GeoGroup {
          Cities(limit: $limit, page: $page, filter: $filter) {
            cities {
                data {
                  ${fields}
                }
                pageInfo { ${GQL_PAGE_INFO_MODEL} }
             }
           }   
        }
    }
`;

export const GQL_CITY_QUERY = (fields: string) => gql`
  query getCity($filter: CityQueryFilter) {
    GeoGroup {
      City(filter: $filter) {
        city {
          ${fields}
        }
      }
    }
  }
`;

export const GQL_CITIES_FIELDS = `
    id
    title
    country {
      code
      title
    }
`;

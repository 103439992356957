import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

export interface Star {
  isActive: boolean;
  color: string;
}

@Component({
  selector: 'app-product-rating',
  templateUrl: './product-rating.component.html',
  styleUrls: ['./product-rating.component.scss']
})
export class ProductRatingComponent implements OnInit {

  @Input() stars: 5;
  @Input() set activeStar(val: number) {
    this._activeStar = val;
    this.buildStars(val);
  }
  get activeStar() {
    return this._activeStar;
  }
  _activeStar: number;
  @Input() onlyIndication: boolean;
  @Input() size = 13;

  @Input() activeColor: string;
  @Input() backColor: string;
  @Output() starSelected: EventEmitter<number> = new EventEmitter<number>();
  starObjs: Star[];
  constructor() { }

  ngOnInit() {
    this.buildStars(this.activeStar);
  }

  onStarClicked(star) {
    if (!this.onlyIndication) {
      const starNumber: number = star + 1;
      this.buildStars(starNumber);
      this.starSelected.emit(starNumber);
    }
  }

  private buildStars(stars) {
    this.starObjs = [];

    for (let i = 0; i < this.stars; i++) {
      if (stars > i) {
        this.starObjs.push({
          isActive: true,
          color: this.activeColor
        });
      } else {
        this.starObjs.push({
          isActive: false,
          color: this.backColor
        });
      }
    }
  }


}

<div class="container">
  <div class="block__title">
    <span *ngIf="recommended" i18n="@@recommended_products">Recommended products:</span>
    <span *ngIf="buywithproduct" i18n="@@bought_with_product">
      Bought with this product:
    </span>
  </div>
  <swiper class="swiper-slide similar-products-slider" [config]="config" [class.recommended]="recommended">
    <ng-template swiperSlide *ngFor="let item of similar;">
      <!-- <app-product-card [product]="item"></app-product-card> -->
    </ng-template>
  </swiper>
</div>


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CountriesApiService } from '../api/GeoGroup/countries-api.service';
import { CountriesQuery, GQL_COUNTRIES_FIELDS, PaginationResponse } from '@core/models';
import { Country } from '@generated';


@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private countryApiService: CountriesApiService) { }

  getPage(query: CountriesQuery): Observable<PaginationResponse<Country>> {
    return this.countryApiService.getCountries(query, GQL_COUNTRIES_FIELDS);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimilarProductsSliderComponent } from './similar-products-slider.component';
import { VerteraCommonModule } from '@vertera-common';
import { SwiperModule } from 'swiper/angular';



@NgModule({
  declarations: [SimilarProductsSliderComponent],
  imports: [CommonModule, VerteraCommonModule, SwiperModule],
  exports: [SimilarProductsSliderComponent],
})
export class SimilarProductsSliderModule {}

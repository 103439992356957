import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CartDeliveryTypesQueryPayload } from '@generated';
import { GQL_CART_DELIVERY_TYPES_QUERY } from '@core/models/CartGroup/cart-delivery-types.gql';

@Injectable(
  {
    providedIn: 'root',
  }
)
export class CartDeliveryTypesApiService {
  constructor(private apollo: Apollo) { }

  public get(userEnvironmentUuid: string, fields: string): Observable<CartDeliveryTypesQueryPayload> {
    return this.apollo
      .query({
        query: GQL_CART_DELIVERY_TYPES_QUERY(fields),
        variables: { userEnvironmentUuid },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res: any) => res.data.CartGroup.CartDeliveryTypes));
  }
}

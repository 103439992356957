import { Injectable } from '@angular/core';
import { Metrika } from 'ng-yandex-metrika';

declare const fbq: any;
declare const gtag: any;

@Injectable({
  providedIn: 'root'
})
export class VerteraAnalyticsService {

  constructor(private metrika: Metrika) { }

  facebookTrack(event: string) {
    try {
      if (fbq && event) {
        fbq('track', event);
      }
    } catch {
      console.error('Failed to track');
    }
  }

  yandexMetrikaTrack(event: string) {
    try {
      this.metrika.fireEvent(event);
    } catch {
      console.error('Failed to track');
    }
  }

  googleAnalyticsTrack(event: string) {
    try {
      gtag('event', event, {
        event_category: 'forms',
      });
    } catch {
      console.error('Failed to track');
    }
  }

  trackAll(event: string) {
    this.facebookTrack(event);
    this.googleAnalyticsTrack(event);
    this.yandexMetrikaTrack(event);
  }
}

import { Injectable, Inject, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

export interface ILanguage {
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public readonly supportedLanguages = ['en','ru','es','cs','bg','de','hu','kk'];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  getLanguage(): string {
    return this.locale.substr(0, 2);
  }

  getCurrentLanguage(languages: ILanguage[]): ILanguage {
    const language = this.getLanguage();
    const result = languages.find((lang) => lang.code === language);
    return result ? result : languages[0];
  }

  getBrowserLanguage() {
    if (isPlatformBrowser(this.platformId) && navigator.language) {
      const lang = navigator.language.substr(0, 2);
      return this.supportedLanguages.indexOf(lang) > -1 ? lang : this.getLanguage();
    } else {
      return 'en';
    }
  }
}

import { gql } from 'apollo-angular';
import { GQL_ERROR_FIELDS } from '../common.gql';

export const GQL_USER_ENVIRONMENT_QUERY = (fields: string) => gql`
    query userEnvironment($filter: UserEnvironmentQueryFilter) {
        UserGroup {
            UserEnvironment(filter: $filter) {
                userEnvironment {
                 ${fields}
                }
            ${GQL_ERROR_FIELDS}
          }
      }
    }
`;

export const GQL_USER_ENVIRONMENT_UPDATE_MUTATION = gql`
   mutation ($userEnvironmentUpdateInput: UserEnvironmentUpdateInput) {
    User {
      EnvironmentUpdate(UserEnvironmentUpdateInput: $userEnvironmentUpdateInput) {
        isSuccessful
        userEnvironment {
          uuid
        }
        ${GQL_ERROR_FIELDS}
      }
    }
  }
`;

export const GQL_USER_ENVIRONMENT_FIELDS = `
    uuid
`;

import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { BaseDirective } from './abstract/base.directive';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CityService } from '@core/services/hdbk/city.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseDirective implements OnInit {
  title = 'boss_vertera_frontend';
  cityChosen: boolean;

  constructor(public route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: any, private router: Router,
    private cityService: CityService) {
    super();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.cityChosen = !!this.cityService.getCity();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.redirectToGeoLang();
    }, 1000);
  }


  private redirectToGeoLang(): void {
    if (isPlatformBrowser(this.platformId) && (!this.cityChosen || this.route.snapshot.queryParams.global === 'true')) {
      const global_country_id = this.route.snapshot.queryParams.global_country_id;
      if (global_country_id) {
        this.router.navigate(['geo-lang'],
          { queryParamsHandling: 'merge', queryParams: { country_id: global_country_id, global_country_id: undefined } });
      } else {
        this.router.navigate(['geo-lang'], { queryParamsHandling: 'merge' });
      }
    }
  }
}

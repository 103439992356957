import { gql } from 'apollo-angular';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Bigint: { input: any; output: any; }
  /**
   * <b>Принимает дату в формате:</b> <br>
   *                     YYYY-MM-DD<br>
   *                     <b>Отдает дату в формате</b> YYYY-MM-DD
   *
   */
  Date: { input: any; output: any; }
  /**
   * <b>Принимает дату в формате:</b> <br>
   *                     YYYY-MM-DD hh:mm:ss - если часовой пояс у пользователя не указан, сохраняется в часовом поясе +3<br>
   *                     YYYY-MM-DD hh:mm:ss ±hh:mm - сохранится в указанном часовом поясе <br>
   *                     YYYY-MM-DD hh:mm:ss±hhmm - сохранится в указанном часовом поясе <br>
   *                     Unix timestamp  <br><br>
   *                     <b>Отдает дату в формате</b> YYYY-MM-DDThh:mm:ss ±hhmm
   *
   */
  DateTime: { input: any; output: any; }
  Email: { input: any; output: any; }
  Phone: { input: any; output: any; }
  /** UUID4. Пример: 123e4567-e89b-12d3-a456-426655440000 */
  UUID: { input: any; output: any; }
  /**
   * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
   *  [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
   */
  Upload: { input: any; output: any; }
  /** Ссылка */
  Url: { input: any; output: any; }
};

/** Адрес */
export type Address = {
  __typename?: 'Address';
  /** Номер квартиры */
  apartNumber?: Maybe<Scalars['String']['output']>;
  /** Город */
  city?: Maybe<City>;
  /** Страна */
  country?: Maybe<Country>;
  /** Добавлено */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Код домофона */
  doorphoneCode?: Maybe<Scalars['String']['output']>;
  /** Email */
  email?: Maybe<Scalars['String']['output']>;
  /** Имя */
  fname?: Maybe<Scalars['String']['output']>;
  /** Номер корпуса */
  houseBlock?: Maybe<Scalars['String']['output']>;
  /** Номер дома */
  houseNumber?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['Int']['output'];
  /** Основной */
  isMain?: Maybe<Scalars['Boolean']['output']>;
  /** Только данные о пользователе */
  isOnlyUserInfo?: Maybe<Scalars['Boolean']['output']>;
  /** Широта */
  lat?: Maybe<Scalars['Float']['output']>;
  /** Фамилия */
  lname?: Maybe<Scalars['String']['output']>;
  /** Долгота */
  lng?: Maybe<Scalars['Float']['output']>;
  /** Паспортные данные */
  passport?: Maybe<Scalars['String']['output']>;
  /** Телефон */
  phone?: Maybe<Scalars['Phone']['output']>;
  /** Почтовый индекс */
  postcode?: Maybe<Scalars['String']['output']>;
  /** Улица */
  street?: Maybe<Scalars['String']['output']>;
  /** Обновлено */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Данные для создания адреса сущности */
export type AddressCreateInput = {
  /** Квартира */
  apartNumber?: InputMaybe<Scalars['String']['input']>;
  /** ID города */
  cityId?: InputMaybe<Scalars['Int']['input']>;
  /** Страна */
  countryCode?: InputMaybe<CountryEnum>;
  /** Код домофона */
  doorphoneCode?: InputMaybe<Scalars['String']['input']>;
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** ID Сущности */
  entityId?: InputMaybe<Scalars['Int']['input']>;
  /** Сущность */
  entityType?: InputMaybe<AddressEntityTypeEnum>;
  /** Имя */
  fname?: InputMaybe<Scalars['String']['input']>;
  /** Корпус */
  houseBlock?: InputMaybe<Scalars['String']['input']>;
  /** Дом */
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  /** Основной */
  isMain?: InputMaybe<Scalars['Boolean']['input']>;
  /** Только данные о пользователе */
  isOnlyUserInfo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Широта */
  lat?: InputMaybe<Scalars['Float']['input']>;
  /** Фамилия */
  lname?: InputMaybe<Scalars['String']['input']>;
  /** Долгота */
  lng?: InputMaybe<Scalars['Float']['input']>;
  /** Паспортные данные */
  passport?: InputMaybe<Scalars['String']['input']>;
  /** Телефон */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Почтовый индекс */
  postcode?: InputMaybe<Scalars['String']['input']>;
  /** Улица */
  street?: InputMaybe<Scalars['String']['input']>;
};

/** Информация создания адреса для сущности */
export type AddressCreatePayload = {
  __typename?: 'AddressCreatePayload';
  address?: Maybe<Address>;
  errors?: Maybe<Array<AddressCreateProblem>>;
  /** Id адреса */
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type AddressCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Информация удаления адреса сущности */
export type AddressDeletePayload = {
  __typename?: 'AddressDeletePayload';
  errors?: Maybe<Array<AddressDeleteProblem>>;
  /** Id адреса */
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type AddressDeleteProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Сущность для которого создается адрес */
export enum AddressEntityTypeEnum {
  Contractor = 'CONTRACTOR',
  User = 'USER'
}

export type AddressPagination = {
  __typename?: 'AddressPagination';
  /** List of items on the current page */
  data?: Maybe<Array<Address>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над адресами */
export type AddressPayload = {
  __typename?: 'AddressPayload';
  /** Создание адреса для сущности */
  Create?: Maybe<AddressCreatePayload>;
  /** Удаление адреса сущности */
  Delete?: Maybe<AddressDeletePayload>;
  /** Редактирование адреса для сущности */
  Update?: Maybe<AddressUpdatePayload>;
};


/** Операции над адресами */
export type AddressPayloadCreateArgs = {
  AddressCreateInput?: InputMaybe<AddressCreateInput>;
};


/** Операции над адресами */
export type AddressPayloadDeleteArgs = {
  id: Scalars['Int']['input'];
};


/** Операции над адресами */
export type AddressPayloadUpdateArgs = {
  AddressUpdateInput?: InputMaybe<AddressUpdateInput>;
  id: Scalars['Int']['input'];
};

/** Фильтр */
export type AddressQueryFilter = {
  /** Id адреса */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type AddressQueryPayload = {
  __typename?: 'AddressQueryPayload';
  address?: Maybe<Address>;
  errors?: Maybe<Array<AddressQueryProblem>>;
};

export type AddressQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Данные для редактирования адреса сущности */
export type AddressUpdateInput = {
  /** Квартира */
  apartNumber?: InputMaybe<Scalars['String']['input']>;
  /** ID города */
  cityId?: InputMaybe<Scalars['Int']['input']>;
  /** Страна */
  countryCode?: InputMaybe<CountryEnum>;
  /** Код домофона */
  doorphoneCode?: InputMaybe<Scalars['String']['input']>;
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  fname?: InputMaybe<Scalars['String']['input']>;
  /** Корпус */
  houseBlock?: InputMaybe<Scalars['String']['input']>;
  /** Дом */
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  /** Отдать доступные поля для пользователя */
  isGetFieldsAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Основной */
  isMain?: InputMaybe<Scalars['Boolean']['input']>;
  /** Широта */
  lat?: InputMaybe<Scalars['Float']['input']>;
  /** Фамилия */
  lname?: InputMaybe<Scalars['String']['input']>;
  /** Долгота */
  lng?: InputMaybe<Scalars['Float']['input']>;
  /** Паспортные данные */
  passport?: InputMaybe<Scalars['String']['input']>;
  /** Телефон */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Почтовый индекс */
  postcode?: InputMaybe<Scalars['String']['input']>;
  /** Улица */
  street?: InputMaybe<Scalars['String']['input']>;
};

/** Информация редактирования адреса сущности */
export type AddressUpdatePayload = {
  __typename?: 'AddressUpdatePayload';
  address?: Maybe<Address>;
  errors?: Maybe<Array<AddressUpdateProblem>>;
  /** Id категории */
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type AddressUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type AddressesQueryFilter = {
  /** Id города */
  cityId?: InputMaybe<Scalars['Int']['input']>;
  /** Код страны */
  countryCode?: InputMaybe<CountryEnum>;
  /** Id адреса */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Только данные о пользователе */
  isOnlyUserInfo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Улица */
  street?: InputMaybe<Scalars['String']['input']>;
};

export type AddressesQueryPayload = {
  __typename?: 'AddressesQueryPayload';
  addresses?: Maybe<AddressPagination>;
  errors?: Maybe<Array<AddressesQueryProblem>>;
};

export type AddressesQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Объект уже существует */
export type AlreadyExistsError = Problem & {
  __typename?: 'AlreadyExistsError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  inputName?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** Приложение */
export type Application = {
  __typename?: 'Application';
  /** ConstId */
  constId: ApplicationEnum;
  /** Название */
  title: Scalars['String']['output'];
};

/**
 * Коды приложений.
 *                     <br>ANGIOLIVE - Angiolive,
 *                     <br>VERTERA - Vertera,
 *                     <br>MIRACLE - Miracle,
 *                     <br>SENSATION - Sensation,
 *                     <br>THALASSOLAB - Thalassolab,
 *                     <br>VERTERA_GEL - Vertera Gel,
 *                     <br>VERTERA_PARTNER - Vertera (Партнеры),
 *                     <br>DETOX_JUICE - Detox-juice,
 *                     <br>VERTERA_NEW - Vertera New,
 *                     <br>VERTERA_MOBILE - Vertera New мобильное приложения,
 *                     <br>VERTERA_TURKIE - Vertera Turkey,
 *                     <br>ANGIOLIVE_NEW_2 - Angiolive New 2,
 *                     <br>VERTERA_AZERBAIJAN - Vertera Azerbaijan,
 *                     <br>VERTERA_SERBIA - Vertera Serbia,
 *                     <br>VERTERA_MAKEDONIA - Vertera Macedonia,
 *                     <br>ANGIOLIVE_B - Angiolive B,
 *                     <br>HYDRATE_COLLAGEN - Hydrate collagen,
 *                     <br>VERTERA_EUROPE - Vertera Europe,
 *                     <br>VERTERA_CLUB - Vertera Club,
 *                     <br>BIOINNOVATION_SPACE - Пространство биоинноваций,
 *                     <br>VERTERA_MEXICO - Vertera Mexico,
 *                     <br>ANGIOLIVE_MEXICO - Angiolive Mexico,
 *                     <br>TEST_APPLICATION - Тестовое приложение,
 *                     <br>OS3_ADMIN - OS3_ADMIN,
 *                     <br>OS3_PARTNER - OS3_PARTNER,
 *                     <br>ECA - ECA,
 *                     <br>UNIVERSITY - Университет,
 *                     <br>VERTERA_KAZAKHSTAN - Vertera Kazakhstan,
 *                     <br>VERTERA_JUICE - Vertera Juice
 */
export enum ApplicationEnum {
  Angiolive = 'ANGIOLIVE',
  AngioliveB = 'ANGIOLIVE_B',
  AngioliveMexico = 'ANGIOLIVE_MEXICO',
  AngioliveNew_2 = 'ANGIOLIVE_NEW_2',
  BioinnovationSpace = 'BIOINNOVATION_SPACE',
  Complex = 'COMPLEX',
  Eca = 'ECA',
  HydrateCollagen = 'HYDRATE_COLLAGEN',
  MiningClub = 'MINING_CLUB',
  Miracle = 'MIRACLE',
  Os3Admin = 'OS3_ADMIN',
  Os3Partner = 'OS3_PARTNER',
  Sensation = 'SENSATION',
  SensationMexico = 'SENSATION_MEXICO',
  SmartKid = 'SMART_KID',
  TestApplication = 'TEST_APPLICATION',
  Thalassolab = 'THALASSOLAB',
  University = 'UNIVERSITY',
  VerteraAzerbaijan = 'VERTERA_AZERBAIJAN',
  VerteraClub = 'VERTERA_CLUB',
  VerteraEurope = 'VERTERA_EUROPE',
  VerteraGel = 'VERTERA_GEL',
  VerteraGeorgia = 'VERTERA_GEORGIA',
  VerteraGlobal = 'VERTERA_GLOBAL',
  VerteraJuice = 'VERTERA_JUICE',
  VerteraKazakhstan = 'VERTERA_KAZAKHSTAN',
  VerteraMakedonia = 'VERTERA_MAKEDONIA',
  VerteraMexico = 'VERTERA_MEXICO',
  VerteraMobile = 'VERTERA_MOBILE',
  VerteraMoldova = 'VERTERA_MOLDOVA',
  VerteraNew = 'VERTERA_NEW',
  VerteraSerbia = 'VERTERA_SERBIA',
  VerteraTurkie = 'VERTERA_TURKIE',
  WrToken = 'WR_TOKEN'
}

/** Дополнительная информация по счету */
export type BalanceCalculatedData = {
  __typename?: 'BalanceCalculatedData';
  /** Доступная сумма */
  availableAmount?: Maybe<Scalars['Float']['output']>;
};

/** Сущность, для которой создается счет */
export enum BalanceEntityTypeEnum {
  Storage = 'STORAGE',
  User = 'USER'
}

/** Статус счета */
export enum BalanceStatusEnum {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Inactive = 'INACTIVE'
}

/** Транзакция */
export type BalanceTransaction = {
  __typename?: 'BalanceTransaction';
  /** Сумма транзакции */
  amount?: Maybe<Scalars['Float']['output']>;
  /** Баланс */
  balance?: Maybe<BalanceType>;
  /** Расчетные данные по транзакции */
  calculatedData?: Maybe<BalanceTransactionCalculatedData>;
  /** Когда добавил */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Id сущности */
  entityId?: Maybe<Scalars['Int']['output']>;
  /** Тип сущности */
  entityType?: Maybe<BalanceTransactionEntityTypeEnum>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Тип операции транзакции */
  operationTypeConst?: Maybe<BalanceTransactionOperationTypeEnum>;
  /** Партиция (год) */
  part?: Maybe<Scalars['Int']['output']>;
  /** Источник транзакции */
  sourceConst?: Maybe<BalanceTransactionSourceEnum>;
  /** Тип транзакции */
  typeConst?: Maybe<BalanceTransactionTypeEnum>;
  /** Группа типа транзакции */
  typeGroupConst?: Maybe<BalanceTransactionTypeGroup>;
};

/** Дополнительная информация по транзакции */
export type BalanceTransactionCalculatedData = {
  __typename?: 'BalanceTransactionCalculatedData';
  /** Комментарий к транзакции */
  comment?: Maybe<Scalars['String']['output']>;
};

/** Сущность, для которой создаётся транзакция */
export enum BalanceTransactionEntityTypeEnum {
  BalanceTransaction = 'BALANCE_TRANSACTION',
  BalanceTransferRequest = 'BALANCE_TRANSFER_REQUEST',
  ContractorWithdraw = 'CONTRACTOR_WITHDRAW',
  MlmUserBonus = 'MLM_USER_BONUS',
  Order = 'ORDER',
  OrderPayment = 'ORDER_PAYMENT',
  User = 'USER',
  UserMlmBinarRecalculateLog = 'USER_MLM_BINAR_RECALCULATE_LOG'
}

/** Тип операции транзакции */
export enum BalanceTransactionOperationTypeEnum {
  Minus = 'MINUS',
  Plus = 'PLUS'
}

export type BalanceTransactionPagination = {
  __typename?: 'BalanceTransactionPagination';
  /** List of items on the current page */
  data?: Maybe<Array<BalanceTransaction>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

export type BalanceTransactionPrimaryKeyFilter = {
  /** ID транзакции */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Партиция (год) транзакции */
  part?: InputMaybe<Scalars['Int']['input']>;
};

/** Источник транзакции */
export enum BalanceTransactionSourceEnum {
  Admin = 'ADMIN',
  BalanceMlmBinarRecalculation = 'BALANCE_MLM_BINAR_RECALCULATION',
  BalanceTransferRequest = 'BALANCE_TRANSFER_REQUEST',
  ContractorWithdraw = 'CONTRACTOR_WITHDRAW',
  MlmUserBonus = 'MLM_USER_BONUS',
  Order = 'ORDER'
}

/** Тип транзакции */
export enum BalanceTransactionTypeEnum {
  BalanceTransferRequest = 'BALANCE_TRANSFER_REQUEST',
  Cancel = 'CANCEL',
  Commission = 'COMMISSION',
  MlmBonusBb = 'MLM_BONUS_BB',
  MlmBonusBk = 'MLM_BONUS_BK',
  MlmBonusBn = 'MLM_BONUS_BN',
  MlmBonusBzp = 'MLM_BONUS_BZP',
  MlmBonusKbb = 'MLM_BONUS_KBB',
  MlmBonusKbbCorrection = 'MLM_BONUS_KBB_CORRECTION',
  OrderPayment = 'ORDER_PAYMENT',
  Replenishment = 'REPLENISHMENT',
  Withdraw = 'WITHDRAW'
}

/** Группа типа транзакции */
export type BalanceTransactionTypeGroup = {
  __typename?: 'BalanceTransactionTypeGroup';
  /** Код группы */
  code?: Maybe<BalanceTransactionTypeGroupEnum>;
};

/** Список групп типов транзакций */
export enum BalanceTransactionTypeGroupEnum {
  Bonuses = 'BONUSES',
  Cancel = 'CANCEL',
  Expenses = 'EXPENSES',
  Orders = 'ORDERS',
  PersonalFinance = 'PERSONAL_FINANCE',
  TransferFinance = 'TRANSFER_FINANCE'
}

/** Фильтр */
export type BalanceTransactionsQueryFilter = {
  /** Валюта */
  balanceCurrencyCode?: InputMaybe<CurrencyEnum>;
  /** ID счета */
  balanceId?: InputMaybe<Scalars['Int']['input']>;
  /** Дата создания от */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата создания до */
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** ID сущности */
  entityId?: InputMaybe<Scalars['Int']['input']>;
  /** Тип сущности */
  entityType?: InputMaybe<BalanceTransactionEntityTypeEnum>;
  /** ID mlm-периода */
  mlmPeriodId?: InputMaybe<Scalars['Int']['input']>;
  /** Тип операции */
  operationTypeConsts?: InputMaybe<Array<BalanceTransactionOperationTypeEnum>>;
  /** Первичный ключ транзакции */
  primaryKey?: InputMaybe<BalanceTransactionPrimaryKeyFilter>;
  /** Источник */
  sourceConsts?: InputMaybe<Array<BalanceTransactionSourceEnum>>;
  /** Тип */
  typeConsts?: InputMaybe<Array<BalanceTransactionTypeEnum>>;
  /** Группа типов */
  typeGroupConsts?: InputMaybe<Array<BalanceTransactionTypeGroupEnum>>;
};

export type BalanceTransactionsQueryPayload = {
  __typename?: 'BalanceTransactionsQueryPayload';
  balanceTransactions?: Maybe<BalanceTransactionPagination>;
  errors?: Maybe<Array<BalanceTransactionsQueryProblem>>;
};

export type BalanceTransactionsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Заявка на перемещение средств */
export type BalanceTransferRequest = {
  __typename?: 'BalanceTransferRequest';
  /** Сумма */
  amount?: Maybe<Scalars['Float']['output']>;
  /** Баланс, с которого перемещаются средства */
  balanceFrom?: Maybe<BalanceType>;
  /** Баланс, на который перемещаются средства */
  balanceTo?: Maybe<BalanceType>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Статус */
  statusConst?: Maybe<BalanceTransferRequestStatusEnum>;
  /** Тип */
  typeConst?: Maybe<BalanceTransferRequestTypeEnum>;
};

export type BalanceTransferRequestCreateInput = {
  /** Сумма */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** Баланс, с которого перемещаются средства */
  balanceIdFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Баланс, на который перемещаются средства */
  balanceIdTo?: InputMaybe<Scalars['Int']['input']>;
  /** Тип */
  typeConst?: InputMaybe<BalanceTransferRequestTypeEnum>;
};

/** Информация создания заявки на перемещение средств */
export type BalanceTransferRequestCreatePayload = {
  __typename?: 'BalanceTransferRequestCreatePayload';
  balanceTransferRequest?: Maybe<BalanceTransferRequest>;
  errors?: Maybe<Array<BalanceTransferRequestCreateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type BalanceTransferRequestCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Операции над заявками на перемещение средств */
export type BalanceTransferRequestPayload = {
  __typename?: 'BalanceTransferRequestPayload';
  Create?: Maybe<BalanceTransferRequestCreatePayload>;
};


/** Операции над заявками на перемещение средств */
export type BalanceTransferRequestPayloadCreateArgs = {
  BalanceTransferRequestCreateInput?: InputMaybe<BalanceTransferRequestCreateInput>;
};

/** Статус заявки на перемещение средств */
export enum BalanceTransferRequestStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT'
}

/** Тип заявки на перемещение средств */
export enum BalanceTransferRequestTypeEnum {
  AutoConvert = 'AUTO_CONVERT',
  CompanyToStorage = 'COMPANY_TO_STORAGE',
  CompanyToUser = 'COMPANY_TO_USER',
  ManualConvert = 'MANUAL_CONVERT',
  StorageToStorage = 'STORAGE_TO_STORAGE',
  StorageToUser = 'STORAGE_TO_USER',
  UserToAnotherUser = 'USER_TO_ANOTHER_USER',
  UserToStorage = 'USER_TO_STORAGE',
  UserToUser = 'USER_TO_USER'
}

/** Счет */
export type BalanceType = {
  __typename?: 'BalanceType';
  /** Баланс счета */
  amount?: Maybe<Scalars['Float']['output']>;
  /** Расчетные данные по счету */
  calculatedData?: Maybe<BalanceCalculatedData>;
  /** Валюта */
  currency?: Maybe<Currency>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Базовый счет */
  isMain?: Maybe<Scalars['Boolean']['output']>;
  /** Баланс резерва счета */
  reserveAmount?: Maybe<Scalars['Float']['output']>;
  /** Статус */
  statusConst?: Maybe<BalanceStatusEnum>;
  /** Название счета */
  title?: Maybe<Scalars['String']['output']>;
  /** Статус */
  typeConst?: Maybe<BalanceTypeEnum>;
};

/** Тип счета */
export enum BalanceTypeEnum {
  Binary = 'BINARY',
  Bonus = 'BONUS',
  Credit = 'CREDIT',
  OrderPayment = 'ORDER_PAYMENT',
  Settlement = 'SETTLEMENT'
}

export type BalanceTypePagination = {
  __typename?: 'BalanceTypePagination';
  /** List of items on the current page */
  data?: Maybe<Array<BalanceType>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр */
export type BalanceUserQueryFilter = {
  /** ID */
  id: Scalars['Int']['input'];
};

export type BalanceUserQueryPayload = {
  __typename?: 'BalanceUserQueryPayload';
  balance?: Maybe<BalanceType>;
  errors?: Maybe<Array<BalanceUserQueryProblem>>;
};

export type BalanceUserQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type BalancesUserQueryFilter = {
  /** Валюты баланса */
  currencyCodes?: InputMaybe<Array<InputMaybe<CurrencyEnum>>>;
  /** Типы баланса */
  typeConsts?: InputMaybe<Array<InputMaybe<BalanceTypeEnum>>>;
};

export type BalancesUserQueryPayload = {
  __typename?: 'BalancesUserQueryPayload';
  balances?: Maybe<BalanceTypePagination>;
  errors?: Maybe<Array<BalancesUserQueryProblem>>;
};

export type BalancesUserQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Позиция корзины */
export type Cart = {
  __typename?: 'Cart';
  /** Количество */
  amount: Scalars['Int']['output'];
  /** Автор */
  author?: Maybe<User>;
  /** Город */
  city?: Maybe<City>;
  /** Добавлено */
  createdAt: Scalars['DateTime']['output'];
  /** Валюта */
  currency?: Maybe<Currency>;
  /** ID */
  id: Scalars['Int']['output'];
  /** Доступен к заказу */
  isAvailableToOrder?: Maybe<Scalars['Boolean']['output']>;
  /** Позиция корзины выбранная пользователем */
  isSelected: Scalars['Boolean']['output'];
  /** Товар */
  product?: Maybe<Product>;
  /** Склад */
  storage?: Maybe<Storage>;
  /** Обновлено */
  updatedAt: Scalars['DateTime']['output'];
  /** Покупатель */
  user?: Maybe<User>;
};

export type CartCreateInput = {
  /** Количество */
  amount: Scalars['Int']['input'];
  /** Slug товара */
  productSlug: Scalars['String']['input'];
};

export type CartCreatePayload = {
  __typename?: 'CartCreatePayload';
  cart?: Maybe<Cart>;
  errors?: Maybe<Array<Maybe<CartCreateProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type CartCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type CartDecPayload = {
  __typename?: 'CartDecPayload';
  cart?: Maybe<Cart>;
  errors?: Maybe<Array<Maybe<CartDecProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type CartDecProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type CartDeletePayload = {
  __typename?: 'CartDeletePayload';
  errors?: Maybe<Array<Maybe<CartDeleteProblem>>>;
  ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type CartDeleteProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type CartDeliveryTypesQueryPayload = {
  __typename?: 'CartDeliveryTypesQueryPayload';
  errors?: Maybe<Array<CartDeliveryTypesQueryProblem>>;
  storageDeliveries?: Maybe<Array<Maybe<StorageDelivery>>>;
};

export type CartDeliveryTypesQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type CartGroupPayload = {
  __typename?: 'CartGroupPayload';
  /** Типы доставки для корзины пользователя */
  CartDeliveryTypes?: Maybe<CartDeliveryTypesQueryPayload>;
  /** Корзина пользователя */
  Carts?: Maybe<CartsQueryPayload>;
};


export type CartGroupPayloadCartDeliveryTypesArgs = {
  userEnvironmentUuid: Scalars['UUID']['input'];
};


export type CartGroupPayloadCartsArgs = {
  commonFilter?: InputMaybe<CartsQueryFilter>;
  infoFilter?: InputMaybe<CartsInfoQueryFilter>;
  userEnvironmentUuid: Scalars['UUID']['input'];
};

export type CartIncPayload = {
  __typename?: 'CartIncPayload';
  cart?: Maybe<Cart>;
  errors?: Maybe<Array<Maybe<CartIncProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type CartIncProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Информация по корзине */
export type CartInfo = {
  __typename?: 'CartInfo';
  /** Количество товаров в корзине */
  productQuantity: Scalars['Int']['output'];
  /** Количество товаров в корзине подарков */
  rewardProductQuantity: Scalars['Int']['output'];
  /** Стоимость доставки товаров из корзины и корзины подарков */
  totalDeliveryPrice?: Maybe<Scalars['Float']['output']>;
  /** Итоговая скидка корзины */
  totalDiscounts?: Maybe<Array<Price>>;
  /** Итоговая стоимость корзины в гостевой ценовой колонке */
  totalGuestPrices?: Maybe<Array<Price>>;
  /** Итоговая стоимость корзины */
  totalPrices?: Maybe<Array<Price>>;
  /** Общее количество товаров в корзине и корзине подарков */
  totalQuantity: Scalars['Int']['output'];
  /** Итоговый вес корзины */
  totalWeight: Scalars['Int']['output'];
};

export type CartMultipleUpdateInput = {
  /** Позиция корзины выбранная пользователем */
  isSelected?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CartMultipleUpdatePayload = {
  __typename?: 'CartMultipleUpdatePayload';
  errors?: Maybe<Array<Maybe<CartMultipleUpdateProblem>>>;
  ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type CartMultipleUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Операции над корзиной пользователя */
export type CartPayload = {
  __typename?: 'CartPayload';
  /** Добавить товара в корзину */
  Create?: Maybe<CartCreatePayload>;
  /** Уменьшить количество товара в корзине */
  Dec?: Maybe<CartDecPayload>;
  /** Удалить товар из корзины */
  Delete?: Maybe<CartDeletePayload>;
  /** Увеличить количество товара в корзине */
  Inc?: Maybe<CartIncPayload>;
  /** Редактировать несколько товаров в корзине */
  MultipleUpdate?: Maybe<CartMultipleUpdatePayload>;
  /** Редактировать товар в корзине */
  Update?: Maybe<CartUpdatePayload>;
};


/** Операции над корзиной пользователя */
export type CartPayloadCreateArgs = {
  CartCreateInput?: InputMaybe<CartCreateInput>;
  userEnvironmentUuid: Scalars['UUID']['input'];
};


/** Операции над корзиной пользователя */
export type CartPayloadDecArgs = {
  id: Scalars['Int']['input'];
  userEnvironmentUuid: Scalars['UUID']['input'];
};


/** Операции над корзиной пользователя */
export type CartPayloadDeleteArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  userEnvironmentUuid: Scalars['UUID']['input'];
};


/** Операции над корзиной пользователя */
export type CartPayloadIncArgs = {
  id: Scalars['Int']['input'];
  userEnvironmentUuid: Scalars['UUID']['input'];
};


/** Операции над корзиной пользователя */
export type CartPayloadMultipleUpdateArgs = {
  CartMultipleUpdateInput?: InputMaybe<CartMultipleUpdateInput>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  userEnvironmentUuid: Scalars['UUID']['input'];
};


/** Операции над корзиной пользователя */
export type CartPayloadUpdateArgs = {
  CartUpdateInput?: InputMaybe<CartUpdateInput>;
  id: Scalars['Int']['input'];
  userEnvironmentUuid: Scalars['UUID']['input'];
};

/** Для доступа к награде необходимо иметь в корзине товаров на указанное количество баллов */
export type CartRewardAssembledGroupCartPointMoreThanConditionDataType = {
  __typename?: 'CartRewardAssembledGroupCartPointMoreThanConditionDataType';
  /** Код валюты */
  currencyCode: CurrencyEnum;
  /** Текущее количество */
  currentPointAmount: Scalars['Float']['output'];
  /** Описание */
  description: Scalars['String']['output'];
  /** Выполнено */
  isCompleted: Scalars['Boolean']['output'];
  /** Необходимое количество */
  requiredPointAmount: Scalars['Float']['output'];
};

/** Условия доступа к подаркам */
export type CartRewardAssembledGroupConditionsDataType = {
  __typename?: 'CartRewardAssembledGroupConditionsDataType';
  /** Для доступа к награде необходимо иметь в корзине товаров на указанное количество баллов */
  cartPointMoreThan?: Maybe<CartRewardAssembledGroupCartPointMoreThanConditionDataType>;
  /** Для доступа к награде необходимо иметь указанное количество оплаченных заказов */
  havePaidOrders?: Maybe<CartRewardAssembledGroupHavePaidOrdersConditionDataType>;
};

/** Данные о счетчике подарков (собираемые группы подарков) */
export type CartRewardAssembledGroupCountersDataType = {
  __typename?: 'CartRewardAssembledGroupCountersDataType';
  /** Подарки на сумму товаров из корзины */
  nPlusMCounter?: Maybe<CartRewardAssembledGroupNPlusMCounterDataType>;
  /** Подарки с жесткой привязкой товаров в корзине к подаркам */
  nPlusMHardCounter?: Maybe<CartRewardAssembledGroupNPlusMHardCounterDataType>;
  /** Подарки на сумму баллов из набранных в корзине товаров */
  priceToPriceCounter?: Maybe<CartRewardAssembledGroupPriceToPriceCounterDataType>;
};

/** Даные о позициях корзины подарков (собираемые группы подарков) */
export type CartRewardAssembledGroupDataType = {
  __typename?: 'CartRewardAssembledGroupDataType';
  /** Купон */
  coupon: CartRewardCouponDataType;
  /** Общие условия доступа к подаркам */
  generalConditions?: Maybe<CartRewardAssembledGroupConditionsDataType>;
  /** Собираемые группы подарков */
  groups?: Maybe<Array<CartRewardAssembledGroupGroupDataType>>;
};

/** Собираемая группа подарков (собираемые группы подарков) */
export type CartRewardAssembledGroupGroupDataType = {
  __typename?: 'CartRewardAssembledGroupGroupDataType';
  /** Локальные условия доступа к подаркам */
  conditions?: Maybe<CartRewardAssembledGroupConditionsDataType>;
  /** Данные о счетчике подарков */
  counter: CartRewardAssembledGroupCountersDataType;
  /** Индекс группы */
  groupIndex: Scalars['Int']['output'];
  /** Данные о доступном количестве подарков */
  rewards?: Maybe<Array<CartRewardAssembledGroupRewardDataType>>;
};

/** Для доступа к награде необходимо иметь указанное количество оплаченных заказов */
export type CartRewardAssembledGroupHavePaidOrdersConditionDataType = {
  __typename?: 'CartRewardAssembledGroupHavePaidOrdersConditionDataType';
  /** Текущее количество */
  currentOrderPaidCount: Scalars['Int']['output'];
  /** Описание */
  description: Scalars['String']['output'];
  /** Выполнено */
  isCompleted: Scalars['Boolean']['output'];
  /** Необходимое количество */
  requiredOrderPaidCount: Scalars['Int']['output'];
};

/** Подарки на сумму товаров из корзины (собираемые группы подарков) */
export type CartRewardAssembledGroupNPlusMCounterDataType = {
  __typename?: 'CartRewardAssembledGroupNPlusMCounterDataType';
  /** Тип награды */
  rewardType: Scalars['String']['output'];
};

/** Подарки с жесткой привязкой товаров в корзине к подаркам (собираемые группы подарков) */
export type CartRewardAssembledGroupNPlusMHardCounterDataType = {
  __typename?: 'CartRewardAssembledGroupNPlusMHardCounterDataType';
  /** Тип награды */
  rewardType: Scalars['String']['output'];
};

/** Подарки на сумму баллов из набранных в корзине товаров (собираемые группы подарков) */
export type CartRewardAssembledGroupPriceToPriceCounterDataType = {
  __typename?: 'CartRewardAssembledGroupPriceToPriceCounterDataType';
  /** Код валюты */
  currencyCode: CurrencyEnum;
};

/** Данные о доступном количестве подарков (собираемые группы подарков) */
export type CartRewardAssembledGroupRewardDataType = {
  __typename?: 'CartRewardAssembledGroupRewardDataType';
  /** Доступное количество */
  availableAmount: Scalars['Int']['output'];
  /** Текущее количество */
  currentAmount: Scalars['Int']['output'];
  /** Товар */
  product: Product;
};

/** Купон */
export type CartRewardCouponDataType = {
  __typename?: 'CartRewardCouponDataType';
  /** Описание */
  description: Scalars['String']['output'];
  /** ID */
  id: Scalars['Int']['output'];
  /** Выбран ли купон для пользователя */
  isSelected: Scalars['Boolean']['output'];
  /** Приоритет */
  priority: Scalars['Int']['output'];
  /** Краткое описание */
  shortDescription: Scalars['String']['output'];
  /** Дата начала периода действия */
  startAt: Scalars['DateTime']['output'];
  /** Дата окончания периода действия */
  stopAt?: Maybe<Scalars['DateTime']['output']>;
  /** Название */
  title: Scalars['String']['output'];
};

export type CartRewardGroupPayload = {
  __typename?: 'CartRewardGroupPayload';
  /** Корзина подарков пользователя */
  CartRewards?: Maybe<CartRewardsQueryPayload>;
};


export type CartRewardGroupPayloadCartRewardsArgs = {
  commonFilter?: InputMaybe<CartRewardsQueryCommonFilter>;
  userEnvironmentUuid: Scalars['UUID']['input'];
};

/** Операции над корзиной подарков пользователя */
export type CartRewardPayload = {
  __typename?: 'CartRewardPayload';
  /** Редактировать товар в корзине подарков */
  Update?: Maybe<CartRewardUpdatePayload>;
};


/** Операции над корзиной подарков пользователя */
export type CartRewardPayloadUpdateArgs = {
  CartRewardUpdateInput?: InputMaybe<CartRewardUpdateInput>;
  userEnvironmentUuid: Scalars['UUID']['input'];
};

export type CartRewardUpdateInput = {
  /** Количество */
  amount: Scalars['Int']['input'];
  /** ID Купона */
  couponId: Scalars['Int']['input'];
  /** Индекс группы */
  groupIndex: Scalars['Int']['input'];
  /** Slug товара */
  productSlug: Scalars['String']['input'];
};

export type CartRewardUpdatePayload = {
  __typename?: 'CartRewardUpdatePayload';
  couponId?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<Maybe<CartRewardUpdateProblem>>>;
  groupIndex?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  rewards?: Maybe<Array<CartRewardAssembledGroupRewardDataType>>;
};

export type CartRewardUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Общий фильтр корзины подарков */
export type CartRewardsQueryCommonFilter = {
  /** ID купона */
  couponId?: InputMaybe<Scalars['Int']['input']>;
  /** Индекс группы */
  groupIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type CartRewardsQueryPayload = {
  __typename?: 'CartRewardsQueryPayload';
  cartRewardAssembledGroups?: Maybe<Array<CartRewardAssembledGroupDataType>>;
  errors?: Maybe<Array<CartRewardsQueryProblem>>;
};

export type CartRewardsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type CartUpdateInput = {
  /** Количество */
  amount?: InputMaybe<Scalars['Int']['input']>;
};

export type CartUpdatePayload = {
  __typename?: 'CartUpdatePayload';
  cart?: Maybe<Cart>;
  errors?: Maybe<Array<Maybe<CartUpdateProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type CartUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр информации по корзине */
export type CartsInfoQueryFilter = {
  /** Профиль доставки */
  storageDeliveryId?: InputMaybe<Scalars['Int']['input']>;
};

/** Общий фильтр корзины */
export type CartsQueryFilter = {
  /** ID позиции товара в корзине */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CartsQueryPayload = {
  __typename?: 'CartsQueryPayload';
  cartInfo?: Maybe<CartInfo>;
  carts?: Maybe<Array<Maybe<Cart>>>;
  errors?: Maybe<Array<CartsQueryProblem>>;
};

export type CartsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type CategoriesQueryFilter = {
  /** Код страны */
  countryCode?: InputMaybe<CountryEnum>;
  /** Id категории */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Id родительской категории */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  /** Slug категории */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** Название категории */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Тип категории */
  typeConst: CategoryTypeEnum;
};

export type CategoriesQueryPayload = {
  __typename?: 'CategoriesQueryPayload';
  categories?: Maybe<CategoryPagination>;
  errors?: Maybe<Array<CategoriesQueryProblem>>;
};

export type CategoriesQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Категория */
export type Category = {
  __typename?: 'Category';
  /** Список стран */
  countries?: Maybe<Array<Country>>;
  /** ID */
  id: Scalars['Int']['output'];
  /** Изображение категории на языке пользователя */
  image?: Maybe<CategoryImage>;
  /** Родительская категория */
  parent?: Maybe<Category>;
  /** Slug категории */
  slug?: Maybe<Scalars['String']['output']>;
  /** Название */
  title: Scalars['String']['output'];
  /** Название для мобильных телефонов */
  titleMobile: Scalars['String']['output'];
};

/** Изображение категории */
export type CategoryImage = {
  __typename?: 'CategoryImage';
  /** Id файла */
  id?: Maybe<Scalars['Int']['output']>;
  /** Изображение */
  image?: Maybe<Image>;
  /** Название файла */
  title?: Maybe<Scalars['String']['output']>;
};

export type CategoryPagination = {
  __typename?: 'CategoryPagination';
  /** List of items on the current page */
  data?: Maybe<Array<Category>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр */
export type CategoryQueryFilter = {
  /** Id категории */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Slug категории */
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryQueryPayload = {
  __typename?: 'CategoryQueryPayload';
  category?: Maybe<Category>;
  errors?: Maybe<Array<CategoryQueryProblem>>;
};

export type CategoryQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Тип категории */
export enum CategoryTypeEnum {
  Product = 'PRODUCT'
}

/** Фильтр */
export type CitiesQueryFilter = {
  /** Страна */
  countryCode?: InputMaybe<CountryEnum>;
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Столица */
  isCapital?: InputMaybe<Scalars['Boolean']['input']>;
  /** Название */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CitiesQueryPayload = {
  __typename?: 'CitiesQueryPayload';
  cities?: Maybe<CityPagination>;
  errors?: Maybe<Array<CitiesQueryProblem>>;
};

export type CitiesQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Город */
export type City = {
  __typename?: 'City';
  /** Страна */
  country?: Maybe<Country>;
  /** ID */
  id: Scalars['Int']['output'];
  /** Столица */
  isCapital?: Maybe<Scalars['Boolean']['output']>;
  /** Широта */
  lat?: Maybe<Scalars['Float']['output']>;
  /** Долгота */
  lng?: Maybe<Scalars['Float']['output']>;
  /** Название */
  title: Scalars['String']['output'];
};

export type CityPagination = {
  __typename?: 'CityPagination';
  /** List of items on the current page */
  data?: Maybe<Array<City>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр */
export type CityQueryFilter = {
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CityQueryPayload = {
  __typename?: 'CityQueryPayload';
  city?: Maybe<City>;
};

/** Комментарий */
export type Comment = {
  __typename?: 'Comment';
  /** Когда добавил */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Описание */
  description?: Maybe<Scalars['String']['output']>;
  /** Id сущности */
  entityId?: Maybe<Scalars['Int']['output']>;
  /** Название сущности */
  entityType?: Maybe<CommentEntityTypeEnum>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Источник комментария */
  sourceConst?: Maybe<CommentSourceEnum>;
};

/** Сущность в мутации создания комментария */
export enum CommentCreateEntityTypeEnum {
  ContractorWithdraw = 'CONTRACTOR_WITHDRAW',
  Order = 'ORDER'
}

export type CommentCreateInput = {
  /** Описание */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ID Сущности */
  entityId?: InputMaybe<Scalars['Int']['input']>;
  /** Сущность */
  entityType?: InputMaybe<CommentCreateEntityTypeEnum>;
};

export type CommentCreatePayload = {
  __typename?: 'CommentCreatePayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<Maybe<CommentCreateProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type CommentCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Сущность комментария */
export enum CommentEntityTypeEnum {
  ContractorWithdraw = 'CONTRACTOR_WITHDRAW',
  Order = 'ORDER',
  Review = 'REVIEW'
}

/** Сущности комментария в фильтре запроса списка */
export enum CommentEntityTypeFilterEnum {
  ContractorWithdraw = 'CONTRACTOR_WITHDRAW',
  Order = 'ORDER'
}

export type CommentPagination = {
  __typename?: 'CommentPagination';
  /** List of items on the current page */
  data?: Maybe<Array<Comment>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над комментариями */
export type CommentPayload = {
  __typename?: 'CommentPayload';
  Create?: Maybe<CommentCreatePayload>;
};


/** Операции над комментариями */
export type CommentPayloadCreateArgs = {
  CommentCreateInput?: InputMaybe<CommentCreateInput>;
};

/** Источник от которого комментарий */
export enum CommentSourceEnum {
  Admin = 'ADMIN',
  Buyer = 'BUYER'
}

/** Общий фильтр комментариев */
export type CommentsQueryFilter = {
  /** ID Сущности */
  entityId: Scalars['Int']['input'];
  /** Сущность */
  entityType: CommentEntityTypeFilterEnum;
};

export type CommentsQueryPayload = {
  __typename?: 'CommentsQueryPayload';
  comments?: Maybe<CommentPagination>;
  errors?: Maybe<Array<CommentsQueryProblem>>;
};

export type CommentsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type CommonGroupPayload = {
  __typename?: 'CommonGroupPayload';
  /** Список переводов */
  Translations?: Maybe<TranslationsQueryPayload>;
};


export type CommonGroupPayloadTranslationsArgs = {
  filter?: InputMaybe<TranslationsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type ContentGroupPayload = {
  __typename?: 'ContentGroupPayload';
  /** Материал */
  Material?: Maybe<MaterialQueryPayload>;
  /** Список материалов */
  Materials?: Maybe<MaterialsQueryPayload>;
  /** Вебинар */
  Webinar?: Maybe<WebinarQueryPayload>;
  /** Список вебинаров */
  Webinars?: Maybe<WebinarsQueryPayload>;
};


export type ContentGroupPayloadMaterialArgs = {
  filter: MaterialQueryFilter;
};


export type ContentGroupPayloadMaterialsArgs = {
  filter?: InputMaybe<MaterialsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  sort?: InputMaybe<MaterialsSortEnum>;
};


export type ContentGroupPayloadWebinarArgs = {
  filter?: InputMaybe<WebinarQueryFilter>;
};


export type ContentGroupPayloadWebinarsArgs = {
  filter?: InputMaybe<WebinarsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

/** Континеты */
export enum ContinentEnum {
  Africa = 'AFRICA',
  America = 'AMERICA',
  Antarctica = 'ANTARCTICA',
  Asia = 'ASIA',
  Europe = 'EUROPE',
  Oceania = 'OCEANIA'
}

/** Контрагент */
export type Contractor = {
  __typename?: 'Contractor';
  /** Наименование организации */
  companyName?: Maybe<Scalars['String']['output']>;
  /** Дата заключения контракта */
  contractCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** КПП */
  cpp?: Maybe<Scalars['String']['output']>;
  /** Дата выдачи свидетельства / внесения записи в ЕГРИП в формате Y-m-d */
  egripDate?: Maybe<Scalars['String']['output']>;
  /** Кем выдано свидетельство ЕГРИП */
  egripIssuer?: Maybe<Scalars['String']['output']>;
  /** Тип занятости */
  employmentTypeConst?: Maybe<ContractorEmploymentTypeEnum>;
  /** Имя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** ИНН */
  inn?: Maybe<Scalars['String']['output']>;
  /** Фамилия */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Главный адрес */
  mainAddress?: Maybe<Address>;
  /** Отчество */
  middleName?: Maybe<Scalars['String']['output']>;
  /** ОГРН */
  ogrn?: Maybe<Scalars['String']['output']>;
  /** ОГРНИП */
  ogrnip?: Maybe<Scalars['String']['output']>;
  /** Кем выдано свидетельство ОГРНИП */
  ogrnipIssuer?: Maybe<Scalars['String']['output']>;
  /** Дата выдачи паспорта */
  passportDate?: Maybe<Scalars['String']['output']>;
  /** Код подразделения паспорта */
  passportDivisionCode?: Maybe<Scalars['String']['output']>;
  /** Кем выдан паспорт */
  passportIssuer?: Maybe<Scalars['String']['output']>;
  /** Серия и номер паспорта */
  passportSeriesAndNumber?: Maybe<Scalars['String']['output']>;
  /** Дата постановки на учет в качестве самозанятого */
  registrationDate?: Maybe<Scalars['String']['output']>;
  /** Серия и номер свидетельства о регистрации в качестве ИП */
  seriesAndNumber?: Maybe<Scalars['String']['output']>;
  /** Правовая основа */
  userLegalBasisForContract?: Maybe<Scalars['String']['output']>;
  /** Pk пользователя */
  userPk?: Maybe<Scalars['Int']['output']>;
};

export type ContractorCreateInput = {
  /** Тип занятости */
  employmentTypeConst?: InputMaybe<ContractorEmploymentTypeEnum>;
  /** Реквизиты для ИП */
  requisitesIndividual?: InputMaybe<RequisiteCreateIndividualInput>;
  /** Реквизиты для Юридического лица */
  requisitesLegalEntity?: InputMaybe<RequisiteCreateLegalEntityInput>;
  /** Реквизиты для самозанятого */
  requisitesSelfEmployed?: InputMaybe<RequisiteCreateSelfEmployedInput>;
};

/** Информация создания контрагентов */
export type ContractorCreatePayload = {
  __typename?: 'ContractorCreatePayload';
  contractor?: Maybe<Contractor>;
  errors?: Maybe<Array<ContractorCreateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type ContractorCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Тип занятости */
export enum ContractorEmploymentTypeEnum {
  Individual = 'INDIVIDUAL',
  LegalEntity = 'LEGAL_ENTITY',
  PhysicalPerson = 'PHYSICAL_PERSON',
  SelfEmployed = 'SELF_EMPLOYED'
}

/** Файл контрагента */
export type ContractorFile = {
  __typename?: 'ContractorFile';
  /** Ссылка на файл */
  file?: Maybe<Scalars['String']['output']>;
  /** Id файла */
  id?: Maybe<Scalars['Int']['output']>;
  /** mime type файла */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** Ссылка на превью 300px */
  preview?: Maybe<Scalars['String']['output']>;
  /** Размер файла в байтах */
  size?: Maybe<Scalars['Int']['output']>;
  /** Статус файла */
  statusConst?: Maybe<ContractorFileStatusEnum>;
  /** Название файла */
  title?: Maybe<Scalars['String']['output']>;
  /** Тип файла */
  typeConst?: Maybe<ContractorFileTypeEnum>;
};

export type ContractorFilePagination = {
  __typename?: 'ContractorFilePagination';
  /** List of items on the current page */
  data?: Maybe<Array<ContractorFile>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр */
export type ContractorFileQueryFilter = {
  /** ID контрагента */
  contractorId: Scalars['Int']['input'];
  /** ID файла */
  fileId?: InputMaybe<Scalars['Int']['input']>;
};

export type ContractorFileQueryPayload = {
  __typename?: 'ContractorFileQueryPayload';
  errors?: Maybe<Array<ContractorFileQueryProblem>>;
  file?: Maybe<ContractorFile>;
};

export type ContractorFileQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Статусы файлов контрагента */
export enum ContractorFileStatusEnum {
  New = 'NEW',
  Rejected = 'REJECTED',
  Verified = 'VERIFIED'
}

/** Тип документа контрагента */
export enum ContractorFileTypeEnum {
  CompanyCharter = 'COMPANY_CHARTER',
  DirectorAppointmentOrderOrPowerOfAttorney = 'DIRECTOR_APPOINTMENT_ORDER_OR_POWER_OF_ATTORNEY',
  IndividualCertificate = 'INDIVIDUAL_CERTIFICATE',
  InnCertificate = 'INN_CERTIFICATE',
  LegalEntity = 'LEGAL_ENTITY',
  Passport = 'PASSPORT',
  SelfEmployed = 'SELF_EMPLOYED',
  WithdrawalAgreement = 'WITHDRAWAL_AGREEMENT',
  WithdrawalAgreementSigned = 'WITHDRAWAL_AGREEMENT_SIGNED'
}

export type ContractorFilesCreateInput = {
  /** Файлы документа */
  files?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>;
  /** Тип документа */
  typeConst?: InputMaybe<ContractorFileTypeEnum>;
};

/** Информация добавления файлов контрагента */
export type ContractorFilesCreatePayload = {
  __typename?: 'ContractorFilesCreatePayload';
  contractor?: Maybe<Contractor>;
  contractorId?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<ContractorFilesCreateProblem>>;
  fileIds?: Maybe<Array<Scalars['Int']['output']>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type ContractorFilesCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Информация удаления файлов контрагента */
export type ContractorFilesDeletePayload = {
  __typename?: 'ContractorFilesDeletePayload';
  /** ID контрагента */
  contractorId?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<ContractorFilesDeleteProblem>>;
  /** Id файлов контрагента */
  fileIds?: Maybe<Array<Scalars['Int']['output']>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type ContractorFilesDeleteProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Операции над файлами контрагента */
export type ContractorFilesPayload = {
  __typename?: 'ContractorFilesPayload';
  Create?: Maybe<ContractorFilesCreatePayload>;
  /** Удаление файлов контрагента */
  Delete?: Maybe<ContractorFilesDeletePayload>;
};


/** Операции над файлами контрагента */
export type ContractorFilesPayloadCreateArgs = {
  ContractorFilesCreateInput?: InputMaybe<ContractorFilesCreateInput>;
  contractorId: Scalars['Int']['input'];
};


/** Операции над файлами контрагента */
export type ContractorFilesPayloadDeleteArgs = {
  contractorId: Scalars['Int']['input'];
  fileIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Фильтр */
export type ContractorFilesQueryFilter = {
  /** ID контрагента */
  contractorId: Scalars['Int']['input'];
  /** IDs файлов */
  fileIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Статусы файлов */
  statusConsts?: InputMaybe<Array<ContractorFileStatusEnum>>;
  /** Типы файлов */
  typeConsts?: InputMaybe<Array<ContractorFileTypeEnum>>;
  /** ID договора */
  userContractId?: InputMaybe<Scalars['Int']['input']>;
};

export type ContractorFilesQueryPayload = {
  __typename?: 'ContractorFilesQueryPayload';
  errors?: Maybe<Array<ContractorFilesQueryProblem>>;
  files?: Maybe<ContractorFilePagination>;
};

export type ContractorFilesQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type ContractorPagination = {
  __typename?: 'ContractorPagination';
  /** List of items on the current page */
  data?: Maybe<Array<Contractor>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над контрагентами */
export type ContractorPayload = {
  __typename?: 'ContractorPayload';
  Create?: Maybe<ContractorCreatePayload>;
  Update?: Maybe<ContractorUpdatePayload>;
};


/** Операции над контрагентами */
export type ContractorPayloadCreateArgs = {
  ContractorCreateInput?: InputMaybe<ContractorCreateInput>;
};


/** Операции над контрагентами */
export type ContractorPayloadUpdateArgs = {
  ContractorUpdateInput?: InputMaybe<ContractorUpdateInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** Фильтр */
export type ContractorQueryFilter = {
  /** Типы занятости */
  employmentTypeConsts?: InputMaybe<Array<ContractorEmploymentTypeEnum>>;
  /** ID */
  id: Scalars['Int']['input'];
};

export type ContractorQueryPayload = {
  __typename?: 'ContractorQueryPayload';
  contractor?: Maybe<Contractor>;
  errors?: Maybe<Array<ContractorQueryProblem>>;
};

export type ContractorQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Статус контрагента */
export enum ContractorStatusEnum {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Draft = 'DRAFT'
}

export type ContractorUpdateInput = {
  /** Реквизиты для ИП */
  requisitesIndividual?: InputMaybe<RequisiteUpdateIndividualInput>;
  /** Реквизиты для Юридического лица */
  requisitesLegalEntity?: InputMaybe<RequisiteUpdateLegalEntityInput>;
  /** Реквизиты для самозанятого */
  requisitesSelfEmployed?: InputMaybe<RequisiteUpdateSelfEmployedInput>;
};

/** Информация изменения контрагентов */
export type ContractorUpdatePayload = {
  __typename?: 'ContractorUpdatePayload';
  contractor?: Maybe<Contractor>;
  errors?: Maybe<Array<ContractorUpdateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type ContractorUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Кошелек контрагента */
export type ContractorWallet = {
  __typename?: 'ContractorWallet';
  /** Верхний лимит на вывод */
  higherLimit?: Maybe<Scalars['Float']['output']>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Подтвержден кошелек */
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  /** Основной кошелек */
  isMain?: Maybe<Scalars['Boolean']['output']>;
  /** Нижний лимит на вывод */
  lowerLimit?: Maybe<Scalars['Float']['output']>;
  /** Тип */
  paymentTypeConst?: Maybe<ContractorWalletPaymentTypeEnum>;
  /** Поля кошелька */
  requisites?: Maybe<ContractorWalletRequisitesType>;
};

/** Реквизиты банковского счета */
export type ContractorWalletBankAccountRequisitesInput = {
  /** БИК */
  bankIdentificationCode?: InputMaybe<Scalars['String']['input']>;
  /** Название банка */
  bankName?: InputMaybe<Scalars['String']['input']>;
  /** Расчетный счет */
  checkingAccount?: InputMaybe<Scalars['String']['input']>;
  /** Корреспондентский счет */
  correspondentAccount?: InputMaybe<Scalars['String']['input']>;
};

/** Банковский счет */
export type ContractorWalletBankAccountRequisitesType = {
  __typename?: 'ContractorWalletBankAccountRequisitesType';
  /** БИК */
  bankIdentificationCode?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bankName?: Maybe<Scalars['String']['output']>;
  /** Расчетный счет */
  checkingAccount?: Maybe<Scalars['String']['output']>;
  /** Корреспондентский счет */
  correspondentAccount?: Maybe<Scalars['String']['output']>;
};

/** Реквизиты банковской карты */
export type ContractorWalletBankCardRequisitesInput = {
  /** Владелец кошелька */
  accountOwner?: InputMaybe<Scalars['String']['input']>;
  /** Название банка */
  bankName?: InputMaybe<Scalars['String']['input']>;
  /** Страна */
  countryCode?: InputMaybe<CountryEnum>;
  /** Валюта */
  currencyCode?: InputMaybe<CurrencyEnum>;
  /** Номер */
  number?: InputMaybe<Scalars['String']['input']>;
  /** Окончание действия */
  validThru?: InputMaybe<Scalars['String']['input']>;
};

/** Банковская карта */
export type ContractorWalletBankCardRequisitesType = {
  __typename?: 'ContractorWalletBankCardRequisitesType';
  /** Владелец кошелька */
  accountOwner?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bankName?: Maybe<Scalars['String']['output']>;
  /** Страна */
  countryCode?: Maybe<CountryEnum>;
  /** Валюта */
  currencyCode?: Maybe<CurrencyEnum>;
  /** Номер */
  number?: Maybe<Scalars['String']['output']>;
  /** Окончание действия */
  validThru?: Maybe<Scalars['String']['output']>;
};

export type ContractorWalletCreateInput = {
  /** Id контрагента */
  contractorId?: InputMaybe<Scalars['Int']['input']>;
  /** Основной кошелек */
  isMain?: InputMaybe<Scalars['Boolean']['input']>;
  /** Тип */
  paymentTypeConst?: InputMaybe<ContractorWalletPaymentTypeEnum>;
  /** Поля кошелька */
  requisites?: InputMaybe<ContractorWalletRequisitesInput>;
};

/** Информация создания кошелька */
export type ContractorWalletCreatePayload = {
  __typename?: 'ContractorWalletCreatePayload';
  contractorWallet?: Maybe<ContractorWallet>;
  errors?: Maybe<Array<ContractorWalletCreateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type ContractorWalletCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Информация удаления кошелька */
export type ContractorWalletDeletePayload = {
  __typename?: 'ContractorWalletDeletePayload';
  errors?: Maybe<Array<ContractorWalletDeleteProblem>>;
  /** ID удаленного кошелька */
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type ContractorWalletDeleteProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type ContractorWalletPagination = {
  __typename?: 'ContractorWalletPagination';
  /** List of items on the current page */
  data?: Maybe<Array<ContractorWallet>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над кошельками контрагента */
export type ContractorWalletPayload = {
  __typename?: 'ContractorWalletPayload';
  /** Создание кошелька для контрагента */
  Create?: Maybe<ContractorWalletCreatePayload>;
  /** Удаление кошелька контрагента */
  Delete?: Maybe<ContractorWalletDeletePayload>;
  /** Редактирование кошелька контрагента */
  Update?: Maybe<ContractorWalletUpdatePayload>;
};


/** Операции над кошельками контрагента */
export type ContractorWalletPayloadCreateArgs = {
  ContractorWalletCreateInput?: InputMaybe<ContractorWalletCreateInput>;
};


/** Операции над кошельками контрагента */
export type ContractorWalletPayloadDeleteArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Операции над кошельками контрагента */
export type ContractorWalletPayloadUpdateArgs = {
  ContractorWalletUpdateInput?: InputMaybe<ContractorWalletUpdateInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** Тип кошелька контрагента */
export enum ContractorWalletPaymentTypeEnum {
  BankAccount = 'BANK_ACCOUNT',
  BankCard = 'BANK_CARD'
}

/** Фильтр */
export type ContractorWalletQueryFilter = {
  /** ID */
  id: Scalars['Int']['input'];
};

export type ContractorWalletQueryPayload = {
  __typename?: 'ContractorWalletQueryPayload';
  contractorWallet?: Maybe<ContractorWallet>;
  errors?: Maybe<Array<ContractorWalletQueryProblem>>;
};

export type ContractorWalletQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Реквизиты кошелька */
export type ContractorWalletRequisitesInput = {
  /** Банковский счет */
  bankAccountRequisites?: InputMaybe<ContractorWalletBankAccountRequisitesInput>;
  /** Банковская карта */
  bankCardRequisites?: InputMaybe<ContractorWalletBankCardRequisitesInput>;
};

/** Реквизиты кошелька */
export type ContractorWalletRequisitesType = {
  __typename?: 'ContractorWalletRequisitesType';
  /** Банковский счет */
  bankAccountRequisites?: Maybe<ContractorWalletBankAccountRequisitesType>;
  /** Банковская карта */
  bankCardRequisites?: Maybe<ContractorWalletBankCardRequisitesType>;
};

export type ContractorWalletUpdateInput = {
  /** Основной кошелек */
  isMain?: InputMaybe<Scalars['Boolean']['input']>;
  /** Поля кошелька */
  requisites?: InputMaybe<ContractorWalletRequisitesInput>;
};

/** Информация изменения кошелька */
export type ContractorWalletUpdatePayload = {
  __typename?: 'ContractorWalletUpdatePayload';
  contractorWallet?: Maybe<ContractorWallet>;
  errors?: Maybe<Array<ContractorWalletUpdateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type ContractorWalletUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type ContractorWalletsQueryFilter = {
  /** IDs */
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ContractorWalletsQueryPayload = {
  __typename?: 'ContractorWalletsQueryPayload';
  contractorWallets?: Maybe<ContractorWalletPagination>;
  errors?: Maybe<Array<ContractorWalletsQueryProblem>>;
};

export type ContractorWalletsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Заявка на вывод средств */
export type ContractorWithdraw = {
  __typename?: 'ContractorWithdraw';
  /** Сумма вывода */
  amount?: Maybe<Scalars['Float']['output']>;
  /** Приложение */
  application?: Maybe<Application>;
  /** Счет */
  balance?: Maybe<BalanceType>;
  /** Комиссия */
  commission?: Maybe<Scalars['Float']['output']>;
  /** Контрагент */
  contractor?: Maybe<Contractor>;
  /** Добавлено */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Валюта */
  currency?: Maybe<Currency>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Статус */
  statusConst?: Maybe<ContractorWithdrawStatusEnum>;
  /** Итого спишется со счета */
  totalAmount?: Maybe<Scalars['Float']['output']>;
  /** Договор */
  userContract?: Maybe<UserContract>;
};

/** Предварительные расчеты по заявке на вывод средств */
export type ContractorWithdrawCalculation = {
  __typename?: 'ContractorWithdrawCalculation';
  /** Сумма, доступная для вывода (сумма счета минус сумма резерва минус сумма комиссии */
  balanceAvailableAmount?: Maybe<Scalars['Float']['output']>;
  /** Комиссия на вывод средств */
  contractorWithdrawCommissionAmount?: Maybe<Scalars['Float']['output']>;
  /** Максимальный лимит суммы на счете */
  contractorWithdrawLimitMaxAmount?: Maybe<Scalars['Float']['output']>;
  /** Минимальный лимит суммы на счете */
  contractorWithdrawLimitMinAmount?: Maybe<Scalars['Float']['output']>;
  /** Сумма на вывод средств + комиссия */
  contractorWithdrawTotalAmount?: Maybe<Scalars['Float']['output']>;
};

export type ContractorWithdrawCalculationQueryPayload = {
  __typename?: 'ContractorWithdrawCalculationQueryPayload';
  contractorWithdrawCalculation?: Maybe<ContractorWithdrawCalculation>;
  errors?: Maybe<Array<ContractorWithdrawCalculationQueryProblem>>;
};

export type ContractorWithdrawCalculationQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type ContractorWithdrawCreateInput = {
  /** Сумма вывода */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** ID счета */
  balanceId?: InputMaybe<Scalars['Int']['input']>;
  /** ID контрагента */
  contractorId?: InputMaybe<Scalars['Int']['input']>;
  /** ID договора */
  userContractId?: InputMaybe<Scalars['Int']['input']>;
};

/** Информация создания заявки на вывод средств */
export type ContractorWithdrawCreatePayload = {
  __typename?: 'ContractorWithdrawCreatePayload';
  contractorWithdraw?: Maybe<ContractorWithdraw>;
  errors?: Maybe<Array<ContractorWithdrawCreateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type ContractorWithdrawCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type ContractorWithdrawPagination = {
  __typename?: 'ContractorWithdrawPagination';
  /** List of items on the current page */
  data?: Maybe<Array<ContractorWithdraw>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над заявками на вывод средств */
export type ContractorWithdrawPayload = {
  __typename?: 'ContractorWithdrawPayload';
  Create?: Maybe<ContractorWithdrawCreatePayload>;
};


/** Операции над заявками на вывод средств */
export type ContractorWithdrawPayloadCreateArgs = {
  ContractorWithdrawCreateInput?: InputMaybe<ContractorWithdrawCreateInput>;
};

/** Фильтр */
export type ContractorWithdrawQueryFilter = {
  /** ID */
  id: Scalars['Int']['input'];
};

export type ContractorWithdrawQueryPayload = {
  __typename?: 'ContractorWithdrawQueryPayload';
  contractorWithdraw?: Maybe<ContractorWithdraw>;
  errors?: Maybe<Array<ContractorWithdrawQueryProblem>>;
};

export type ContractorWithdrawQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Статус заявки на выплату контрагента */
export enum ContractorWithdrawStatusEnum {
  Canceled = 'CANCELED',
  Denied = 'DENIED',
  New = 'NEW',
  Paid = 'PAID'
}

/** Фильтр */
export type ContractorWithdrawsQueryFilter = {
  /** ID счета */
  balanceId?: InputMaybe<Scalars['Int']['input']>;
  /** Тип занятости контрагента */
  contractorEmploymentTypeConsts?: InputMaybe<Array<InputMaybe<ContractorEmploymentTypeEnum>>>;
  /** ID контрагента */
  contractorId?: InputMaybe<Scalars['Int']['input']>;
  /** Тип кошелька */
  contractorWalletPaymentTypeConsts?: InputMaybe<Array<InputMaybe<ContractorWalletPaymentTypeEnum>>>;
  /** Дата создания от */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата создания до */
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Валюта заявки */
  currencyCodes?: InputMaybe<Array<InputMaybe<CurrencyEnum>>>;
  /** IDs */
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Статус */
  statusConsts?: InputMaybe<Array<InputMaybe<ContractorWithdrawStatusEnum>>>;
  /** ID договора */
  userContractId?: InputMaybe<Scalars['Int']['input']>;
};

export type ContractorWithdrawsQueryPayload = {
  __typename?: 'ContractorWithdrawsQueryPayload';
  contractorWithdraws?: Maybe<ContractorWithdrawPagination>;
  errors?: Maybe<Array<ContractorWithdrawsQueryProblem>>;
};

export type ContractorWithdrawsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type ContractorsQueryFilter = {
  /** Типы занятости */
  employmentTypeConsts?: InputMaybe<Array<ContractorEmploymentTypeEnum>>;
  /** IDs */
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ContractorsQueryPayload = {
  __typename?: 'ContractorsQueryPayload';
  contractors?: Maybe<ContractorPagination>;
  errors?: Maybe<Array<ContractorsQueryProblem>>;
};

export type ContractorsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type CountriesQueryFilter = {
  /** ConstId приложения для фильтрации всех стран приложения */
  applicationConstId?: InputMaybe<ApplicationEnum>;
  /** Код */
  code?: InputMaybe<CountryEnum>;
  /** Краткое наименование */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CountriesQueryPayload = {
  __typename?: 'CountriesQueryPayload';
  countries?: Maybe<CountryPagination>;
};

/** Страна */
export type Country = {
  __typename?: 'Country';
  /** Буквенный код альфа-3 */
  alpha3?: Maybe<Scalars['String']['output']>;
  /** Столица */
  capitalId?: Maybe<Scalars['Int']['output']>;
  /** Цифровой код */
  code: Scalars['String']['output'];
  /** Континент */
  continent?: Maybe<ContinentEnum>;
  /** Валюта */
  currency?: Maybe<Currency>;
  /** Полное официальное наименование */
  fullname?: Maybe<Scalars['String']['output']>;
  /** Опубликована */
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  /** Код страны на iso.org */
  isoCode?: Maybe<Scalars['String']['output']>;
  /** Язык */
  language?: Maybe<LanguageEnum>;
  /** Широта */
  lat?: Maybe<Scalars['Float']['output']>;
  /** Долгота */
  lng?: Maybe<Scalars['Float']['output']>;
  /** Мобильный код */
  mcc?: Maybe<Scalars['String']['output']>;
  /** Телефонный код */
  phoneCode?: Maybe<Scalars['String']['output']>;
  /** Краткое наименование */
  title: Scalars['String']['output'];
};

/** Страны */
export enum CountryEnum {
  Ab = 'AB',
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  An = 'AN',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cp = 'CP',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Os = 'OS',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

export type CountryPagination = {
  __typename?: 'CountryPagination';
  /** List of items on the current page */
  data?: Maybe<Array<Country>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр */
export type CountryQueryFilter = {
  /** Код */
  code?: InputMaybe<CountryEnum>;
};

export type CountryQueryPayload = {
  __typename?: 'CountryQueryPayload';
  country?: Maybe<Country>;
};

/** Купон */
export type Coupon = {
  __typename?: 'Coupon';
  /** Описание */
  description?: Maybe<Scalars['String']['output']>;
  /** ID Купона */
  id: Scalars['Int']['output'];
  /** Краткое описание */
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** Slug */
  slug: Scalars['String']['output'];
  /** Название */
  title: Scalars['String']['output'];
};

export type CouponGroupPayload = {
  __typename?: 'CouponGroupPayload';
  /** Купоны пользователя */
  CouponsUser?: Maybe<CouponsUserQueryPayload>;
};


export type CouponGroupPayloadCouponsUserArgs = {
  userEnvironmentUuid: Scalars['UUID']['input'];
};

/** Выбран ли купон для пользователя */
export enum CouponStateEnum {
  Selected = 'SELECTED',
  Unselected = 'UNSELECTED'
}

/** Купон */
export type CouponUsageDataType = {
  __typename?: 'CouponUsageDataType';
  /** Описание */
  description?: Maybe<Scalars['String']['output']>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Приоритет */
  priority?: Maybe<Scalars['Int']['output']>;
  /** Краткое описание */
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** Дата начала периода действия */
  startAt?: Maybe<Scalars['DateTime']['output']>;
  /** Выбран ли купон для пользователя */
  state?: Maybe<CouponStateEnum>;
  /** Дата окончания периода действия */
  stopAt?: Maybe<Scalars['DateTime']['output']>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
};

export type CouponsUserQueryPayload = {
  __typename?: 'CouponsUserQueryPayload';
  coupons?: Maybe<Array<CouponUsageDataType>>;
  errors?: Maybe<Array<CouponsUserQueryProblem>>;
};

export type CouponsUserQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Валюта */
export type Currency = {
  __typename?: 'Currency';
  /** Аббревиатура */
  abbreviation: Scalars['String']['output'];
  /** Код валюты */
  code: CurrencyEnum;
  /** Разрешена оплата */
  isAllowedPayment?: Maybe<Scalars['Float']['output']>;
  /** Курс к рублю */
  rate?: Maybe<Scalars['Float']['output']>;
  /** Название валюты */
  title?: Maybe<Scalars['String']['output']>;
};

/** Валюты */
export enum CurrencyEnum {
  Alb = 'ALB',
  Ars = 'ARS',
  Azn = 'AZN',
  Bgn = 'BGN',
  Bpv = 'BPV',
  Btc = 'BTC',
  Byn = 'BYN',
  Cad = 'CAD',
  Chf = 'CHF',
  Clp = 'CLP',
  Cop = 'COP',
  Cpv = 'CPV',
  Cup = 'CUP',
  Czk = 'CZK',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Gel = 'GEL',
  Hrk = 'HRK',
  Huf = 'HUF',
  Ils = 'ILS',
  Kgs = 'KGS',
  Krw = 'KRW',
  Kzt = 'KZT',
  Ltl = 'LTL',
  Mdl = 'MDL',
  Mkd = 'MKD',
  Mnt = 'MNT',
  Mxn = 'MXN',
  Nio = 'NIO',
  Nok = 'NOK',
  Pen = 'PEN',
  Pln = 'PLN',
  Pyg = 'PYG',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Sek = 'SEK',
  Tjs = 'TJS',
  Tm = 'TM',
  Try = 'TRY',
  Uah = 'UAH',
  Ue = 'UE',
  Usd = 'USD',
  Uzs = 'UZS',
  Vnd = 'VND',
  Vpv = 'VPV',
  Wrc = 'WRC',
  Wrt = 'WRT'
}

/** Валюты-баллы */
export enum CurrencyPointEnum {
  Bpv = 'BPV',
  Cpv = 'CPV',
  Tm = 'TM',
  Vpv = 'VPV',
  Wrt = 'WRT'
}

export type DeliveryGroupPayload = {
  __typename?: 'DeliveryGroupPayload';
  /** Точки доставки */
  DeliveryPoints?: Maybe<DeliveryPointsQueryPayload>;
};


export type DeliveryGroupPayloadDeliveryPointsArgs = {
  filter?: InputMaybe<DeliveryPointsQueryFilter>;
  userEnvironmentUuid: Scalars['UUID']['input'];
};

/** Точка доставки */
export type DeliveryPoint = {
  __typename?: 'DeliveryPoint';
  /** Адрес */
  address?: Maybe<Scalars['String']['output']>;
  /** Информация по корзине */
  cartInfo?: Maybe<DeliveryPointCartInfo>;
  /** Город */
  city: City;
  /** Контактное лицо */
  contactPerson?: Maybe<Scalars['String']['output']>;
  /** Срок доставки */
  days?: Maybe<Scalars['Int']['output']>;
  /** Тип доставки */
  deliveryTypeConst: StorageDeliveryTypeEnum;
  /** Описание */
  description?: Maybe<Scalars['String']['output']>;
  /** Внешний ID */
  externalId?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['Int']['output'];
  /** Широта */
  lat: Scalars['String']['output'];
  /** Долгота */
  lng: Scalars['String']['output'];
  /** Телефон */
  phone?: Maybe<Scalars['Phone']['output']>;
  /** Стоимость */
  price?: Maybe<Price>;
  /** Название */
  title: Scalars['String']['output'];
};

/** Данные по корзине для точки доставки */
export type DeliveryPointCartInfo = {
  __typename?: 'DeliveryPointCartInfo';
  /** Товары, которых нет в наличии на складе */
  productsUnavailable?: Maybe<Array<DeliveryPointProductUnavailable>>;
  /** Статус наличия товаров на складе точки доставки */
  statusConst?: Maybe<ProductAvailableStatusInStorageEnum>;
};

/** Товар, которого нет в наличии на складе */
export type DeliveryPointProductUnavailable = {
  __typename?: 'DeliveryPointProductUnavailable';
  /** Количество товара в корзине */
  amount?: Maybe<Scalars['Int']['output']>;
  /** ID товара */
  id?: Maybe<Scalars['Int']['output']>;
  /** Главное изображение товара */
  mainImage?: Maybe<ProductImage>;
  /** Название товара */
  title?: Maybe<Scalars['String']['output']>;
};

/** Фильтр */
export type DeliveryPointsQueryFilter = {
  /** Тип доставки */
  deliveryTypeConst: StorageDeliveryTypeEnum;
};

export type DeliveryPointsQueryPayload = {
  __typename?: 'DeliveryPointsQueryPayload';
  deliveryPoints?: Maybe<Array<Maybe<DeliveryPoint>>>;
  errors?: Maybe<Array<DeliveryPointsQueryProblem>>;
};

export type DeliveryPointsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Тип доставки */
export type DeliveryType = {
  __typename?: 'DeliveryType';
  /** ID */
  constId: StorageDeliveryTypeEnum;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
};

export type DictionaryGroupPayload = {
  __typename?: 'DictionaryGroupPayload';
  /** Список категорий */
  Categories?: Maybe<CategoriesQueryPayload>;
  /** Категория */
  Category?: Maybe<CategoryQueryPayload>;
  /** Список доступных языков перевода */
  Languages?: Maybe<LanguagesQueryPayload>;
  /** Ссылка скачивания шаблона печати */
  PrintTemplateLink?: Maybe<PrintTemplateLinkQueryPayload>;
  /** Список тегов */
  Tags?: Maybe<TagsQueryPayload>;
};


export type DictionaryGroupPayloadCategoriesArgs = {
  filter?: InputMaybe<CategoriesQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type DictionaryGroupPayloadCategoryArgs = {
  filter?: InputMaybe<CategoryQueryFilter>;
};


export type DictionaryGroupPayloadLanguagesArgs = {
  filter?: InputMaybe<LanguagesQueryFilter>;
};


export type DictionaryGroupPayloadPrintTemplateLinkArgs = {
  filter?: InputMaybe<PrintTemplateLinkQueryFilter>;
};


export type DictionaryGroupPayloadTagsArgs = {
  filter?: InputMaybe<TagsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type FeedbackGroupPayload = {
  __typename?: 'FeedbackGroupPayload';
  /** Комментарии пользователя */
  Comments?: Maybe<CommentsQueryPayload>;
  /** Отзывы */
  Reviews?: Maybe<ReviewsQueryPayload>;
};


export type FeedbackGroupPayloadCommentsArgs = {
  filter?: InputMaybe<CommentsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type FeedbackGroupPayloadReviewsArgs = {
  filter?: InputMaybe<ReviewsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  sort?: InputMaybe<ReviewsSortEnum>;
};

/** Нет доступа к полю */
export type FieldForbiddenError = Problem & {
  __typename?: 'FieldForbiddenError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  inputName?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** Ошибка загрузки файла */
export type FileUploadError = Problem & {
  __typename?: 'FileUploadError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  inputName?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type FiltersQueryPayload = {
  __typename?: 'FiltersQueryPayload';
  /** Валюта каталога */
  currencyCode?: Maybe<CurrencyEnum>;
  errors?: Maybe<Array<FiltersQueryProblem>>;
  /** Фильтры по товарам */
  filters?: Maybe<ProductFilters>;
};

export type FiltersQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type FinanceGroupPayload = {
  __typename?: 'FinanceGroupPayload';
  /** Транзакции */
  BalanceTransactions?: Maybe<BalanceTransactionsQueryPayload>;
  /** Счет пользователя */
  BalanceUser?: Maybe<BalanceUserQueryPayload>;
  /** Счета пользователя */
  BalancesUser?: Maybe<BalancesUserQueryPayload>;
  /** Заявка контрагента на вывод средств */
  ContractorWithdraw?: Maybe<ContractorWithdrawQueryPayload>;
  /** Предварительные расчеты по заявке на вывод средств */
  ContractorWithdrawCalculation?: Maybe<ContractorWithdrawCalculationQueryPayload>;
  /** Справочник заявок контрагента на вывод средств */
  ContractorWithdraws?: Maybe<ContractorWithdrawsQueryPayload>;
  /** Список методов оплаты */
  PaymentMethods?: Maybe<PaymentMethodsQueryPayload>;
};


export type FinanceGroupPayloadBalanceTransactionsArgs = {
  filter?: InputMaybe<BalanceTransactionsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type FinanceGroupPayloadBalanceUserArgs = {
  filter?: InputMaybe<BalanceUserQueryFilter>;
};


export type FinanceGroupPayloadBalancesUserArgs = {
  filter?: InputMaybe<BalancesUserQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type FinanceGroupPayloadContractorWithdrawArgs = {
  filter?: InputMaybe<ContractorWithdrawQueryFilter>;
};


export type FinanceGroupPayloadContractorWithdrawCalculationArgs = {
  balanceId: Scalars['Int']['input'];
  contractorWithdrawAmount: Scalars['Float']['input'];
};


export type FinanceGroupPayloadContractorWithdrawsArgs = {
  filter?: InputMaybe<ContractorWithdrawsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type FinanceGroupPayloadPaymentMethodsArgs = {
  filter?: InputMaybe<PaymentMethodsQueryFilter>;
};

/** Нет доступа к объекту */
export type ForbiddenError = Problem & {
  __typename?: 'ForbiddenError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  inputName?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GeoGroupPayload = {
  __typename?: 'GeoGroupPayload';
  /** Адрес */
  Address?: Maybe<AddressQueryPayload>;
  /** Адреса */
  Addresses?: Maybe<AddressesQueryPayload>;
  /** Города */
  Cities?: Maybe<CitiesQueryPayload>;
  /** Город */
  City?: Maybe<CityQueryPayload>;
  /** Страны */
  Countries?: Maybe<CountriesQueryPayload>;
  /** Страна */
  Country?: Maybe<CountryQueryPayload>;
};


export type GeoGroupPayloadAddressArgs = {
  filter?: InputMaybe<AddressQueryFilter>;
};


export type GeoGroupPayloadAddressesArgs = {
  filter?: InputMaybe<AddressesQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type GeoGroupPayloadCitiesArgs = {
  filter?: InputMaybe<CitiesQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type GeoGroupPayloadCityArgs = {
  filter?: InputMaybe<CityQueryFilter>;
};


export type GeoGroupPayloadCountriesArgs = {
  filter?: InputMaybe<CountriesQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type GeoGroupPayloadCountryArgs = {
  filter?: InputMaybe<CountryQueryFilter>;
};

/** Image */
export type Image = {
  __typename?: 'Image';
  /** Изображение оригинал */
  original: Scalars['Url']['output'];
  /** Изображение 150x150 */
  size150: Scalars['Url']['output'];
  /** Изображение 300x300 */
  size300: Scalars['Url']['output'];
  /** Изображение 600x600 */
  size600: Scalars['Url']['output'];
};

/** Язык перевода */
export type Language = {
  __typename?: 'Language';
  /** ISO код языка */
  code: LanguageEnum;
  /** Перевод названия языка */
  title: Scalars['String']['output'];
  /** Название на языке происхождения */
  titleOrigin: Scalars['String']['output'];
};

/** Доступные языки */
export enum LanguageEnum {
  Az = 'AZ',
  Bg = 'BG',
  Cs = 'CS',
  De = 'DE',
  El = 'EL',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  Hu = 'HU',
  It = 'IT',
  Ka = 'KA',
  Kk = 'KK',
  Ky = 'KY',
  La = 'LA',
  Mk = 'MK',
  Mo = 'MO',
  Pl = 'PL',
  Pt = 'PT',
  Ru = 'RU',
  Sr = 'SR',
  Tr = 'TR'
}

/** Фильтр */
export type LanguagesQueryFilter = {
  /** ConstId приложения для фильтрации всех языков приложения */
  applicationConstId?: InputMaybe<ApplicationEnum>;
  /** ISO код языка */
  code?: InputMaybe<LanguageEnum>;
};

export type LanguagesQueryPayload = {
  __typename?: 'LanguagesQueryPayload';
  errors?: Maybe<Array<LanguagesQueryProblem>>;
  /** Доступные языки переводов */
  languages?: Maybe<Array<Language>>;
};

export type LanguagesQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type LegalGroupPayload = {
  __typename?: 'LegalGroupPayload';
  /** Контрагент пользователя */
  Contractor?: Maybe<ContractorQueryPayload>;
  /** Файл контрагента */
  ContractorFile?: Maybe<ContractorFileQueryPayload>;
  /** Файлы контрагента */
  ContractorFiles?: Maybe<ContractorFilesQueryPayload>;
  /** Кошелек контрагента */
  ContractorWallet?: Maybe<ContractorWalletQueryPayload>;
  /** Справочник кошельков контрагента */
  ContractorWallets?: Maybe<ContractorWalletsQueryPayload>;
  /** Список контрагентов пользователя */
  Contractors?: Maybe<ContractorsQueryPayload>;
  /** Договор Контрагента пользователя */
  UserContract?: Maybe<UserContractQueryPayload>;
  /** Список договоров контрагентов пользователя */
  UserContracts?: Maybe<UserContractsQueryPayload>;
};


export type LegalGroupPayloadContractorArgs = {
  filter?: InputMaybe<ContractorQueryFilter>;
};


export type LegalGroupPayloadContractorFileArgs = {
  filter?: InputMaybe<ContractorFileQueryFilter>;
};


export type LegalGroupPayloadContractorFilesArgs = {
  filter?: InputMaybe<ContractorFilesQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type LegalGroupPayloadContractorWalletArgs = {
  filter?: InputMaybe<ContractorWalletQueryFilter>;
};


export type LegalGroupPayloadContractorWalletsArgs = {
  filter?: InputMaybe<ContractorWalletsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type LegalGroupPayloadContractorsArgs = {
  filter?: InputMaybe<ContractorsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type LegalGroupPayloadUserContractArgs = {
  filter?: InputMaybe<UserContractQueryFilter>;
};


export type LegalGroupPayloadUserContractsArgs = {
  filter?: InputMaybe<UserContractsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

/** Лайк */
export type Like = {
  __typename?: 'Like';
  /** ID */
  id: Scalars['Int']['output'];
  /** Отзыв */
  review?: Maybe<Review>;
  /** Кто добавил */
  user?: Maybe<User>;
  /** Значение */
  value: LikeValueEnum;
};

/** Сущность, для которой ставится лайк */
export enum LikeEntityTypeEnum {
  Review = 'REVIEW'
}

/** Операции над оценками */
export type LikePayload = {
  __typename?: 'LikePayload';
  Update?: Maybe<LikeUpdatePayload>;
};


/** Операции над оценками */
export type LikePayloadUpdateArgs = {
  LikeUpdateInput?: InputMaybe<LikeUpdateInput>;
};

export type LikeUpdateInput = {
  /** ID Сущности */
  entityId?: InputMaybe<Scalars['Int']['input']>;
  /** Сущность */
  entityType?: InputMaybe<LikeEntityTypeEnum>;
  /** Значение */
  value?: InputMaybe<LikeValueEnum>;
};

export type LikeUpdatePayload = {
  __typename?: 'LikeUpdatePayload';
  errors?: Maybe<Array<Maybe<LikeUpdateProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  like?: Maybe<Like>;
};

export type LikeUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Значение лайка */
export enum LikeValueEnum {
  Dislike = 'DISLIKE',
  Empty = 'EMPTY',
  Like = 'LIKE'
}

/** Шаблон сообщения рассылки */
export type MailingMessageTemplate = {
  __typename?: 'MailingMessageTemplate';
  /** Файлы */
  files?: Maybe<Array<Maybe<MailingMessageTemplateFile>>>;
  /** Id */
  id?: Maybe<Scalars['Int']['output']>;
  /** Главное изображение */
  mainImage?: Maybe<Image>;
  /** Анонс */
  preview?: Maybe<Scalars['String']['output']>;
  /** Текст */
  text?: Maybe<Scalars['String']['output']>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
};

/** Файл шаблона сообщения рассылки */
export type MailingMessageTemplateFile = {
  __typename?: 'MailingMessageTemplateFile';
  /** Id файла */
  id?: Maybe<Scalars['Int']['output']>;
  /** mime type файла */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** Размер файла в байтах */
  size?: Maybe<Scalars['Int']['output']>;
  /** Название файла */
  title?: Maybe<Scalars['String']['output']>;
  /** Url */
  url?: Maybe<Scalars['Url']['output']>;
};

/** Материал */
export type Material = {
  __typename?: 'Material';
  /** Категория */
  category?: Maybe<Category>;
  /** Данные файла материала */
  fileData?: Maybe<MaterialFileDataType>;
  /** ID материала */
  id?: Maybe<Scalars['Int']['output']>;
  /** Ссылка */
  link?: Maybe<Scalars['Url']['output']>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
  /** Тип */
  typeConst?: Maybe<MaterialTypeEnum>;
  /** Версия файла */
  versionAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Файл материала */
export type MaterialFile = {
  __typename?: 'MaterialFile';
  /** Url */
  url?: Maybe<Scalars['Url']['output']>;
};

/** Данные файла материала */
export type MaterialFileDataType = {
  __typename?: 'MaterialFileDataType';
  /** Основной файл */
  file?: Maybe<MaterialFile>;
  /** Превью изображение файла */
  previewImage?: Maybe<MaterialFilePreviewImage>;
};

/** Превью изображение файла материала */
export type MaterialFilePreviewImage = {
  __typename?: 'MaterialFilePreviewImage';
  /** Изображение */
  image?: Maybe<Image>;
};

export type MaterialPagination = {
  __typename?: 'MaterialPagination';
  /** List of items on the current page */
  data?: Maybe<Array<Material>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр */
export type MaterialQueryFilter = {
  /** ID материала */
  id: Scalars['Int']['input'];
};

export type MaterialQueryPayload = {
  __typename?: 'MaterialQueryPayload';
  errors?: Maybe<Array<MaterialQueryProblem>>;
  material?: Maybe<Material>;
};

export type MaterialQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Тип материала */
export enum MaterialTypeEnum {
  File = 'FILE',
  Link = 'LINK'
}

/** Фильтр */
export type MaterialsQueryFilter = {
  /** ID категории */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** ID материала */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Название */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Тип */
  typeConst?: InputMaybe<MaterialTypeEnum>;
};

export type MaterialsQueryPayload = {
  __typename?: 'MaterialsQueryPayload';
  errors?: Maybe<Array<MaterialsQueryProblem>>;
  materials?: Maybe<MaterialPagination>;
};

export type MaterialsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Допустимые сортировки списка материалов */
export enum MaterialsSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC'
}

/** Статусы */
export enum MlmBinarActivityStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Part = 'PART'
}

/** Mlm Binar ячейка */
export type MlmBinarCell = {
  __typename?: 'MlmBinarCell';
  /** Разрешения */
  accessForUser: MlmBinarCellAccessForUser;
  /** Тип бинара */
  binarConst: MlmBinarCellBinarEnum;
  /** Рассчитываемые ячейки бинара */
  calculatedData?: Maybe<MlmBinarCellCounterCalculatedData>;
  /** Абсолютный уровень в дереве */
  depthAbsolute: Scalars['Int']['output'];
  /** Относительный уровень в дереве */
  depthRelative: Scalars['Int']['output'];
  /** ID */
  id: Scalars['Int']['output'];
  /** lft */
  lft: Scalars['Bigint']['output'];
  /** Счетчик */
  mlmBinarCellCounter?: Maybe<MlmBinarCellCounterForMlmBinarCell>;
  /** Относительный уровень по дереву наставника */
  mlmUserTreeReferrerDepthRelative?: Maybe<Scalars['Int']['output']>;
  /** Групповой объем ноги бинара слева за весь период */
  pointBpvUnlimitedLeft?: Maybe<Scalars['Float']['output']>;
  /** Групповой объем ноги бинара справа за весь период */
  pointBpvUnlimitedRight?: Maybe<Scalars['Float']['output']>;
  /** rgt */
  rgt: Scalars['Bigint']['output'];
  /** Позиция */
  sideConst?: Maybe<MlmBinarCellSideEnum>;
  /** Статус */
  statusConst: MlmBinarCellStatusEnum;
  /** Пользователь */
  user?: Maybe<UserForMlmBinarCell>;
};

/** Mlm Binar ячейка */
export type MlmBinarCellAccessForUser = {
  __typename?: 'MlmBinarCellAccessForUser';
  /** Разрешено разместить пользователей в ячейку */
  isAllowToFill: Scalars['Boolean']['output'];
  /** Разрешено удалить из ячейки пользователя */
  isAllowToFree: Scalars['Boolean']['output'];
};

/** Тип бинара */
export enum MlmBinarCellBinarEnum {
  B1 = 'B1',
  B2 = 'B2'
}

/** Расчитываемые данные  */
export type MlmBinarCellCounterCalculatedData = {
  __typename?: 'MlmBinarCellCounterCalculatedData';
  /** Ячейка находитеся в ноге спиловера */
  isSpillover: Scalars['Boolean']['output'];
  /** Информация по левой ноге */
  legLeft: MlmBinarCellInfoLeg;
  /** Информация по правой ноге */
  legRight: MlmBinarCellInfoLeg;
};

/** Счетчик ячейки */
export type MlmBinarCellCounterForMlmBinarCell = {
  __typename?: 'MlmBinarCellCounterForMlmBinarCell';
  /** ID */
  id: Scalars['Int']['output'];
  /** Слева баллов */
  leftPointBpv: Scalars['Float']['output'];
  /** Справа баллов */
  rightPointBpv: Scalars['Float']['output'];
};

/** Данные для добавления в ячейку пользователя */
export type MlmBinarCellFillInput = {
  /** Id ячейки */
  mlmBinarCellId: Scalars['Int']['input'];
  /** Пользователь */
  user: UserInput;
};

/** Информация о размещенном партнере в бинаре */
export type MlmBinarCellFillPayload = {
  __typename?: 'MlmBinarCellFillPayload';
  errors?: Maybe<Array<MlmBinarCellFillProblem>>;
  /** Id ячейки */
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  mlmBinarCell?: Maybe<MlmBinarCell>;
};

export type MlmBinarCellFillProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Информация по ногам ячейки */
export type MlmBinarCellInfoLeg = {
  __typename?: 'MlmBinarCellInfoLeg';
  /** Сильная нога за все время нахождения в ячейке с безлимитом по бинарному статусу */
  isStrongUnlimitedByMlmBinarCell: Scalars['Boolean']['output'];
};

/** Навигация по бинару */
export type MlmBinarCellNavigation = {
  __typename?: 'MlmBinarCellNavigation';
  /** ID ячейки бизнес центра */
  cellBusinessCenterId?: Maybe<Scalars['Int']['output']>;
  /** ID ячейки аплайна */
  cellUplineId?: Maybe<Scalars['Int']['output']>;
  /** Информация по левой ноге */
  legLeft?: Maybe<MlmBinarCellNavigationLeg>;
  /** Информация по правой ноге */
  legRight?: Maybe<MlmBinarCellNavigationLeg>;
};

/** Информация по ноге */
export type MlmBinarCellNavigationLeg = {
  __typename?: 'MlmBinarCellNavigationLeg';
  /** Свободная ячейка */
  cellFreeId?: Maybe<Scalars['Int']['output']>;
  /** Родительская свободной ячейки */
  cellFreeParentId?: Maybe<Scalars['Int']['output']>;
};

export type MlmBinarCellPagination = {
  __typename?: 'MlmBinarCellPagination';
  /** List of items on the current page */
  data?: Maybe<Array<MlmBinarCell>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Mlm Binar ячейка в навигации */
export type MlmBinarCellPath = {
  __typename?: 'MlmBinarCellPath';
  /** Относительный уровень в дереве */
  depthRelative?: Maybe<Scalars['Int']['output']>;
  /** Относительный уровень по дереву наставника */
  mlmUserTreeReferrerDepthRelative?: Maybe<Scalars['Int']['output']>;
  /** Позиция */
  sideConst?: Maybe<MlmBinarCellSideEnum>;
  /** Пользователь */
  user?: Maybe<UserForMlmBinarCellPath>;
};

export type MlmBinarCellPathPagination = {
  __typename?: 'MlmBinarCellPathPagination';
  /** List of items on the current page */
  data?: Maybe<Array<MlmBinarCellPath>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над ячейками бинара */
export type MlmBinarCellPayload = {
  __typename?: 'MlmBinarCellPayload';
  /** Добавить пользователя в бинар */
  Fill?: Maybe<MlmBinarCellFillPayload>;
  /** Добавить пользователя в бинар */
  ToFree?: Maybe<MlmBinarCellToFreePayload>;
};


/** Операции над ячейками бинара */
export type MlmBinarCellPayloadFillArgs = {
  MlmBinarCellFillInput?: InputMaybe<MlmBinarCellFillInput>;
};


/** Операции над ячейками бинара */
export type MlmBinarCellPayloadToFreeArgs = {
  MlmBinarCellToFreeInput?: InputMaybe<MlmBinarCellToFreeInput>;
};

/** Бинар */
export enum MlmBinarCellSideEnum {
  B1 = 'B1',
  B2 = 'B2',
  L = 'L',
  R = 'R'
}

/** Статус ячейки бинара */
export enum MlmBinarCellStatusEnum {
  Blocked = 'BLOCKED',
  Busy = 'BUSY',
  Free = 'FREE'
}

/** Данные для удаления пользователя из ячейки */
export type MlmBinarCellToFreeInput = {
  /** Id ячейки */
  mlmBinarCellId: Scalars['Int']['input'];
};

/** Информация о ячейке в бинаре */
export type MlmBinarCellToFreePayload = {
  __typename?: 'MlmBinarCellToFreePayload';
  errors?: Maybe<Array<MlmBinarCellToFreeProblem>>;
  /** Id ячейки */
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  mlmBinarCell?: Maybe<MlmBinarCell>;
};

export type MlmBinarCellToFreeProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр для определения дочерней ячейки */
export type MlmBinarCellsPathQueryChildFilter = {
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Id ref пользователя */
  userIdRef?: InputMaybe<Scalars['Int']['input']>;
  /** Pk пользователя */
  userPk?: InputMaybe<Scalars['Int']['input']>;
};

/** Фильтр */
export type MlmBinarCellsPathQueryFilter = {
  /** Ячейка дочерняя */
  cellChild?: InputMaybe<MlmBinarCellsPathQueryChildFilter>;
  /** Id ref пользователя ячейки */
  userIdRef?: InputMaybe<Scalars['Int']['input']>;
};

export type MlmBinarCellsPathQueryPayload = {
  __typename?: 'MlmBinarCellsPathQueryPayload';
  errors?: Maybe<Array<MlmBinarCellsPathQueryProblem>>;
  mlmBinarCellsPath?: Maybe<MlmBinarCellPathPagination>;
};

export type MlmBinarCellsPathQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type MlmBinarCellsQueryFilter = {
  /** Тип бинара */
  binarConst?: InputMaybe<MlmBinarCellBinarEnum>;
  /** Поиск ячейки с который начинаем просмотр бинара (От кого смотрим) */
  cellParent?: InputMaybe<MlmBinarCellsQueryParentFilter>;
  /** Относительный уровень до */
  depthRelativeTo?: InputMaybe<Scalars['Int']['input']>;
};

/** Фильтр для поиска родительской ячейки */
export type MlmBinarCellsQueryParentFilter = {
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Id ref пользователя */
  userIdRef?: InputMaybe<Scalars['Int']['input']>;
  /** Pk пользователя */
  userPk?: InputMaybe<Scalars['Int']['input']>;
};

export type MlmBinarCellsQueryPayload = {
  __typename?: 'MlmBinarCellsQueryPayload';
  errors?: Maybe<Array<MlmBinarCellsQueryProblem>>;
  mlmBinarCells?: Maybe<MlmBinarCellPagination>;
  navigation?: Maybe<MlmBinarCellNavigation>;
  stats?: Maybe<MlmBinarCellsQueryStat>;
};

export type MlmBinarCellsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Статистика */
export type MlmBinarCellsQueryStat = {
  __typename?: 'MlmBinarCellsQueryStat';
  isSuccessful: Scalars['Boolean']['output'];
  mlmBinarCellsBusyCount?: Maybe<Scalars['Int']['output']>;
};

export type MlmGroupPayload = {
  __typename?: 'MlmGroupPayload';
  /** Список ячеек бинара */
  MlmBinarCells?: Maybe<MlmBinarCellsQueryPayload>;
  /** Путь между авторизованным пользователем и указанным пользователем в бинаре */
  MlmBinarCellsPath?: Maybe<MlmBinarCellsPathQueryPayload>;
  /** Список Mlm периодов */
  MlmPeriods?: Maybe<MlmPeriodsQueryPayload>;
  /** Бонус пользователя */
  MlmUserBonus?: Maybe<MlmUserBonusQueryPayload>;
  /** Бонусы пользователя */
  MlmUserBonuses?: Maybe<MlmUserBonusesQueryPayload>;
  /** Бонусы пользователя */
  UserMlmBinarSettingReferral?: Maybe<UserMlmBinarSettingReferralQueryPayload>;
  /** Бонусы пользователя */
  UserMlmBinarSettingReferrals?: Maybe<UserMlmBinarSettingReferralsQueryPayload>;
};


export type MlmGroupPayloadMlmBinarCellsArgs = {
  filter?: InputMaybe<MlmBinarCellsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type MlmGroupPayloadMlmBinarCellsPathArgs = {
  filter?: InputMaybe<MlmBinarCellsPathQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type MlmGroupPayloadMlmPeriodsArgs = {
  filter?: InputMaybe<MlmPeriodsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type MlmGroupPayloadMlmUserBonusArgs = {
  filter?: InputMaybe<MlmUserBonusQueryFilter>;
};


export type MlmGroupPayloadMlmUserBonusesArgs = {
  filter?: InputMaybe<MlmUserBonusesQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type MlmGroupPayloadUserMlmBinarSettingReferralArgs = {
  filter?: InputMaybe<UserMlmBinarSettingReferralQueryFilter>;
};


export type MlmGroupPayloadUserMlmBinarSettingReferralsArgs = {
  filter?: InputMaybe<UserMlmBinarSettingReferralsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

/** Mlm период */
export type MlmPeriod = {
  __typename?: 'MlmPeriod';
  /** Дата последнего успешного расчета классического маркетинга показывать пользователям */
  classicCalculationAt?: Maybe<Scalars['DateTime']['output']>;
  /** Дата и время конца */
  endAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID */
  id: Scalars['Int']['output'];
  /** Дата и время начало */
  startAt: Scalars['DateTime']['output'];
  /** Статус */
  statusConst?: Maybe<MlmPeriodStatusEnum>;
  /** Название */
  title: Scalars['String']['output'];
};

export type MlmPeriodPagination = {
  __typename?: 'MlmPeriodPagination';
  /** List of items on the current page */
  data?: Maybe<Array<MlmPeriod>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Статус mlm периода */
export enum MlmPeriodStatusEnum {
  Calculate = 'CALCULATE',
  Close = 'CLOSE',
  Current = 'CURRENT'
}

/** Фильтр */
export type MlmPeriodsQueryFilter = {
  /** Id mlm периода */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MlmPeriodsQueryPayload = {
  __typename?: 'MlmPeriodsQueryPayload';
  errors?: Maybe<Array<MlmPeriodsQueryProblem>>;
  mlmPeriods?: Maybe<MlmPeriodPagination>;
};

export type MlmPeriodsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** MLM Ранг пользователя */
export type MlmRank = {
  __typename?: 'MlmRank';
  /** ConstId */
  constId: MlmRankEnum;
  /** Должность */
  position: Scalars['String']['output'];
  /** Название */
  title: Scalars['String']['output'];
};

/**
 * Коды рангов пользователя.
 *                 <br>CLIENT - Клиент,
 *                 <br>PARTNER - Партнер,
 *                 <br>PRIVILEGED_PARTNER - Привилегированный партнер,
 *                 <br>GARNET - Гранат,
 *                 <br>RUBY - Рубин,
 *                 <br>EMERALD - Изумруд,
 *                 <br>SAPPHIRE - Сапфир,
 *                 <br>DIAMOND - Бриллиант,
 *                 <br>DIAMOND_1 - Бриллиант 1 карат,
 *                 <br>DIAMOND_2 - Бриллиант 2 карата,
 *                 <br>DIAMOND_3 - Красный Бриллиант 3 карата,
 *                 <br>DIAMOND_4 - Красный Бриллиант 4 карата,
 *                 <br>DIAMOND_5 - Красный Бриллиант 5 каратов,
 *                 <br>DIAMOND_6 - Красный Бриллиант 6 каратов,
 *                 <br>BLACK_BRILLIANT - Черный Бриллиант,
 *                 <br>NATIONAL_LEADER - Национальный лидер
 */
export enum MlmRankEnum {
  BlackBrilliant = 'BLACK_BRILLIANT',
  Client = 'CLIENT',
  Diamond = 'DIAMOND',
  Diamond_1 = 'DIAMOND_1',
  Diamond_2 = 'DIAMOND_2',
  Diamond_3 = 'DIAMOND_3',
  Diamond_4 = 'DIAMOND_4',
  Diamond_5 = 'DIAMOND_5',
  Diamond_6 = 'DIAMOND_6',
  DiamondAmbassador = 'DIAMOND_AMBASSADOR',
  Emerald = 'EMERALD',
  Garnet = 'GARNET',
  NationalLeader = 'NATIONAL_LEADER',
  Partner = 'PARTNER',
  PrivilegedPartner = 'PRIVILEGED_PARTNER',
  Ruby = 'RUBY',
  Sapphire = 'SAPPHIRE'
}

/** Бонус пользователя */
export type MlmUserBonus = {
  __typename?: 'MlmUserBonus';
  /** Транзакция */
  balanceTransaction?: Maybe<BalanceTransaction>;
  /** Расчетные данные по бонусу пользователя */
  calculatedData?: Maybe<MlmUserBonusCalculatedData>;
  /** Когда добавил */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Дополнительная информация по бонусу пользователя */
  data?: Maybe<MlmUserBonusDataType>;
  /** Id сущности */
  entityId?: Maybe<Scalars['Int']['output']>;
  /** Тип сущности */
  entityType?: Maybe<MlmUserBonusEntityTypeEnum>;
  /** Группа бонуса пользователя */
  groupConst?: Maybe<MlmUserBonusTypeGroup>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Цена */
  price?: Maybe<Price>;
  /** Статус */
  statusConst?: Maybe<MlmUserBonusStatusEnum>;
  /** Подгруппа бонуса пользователя */
  subGroupConst?: Maybe<MlmUserBonusTypeSubGroup>;
  /** Обновлено */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Дополнительная информация по бонусу пользователя */
export type MlmUserBonusCalculatedData = {
  __typename?: 'MlmUserBonusCalculatedData';
  /** Выполнена личная активность пользователя */
  isMlmUserActivity?: Maybe<Scalars['Boolean']['output']>;
  /** Дата окончания личной активности */
  mlmUserActivityStopAt?: Maybe<Scalars['DateTime']['output']>;
  /** Бинарная активность пользователя */
  mlmUserBinarActivityStatusConst?: Maybe<MlmBinarActivityStatusEnum>;
  /** Дата окончания бинарной активности */
  mlmUserBinarActivityStopAt?: Maybe<Scalars['DateTime']['output']>;
  /** Бинарный статус пользователя */
  mlmUserBinarUserStatusTypeStatusConst?: Maybe<UserMlmBinarUserStatusTypeConstEnum>;
  /** Лимит BPV бинарного статуса пользователя */
  mlmUserBinarUserStatusTypeStatusPointBpvLimit?: Maybe<Scalars['Int']['output']>;
};

/** Дополнительная информация по бонусу пользователя БН */
export type MlmUserBonusDataBnValueType = {
  __typename?: 'MlmUserBonusDataBnValueType';
  /** PK партнера, за которого начислен БН */
  referralPk?: Maybe<Scalars['Int']['output']>;
};

/** Дополнительная информация по бонусу пользователя БЗП */
export type MlmUserBonusDataBzpValueType = {
  __typename?: 'MlmUserBonusDataBzpValueType';
  /** Линия партнера, за которого начислен БЗП */
  referralDepth?: Maybe<Scalars['Int']['output']>;
};

/** Дополнительная информация по бонусу пользователя КББ */
export type MlmUserBonusDataKbbValueType = {
  __typename?: 'MlmUserBonusDataKbbValueType';
  /** Баллов счетчика схлопнулось */
  mlmBinarCellCounterCollapsedPointBpv?: Maybe<Scalars['Float']['output']>;
  /** Баллы счетчика слева */
  mlmBinarCellCounterLeftPointBpv?: Maybe<Scalars['Float']['output']>;
  /** Баллы счетчика справа */
  mlmBinarCellCounterRightPointBpv?: Maybe<Scalars['Float']['output']>;
  /** Сохраненный объем слева */
  mlmBinarCellLeftCounterStartPointBpv?: Maybe<Scalars['Float']['output']>;
  /** Сохраненный объем справа */
  mlmBinarCellRightCounterStartPointBpv?: Maybe<Scalars['Float']['output']>;
};

/** Дополнительная информация по бонусу пользователя */
export type MlmUserBonusDataType = {
  __typename?: 'MlmUserBonusDataType';
  /** Дополнительная информация по бонусу пользователя БН */
  mlmUserBonusDataBnValue?: Maybe<MlmUserBonusDataBnValueType>;
  /** Дополнительная информация по бонусу пользователя БЗП */
  mlmUserBonusDataBzpValue?: Maybe<MlmUserBonusDataBzpValueType>;
  /** Дополнительная информация по бонусу пользователя КББ */
  mlmUserBonusDataKbbValue?: Maybe<MlmUserBonusDataKbbValueType>;
};

/** Сущность бонуса пользователя */
export enum MlmUserBonusEntityTypeEnum {
  MlmBinarCellCounter = 'MLM_BINAR_CELL_COUNTER',
  MlmClassicOrder = 'MLM_CLASSIC_ORDER',
  MlmPeriod = 'MLM_PERIOD'
}

export type MlmUserBonusPagination = {
  __typename?: 'MlmUserBonusPagination';
  /** List of items on the current page */
  data?: Maybe<Array<MlmUserBonus>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр */
export type MlmUserBonusQueryFilter = {
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MlmUserBonusQueryPayload = {
  __typename?: 'MlmUserBonusQueryPayload';
  errors?: Maybe<Array<MlmUserBonusQueryProblem>>;
  mlmUserBonus?: Maybe<MlmUserBonus>;
};

export type MlmUserBonusQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Статус бонуса пользователя */
export enum MlmUserBonusStatusEnum {
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  Scheduled = 'SCHEDULED',
  Skipped = 'SKIPPED'
}

/** Группа бонуса пользователя */
export type MlmUserBonusTypeGroup = {
  __typename?: 'MlmUserBonusTypeGroup';
  /** Код группы */
  code?: Maybe<MlmUserBonusTypeGroupEnum>;
};

/** Список групп бонусов пользователя */
export enum MlmUserBonusTypeGroupEnum {
  Bb = 'BB',
  Bk = 'BK',
  Bn = 'BN',
  Bzp = 'BZP',
  Kbb = 'KBB'
}

/** Подгруппа бонуса пользователя */
export type MlmUserBonusTypeSubGroup = {
  __typename?: 'MlmUserBonusTypeSubGroup';
  /** Код подгруппы */
  code?: Maybe<MlmUserBonusTypeSubGroupEnum>;
};

/** Список подгрупп бонусов пользователя */
export enum MlmUserBonusTypeSubGroupEnum {
  BzpReferralDepthOne = 'BZP_REFERRAL_DEPTH_ONE',
  BzpReferralDepthTwo = 'BZP_REFERRAL_DEPTH_TWO'
}

/** Фильтр */
export type MlmUserBonusesBalanceTransactionsQueryFilter = {
  /** Первичный ключ транзакции */
  primaryKey?: InputMaybe<BalanceTransactionPrimaryKeyFilter>;
};

/** Фильтр */
export type MlmUserBonusesQueryFilter = {
  /** Транзакция */
  balanceTransaction?: InputMaybe<MlmUserBonusesBalanceTransactionsQueryFilter>;
  /** ID сущности */
  entityId?: InputMaybe<Scalars['Int']['input']>;
  /** Тип сущности */
  entityType?: InputMaybe<MlmUserBonusEntityTypeEnum>;
  /** IDs бонусов пользователя */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Статусы */
  statusConsts?: InputMaybe<Array<MlmUserBonusStatusEnum>>;
};

export type MlmUserBonusesQueryPayload = {
  __typename?: 'MlmUserBonusesQueryPayload';
  errors?: Maybe<Array<MlmUserBonusesQueryProblem>>;
  mlmUserBonuses?: Maybe<MlmUserBonusPagination>;
};

export type MlmUserBonusesQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Дерево */
export enum MlmUserTreeFilterEnum {
  Referrer = 'REFERRER',
  Sponsor = 'SPONSOR'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Адреса */
  Address?: Maybe<AddressPayload>;
  /** Заявки на перемещение средств */
  BalanceTransferRequest?: Maybe<BalanceTransferRequestPayload>;
  /** Корзина пользователя */
  Cart?: Maybe<CartPayload>;
  /** Корзина подарков пользователя */
  CartReward?: Maybe<CartRewardPayload>;
  /** Комментарии */
  Comment?: Maybe<CommentPayload>;
  /** Контрагенты */
  Contractor?: Maybe<ContractorPayload>;
  /** Файлы контрагента */
  ContractorFiles?: Maybe<ContractorFilesPayload>;
  /** Кошельки пользователя */
  ContractorWallet?: Maybe<ContractorWalletPayload>;
  /** Заявки на вывод средств */
  ContractorWithdraw?: Maybe<ContractorWithdrawPayload>;
  /** Оценки */
  Like?: Maybe<LikePayload>;
  /** Ячейки бинара */
  MlmBinarCell?: Maybe<MlmBinarCellPayload>;
  /** Заказы */
  Order?: Maybe<OrderPayload>;
  /** Отзывы */
  Review?: Maybe<ReviewPayload>;
  /** Заявки на открытие складов */
  StorageOpeningRequest?: Maybe<StorageOpeningRequestPayload>;
  /** Теги */
  Tag?: Maybe<TagPayload>;
  /** Связи сущностей с тегами */
  TagEntityRelation?: Maybe<TagEntityRelationPayload>;
  /** Пользователи */
  User?: Maybe<UserPayload>;
  /** Договор контрагента */
  UserContract?: Maybe<UserContractPayload>;
  /** Пользовательские профили доставки */
  UserDeliveryProfile?: Maybe<UserDeliveryProfilePayload>;
  /** Заявка обратной связи */
  UserFeedbackRequest?: Maybe<UserFeedbackRequestPayload>;
  /** Настройки пользователя по размещению рефералов в бинаре */
  UserMlmBinarSettingReferral?: Maybe<UserMlmBinarSettingReferralPayload>;
  /** Избранные товары пользователя */
  UserProductFavorite?: Maybe<UserProductFavoritePayload>;
  /** Реферальный ссылки пользователей */
  UserRefLink?: Maybe<UserRefLinkPayload>;
};

/** Объект не найден */
export type NotFoundError = Problem & {
  __typename?: 'NotFoundError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  inputName?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** Заказ */
export type Order = {
  __typename?: 'Order';
  /** Сумма */
  amount: Price;
  /** Автор */
  author: User;
  /** Добавлено */
  createdAt: Scalars['DateTime']['output'];
  /** Валюта */
  currency: Currency;
  /** Сумма доставки */
  deliveryAmount: Price;
  /** ID */
  id: Scalars['Int']['output'];
  /** Элементы заказа */
  items?: Maybe<Array<Maybe<OrderItem>>>;
  /** Mlm период */
  mlmPeriod: MlmPeriod;
  /** Дата оплаты */
  paymentAt?: Maybe<Scalars['DateTime']['output']>;
  /** Способ оплаты */
  paymentConst?: Maybe<PaymentEnum>;
  /** Группа способа оплаты */
  paymentGroupConst?: Maybe<PaymentGroupEnum>;
  /** Режим начисление баллов */
  pointModeConst: OrderPointModeEnum;
  /** Баллы */
  points: Point;
  /** Отгрузки */
  shipments?: Maybe<Array<Maybe<OrderShipment>>>;
  /** Статус */
  statusConst: OrderStatusEnum;
  /** Масса брутто всех элементов заказа (г.) */
  totalGrossWeight: Scalars['Int']['output'];
  /** Обновлено */
  updatedAt: Scalars['DateTime']['output'];
  /** Покупатель */
  user: User;
};

export type OrderCreateInput = {
  /** Валюта */
  currencyCode: CurrencyEnum;
  /** Доставка */
  delivery: OrderDeliveryInput;
  /** Способ оплаты */
  payment?: InputMaybe<PaymentEnum>;
  /** Группа оплаты */
  paymentGroup?: InputMaybe<PaymentGroupEnum>;
};

export type OrderCreatePayload = {
  __typename?: 'OrderCreatePayload';
  errors?: Maybe<Array<Maybe<OrderCreateProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  order?: Maybe<Order>;
};

export type OrderCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type OrderDeliveryInput = {
  /** ID адреса доставки */
  addressId: Scalars['Int']['input'];
  /** Город доставки */
  cityId: Scalars['Int']['input'];
  /** Страна доставки */
  countryCode: CountryEnum;
  /** Пункт доставки */
  pointId?: InputMaybe<Scalars['Int']['input']>;
  /** Профиль доставки */
  storageDeliveryId: Scalars['Int']['input'];
};

/** Элемент заказа */
export type OrderItem = {
  __typename?: 'OrderItem';
  /** Купон */
  coupon?: Maybe<Coupon>;
  /** Индекс собираемой группы купона */
  couponAssembledGroupIndex?: Maybe<Scalars['Int']['output']>;
  /** Добавлено */
  createdAt: Scalars['DateTime']['output'];
  /** Масса брутто (г.) */
  grossWeight: Scalars['Int']['output'];
  /** ID */
  id: Scalars['Int']['output'];
  /** Баллы */
  points: Point;
  /** Цена */
  price: Price;
  /** Товар */
  product: Product;
  /** Количество */
  quantity: Scalars['Int']['output'];
  /** Тип */
  typeConst: OrderItemTypeEnum;
  /** Обновлено */
  updatedAt: Scalars['DateTime']['output'];
};

/**
 * Тип элемента заказа.
 *             <br>PRODUCT - Товар,
 *             <br>REWARD - Акционный товар
 *
 */
export enum OrderItemTypeEnum {
  Product = 'PRODUCT',
  Reward = 'REWARD'
}

export type OrderPagination = {
  __typename?: 'OrderPagination';
  /** List of items on the current page */
  data?: Maybe<Array<Order>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над заказами */
export type OrderPayload = {
  __typename?: 'OrderPayload';
  /** Создание заказа */
  Create?: Maybe<OrderCreatePayload>;
  /** Создание заказа */
  Update?: Maybe<OrderUpdatePayload>;
};


/** Операции над заказами */
export type OrderPayloadCreateArgs = {
  OrderCreateInput?: InputMaybe<OrderCreateInput>;
};


/** Операции над заказами */
export type OrderPayloadUpdateArgs = {
  OrderUpdateInput?: InputMaybe<OrderUpdateInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** Режим начисление баллов */
export enum OrderPointModeEnum {
  Bpv = 'BPV'
}

/** Фильтр */
export type OrderQueryFilter = {
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderQueryPayload = {
  __typename?: 'OrderQueryPayload';
  errors?: Maybe<Array<OrderQueryProblem>>;
  order?: Maybe<Order>;
};

export type OrderQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Отгрузка */
export type OrderShipment = {
  __typename?: 'OrderShipment';
  /** Адрес */
  address: Address;
  /** Добавлено */
  createdAt: Scalars['DateTime']['output'];
  /** Дата доставки */
  deliveryDate?: Maybe<Scalars['String']['output']>;
  /** Точка доставки */
  deliveryPoint?: Maybe<DeliveryPoint>;
  /** ID */
  id: Scalars['Int']['output'];
  /** Элементы отгрузки */
  items?: Maybe<Array<Maybe<OrderShipmentItem>>>;
  /** Заказ */
  order: Order;
  /** Цена */
  price: Price;
  /** Изменение статуса */
  statusChangedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Статус */
  statusConst: OrderShipmentStatusEnum;
  /** Истоярия статусов отгрузки */
  statusHistories?: Maybe<Array<Maybe<OrderShipmentStatusHistory>>>;
  /** Склад */
  storage: Storage;
  /** Способ доставки */
  storageDelivery?: Maybe<StorageDelivery>;
  /** Трэк номер */
  trackNumber?: Maybe<Scalars['String']['output']>;
  /** Обновлено */
  updatedAt: Scalars['DateTime']['output'];
};

/** Элемент отгрузки */
export type OrderShipmentItem = {
  __typename?: 'OrderShipmentItem';
  /** Добавлено */
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['Int']['output'];
  /** Элемент заказа */
  orderItem: OrderItem;
  /** Отгрузка */
  orderShipment: OrderShipment;
  /** Количество */
  quantity: Scalars['Int']['output'];
  /** Обновлено */
  updatedAt: Scalars['DateTime']['output'];
};

/** Статус отгрузки */
export enum OrderShipmentStatusEnum {
  Canceled = 'CANCELED',
  Collecting = 'COLLECTING',
  Completed = 'COMPLETED',
  InTransit = 'IN_TRANSIT',
  New = 'NEW',
  ReadyToIssue = 'READY_TO_ISSUE'
}

/** Элемент отгрузки */
export type OrderShipmentStatusHistory = {
  __typename?: 'OrderShipmentStatusHistory';
  /** Когда добавил */
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['Int']['output'];
  /** Предыдущий статус */
  previousStatusConst?: Maybe<OrderShipmentStatusEnum>;
  /** Отгрузка */
  shipment: OrderShipment;
  /** Статус */
  statusConst: OrderShipmentStatusEnum;
};

/**
 * Статус заказа.
 *             <br>NEW - Новый,
 *             <br>PAID - Оплачен,
 *             <br>COLLECTING - На сборке,
 *             <br>IN_TRANSIT - В пути,
 *             <br>READY_TO_ISSUE - Готов к выдаче,
 *             <br>PARTIALLY_COMPLETED - Частично выполнен,
 *             <br>COMPLETED - Выполнен,
 *             <br>FINISHED - Завершен,
 *             <br>CANCELED - Отменен
 *
 */
export enum OrderStatusEnum {
  Canceled = 'CANCELED',
  Collecting = 'COLLECTING',
  Completed = 'COMPLETED',
  Finished = 'FINISHED',
  InTransit = 'IN_TRANSIT',
  New = 'NEW',
  Paid = 'PAID',
  PartiallyCompleted = 'PARTIALLY_COMPLETED',
  ReadyToIssue = 'READY_TO_ISSUE'
}

export type OrderUpdateInput = {
  /** Статус */
  statusConst: OrderStatusEnum;
};

export type OrderUpdatePayload = {
  __typename?: 'OrderUpdatePayload';
  errors?: Maybe<Array<Maybe<OrderUpdateProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  order?: Maybe<Order>;
};

export type OrderUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type OrdersQueryFilter = {
  /** Дата создания от */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата создания до */
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Заказы на партнеров */
  isOnlyPartnerOrders?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID MLM периода */
  mlmPeriodId?: InputMaybe<Scalars['Int']['input']>;
  /** Минимальный балл BPV */
  pointBpvFrom?: InputMaybe<Scalars['Float']['input']>;
  /** Максимальный балл BPV */
  pointBpvTo?: InputMaybe<Scalars['Float']['input']>;
  /** Статус */
  statusConsts?: InputMaybe<Array<OrderStatusEnum>>;
  /** Полное имя покупателя (фамилия, имя и отчество) */
  userFullName?: InputMaybe<Scalars['String']['input']>;
  /** ID ref покупателя */
  userIdRef?: InputMaybe<Scalars['Int']['input']>;
  /** ID покупателя */
  userPk?: InputMaybe<Scalars['Int']['input']>;
};

export type OrdersQueryPayload = {
  __typename?: 'OrdersQueryPayload';
  errors?: Maybe<Array<OrdersQueryProblem>>;
  orders?: Maybe<OrderPagination>;
};

export type OrdersQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type Os1GroupPayload = {
  __typename?: 'Os1GroupPayload';
  /** Периоды os1 */
  Periods?: Maybe<Os1PeriodsQueryPayload>;
  /** Данные о пользователе */
  UserProfile?: Maybe<Os1UserProfileQueryPayload>;
  /** Структура пользвоателя */
  UserStructure?: Maybe<Os1UserStructureQueryPayload>;
};


export type Os1GroupPayloadPeriodsArgs = {
  filter?: InputMaybe<Os1PeriodsQueryFilter>;
};


export type Os1GroupPayloadUserProfileArgs = {
  filter?: InputMaybe<Os1UserProfileQueryFilter>;
};


export type Os1GroupPayloadUserStructureArgs = {
  filter?: InputMaybe<Os1UserStructureQueryFilter>;
  page: Scalars['Int']['input'];
};

/** Периоды в os1 */
export type Os1Period = {
  __typename?: 'Os1Period';
  /** Id */
  id: Scalars['Int']['output'];
  /** Название */
  title: Scalars['String']['output'];
};

/** Фильтр */
export type Os1PeriodsQueryFilter = {
  /** Id периода */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type Os1PeriodsQueryPayload = {
  __typename?: 'Os1PeriodsQueryPayload';
  errors?: Maybe<Array<Problem>>;
  periods?: Maybe<Array<Maybe<Os1Period>>>;
};

/** Данные о пользователе os1 */
export type Os1UserProfile = {
  __typename?: 'Os1UserProfile';
  /** Город */
  cityName?: Maybe<Scalars['String']['output']>;
  /** Страна */
  countryName?: Maybe<Scalars['String']['output']>;
  /** Email */
  email?: Maybe<Scalars['String']['output']>;
  /** Id */
  id: Scalars['Int']['output'];
  /** Подписан контракт */
  isVerified: Scalars['Boolean']['output'];
  /** Фио */
  name: Scalars['String']['output'];
  /** Телефон */
  phone?: Maybe<Scalars['Phone']['output']>;
  /** Дата регистрации */
  registrationDateAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Фильтр */
export type Os1UserProfileQueryFilter = {
  /** Id периода os1 */
  periodId?: InputMaybe<Scalars['Int']['input']>;
  /** Id пользователя os1 */
  userId: Scalars['Int']['input'];
};

export type Os1UserProfileQueryPayload = {
  __typename?: 'Os1UserProfileQueryPayload';
  errors?: Maybe<Array<Problem>>;
  userProfile?: Maybe<Os1UserProfile>;
};

/** Данные о пользователе в структуре */
export type Os1UserStructure = {
  __typename?: 'Os1UserStructure';
  /** TM */
  apv: Scalars['Float']['output'];
  /** БОБ */
  bob: Scalars['Float']['output'];
  /** ЛО BPV */
  bpv: Scalars['Float']['output'];
  /** Город */
  cityName?: Maybe<Scalars['String']['output']>;
  /** Дата подписание контракта */
  confirmedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Количество достижений Клуб 200 за всю историю компании */
  countBpv200AllPeriod: Scalars['Int']['output'];
  /** Страна */
  countryName?: Maybe<Scalars['String']['output']>;
  /** ЛО BPV */
  cpv: Scalars['Float']['output'];
  /** Email */
  email?: Maybe<Scalars['String']['output']>;
  /** ГО */
  go: Scalars['Float']['output'];
  /** ГО TM */
  goApv: Scalars['Float']['output'];
  /** ГО BPV */
  goBpv: Scalars['Float']['output'];
  /** ГО OK */
  goOk: Scalars['Float']['output'];
  /** групповой объем РК */
  goRk: Scalars['Float']['output'];
  /** ГОБ */
  gob: Scalars['Float']['output'];
  /** Id */
  id: Scalars['Int']['output'];
  /** Активность бинара */
  isBinarActive: Scalars['Boolean']['output'];
  /** Новичок */
  isNew: Scalars['Boolean']['output'];
  /** Уровень в дереве наставника??? */
  level: Scalars['Int']['output'];
  /** Фио */
  name: Scalars['String']['output'];
  /** Телефон */
  phone?: Maybe<Scalars['Phone']['output']>;
  /** Ранг пользователя */
  rank: Os1UserStructureRank;
  /** ref Code */
  refCode: Scalars['Int']['output'];
  /** Дата регистрации */
  registrationDateAt?: Maybe<Scalars['DateTime']['output']>;
  /** Статистика по периоду */
  statPeriod: Os1UserStructureStatPeriod;
  /** Партнеров в структуре??? */
  structureCountPartners: Scalars['Int']['output'];
  /** Ранг максимальный??? */
  typeWas: Scalars['Boolean']['output'];
  /** ЛО VPV */
  vpv: Scalars['Float']['output'];
};

export type Os1UserStructurePagination = {
  __typename?: 'Os1UserStructurePagination';
  /** List of items on the current page */
  data?: Maybe<Array<Os1UserStructure>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр */
export type Os1UserStructureQueryFilter = {
  /** Название города */
  cityName?: InputMaybe<Scalars['String']['input']>;
  /** GO с */
  goFrom?: InputMaybe<Scalars['Float']['input']>;
  /** ГО ОК с */
  goOkFrom?: InputMaybe<Scalars['Float']['input']>;
  /** ГО ОК до */
  goOkTo?: InputMaybe<Scalars['Float']['input']>;
  /** GO до */
  goTo?: InputMaybe<Scalars['Float']['input']>;
  /** Показывает, кто из партнеров достиг Клуба 200 в выбранном периоде */
  isClub200Period?: InputMaybe<Scalars['Boolean']['input']>;
  /** Показывает, кто из партнеров имеет квалифиц. партнеров в структуре в выбранном периоде */
  isCountQualifiedPartners?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id периода os1 */
  periodId?: InputMaybe<Scalars['Int']['input']>;
  /** Ранги */
  rankIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** ID Ref */
  refCodes?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Дата регистрации с */
  registrationDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата регистрации с */
  registrationDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** ФИО */
  userFullName?: InputMaybe<Scalars['String']['input']>;
  /** Id пользователя os1 */
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type Os1UserStructureQueryPayload = {
  __typename?: 'Os1UserStructureQueryPayload';
  errors?: Maybe<Array<Problem>>;
  users?: Maybe<Os1UserStructurePagination>;
};

/** Ранг пользователя в структуре */
export type Os1UserStructureRank = {
  __typename?: 'Os1UserStructureRank';
  /** Id */
  id: Scalars['Int']['output'];
  /** Название */
  isDone: Scalars['Boolean']['output'];
  /** Название */
  title: Scalars['String']['output'];
};

/** Статистика за период */
export type Os1UserStructureStatPeriod = {
  __typename?: 'Os1UserStructureStatPeriod';
  /** Выполнили до 25 ТМ */
  apv1: Scalars['Int']['output'];
  /** Выполнило 25 ТМ */
  apv25: Scalars['Int']['output'];
  /** 1-24,99 BPV */
  bpv1: Scalars['Int']['output'];
  /** 25-49,99 BPV */
  bpv25: Scalars['Int']['output'];
  /** 50 BPV и выше */
  bpv50: Scalars['Int']['output'];
  /** Сумма BPV по партнерам первой линии */
  bpvSumLevel1: Scalars['Int']['output'];
  /** Количество квалифицированных партнеров */
  countQualifiedPartners: Scalars['Int']['output'];
  /** Число партнеров в структуре */
  countReferral: Scalars['Int']['output'];
  /** Подтвердило квалификацию Партнер */
  rank0: Scalars['Int']['output'];
  /** Подтвердило квалификацию Партнер */
  rank1: Scalars['Int']['output'];
  /**  Подтвердило квалификацию Партнер */
  rank2: Scalars['Int']['output'];
  /** Подтвердило квалификацию 3 */
  rank3: Scalars['Int']['output'];
  /** Подтвердило квалификацию Привилегированный партнер */
  rank4: Scalars['Int']['output'];
  /** Подтвердило квалификацию Изумруд */
  rank5: Scalars['Int']['output'];
  /** Подтвердило квалификацию Сапфир */
  rank6: Scalars['Int']['output'];
  /** Подтвердило квалификацию Бриллиант */
  rank7: Scalars['Int']['output'];
  /** Подтвердило квалификацию Бриллиант 1 карат */
  rank8: Scalars['Int']['output'];
  /** Подтвердило квалификацию Бриллиант 2 карата */
  rank9: Scalars['Int']['output'];
  /** Подтвердило квалификацию 10 */
  rank10: Scalars['Int']['output'];
  /** Подтвердило квалификацию 11 */
  rank11: Scalars['Int']['output'];
  /**  Подтвердило квалификацию Рубин */
  rank12: Scalars['Int']['output'];
  /** Подтвердило квалификацию 13 */
  rank13: Scalars['Int']['output'];
  /** Подтвердило квалификацию 14 */
  rank14: Scalars['Int']['output'];
  /** Подтвердило квалификацию 15 */
  rank15: Scalars['Int']['output'];
  /** Подтвердило квалификацию 16 */
  rank16: Scalars['Int']['output'];
  /** Подтвердило квалификацию 17 */
  rank17: Scalars['Int']['output'];
  /** Подтвердило квалификацию 18 */
  rank18: Scalars['Int']['output'];
  /** Подтвердило квалификацию Гранат */
  rank19: Scalars['Int']['output'];
  /** Подтвердило квалификацию Красный Бриллиант 3 карата */
  rank20: Scalars['Int']['output'];
  /** Подтвердило квалификацию Красный Бриллиант 4 карата */
  rank21: Scalars['Int']['output'];
  /** Подтвердило квалификацию Красный Бриллиант 5 каратов */
  rank22: Scalars['Int']['output'];
  /** Подтвердило квалификацию Красный Бриллиант 6 каратов */
  rank23: Scalars['Int']['output'];
  /** Подтвердило квалификацию 77 */
  rank77: Scalars['Int']['output'];
  /** Подтвердило квалификацию Черный Бриллиант */
  rank78: Scalars['Int']['output'];
  /** Подтвердило квалификацию 99 */
  rank99: Scalars['Int']['output'];
  /** Подтвердило квалификацию 100 */
  rank100: Scalars['Int']['output'];
  /** Подтвердило квалификацию Национальный лидер */
  rank101: Scalars['Int']['output'];
};

/** Информация о постраничной навигации */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Текущая страница */
  currentPage: Scalars['Int']['output'];
  /** Номер первого возвращенного элемента */
  from?: Maybe<Scalars['Int']['output']>;
  /** Есть ли еще страницы */
  hasMorePages: Scalars['Boolean']['output'];
  /** Количество страниц */
  lastPage: Scalars['Int']['output'];
  /** Запрошенное количество элементов */
  perPage: Scalars['Int']['output'];
  /** Номер последнего возвращенного элемента */
  to?: Maybe<Scalars['Int']['output']>;
  /** Всего найдено элементов */
  total: Scalars['Int']['output'];
};

/** Способ оплаты */
export enum PaymentEnum {
  Advcash = 'ADVCASH',
  CashOnDelivery = 'CASH_ON_DELIVERY',
  CreditCard = 'CREDIT_CARD',
  Paypal = 'PAYPAL',
  Qiwi = 'QIWI',
  Revolt = 'REVOLT'
}

/** Группа способа оплаты */
export enum PaymentGroupEnum {
  Acquiring = 'ACQUIRING',
  Cash = 'CASH',
  Online = 'ONLINE',
  OnlineAcquiring = 'ONLINE_ACQUIRING',
  OnlineBalance = 'ONLINE_BALANCE',
  OnlineCash = 'ONLINE_CASH',
  OnlineCertificate = 'ONLINE_CERTIFICATE',
  OnlineWrt = 'ONLINE_WRT',
  OnlineWrtBalance = 'ONLINE_WRT_BALANCE'
}

/** Платежные системы */
export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  /** Платежная система */
  paymentSystem?: Maybe<PaymentSystem>;
  /** Ссылка на оплату */
  paymentUrl?: Maybe<Scalars['String']['output']>;
};

/** Фильтр */
export type PaymentMethodsQueryFilter = {
  /** ID заказа */
  orderId: Scalars['Int']['input'];
};

export type PaymentMethodsQueryPayload = {
  __typename?: 'PaymentMethodsQueryPayload';
  errors?: Maybe<Array<PaymentMethodsQueryProblem>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
};

export type PaymentMethodsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Платежные системы */
export type PaymentSystem = {
  __typename?: 'PaymentSystem';
  /** Аббревиатура */
  abbreviation?: Maybe<Scalars['String']['output']>;
  /** Приложения */
  applications?: Maybe<Array<Maybe<Application>>>;
  /** Код платежной системы */
  code: PaymentSystemEnum;
  /** Валюты */
  currencies?: Maybe<Array<Currency>>;
  /** Принимает платежи */
  isAllowedPayment?: Maybe<Scalars['Boolean']['output']>;
  /** ID OS */
  osId?: Maybe<Scalars['Int']['output']>;
  /** Способ оплаты */
  paymentConsts?: Maybe<Array<PaymentEnum>>;
  /** Статус */
  statusConst?: Maybe<PaymentSystemStatusEnum>;
  /** Название */
  title: Scalars['String']['output'];
  /** Тип платежной системы */
  typeConst?: Maybe<PaymentSystemTypeEnum>;
};

/** Коды платежных систем */
export enum PaymentSystemEnum {
  CyberSource = 'CyberSource',
  Epoint = 'Epoint',
  ModulBank = 'ModulBank',
  Paynet = 'Paynet',
  AdvCashKzt = 'advCashKZT',
  Advcash = 'advcash',
  AdvcashVlv = 'advcash_vlv',
  CPay = 'cPay',
  CardRfi = 'card_rfi',
  Evo = 'evo',
  Evo2Cap = 'evo2_cap',
  EvoCap = 'evo_cap',
  EvoH = 'evo_h',
  EvoHa = 'evo_ha',
  EvoHe = 'evo_he',
  Klikand = 'klikand',
  KlikandpayEs = 'klikandpay_es',
  Payeer = 'payeer',
  Paymill = 'paymill',
  Paypal = 'paypal',
  PaypalGk = 'paypal_gk',
  PaypalVo = 'paypal_vo',
  Paysera = 'paysera',
  Qiwi = 'qiwi',
  Qiwi2 = 'qiwi2',
  Qiwi3 = 'qiwi3',
  Qiwi4 = 'qiwi4',
  Revolut = 'revolut',
  Tinkoff = 'tinkoff',
  Yandex = 'yandex',
  Yizipay = 'yizipay'
}

/** Статус платежной системы */
export enum PaymentSystemStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Тип платежной системы */
export enum PaymentSystemTypeEnum {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

/** Тип способа оплаты */
export enum PaymentTypeEnum {
  Acquiring = 'ACQUIRING',
  Balance = 'BALANCE',
  Cash = 'CASH',
  Certificate = 'CERTIFICATE',
  Online = 'ONLINE',
  Wrt = 'WRT'
}

/** Баллы */
export type Point = {
  __typename?: 'Point';
  /** Код валюты */
  currency: CurrencyEnum;
  /** Цена */
  value?: Maybe<Scalars['Float']['output']>;
};

/** Цена в валюте */
export type Price = {
  __typename?: 'Price';
  /** Код валюты */
  currency: CurrencyEnum;
  /** Цена */
  value?: Maybe<Scalars['Float']['output']>;
};

/** Сущность шаблона печати */
export enum PrintTemplateEntityEnum {
  Contractor = 'CONTRACTOR',
  Order = 'ORDER',
  OrderShipment = 'ORDER_SHIPMENT'
}

/** Фильтр */
export type PrintTemplateLinkQueryFilter = {
  /** ID сущности */
  entityId?: InputMaybe<Scalars['Int']['input']>;
  /** Сущность генерации печатной формы */
  entityType?: InputMaybe<PrintTemplateEntityEnum>;
  /** Тип печатной формы */
  typeConst: PrintTemplateTypeEnum;
};

export type PrintTemplateLinkQueryPayload = {
  __typename?: 'PrintTemplateLinkQueryPayload';
  errors?: Maybe<Array<PrintTemplateLinkQueryProblem>>;
  /** Ссылка скачивания */
  url?: Maybe<Scalars['Url']['output']>;
};

export type PrintTemplateLinkQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Тип шаблона печати */
export enum PrintTemplateTypeEnum {
  ContractorPaidServiceAgreement = 'CONTRACTOR_PAID_SERVICE_AGREEMENT',
  CustomerOrder = 'CUSTOMER_ORDER',
  InvoiceOrderShipment = 'INVOICE_ORDER_SHIPMENT'
}

/** Логические ошибки */
export type Problem = {
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** Общая информация по товарам */
export type Product = {
  __typename?: 'Product';
  /** Данные об товаре, для корзины пользователя */
  cart?: Maybe<ProductCart>;
  /** Список категорий */
  categories?: Maybe<Array<Maybe<Category>>>;
  /** Информация о купоне, который действует на товар */
  couponInfo?: Maybe<ProductCouponInfo>;
  /** Описание */
  description: Scalars['String']['output'];
  /** Документы товара */
  documents?: Maybe<Array<Maybe<ProductDocument>>>;
  /** Флаги товара */
  flags?: Maybe<ProductFlag>;
  /** ID товара */
  id: Scalars['Int']['output'];
  /** Изображения товара */
  images?: Maybe<Array<Maybe<ProductImage>>>;
  /** Доступен к заказу */
  isAvailableToOrder?: Maybe<Scalars['Boolean']['output']>;
  /** Наличие товара в списке избранных пользователя */
  isMyFavorite: Scalars['Boolean']['output'];
  /** Главное изображение товара */
  mainImage?: Maybe<ProductImage>;
  /** Максимальное число товара в корзине */
  maxCountInOrder?: Maybe<Scalars['Int']['output']>;
  /** Баллы товара для пользователя с учетом окружения */
  points?: Maybe<Array<Maybe<ProductPoint>>>;
  /** Цены товара */
  prices?: Maybe<ProductPrices>;
  /** Статистика по товару */
  productStats?: Maybe<ProductStats>;
  /** Похожие товары */
  productsRelated?: Maybe<Array<Maybe<Product>>>;
  /** Краткое описание товара */
  shortDescription: Scalars['String']['output'];
  /** Slug */
  slug: Scalars['String']['output'];
  /** Статус товара */
  statusConst?: Maybe<ProductStatusEnum>;
  /** Теги */
  tags?: Maybe<Array<Maybe<Tag>>>;
  /** Название */
  title: Scalars['String']['output'];
  /** Атрибуты относительно типа продукта */
  typeAttributes?: Maybe<ProductTypeAttributes>;
  /** Тип товара */
  typeConst: ProductTypeEnum;
  /** Параметры относительно типа продукта */
  typeData?: Maybe<ProductTypeData>;
  /** Единица измерения */
  unitConst?: Maybe<ProductUnitEnum>;
  /** Артикул */
  vendorCode: Scalars['String']['output'];
};

/** Параметры для типа Собираемый набор */
export type ProductAssembledSet = {
  __typename?: 'ProductAssembledSet';
  /** Группы собираемого набора */
  groups?: Maybe<Array<ProductAssembledSetGroup>>;
};

/** Группа собираемого набора */
export type ProductAssembledSetGroup = {
  __typename?: 'ProductAssembledSetGroup';
  /** Максимальное количество */
  countMax?: Maybe<Scalars['Int']['output']>;
  /** Номер группы */
  groupNumber?: Maybe<Scalars['Int']['output']>;
  /** Доля */
  groupShape?: Maybe<Scalars['Float']['output']>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Продукты группы */
  products?: Maybe<Array<Product>>;
};

/** Атрибуты для типа Собираемый набор */
export type ProductAssembledSetType = {
  __typename?: 'ProductAssembledSetType';
  /** Параметры для типа Товар */
  product?: Maybe<ProductProductType>;
};

/** Статус наличия товаров на складе */
export enum ProductAvailableStatusInStorageEnum {
  Available = 'AVAILABLE',
  NotAvailable = 'NOT_AVAILABLE',
  PartiallyAvailable = 'PARTIALLY_AVAILABLE'
}

/** Данные об товаре, для корзины пользователя */
export type ProductCart = {
  __typename?: 'ProductCart';
  /** ID позиции товара в корзине */
  cartId?: Maybe<Scalars['Int']['output']>;
  /** Количество товара в корзине пользователя */
  count?: Maybe<Scalars['Int']['output']>;
};

/** Фильтр по категории */
export type ProductCategoryFilterType = {
  __typename?: 'ProductCategoryFilterType';
  /** Количество товаров */
  count?: Maybe<Scalars['Int']['output']>;
  /** Уровень вложенности */
  depth?: Maybe<Scalars['Int']['output']>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** ЧПУ (Slug) */
  slug?: Maybe<Scalars['String']['output']>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
};

/** Параметры для типа Укомплектованный набор */
export type ProductCompletedSet = {
  __typename?: 'ProductCompletedSet';
  /** Продукты укомплектованного набора */
  items?: Maybe<Array<ProductCompletedSetItem>>;
};

/** Продукт укомплектованный набор */
export type ProductCompletedSetItem = {
  __typename?: 'ProductCompletedSetItem';
  /** Количество */
  amount?: Maybe<Scalars['Int']['output']>;
  /** Доля */
  groupShape?: Maybe<Scalars['Float']['output']>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Продукт */
  product?: Maybe<Product>;
};

/** Атрибуты для типа Укомплектованный набор */
export type ProductCompletedSetType = {
  __typename?: 'ProductCompletedSetType';
  /** Параметры для типа Товар */
  product?: Maybe<ProductProductType>;
};

/** Информация о купоне, который действует на товар */
export type ProductCouponInfo = {
  __typename?: 'ProductCouponInfo';
  /** Другие доступные купоны для товара */
  availableOtherCoupons?: Maybe<Array<Maybe<CouponUsageDataType>>>;
  /** Текущий купон для товара */
  currentCoupon?: Maybe<CouponUsageDataType>;
  /** Индекс собираемой группы */
  currentCouponAssembledGroupIndex: Scalars['Int']['output'];
};

/** Документ товара */
export type ProductDocument = {
  __typename?: 'ProductDocument';
  /** Id файла */
  id?: Maybe<Scalars['Int']['output']>;
  /** Название файла */
  title?: Maybe<Scalars['String']['output']>;
  /** Url */
  url?: Maybe<Scalars['Url']['output']>;
};

/** Фильтры по товарам */
export type ProductFilters = {
  __typename?: 'ProductFilters';
  /** Категории */
  categories?: Maybe<Array<Maybe<ProductCategoryFilterType>>>;
  /** Количество товаров по текущим фильтрам */
  count?: Maybe<Scalars['Int']['output']>;
  /** Баллы */
  pointBpv?: Maybe<ProductRangeFilterType>;
  /** Цена */
  price?: Maybe<ProductRangeFilterType>;
  /** Выбранные категории */
  selectedCategories?: Maybe<Array<Maybe<ProductSelectedCategoryFilterType>>>;
  /** Выбранные склады */
  selectedStorages?: Maybe<Array<Maybe<ProductSelectedStorageFilterType>>>;
  /** Выбранные тэги */
  selectedTags?: Maybe<Array<Maybe<ProductSelectedTagFilterType>>>;
  /** Склады */
  storages?: Maybe<Array<Maybe<ProductStorageFilterType>>>;
  /** Тэги */
  tags?: Maybe<Array<Maybe<ProductTagFilterType>>>;
};

/** Флаги товара */
export type ProductFlag = {
  __typename?: 'ProductFlag';
  /** Halal */
  isHalal?: Maybe<Scalars['Boolean']['output']>;
  /** Хит */
  isHit?: Maybe<Scalars['Boolean']['output']>;
  /** Кошер */
  isKosher?: Maybe<Scalars['Boolean']['output']>;
  /** Новинка */
  isNew?: Maybe<Scalars['Boolean']['output']>;
  /** Promax */
  isPromax?: Maybe<Scalars['Boolean']['output']>;
  /** Веган */
  isVegan?: Maybe<Scalars['Boolean']['output']>;
};

export type ProductGroupPayload = {
  __typename?: 'ProductGroupPayload';
  /** Фильтры каталога */
  Filters?: Maybe<FiltersQueryPayload>;
  /** Товар */
  Product?: Maybe<ProductQueryPayload>;
  /** Товары каталога */
  Products?: Maybe<ProductsQueryPayload>;
};


export type ProductGroupPayloadFiltersArgs = {
  commonFilter?: InputMaybe<ProductsQueryFilter>;
  userEnvironmentUuid: Scalars['UUID']['input'];
};


export type ProductGroupPayloadProductArgs = {
  filter?: InputMaybe<ProductQueryFilter>;
  userEnvironmentUuid: Scalars['UUID']['input'];
};


export type ProductGroupPayloadProductsArgs = {
  commonFilter?: InputMaybe<ProductsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  sort?: InputMaybe<ProductsSortEnum>;
  userEnvironmentUuid: Scalars['UUID']['input'];
};

/** Изображение товара */
export type ProductImage = {
  __typename?: 'ProductImage';
  /** Id файла */
  id?: Maybe<Scalars['Int']['output']>;
  /** Изображение */
  image?: Maybe<Image>;
  /** Название файла */
  title?: Maybe<Scalars['String']['output']>;
};

/** Атрибуты для типа Лицензия */
export type ProductLicenseType = {
  __typename?: 'ProductLicenseType';
  /** Количество дней до истечения срока действия лицензии */
  daysUntilExpiration?: Maybe<Scalars['Int']['output']>;
};

export type ProductPagination = {
  __typename?: 'ProductPagination';
  /** List of items on the current page */
  data?: Maybe<Array<Product>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Баллы товара для пользователя с учетом окружения */
export type ProductPoint = {
  __typename?: 'ProductPoint';
  /** Код валюты балла */
  code?: Maybe<CurrencyPointEnum>;
  /** Значение */
  value?: Maybe<Scalars['Float']['output']>;
};

/** Атрибуты для типа Полиграфия */
export type ProductPolygraphyType = {
  __typename?: 'ProductPolygraphyType';
  /** Масса брутто (г.) */
  grossWeight?: Maybe<Scalars['Int']['output']>;
  /** Масса нетто (г.) */
  netWeight?: Maybe<Scalars['Int']['output']>;
};

/** Цены товара */
export type ProductPrices = {
  __typename?: 'ProductPrices';
  /** Цена товара для пользователя с учетом окружения */
  price?: Maybe<Scalars['Float']['output']>;
  /** Название ценовой колонки */
  priceClmnTitle?: Maybe<Scalars['String']['output']>;
  /** Цена товара для колонки Гость */
  priceGuest?: Maybe<Scalars['Float']['output']>;
  /** Цена товара для колонки Партнер */
  pricePartner?: Maybe<Scalars['Float']['output']>;
};

/** Атрибуты для типа Товар */
export type ProductProductType = {
  __typename?: 'ProductProductType';
  /** Вмещаемый объем (мм3) */
  accommodatedVolume?: Maybe<Scalars['Float']['output']>;
  /** Состав */
  composition?: Maybe<Scalars['String']['output']>;
  /** Масса брутто (г.) */
  grossWeight?: Maybe<Scalars['Int']['output']>;
  /** Высота (мм.) */
  height?: Maybe<Scalars['Float']['output']>;
  /** Длина (мм.) */
  length?: Maybe<Scalars['Float']['output']>;
  /** Масса нетто (г.) */
  netWeight?: Maybe<Scalars['Int']['output']>;
  /** Применение */
  using?: Maybe<Scalars['String']['output']>;
  /** Объем (мм3) */
  volume?: Maybe<Scalars['Float']['output']>;
  /** Ширина (мм.) */
  width?: Maybe<Scalars['Float']['output']>;
};

/** Фильтр */
export type ProductQueryFilter = {
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductQueryPayload = {
  __typename?: 'ProductQueryPayload';
  /** Валюта каталога */
  currencyCode?: Maybe<CurrencyEnum>;
  errors?: Maybe<Array<ProductQueryProblem>>;
  product?: Maybe<Product>;
};

export type ProductQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр по диапозону */
export type ProductRangeFilterType = {
  __typename?: 'ProductRangeFilterType';
  /** Максимальное значение */
  max?: Maybe<Scalars['Float']['output']>;
  /** Минимальное значение */
  min?: Maybe<Scalars['Float']['output']>;
};

/** Фильтр по категории */
export type ProductSelectedCategoryFilterType = {
  __typename?: 'ProductSelectedCategoryFilterType';
  /** Уровень вложенности */
  depth?: Maybe<Scalars['Int']['output']>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** ЧПУ (Slug) */
  slug?: Maybe<Scalars['String']['output']>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
};

/** Фильтр по складу */
export type ProductSelectedStorageFilterType = {
  __typename?: 'ProductSelectedStorageFilterType';
  /** Адрес склада */
  address?: Maybe<Address>;
  /** Комментарий */
  comment?: Maybe<Scalars['String']['output']>;
  /** Контакты */
  contact?: Maybe<StorageContact>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
};

/** Фильтр по тэгу */
export type ProductSelectedTagFilterType = {
  __typename?: 'ProductSelectedTagFilterType';
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
};

/** Статистика по товару */
export type ProductStats = {
  __typename?: 'ProductStats';
  /** Средняя оценка опубликованных отзывов */
  reviewsPublishedAvgRating?: Maybe<Scalars['Float']['output']>;
  /** Количество опубликованных отзывов */
  reviewsPublishedCount?: Maybe<Scalars['Int']['output']>;
};

/** Статусы товара */
export enum ProductStatusEnum {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  HiddenForEveryone = 'HIDDEN_FOR_EVERYONE',
  HiddenForVertera = 'HIDDEN_FOR_VERTERA',
  Published = 'PUBLISHED'
}

/** Фильтр по складу */
export type ProductStorageFilterType = {
  __typename?: 'ProductStorageFilterType';
  /** Количество товаров */
  count?: Maybe<Scalars['Int']['output']>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
};

/** Фильтр по тэгу */
export type ProductTagFilterType = {
  __typename?: 'ProductTagFilterType';
  /** Количество товаров */
  count?: Maybe<Scalars['Int']['output']>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
};

export type ProductTypeAttributes = ProductAssembledSetType | ProductCompletedSetType | ProductLicenseType | ProductPolygraphyType | ProductProductType;

export type ProductTypeData = ProductAssembledSet | ProductCompletedSet;

/** Типы товара */
export enum ProductTypeEnum {
  AssembledSet = 'ASSEMBLED_SET',
  Card = 'CARD',
  Certificate = 'CERTIFICATE',
  CompletedSet = 'COMPLETED_SET',
  Franchise = 'FRANCHISE',
  Insurance = 'INSURANCE',
  License = 'LICENSE',
  Package = 'PACKAGE',
  PackingSet = 'PACKING_SET',
  Polygraphy = 'POLYGRAPHY',
  Product = 'PRODUCT',
  Service = 'SERVICE',
  VirtualProduct = 'VIRTUAL_PRODUCT'
}

/** Единицы измерения товара */
export enum ProductUnitEnum {
  Mg = 'MG',
  Ml = 'ML',
  Psc = 'PSC'
}

/** Общий фильтр товаров */
export type ProductsQueryFilter = {
  /** IDs категорий */
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Halal */
  isHalal?: InputMaybe<Scalars['Boolean']['input']>;
  /** Хит */
  isHit?: InputMaybe<Scalars['Boolean']['input']>;
  /** Кошер */
  isKosher?: InputMaybe<Scalars['Boolean']['input']>;
  /** Новинка */
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  /** Promax */
  isPromax?: InputMaybe<Scalars['Boolean']['input']>;
  /** Веган */
  isVegan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Минимальный балл BPV */
  pointBpvFrom?: InputMaybe<Scalars['Float']['input']>;
  /** Максимальный балл BPV */
  pointBpvTo?: InputMaybe<Scalars['Float']['input']>;
  /** Минимальная цена */
  priceFrom?: InputMaybe<Scalars['Float']['input']>;
  /** Максимальная цена */
  priceTo?: InputMaybe<Scalars['Float']['input']>;
  /** Запрос для релевантного поиска */
  relevantSearch?: InputMaybe<Scalars['String']['input']>;
  /** Slugs */
  slugs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** IDs тегов */
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ProductsQueryPayload = {
  __typename?: 'ProductsQueryPayload';
  /** Валюта каталога */
  currencyCode?: Maybe<CurrencyEnum>;
  errors?: Maybe<Array<ProductsQueryProblem>>;
  products?: Maybe<ProductPagination>;
};

export type ProductsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Допустимые сортировки списка товаров */
export enum ProductsSortEnum {
  IsHitDesc = 'IS_HIT_DESC',
  IsNewDesc = 'IS_NEW_DESC',
  PointBpvAsc = 'POINT_BPV_ASC',
  PointBpvDesc = 'POINT_BPV_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC'
}

export type Query = {
  __typename?: 'Query';
  /** Корзина пользователя (Cart) */
  CartGroup?: Maybe<CartGroupPayload>;
  /** Корзина подарков пользователя (CartReward) */
  CartRewardGroup?: Maybe<CartRewardGroupPayload>;
  /** Общее (Translation) */
  CommonGroup?: Maybe<CommonGroupPayload>;
  /** Контент (Material, Webinar) */
  ContentGroup?: Maybe<ContentGroupPayload>;
  /** Купоны (Coupon) */
  CouponGroup?: Maybe<CouponGroupPayload>;
  /** Доставка (Delivery) */
  DeliveryGroup?: Maybe<DeliveryGroupPayload>;
  /** Справочники (Category, Tags, Languages, PrintTemplateLink) */
  DictionaryGroup?: Maybe<DictionaryGroupPayload>;
  /** Фидбек (Review, Comment) */
  FeedbackGroup?: Maybe<FeedbackGroupPayload>;
  /** Финансы (Balance, BalanceTransaction, PaymentSystem, ContractorWithdraw, ContractorWithdrawCalculation) */
  FinanceGroup?: Maybe<FinanceGroupPayload>;
  /** Местоположение (City, Country) */
  GeoGroup?: Maybe<GeoGroupPayload>;
  /** Финансы (Contractor, ContractorWallet, ContractorFile, UserContract) */
  LegalGroup?: Maybe<LegalGroupPayload>;
  /** МЛМ (MlmPeriods, MlmBinarCell, MlmUserBonus, UserMlmBinarSettingReferral) */
  MlmGroup?: Maybe<MlmGroupPayload>;
  /** Данные из os1 */
  Os1Group?: Maybe<Os1GroupPayload>;
  /** Товары (Product) */
  ProductGroup?: Maybe<ProductGroupPayload>;
  /** Склады (StorageOpeningRequest, Storage) */
  StorageGroup?: Maybe<StorageGroupPayload>;
  /** Магазин (заказы) */
  StoreGroup?: Maybe<StoreGroupPayload>;
  /** Пользователи (User, UserRefLinks, UserProductFavorite, UserNotifications, UserCabinetMessage) */
  UserGroup?: Maybe<UserGroupPayload>;
};

/** Реквизиты для ИП */
export type RequisiteCreateIndividualInput = {
  /** Дата выдачи свидетельства / внесения записи в ЕГРИП в формате Y-m-d */
  egripDate?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** ИНН */
  inn?: InputMaybe<Scalars['String']['input']>;
  /** Фамилия */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** ОГРНИП */
  ogrnip?: InputMaybe<Scalars['String']['input']>;
  /** Кем выдано свидетельство ОГРНИП */
  ogrnipIssuer?: InputMaybe<Scalars['String']['input']>;
  /** Дата выдачи паспорта */
  passportDate?: InputMaybe<Scalars['String']['input']>;
  /** Код подразделения паспорта */
  passportDivisionCode?: InputMaybe<Scalars['String']['input']>;
  /** Кем выдан паспорт */
  passportIssuer?: InputMaybe<Scalars['String']['input']>;
  /** Серия и номер паспорта */
  passportSeriesAndNumber?: InputMaybe<Scalars['String']['input']>;
  /** Серия и номер свидетельства о регистрации в качестве ИП */
  seriesAndNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Реквизиты для Юридического лица */
export type RequisiteCreateLegalEntityInput = {
  /** Наименование организации */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** КПП */
  cpp?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** ИНН */
  inn?: InputMaybe<Scalars['String']['input']>;
  /** Фамилия */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** ОГРН */
  ogrn?: InputMaybe<Scalars['String']['input']>;
  /** Дата выдачи паспорта генерального директора */
  passportDate?: InputMaybe<Scalars['String']['input']>;
  /** Код подразделения паспорта генерального директора */
  passportDivisionCode?: InputMaybe<Scalars['String']['input']>;
  /** Кем выдан паспорт генерального директора */
  passportIssuer?: InputMaybe<Scalars['String']['input']>;
  /** Серия и номер паспорта генерального директора */
  passportSeriesAndNumber?: InputMaybe<Scalars['String']['input']>;
  /** Правовая основа */
  userLegalBasisForContract?: InputMaybe<Scalars['String']['input']>;
};

/** Реквизиты для Самозанятого */
export type RequisiteCreateSelfEmployedInput = {
  /** Имя */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** ИНН */
  inn?: InputMaybe<Scalars['String']['input']>;
  /** Фамилия */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Дата выдачи паспорта */
  passportDate?: InputMaybe<Scalars['String']['input']>;
  /** Код подразделения паспорта */
  passportDivisionCode?: InputMaybe<Scalars['String']['input']>;
  /** Кем выдан паспорт */
  passportIssuer?: InputMaybe<Scalars['String']['input']>;
  /** Серия и номер паспорта */
  passportSeriesAndNumber?: InputMaybe<Scalars['String']['input']>;
  /** Дата постановки на учет в качестве самозанятого */
  registrationDate?: InputMaybe<Scalars['String']['input']>;
};

/** Данные для редактирования реквизитов "ИП" */
export type RequisiteUpdateIndividualInput = {
  /** Дата выдачи паспорта */
  passportDate?: InputMaybe<Scalars['String']['input']>;
  /** Код подразделения паспорта */
  passportDivisionCode?: InputMaybe<Scalars['String']['input']>;
  /** Кем выдан паспорт */
  passportIssuer?: InputMaybe<Scalars['String']['input']>;
  /** Серия и номер паспорта */
  passportSeriesAndNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Данные для редактирования реквизитов "Юридического лица" */
export type RequisiteUpdateLegalEntityInput = {
  /** Дата выдачи паспорта генерального директора */
  passportDate?: InputMaybe<Scalars['String']['input']>;
  /** Код подразделения паспорта генерального директора */
  passportDivisionCode?: InputMaybe<Scalars['String']['input']>;
  /** Кем выдан паспорт генерального директора */
  passportIssuer?: InputMaybe<Scalars['String']['input']>;
  /** Серия и номер паспорта генерального директора */
  passportSeriesAndNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Данные для редактирования реквизитов "Самозанятого" */
export type RequisiteUpdateSelfEmployedInput = {
  /** Дата выдачи паспорта */
  passportDate?: InputMaybe<Scalars['String']['input']>;
  /** Код подразделения паспорта */
  passportDivisionCode?: InputMaybe<Scalars['String']['input']>;
  /** Кем выдан паспорт */
  passportIssuer?: InputMaybe<Scalars['String']['input']>;
  /** Серия и номер паспорта */
  passportSeriesAndNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Отзыв */
export type Review = {
  __typename?: 'Review';
  /** Ответ администратора */
  answer?: Maybe<Comment>;
  /** Дата создания */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Описание */
  description?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['Int']['output'];
  /** Изображения отзыва */
  images?: Maybe<Array<ReviewImage>>;
  /** Информация о лайках отзыва */
  likesData?: Maybe<ReviewLike>;
  /** Оценка */
  rating: Scalars['Int']['output'];
  /** Статус */
  statusConst?: Maybe<ReviewStatusEnum>;
  /** Время использования */
  usageTimeConst?: Maybe<ReviewUsageTimeEnum>;
  /** Пользователь */
  user?: Maybe<UserReviewer>;
};

export type ReviewCreateInput = {
  /** Описание */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ID Сущности */
  entityId?: InputMaybe<Scalars['Int']['input']>;
  /** Сущность */
  entityType?: InputMaybe<ReviewEntityTypeEnum>;
  /** Изображения отзыва */
  files?: InputMaybe<Array<Scalars['Upload']['input']>>;
  /** Оценка */
  rating?: InputMaybe<Scalars['Int']['input']>;
  /** Время использования */
  usageTimeConst?: InputMaybe<ReviewUsageTimeEnum>;
};

export type ReviewCreatePayload = {
  __typename?: 'ReviewCreatePayload';
  errors?: Maybe<Array<Maybe<ReviewCreateProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  review?: Maybe<Review>;
};

export type ReviewCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Сущность, для которой создается отзыв */
export enum ReviewEntityTypeEnum {
  Product = 'PRODUCT'
}

/** Изображение товара */
export type ReviewImage = {
  __typename?: 'ReviewImage';
  /** Id файла */
  id?: Maybe<Scalars['Int']['output']>;
  /** Изображение */
  image?: Maybe<Image>;
  /** Название файла */
  title?: Maybe<Scalars['String']['output']>;
};

/** Лайки отзыва */
export type ReviewLike = {
  __typename?: 'ReviewLike';
  /** Количество дизлайков */
  dislike?: Maybe<Scalars['Int']['output']>;
  /** Количество лайков */
  like?: Maybe<Scalars['Int']['output']>;
  /** Реакция пользователя на отзыв */
  userLikeValue?: Maybe<LikeValueEnum>;
};

export type ReviewPagination = {
  __typename?: 'ReviewPagination';
  /** List of items on the current page */
  data?: Maybe<Array<Review>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над отзывами */
export type ReviewPayload = {
  __typename?: 'ReviewPayload';
  Create?: Maybe<ReviewCreatePayload>;
};


/** Операции над отзывами */
export type ReviewPayloadCreateArgs = {
  ReviewCreateInput?: InputMaybe<ReviewCreateInput>;
};

/** Статус отзыва */
export enum ReviewStatusEnum {
  Cancelled = 'CANCELLED',
  Moderating = 'MODERATING',
  Published = 'PUBLISHED'
}

/** Время использования в отзыве */
export enum ReviewUsageTimeEnum {
  FewMonths = 'FEW_MONTHS',
  LessMonth = 'LESS_MONTH',
  MoreYear = 'MORE_YEAR'
}

/** Общий фильтр отзывов */
export type ReviewsQueryFilter = {
  /** ID Сущности */
  entityId: Scalars['Int']['input'];
  /** Сущность */
  entityType: ReviewEntityTypeEnum;
};

export type ReviewsQueryPayload = {
  __typename?: 'ReviewsQueryPayload';
  errors?: Maybe<Array<ReviewsQueryProblem>>;
  reviews?: Maybe<ReviewPagination>;
};

export type ReviewsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Допустимые сортировки списка отзывов */
export enum ReviewsSortEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  LikeDislikeDifferenceDesc = 'LIKE_DISLIKE_DIFFERENCE_DESC'
}

/** Ошибка сервера */
export type ServerError = Problem & {
  __typename?: 'ServerError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  inputName?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** Профиль в социальной сети */
export type SocialNetworkLink = {
  __typename?: 'SocialNetworkLink';
  /** Ссылка */
  link?: Maybe<UrlInfo>;
  /** Username/идентификатор пользователя */
  username?: Maybe<Scalars['String']['output']>;
};

/** Информация по складу для обычных запросов */
export type Storage = {
  __typename?: 'Storage';
  /** Адрес склада */
  address?: Maybe<Address>;
  /** Контакты */
  contact?: Maybe<StorageContact>;
  /** Валюта */
  currency?: Maybe<Currency>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Владелец */
  owner?: Maybe<User>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
};

/** Контакты склада */
export type StorageContact = {
  __typename?: 'StorageContact';
  /** Контактное лицо */
  contactPerson?: Maybe<Scalars['String']['output']>;
  /** Email */
  email?: Maybe<Scalars['String']['output']>;
  /** Телефон */
  phone?: Maybe<Scalars['Phone']['output']>;
  /** Режим работы */
  workingHours?: Maybe<Scalars['String']['output']>;
};

/** Профиль доставки */
export type StorageDelivery = {
  __typename?: 'StorageDelivery';
  /** Тип расчета */
  calculationTypeConst?: Maybe<StorageDeliveryCalculationTypeEnum>;
  /** Валюта */
  currency?: Maybe<Currency>;
  /** Срок доставки */
  days?: Maybe<Scalars['Int']['output']>;
  /** Тип доставки */
  deliveryType?: Maybe<DeliveryType>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Цена */
  price?: Maybe<Scalars['Float']['output']>;
  /** Склад */
  storage?: Maybe<Storage>;
};

/** Тип расчета */
export enum StorageDeliveryCalculationTypeEnum {
  CalculatingByCalculator = 'CALCULATING_BY_CALCULATOR',
  FixPrice = 'FIX_PRICE',
  Free = 'FREE',
  PriceDependsOnAmount = 'PRICE_DEPENDS_ON_AMOUNT',
  PriceDependsOnPointBpv = 'PRICE_DEPENDS_ON_POINT_BPV',
  PriceDependsOnWeight = 'PRICE_DEPENDS_ON_WEIGHT'
}

/** Тип доставки */
export enum StorageDeliveryTypeEnum {
  DpdCourier = 'DPD_COURIER',
  OzonCourier = 'OZON_COURIER',
  OzonPickpoint = 'OZON_PICKPOINT',
  OzonPostamat = 'OZON_POSTAMAT',
  RussianPost = 'RUSSIAN_POST',
  VerteraCourier = 'VERTERA_COURIER',
  VerteraPickpoint = 'VERTERA_PICKPOINT'
}

/** Информация по складу для пользователя */
export type StorageForUser = {
  __typename?: 'StorageForUser';
  /** Адрес склада */
  address?: Maybe<Address>;
  /** Контакты */
  contact?: Maybe<StorageContact>;
  /** Валюта */
  currency?: Maybe<Currency>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Склад доступен пользователю */
  isAllowedClientStorage?: Maybe<Scalars['Boolean']['output']>;
  /** Владелец */
  owner?: Maybe<User>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
};

export type StorageGroupPayload = {
  __typename?: 'StorageGroupPayload';
  /** Заявка на открытие склада */
  StorageOpeningRequest?: Maybe<StorageOpeningRequestQueryPayload>;
  /** Заявки на открытие складов */
  StorageOpeningRequests?: Maybe<StorageOpeningRequestsQueryPayload>;
  /** Склады */
  Storages?: Maybe<StoragesQueryPayload>;
};


export type StorageGroupPayloadStorageOpeningRequestArgs = {
  filter?: InputMaybe<StorageOpeningRequestFilter>;
};


export type StorageGroupPayloadStorageOpeningRequestsArgs = {
  filter?: InputMaybe<StorageOpeningRequestsFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type StorageGroupPayloadStoragesArgs = {
  filter?: InputMaybe<StoragesQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

/** Общая информация по заявке на открытие склада */
export type StorageOpeningRequest = {
  __typename?: 'StorageOpeningRequest';
  /** Контакты */
  contact?: Maybe<StorageContact>;
  /** Документы */
  documents?: Maybe<StorageOpeningRequestDocuments>;
  /** ID заявки */
  id: Scalars['Int']['output'];
  /** Телефон склада */
  phone?: Maybe<Scalars['Phone']['output']>;
  /** Статус */
  statusConst?: Maybe<StorageOpeningRequestStatusEnum>;
  /** Адрес склада */
  storageAddress?: Maybe<StorageOpeningRequestAddress>;
  /** Адрес пользователя */
  userAddress?: Maybe<StorageOpeningRequestAddress>;
  /** PK заявителя */
  userPk?: Maybe<Scalars['String']['output']>;
};

/** Адрес из заявки */
export type StorageOpeningRequestAddress = {
  __typename?: 'StorageOpeningRequestAddress';
  /** Квартира */
  apartNumber?: Maybe<Scalars['String']['output']>;
  /** ID города */
  cityId: Scalars['Int']['output'];
  /** Страна */
  countryCode: CountryEnum;
  /** Код домофона */
  doorphoneCode?: Maybe<Scalars['String']['output']>;
  /** Имя */
  fname?: Maybe<Scalars['String']['output']>;
  /** Корпус */
  houseBlock?: Maybe<Scalars['String']['output']>;
  /** Дом */
  houseNumber: Scalars['String']['output'];
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Широта */
  lat?: Maybe<Scalars['Float']['output']>;
  /** Фамилия */
  lname?: Maybe<Scalars['String']['output']>;
  /** Долгота */
  lng?: Maybe<Scalars['Float']['output']>;
  /** Паспортные данные */
  passport?: Maybe<Scalars['String']['output']>;
  /** Телефон */
  phone?: Maybe<Scalars['Phone']['output']>;
  /** Почтовый индекс */
  postcode?: Maybe<Scalars['String']['output']>;
  /** Улица */
  street?: Maybe<Scalars['String']['output']>;
};

/** Контактное лицо */
export type StorageOpeningRequestAddressInput = {
  /** Квартира */
  apartNumber?: InputMaybe<Scalars['String']['input']>;
  /** ID города */
  cityId?: InputMaybe<Scalars['Int']['input']>;
  /** Страна */
  countryCode?: InputMaybe<CountryEnum>;
  /** Код домофона */
  doorphoneCode?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  fname?: InputMaybe<Scalars['String']['input']>;
  /** Корпус */
  houseBlock?: InputMaybe<Scalars['String']['input']>;
  /** Дом */
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  /** Широта */
  lat?: InputMaybe<Scalars['Float']['input']>;
  /** Фамилия */
  lname?: InputMaybe<Scalars['String']['input']>;
  /** Долгота */
  lng?: InputMaybe<Scalars['Float']['input']>;
  /** Паспортные данные */
  passport?: InputMaybe<Scalars['String']['input']>;
  /** Телефон */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Почтовый индекс */
  postcode?: InputMaybe<Scalars['String']['input']>;
  /** Улица */
  street?: InputMaybe<Scalars['String']['input']>;
};

/** Контактное лицо */
export type StorageOpeningRequestContactInput = {
  /** Контактное лицо */
  contactPerson?: InputMaybe<Scalars['String']['input']>;
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Телефон */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Режим работы */
  workingHours?: InputMaybe<Scalars['String']['input']>;
};

export type StorageOpeningRequestCreateInput = {
  /** Контакты */
  contact?: InputMaybe<StorageOpeningRequestContactInput>;
  /** Документы */
  documents?: InputMaybe<StorageOpeningRequestDocumentsInput>;
  /** Телефон склада */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Адрес склада */
  storageAddress?: InputMaybe<StorageOpeningRequestAddressInput>;
  /** Адрес пользователя */
  userAddress?: InputMaybe<StorageOpeningRequestAddressInput>;
};

export type StorageOpeningRequestCreatePayload = {
  __typename?: 'StorageOpeningRequestCreatePayload';
  errors?: Maybe<Array<StorageOpeningRequestCreateProblem>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type StorageOpeningRequestCreateProblem = ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Файлы заявки на открытие склада */
export type StorageOpeningRequestDocuments = {
  __typename?: 'StorageOpeningRequestDocuments';
  /** Договор */
  contract?: Maybe<Array<StorageOpeningRequestFile>>;
  /** Свидетельство о регистарции ИП */
  individualRegistrationCertificate?: Maybe<Array<StorageOpeningRequestFile>>;
  /** Файлы паспорта */
  passport?: Maybe<Array<StorageOpeningRequestFile>>;
  /** Файлы паспорта */
  statementsAndRecommendations?: Maybe<Array<StorageOpeningRequestFile>>;
  /** Фотографии помещения */
  storagePhotos?: Maybe<Array<StorageOpeningRequestFile>>;
};

/** Загружаемые файлы для заявки на открытие склада */
export type StorageOpeningRequestDocumentsInput = {
  /** Договор */
  contract?: InputMaybe<Scalars['Upload']['input']>;
  /** Свидетельство о регистарции ИП */
  individualRegistrationCertificate?: InputMaybe<Scalars['Upload']['input']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['Upload']['input']>;
  /** Заявление и рекомендации */
  statementsAndRecommendations?: InputMaybe<Scalars['Upload']['input']>;
  /** Фотографии помещения */
  storagePhotos?: InputMaybe<Scalars['Upload']['input']>;
};

/** Файлы заявки на открытие склада */
export type StorageOpeningRequestFile = {
  __typename?: 'StorageOpeningRequestFile';
  /** Ссылка на файл */
  file?: Maybe<Scalars['String']['output']>;
  /** mime type файла */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** Ссылка на превью 300px */
  preview?: Maybe<Scalars['String']['output']>;
  /** Размер файла в байтах */
  size?: Maybe<Scalars['Int']['output']>;
  /** Название файла */
  title?: Maybe<Scalars['String']['output']>;
};

/** Фильтр */
export type StorageOpeningRequestFilter = {
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type StorageOpeningRequestPagination = {
  __typename?: 'StorageOpeningRequestPagination';
  /** List of items on the current page */
  data?: Maybe<Array<StorageOpeningRequest>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над заявками на открытие складов */
export type StorageOpeningRequestPayload = {
  __typename?: 'StorageOpeningRequestPayload';
  Create?: Maybe<StorageOpeningRequestCreatePayload>;
};


/** Операции над заявками на открытие складов */
export type StorageOpeningRequestPayloadCreateArgs = {
  StorageOpeningRequestCreateInput?: InputMaybe<StorageOpeningRequestCreateInput>;
};

export type StorageOpeningRequestProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type StorageOpeningRequestQueryPayload = {
  __typename?: 'StorageOpeningRequestQueryPayload';
  errors?: Maybe<Array<StorageOpeningRequestProblem>>;
  storageOpeningRequest?: Maybe<StorageOpeningRequest>;
};

/** Статус заявки на открытие склада */
export enum StorageOpeningRequestStatusEnum {
  ContractIsSigned = 'CONTRACT_IS_SIGNED',
  Declined = 'DECLINED',
  InCorrection = 'IN_CORRECTION',
  InSignature = 'IN_SIGNATURE',
  InWork = 'IN_WORK',
  New = 'NEW',
  OriginalContractReceived = 'ORIGINAL_CONTRACT_RECEIVED'
}

/** Фильтр */
export type StorageOpeningRequestsFilter = {
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type StorageOpeningRequestsProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type StorageOpeningRequestsQueryPayload = {
  __typename?: 'StorageOpeningRequestsQueryPayload';
  errors?: Maybe<Array<StorageOpeningRequestsProblem>>;
  storageOpeningRequests?: Maybe<StorageOpeningRequestPagination>;
};

export type StoragePagination = {
  __typename?: 'StoragePagination';
  /** List of items on the current page */
  data?: Maybe<Array<Storage>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Общий фильтр складов */
export type StoragesQueryFilter = {
  /** Город профиля доставки */
  deliveryCityId?: InputMaybe<Scalars['Int']['input']>;
  /** Является центром обслуживания */
  isServiceCenter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StoragesQueryPayload = {
  __typename?: 'StoragesQueryPayload';
  errors?: Maybe<Array<StoragesQueryProblem>>;
  storages?: Maybe<StoragePagination>;
};

export type StoragesQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type StoreGroupPayload = {
  __typename?: 'StoreGroupPayload';
  /** Заказ */
  Order?: Maybe<OrderQueryPayload>;
  /** Заказы */
  Orders?: Maybe<OrdersQueryPayload>;
};


export type StoreGroupPayloadOrderArgs = {
  filter?: InputMaybe<OrderQueryFilter>;
};


export type StoreGroupPayloadOrdersArgs = {
  filter?: InputMaybe<OrdersQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

/** Тег */
export type Tag = {
  __typename?: 'Tag';
  /** IDs сущностей тега */
  entityIds?: Maybe<Array<Scalars['Int']['output']>>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Источник тега */
  sourceConst?: Maybe<TagSourceEnum>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
  /** Тип тега */
  typeConst?: Maybe<TagTypeEnum>;
};

export type TagCreateInput = {
  /** Название тега */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type TagCreatePayload = {
  __typename?: 'TagCreatePayload';
  errors?: Maybe<Array<Maybe<TagCreateProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  tag?: Maybe<Tag>;
};

export type TagCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type TagDeletePayload = {
  __typename?: 'TagDeletePayload';
  errors?: Maybe<Array<Maybe<TagDeleteProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type TagDeleteProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type TagEntityInput = {
  /** Id сущности тега */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Тип сущности тега */
  typeConst?: InputMaybe<TagTypeForEntityRelationsUpdateEnum>;
};

/** Операции над связями сущностей с тегами */
export type TagEntityRelationPayload = {
  __typename?: 'TagEntityRelationPayload';
  Update?: Maybe<TagEntityRelationsUpdatePayload>;
};


/** Операции над связями сущностей с тегами */
export type TagEntityRelationPayloadUpdateArgs = {
  TagEntityInput?: InputMaybe<TagEntityInput>;
  addIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  deleteIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Информация привязки тегов к сущности */
export type TagEntityRelationsUpdatePayload = {
  __typename?: 'TagEntityRelationsUpdatePayload';
  errors?: Maybe<Array<TagEntityRelationsUpdateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type TagEntityRelationsUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type TagPagination = {
  __typename?: 'TagPagination';
  /** List of items on the current page */
  data?: Maybe<Array<Tag>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над тегами */
export type TagPayload = {
  __typename?: 'TagPayload';
  Create?: Maybe<TagCreatePayload>;
  Delete?: Maybe<TagDeletePayload>;
  Update?: Maybe<TagUpdatePayload>;
};


/** Операции над тегами */
export type TagPayloadCreateArgs = {
  TagCreateInput?: InputMaybe<TagCreateInput>;
};


/** Операции над тегами */
export type TagPayloadDeleteArgs = {
  id: Scalars['Int']['input'];
};


/** Операции над тегами */
export type TagPayloadUpdateArgs = {
  TagUpdateInput?: InputMaybe<TagUpdateInput>;
  id: Scalars['Int']['input'];
};

/** Источник тега */
export enum TagSourceEnum {
  UserAdmin = 'USER_ADMIN',
  UserPartner = 'USER_PARTNER'
}

/** Тип тега */
export enum TagTypeEnum {
  Order = 'ORDER',
  Product = 'PRODUCT',
  Storage = 'STORAGE',
  User = 'USER'
}

/** Тип тега для мутации редактирования привязок тегов к сущности */
export enum TagTypeForEntityRelationsUpdateEnum {
  User = 'USER'
}

export type TagUpdateInput = {
  /** Название тега */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type TagUpdatePayload = {
  __typename?: 'TagUpdatePayload';
  errors?: Maybe<Array<Maybe<TagUpdateProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  tag?: Maybe<Tag>;
};

export type TagUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type TagsQueryFilter = {
  /** Ids тега */
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Источники тегов */
  sourceConsts?: InputMaybe<Array<TagSourceEnum>>;
  /** Название */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type TagsQueryPayload = {
  __typename?: 'TagsQueryPayload';
  errors?: Maybe<Array<TagsQueryProblem>>;
  tags?: Maybe<TagPagination>;
};

export type TagsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Временные зоны */
export enum TimezoneEnum {
  AfricaAbidjan = 'AFRICA_ABIDJAN',
  AfricaAccra = 'AFRICA_ACCRA',
  AfricaAddisAbaba = 'AFRICA_ADDIS_ABABA',
  AfricaAlgiers = 'AFRICA_ALGIERS',
  AfricaAsmara = 'AFRICA_ASMARA',
  AfricaBamako = 'AFRICA_BAMAKO',
  AfricaBangui = 'AFRICA_BANGUI',
  AfricaBanjul = 'AFRICA_BANJUL',
  AfricaBissau = 'AFRICA_BISSAU',
  AfricaBlantyre = 'AFRICA_BLANTYRE',
  AfricaBrazzaville = 'AFRICA_BRAZZAVILLE',
  AfricaBujumbura = 'AFRICA_BUJUMBURA',
  AfricaCairo = 'AFRICA_CAIRO',
  AfricaCasablanca = 'AFRICA_CASABLANCA',
  AfricaCeuta = 'AFRICA_CEUTA',
  AfricaConakry = 'AFRICA_CONAKRY',
  AfricaDakar = 'AFRICA_DAKAR',
  AfricaDarEsSalaam = 'AFRICA_DAR_ES_SALAAM',
  AfricaDjibouti = 'AFRICA_DJIBOUTI',
  AfricaDouala = 'AFRICA_DOUALA',
  AfricaElAaiun = 'AFRICA_EL_AAIUN',
  AfricaFreetown = 'AFRICA_FREETOWN',
  AfricaGaborone = 'AFRICA_GABORONE',
  AfricaHarare = 'AFRICA_HARARE',
  AfricaJohannesburg = 'AFRICA_JOHANNESBURG',
  AfricaJuba = 'AFRICA_JUBA',
  AfricaKampala = 'AFRICA_KAMPALA',
  AfricaKhartoum = 'AFRICA_KHARTOUM',
  AfricaKigali = 'AFRICA_KIGALI',
  AfricaKinshasa = 'AFRICA_KINSHASA',
  AfricaLagos = 'AFRICA_LAGOS',
  AfricaLibreville = 'AFRICA_LIBREVILLE',
  AfricaLome = 'AFRICA_LOME',
  AfricaLuanda = 'AFRICA_LUANDA',
  AfricaLubumbashi = 'AFRICA_LUBUMBASHI',
  AfricaLusaka = 'AFRICA_LUSAKA',
  AfricaMalabo = 'AFRICA_MALABO',
  AfricaMaputo = 'AFRICA_MAPUTO',
  AfricaMaseru = 'AFRICA_MASERU',
  AfricaMbabane = 'AFRICA_MBABANE',
  AfricaMogadishu = 'AFRICA_MOGADISHU',
  AfricaMonrovia = 'AFRICA_MONROVIA',
  AfricaNairobi = 'AFRICA_NAIROBI',
  AfricaNdjamena = 'AFRICA_NDJAMENA',
  AfricaNiamey = 'AFRICA_NIAMEY',
  AfricaNouakchott = 'AFRICA_NOUAKCHOTT',
  AfricaOuagadougou = 'AFRICA_OUAGADOUGOU',
  AfricaPortoNovo = 'AFRICA_PORTO_NOVO',
  AfricaSaoTome = 'AFRICA_SAO_TOME',
  AfricaTripoli = 'AFRICA_TRIPOLI',
  AfricaTunis = 'AFRICA_TUNIS',
  AfricaWindhoek = 'AFRICA_WINDHOEK',
  AmericaAdak = 'AMERICA_ADAK',
  AmericaAnchorage = 'AMERICA_ANCHORAGE',
  AmericaAnguilla = 'AMERICA_ANGUILLA',
  AmericaAntigua = 'AMERICA_ANTIGUA',
  AmericaAraguaina = 'AMERICA_ARAGUAINA',
  AmericaArgentinaBuenosAires = 'AMERICA_ARGENTINA_BUENOS_AIRES',
  AmericaArgentinaCatamarca = 'AMERICA_ARGENTINA_CATAMARCA',
  AmericaArgentinaCordoba = 'AMERICA_ARGENTINA_CORDOBA',
  AmericaArgentinaJujuy = 'AMERICA_ARGENTINA_JUJUY',
  AmericaArgentinaLaRioja = 'AMERICA_ARGENTINA_LA_RIOJA',
  AmericaArgentinaMendoza = 'AMERICA_ARGENTINA_MENDOZA',
  AmericaArgentinaRioGallegos = 'AMERICA_ARGENTINA_RIO_GALLEGOS',
  AmericaArgentinaSalta = 'AMERICA_ARGENTINA_SALTA',
  AmericaArgentinaSanJuan = 'AMERICA_ARGENTINA_SAN_JUAN',
  AmericaArgentinaSanLuis = 'AMERICA_ARGENTINA_SAN_LUIS',
  AmericaArgentinaTucuman = 'AMERICA_ARGENTINA_TUCUMAN',
  AmericaArgentinaUshuaia = 'AMERICA_ARGENTINA_USHUAIA',
  AmericaAruba = 'AMERICA_ARUBA',
  AmericaAsuncion = 'AMERICA_ASUNCION',
  AmericaAtikokan = 'AMERICA_ATIKOKAN',
  AmericaBahia = 'AMERICA_BAHIA',
  AmericaBahiaBanderas = 'AMERICA_BAHIA_BANDERAS',
  AmericaBarbados = 'AMERICA_BARBADOS',
  AmericaBelem = 'AMERICA_BELEM',
  AmericaBelize = 'AMERICA_BELIZE',
  AmericaBlancSablon = 'AMERICA_BLANC_SABLON',
  AmericaBoaVista = 'AMERICA_BOA_VISTA',
  AmericaBogota = 'AMERICA_BOGOTA',
  AmericaBoise = 'AMERICA_BOISE',
  AmericaCambridgeBay = 'AMERICA_CAMBRIDGE_BAY',
  AmericaCampoGrande = 'AMERICA_CAMPO_GRANDE',
  AmericaCancun = 'AMERICA_CANCUN',
  AmericaCaracas = 'AMERICA_CARACAS',
  AmericaCayenne = 'AMERICA_CAYENNE',
  AmericaCayman = 'AMERICA_CAYMAN',
  AmericaChicago = 'AMERICA_CHICAGO',
  AmericaChihuahua = 'AMERICA_CHIHUAHUA',
  AmericaCostaRica = 'AMERICA_COSTA_RICA',
  AmericaCreston = 'AMERICA_CRESTON',
  AmericaCuiaba = 'AMERICA_CUIABA',
  AmericaCuracao = 'AMERICA_CURACAO',
  AmericaDanmarkshavn = 'AMERICA_DANMARKSHAVN',
  AmericaDawson = 'AMERICA_DAWSON',
  AmericaDawsonCreek = 'AMERICA_DAWSON_CREEK',
  AmericaDenver = 'AMERICA_DENVER',
  AmericaDetroit = 'AMERICA_DETROIT',
  AmericaDominica = 'AMERICA_DOMINICA',
  AmericaEdmonton = 'AMERICA_EDMONTON',
  AmericaEirunepe = 'AMERICA_EIRUNEPE',
  AmericaElSalvador = 'AMERICA_EL_SALVADOR',
  AmericaFortaleza = 'AMERICA_FORTALEZA',
  AmericaFortNelson = 'AMERICA_FORT_NELSON',
  AmericaGlaceBay = 'AMERICA_GLACE_BAY',
  AmericaGooseBay = 'AMERICA_GOOSE_BAY',
  AmericaGrandTurk = 'AMERICA_GRAND_TURK',
  AmericaGrenada = 'AMERICA_GRENADA',
  AmericaGuadeloupe = 'AMERICA_GUADELOUPE',
  AmericaGuatemala = 'AMERICA_GUATEMALA',
  AmericaGuayaquil = 'AMERICA_GUAYAQUIL',
  AmericaGuyana = 'AMERICA_GUYANA',
  AmericaHalifax = 'AMERICA_HALIFAX',
  AmericaHavana = 'AMERICA_HAVANA',
  AmericaHermosillo = 'AMERICA_HERMOSILLO',
  AmericaIndianaIndianapolis = 'AMERICA_INDIANA_INDIANAPOLIS',
  AmericaIndianaKnox = 'AMERICA_INDIANA_KNOX',
  AmericaIndianaMarengo = 'AMERICA_INDIANA_MARENGO',
  AmericaIndianaPetersburg = 'AMERICA_INDIANA_PETERSBURG',
  AmericaIndianaTellCity = 'AMERICA_INDIANA_TELL_CITY',
  AmericaIndianaVevay = 'AMERICA_INDIANA_VEVAY',
  AmericaIndianaVincennes = 'AMERICA_INDIANA_VINCENNES',
  AmericaIndianaWinamac = 'AMERICA_INDIANA_WINAMAC',
  AmericaInuvik = 'AMERICA_INUVIK',
  AmericaIqaluit = 'AMERICA_IQALUIT',
  AmericaJamaica = 'AMERICA_JAMAICA',
  AmericaJuneau = 'AMERICA_JUNEAU',
  AmericaKentuckyLouisville = 'AMERICA_KENTUCKY_LOUISVILLE',
  AmericaKentuckyMonticello = 'AMERICA_KENTUCKY_MONTICELLO',
  AmericaKralendijk = 'AMERICA_KRALENDIJK',
  AmericaLaPaz = 'AMERICA_LA_PAZ',
  AmericaLima = 'AMERICA_LIMA',
  AmericaLosAngeles = 'AMERICA_LOS_ANGELES',
  AmericaLowerPrinces = 'AMERICA_LOWER_PRINCES',
  AmericaMaceio = 'AMERICA_MACEIO',
  AmericaManagua = 'AMERICA_MANAGUA',
  AmericaManaus = 'AMERICA_MANAUS',
  AmericaMarigot = 'AMERICA_MARIGOT',
  AmericaMartinique = 'AMERICA_MARTINIQUE',
  AmericaMatamoros = 'AMERICA_MATAMOROS',
  AmericaMazatlan = 'AMERICA_MAZATLAN',
  AmericaMenominee = 'AMERICA_MENOMINEE',
  AmericaMerida = 'AMERICA_MERIDA',
  AmericaMetlakatla = 'AMERICA_METLAKATLA',
  AmericaMexicoCity = 'AMERICA_MEXICO_CITY',
  AmericaMiquelon = 'AMERICA_MIQUELON',
  AmericaMoncton = 'AMERICA_MONCTON',
  AmericaMonterrey = 'AMERICA_MONTERREY',
  AmericaMontevideo = 'AMERICA_MONTEVIDEO',
  AmericaMontserrat = 'AMERICA_MONTSERRAT',
  AmericaNassau = 'AMERICA_NASSAU',
  AmericaNewYork = 'AMERICA_NEW_YORK',
  AmericaNipigon = 'AMERICA_NIPIGON',
  AmericaNome = 'AMERICA_NOME',
  AmericaNoronha = 'AMERICA_NORONHA',
  AmericaNorthDakotaBeulah = 'AMERICA_NORTH_DAKOTA_BEULAH',
  AmericaNorthDakotaCenter = 'AMERICA_NORTH_DAKOTA_CENTER',
  AmericaNorthDakotaNewSalem = 'AMERICA_NORTH_DAKOTA_NEW_SALEM',
  AmericaNuuk = 'AMERICA_NUUK',
  AmericaOjinaga = 'AMERICA_OJINAGA',
  AmericaPanama = 'AMERICA_PANAMA',
  AmericaPangnirtung = 'AMERICA_PANGNIRTUNG',
  AmericaParamaribo = 'AMERICA_PARAMARIBO',
  AmericaPhoenix = 'AMERICA_PHOENIX',
  AmericaPortoVelho = 'AMERICA_PORTO_VELHO',
  AmericaPortAuPrince = 'AMERICA_PORT_AU_PRINCE',
  AmericaPortOfSpain = 'AMERICA_PORT_OF_SPAIN',
  AmericaPuertoRico = 'AMERICA_PUERTO_RICO',
  AmericaPuntaArenas = 'AMERICA_PUNTA_ARENAS',
  AmericaRainyRiver = 'AMERICA_RAINY_RIVER',
  AmericaRankinInlet = 'AMERICA_RANKIN_INLET',
  AmericaRecife = 'AMERICA_RECIFE',
  AmericaRegina = 'AMERICA_REGINA',
  AmericaResolute = 'AMERICA_RESOLUTE',
  AmericaRioBranco = 'AMERICA_RIO_BRANCO',
  AmericaSantarem = 'AMERICA_SANTAREM',
  AmericaSantiago = 'AMERICA_SANTIAGO',
  AmericaSantoDomingo = 'AMERICA_SANTO_DOMINGO',
  AmericaSaoPaulo = 'AMERICA_SAO_PAULO',
  AmericaScoresbysund = 'AMERICA_SCORESBYSUND',
  AmericaSitka = 'AMERICA_SITKA',
  AmericaStBarthelemy = 'AMERICA_ST_BARTHELEMY',
  AmericaStJohns = 'AMERICA_ST_JOHNS',
  AmericaStKitts = 'AMERICA_ST_KITTS',
  AmericaStLucia = 'AMERICA_ST_LUCIA',
  AmericaStThomas = 'AMERICA_ST_THOMAS',
  AmericaStVincent = 'AMERICA_ST_VINCENT',
  AmericaSwiftCurrent = 'AMERICA_SWIFT_CURRENT',
  AmericaTegucigalpa = 'AMERICA_TEGUCIGALPA',
  AmericaThule = 'AMERICA_THULE',
  AmericaThunderBay = 'AMERICA_THUNDER_BAY',
  AmericaTijuana = 'AMERICA_TIJUANA',
  AmericaToronto = 'AMERICA_TORONTO',
  AmericaTortola = 'AMERICA_TORTOLA',
  AmericaVancouver = 'AMERICA_VANCOUVER',
  AmericaWhitehorse = 'AMERICA_WHITEHORSE',
  AmericaWinnipeg = 'AMERICA_WINNIPEG',
  AmericaYakutat = 'AMERICA_YAKUTAT',
  AmericaYellowknife = 'AMERICA_YELLOWKNIFE',
  AntarcticaCasey = 'ANTARCTICA_CASEY',
  AntarcticaDavis = 'ANTARCTICA_DAVIS',
  AntarcticaDumontdurville = 'ANTARCTICA_DUMONTDURVILLE',
  AntarcticaMacquarie = 'ANTARCTICA_MACQUARIE',
  AntarcticaMawson = 'ANTARCTICA_MAWSON',
  AntarcticaMcmurdo = 'ANTARCTICA_MCMURDO',
  AntarcticaPalmer = 'ANTARCTICA_PALMER',
  AntarcticaRothera = 'ANTARCTICA_ROTHERA',
  AntarcticaSyowa = 'ANTARCTICA_SYOWA',
  AntarcticaTroll = 'ANTARCTICA_TROLL',
  AntarcticaVostok = 'ANTARCTICA_VOSTOK',
  ArcticLongyearbyen = 'ARCTIC_LONGYEARBYEN',
  AsiaAden = 'ASIA_ADEN',
  AsiaAlmaty = 'ASIA_ALMATY',
  AsiaAmman = 'ASIA_AMMAN',
  AsiaAnadyr = 'ASIA_ANADYR',
  AsiaAqtau = 'ASIA_AQTAU',
  AsiaAqtobe = 'ASIA_AQTOBE',
  AsiaAshgabat = 'ASIA_ASHGABAT',
  AsiaAtyrau = 'ASIA_ATYRAU',
  AsiaBaghdad = 'ASIA_BAGHDAD',
  AsiaBahrain = 'ASIA_BAHRAIN',
  AsiaBaku = 'ASIA_BAKU',
  AsiaBangkok = 'ASIA_BANGKOK',
  AsiaBarnaul = 'ASIA_BARNAUL',
  AsiaBeirut = 'ASIA_BEIRUT',
  AsiaBishkek = 'ASIA_BISHKEK',
  AsiaBrunei = 'ASIA_BRUNEI',
  AsiaChita = 'ASIA_CHITA',
  AsiaChoibalsan = 'ASIA_CHOIBALSAN',
  AsiaColombo = 'ASIA_COLOMBO',
  AsiaDamascus = 'ASIA_DAMASCUS',
  AsiaDhaka = 'ASIA_DHAKA',
  AsiaDili = 'ASIA_DILI',
  AsiaDubai = 'ASIA_DUBAI',
  AsiaDushanbe = 'ASIA_DUSHANBE',
  AsiaFamagusta = 'ASIA_FAMAGUSTA',
  AsiaGaza = 'ASIA_GAZA',
  AsiaHebron = 'ASIA_HEBRON',
  AsiaHongKong = 'ASIA_HONG_KONG',
  AsiaHovd = 'ASIA_HOVD',
  AsiaHoChiMinh = 'ASIA_HO_CHI_MINH',
  AsiaIrkutsk = 'ASIA_IRKUTSK',
  AsiaJakarta = 'ASIA_JAKARTA',
  AsiaJayapura = 'ASIA_JAYAPURA',
  AsiaJerusalem = 'ASIA_JERUSALEM',
  AsiaKabul = 'ASIA_KABUL',
  AsiaKamchatka = 'ASIA_KAMCHATKA',
  AsiaKarachi = 'ASIA_KARACHI',
  AsiaKathmandu = 'ASIA_KATHMANDU',
  AsiaKhandyga = 'ASIA_KHANDYGA',
  AsiaKolkata = 'ASIA_KOLKATA',
  AsiaKrasnoyarsk = 'ASIA_KRASNOYARSK',
  AsiaKualaLumpur = 'ASIA_KUALA_LUMPUR',
  AsiaKuching = 'ASIA_KUCHING',
  AsiaKuwait = 'ASIA_KUWAIT',
  AsiaMacau = 'ASIA_MACAU',
  AsiaMagadan = 'ASIA_MAGADAN',
  AsiaMakassar = 'ASIA_MAKASSAR',
  AsiaManila = 'ASIA_MANILA',
  AsiaMuscat = 'ASIA_MUSCAT',
  AsiaNicosia = 'ASIA_NICOSIA',
  AsiaNovokuznetsk = 'ASIA_NOVOKUZNETSK',
  AsiaNovosibirsk = 'ASIA_NOVOSIBIRSK',
  AsiaOmsk = 'ASIA_OMSK',
  AsiaOral = 'ASIA_ORAL',
  AsiaPhnomPenh = 'ASIA_PHNOM_PENH',
  AsiaPontianak = 'ASIA_PONTIANAK',
  AsiaPyongyang = 'ASIA_PYONGYANG',
  AsiaQatar = 'ASIA_QATAR',
  AsiaQostanay = 'ASIA_QOSTANAY',
  AsiaQyzylorda = 'ASIA_QYZYLORDA',
  AsiaRiyadh = 'ASIA_RIYADH',
  AsiaSakhalin = 'ASIA_SAKHALIN',
  AsiaSamarkand = 'ASIA_SAMARKAND',
  AsiaSeoul = 'ASIA_SEOUL',
  AsiaShanghai = 'ASIA_SHANGHAI',
  AsiaSingapore = 'ASIA_SINGAPORE',
  AsiaSrednekolymsk = 'ASIA_SREDNEKOLYMSK',
  AsiaTaipei = 'ASIA_TAIPEI',
  AsiaTashkent = 'ASIA_TASHKENT',
  AsiaTbilisi = 'ASIA_TBILISI',
  AsiaTehran = 'ASIA_TEHRAN',
  AsiaThimphu = 'ASIA_THIMPHU',
  AsiaTokyo = 'ASIA_TOKYO',
  AsiaTomsk = 'ASIA_TOMSK',
  AsiaUlaanbaatar = 'ASIA_ULAANBAATAR',
  AsiaUrumqi = 'ASIA_URUMQI',
  AsiaUstNera = 'ASIA_UST_NERA',
  AsiaVientiane = 'ASIA_VIENTIANE',
  AsiaVladivostok = 'ASIA_VLADIVOSTOK',
  AsiaYakutsk = 'ASIA_YAKUTSK',
  AsiaYangon = 'ASIA_YANGON',
  AsiaYekaterinburg = 'ASIA_YEKATERINBURG',
  AsiaYerevan = 'ASIA_YEREVAN',
  AtlanticAzores = 'ATLANTIC_AZORES',
  AtlanticBermuda = 'ATLANTIC_BERMUDA',
  AtlanticCanary = 'ATLANTIC_CANARY',
  AtlanticCapeVerde = 'ATLANTIC_CAPE_VERDE',
  AtlanticFaroe = 'ATLANTIC_FAROE',
  AtlanticMadeira = 'ATLANTIC_MADEIRA',
  AtlanticReykjavik = 'ATLANTIC_REYKJAVIK',
  AtlanticSouthGeorgia = 'ATLANTIC_SOUTH_GEORGIA',
  AtlanticStanley = 'ATLANTIC_STANLEY',
  AtlanticStHelena = 'ATLANTIC_ST_HELENA',
  AustraliaAdelaide = 'AUSTRALIA_ADELAIDE',
  AustraliaBrisbane = 'AUSTRALIA_BRISBANE',
  AustraliaBrokenHill = 'AUSTRALIA_BROKEN_HILL',
  AustraliaDarwin = 'AUSTRALIA_DARWIN',
  AustraliaEucla = 'AUSTRALIA_EUCLA',
  AustraliaHobart = 'AUSTRALIA_HOBART',
  AustraliaLindeman = 'AUSTRALIA_LINDEMAN',
  AustraliaLordHowe = 'AUSTRALIA_LORD_HOWE',
  AustraliaMelbourne = 'AUSTRALIA_MELBOURNE',
  AustraliaPerth = 'AUSTRALIA_PERTH',
  AustraliaSydney = 'AUSTRALIA_SYDNEY',
  EuropeAmsterdam = 'EUROPE_AMSTERDAM',
  EuropeAndorra = 'EUROPE_ANDORRA',
  EuropeAstrakhan = 'EUROPE_ASTRAKHAN',
  EuropeAthens = 'EUROPE_ATHENS',
  EuropeBelgrade = 'EUROPE_BELGRADE',
  EuropeBerlin = 'EUROPE_BERLIN',
  EuropeBratislava = 'EUROPE_BRATISLAVA',
  EuropeBrussels = 'EUROPE_BRUSSELS',
  EuropeBucharest = 'EUROPE_BUCHAREST',
  EuropeBudapest = 'EUROPE_BUDAPEST',
  EuropeBusingen = 'EUROPE_BUSINGEN',
  EuropeChisinau = 'EUROPE_CHISINAU',
  EuropeCopenhagen = 'EUROPE_COPENHAGEN',
  EuropeDublin = 'EUROPE_DUBLIN',
  EuropeGibraltar = 'EUROPE_GIBRALTAR',
  EuropeGuernsey = 'EUROPE_GUERNSEY',
  EuropeHelsinki = 'EUROPE_HELSINKI',
  EuropeIsleOfMan = 'EUROPE_ISLE_OF_MAN',
  EuropeIstanbul = 'EUROPE_ISTANBUL',
  EuropeJersey = 'EUROPE_JERSEY',
  EuropeKaliningrad = 'EUROPE_KALININGRAD',
  EuropeKiev = 'EUROPE_KIEV',
  EuropeKirov = 'EUROPE_KIROV',
  EuropeLisbon = 'EUROPE_LISBON',
  EuropeLjubljana = 'EUROPE_LJUBLJANA',
  EuropeLondon = 'EUROPE_LONDON',
  EuropeLuxembourg = 'EUROPE_LUXEMBOURG',
  EuropeMadrid = 'EUROPE_MADRID',
  EuropeMalta = 'EUROPE_MALTA',
  EuropeMariehamn = 'EUROPE_MARIEHAMN',
  EuropeMinsk = 'EUROPE_MINSK',
  EuropeMonaco = 'EUROPE_MONACO',
  EuropeMoscow = 'EUROPE_MOSCOW',
  EuropeOslo = 'EUROPE_OSLO',
  EuropeParis = 'EUROPE_PARIS',
  EuropePodgorica = 'EUROPE_PODGORICA',
  EuropePrague = 'EUROPE_PRAGUE',
  EuropeRiga = 'EUROPE_RIGA',
  EuropeRome = 'EUROPE_ROME',
  EuropeSamara = 'EUROPE_SAMARA',
  EuropeSanMarino = 'EUROPE_SAN_MARINO',
  EuropeSarajevo = 'EUROPE_SARAJEVO',
  EuropeSaratov = 'EUROPE_SARATOV',
  EuropeSimferopol = 'EUROPE_SIMFEROPOL',
  EuropeSkopje = 'EUROPE_SKOPJE',
  EuropeSofia = 'EUROPE_SOFIA',
  EuropeStockholm = 'EUROPE_STOCKHOLM',
  EuropeTallinn = 'EUROPE_TALLINN',
  EuropeTirane = 'EUROPE_TIRANE',
  EuropeUlyanovsk = 'EUROPE_ULYANOVSK',
  EuropeUzhgorod = 'EUROPE_UZHGOROD',
  EuropeVaduz = 'EUROPE_VADUZ',
  EuropeVatican = 'EUROPE_VATICAN',
  EuropeVienna = 'EUROPE_VIENNA',
  EuropeVilnius = 'EUROPE_VILNIUS',
  EuropeVolgograd = 'EUROPE_VOLGOGRAD',
  EuropeWarsaw = 'EUROPE_WARSAW',
  EuropeZagreb = 'EUROPE_ZAGREB',
  EuropeZaporozhye = 'EUROPE_ZAPOROZHYE',
  EuropeZurich = 'EUROPE_ZURICH',
  IndianAntananarivo = 'INDIAN_ANTANANARIVO',
  IndianChagos = 'INDIAN_CHAGOS',
  IndianChristmas = 'INDIAN_CHRISTMAS',
  IndianCocos = 'INDIAN_COCOS',
  IndianComoro = 'INDIAN_COMORO',
  IndianKerguelen = 'INDIAN_KERGUELEN',
  IndianMahe = 'INDIAN_MAHE',
  IndianMaldives = 'INDIAN_MALDIVES',
  IndianMauritius = 'INDIAN_MAURITIUS',
  IndianMayotte = 'INDIAN_MAYOTTE',
  IndianReunion = 'INDIAN_REUNION',
  PacificApia = 'PACIFIC_APIA',
  PacificAuckland = 'PACIFIC_AUCKLAND',
  PacificBougainville = 'PACIFIC_BOUGAINVILLE',
  PacificChatham = 'PACIFIC_CHATHAM',
  PacificChuuk = 'PACIFIC_CHUUK',
  PacificEaster = 'PACIFIC_EASTER',
  PacificEfate = 'PACIFIC_EFATE',
  PacificFakaofo = 'PACIFIC_FAKAOFO',
  PacificFiji = 'PACIFIC_FIJI',
  PacificFunafuti = 'PACIFIC_FUNAFUTI',
  PacificGalapagos = 'PACIFIC_GALAPAGOS',
  PacificGambier = 'PACIFIC_GAMBIER',
  PacificGuadalcanal = 'PACIFIC_GUADALCANAL',
  PacificGuam = 'PACIFIC_GUAM',
  PacificHonolulu = 'PACIFIC_HONOLULU',
  PacificKanton = 'PACIFIC_KANTON',
  PacificKiritimati = 'PACIFIC_KIRITIMATI',
  PacificKosrae = 'PACIFIC_KOSRAE',
  PacificKwajalein = 'PACIFIC_KWAJALEIN',
  PacificMajuro = 'PACIFIC_MAJURO',
  PacificMarquesas = 'PACIFIC_MARQUESAS',
  PacificMidway = 'PACIFIC_MIDWAY',
  PacificNauru = 'PACIFIC_NAURU',
  PacificNiue = 'PACIFIC_NIUE',
  PacificNorfolk = 'PACIFIC_NORFOLK',
  PacificNoumea = 'PACIFIC_NOUMEA',
  PacificPagoPago = 'PACIFIC_PAGO_PAGO',
  PacificPalau = 'PACIFIC_PALAU',
  PacificPitcairn = 'PACIFIC_PITCAIRN',
  PacificPohnpei = 'PACIFIC_POHNPEI',
  PacificPortMoresby = 'PACIFIC_PORT_MORESBY',
  PacificRarotonga = 'PACIFIC_RAROTONGA',
  PacificSaipan = 'PACIFIC_SAIPAN',
  PacificTahiti = 'PACIFIC_TAHITI',
  PacificTarawa = 'PACIFIC_TARAWA',
  PacificTongatapu = 'PACIFIC_TONGATAPU',
  PacificWake = 'PACIFIC_WAKE',
  PacificWallis = 'PACIFIC_WALLIS'
}

/** Информация о переводе */
export type Translation = {
  __typename?: 'Translation';
  /** Группа */
  group?: Maybe<TranslationGroupEnum>;
  /** Ключ */
  key?: Maybe<Scalars['String']['output']>;
  /** Перевод */
  translation?: Maybe<Scalars['String']['output']>;
};

/** Поле с переводом */
export type TranslationField = {
  __typename?: 'TranslationField';
  /** Язык */
  language?: Maybe<LanguageEnum>;
  /** Значение поля */
  value?: Maybe<Scalars['String']['output']>;
};

/**
 * Группа переводов.
 *                 <br>LITERAL - Литерал,
 *                 <br>CONSTANT - Константа,
 *                 <br>APP - App
 */
export enum TranslationGroupEnum {
  App = 'APP',
  Constant = 'CONSTANT',
  Literal = 'LITERAL'
}

export type TranslationPagination = {
  __typename?: 'TranslationPagination';
  /** List of items on the current page */
  data?: Maybe<Array<Translation>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

export type TranslationsProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр по переводам */
export type TranslationsQueryFilter = {
  /** Группа */
  group?: InputMaybe<TranslationGroupEnum>;
};

export type TranslationsQueryPayload = {
  __typename?: 'TranslationsQueryPayload';
  errors?: Maybe<Array<TranslationsProblem>>;
  translations?: Maybe<TranslationPagination>;
};

export type UtmInput = {
  /** Название рекламной кампании */
  campaign?: InputMaybe<Scalars['String']['input']>;
  /** Информация, которая помогает различать объявления, если совпадают другие параметры */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Тип трафика */
  medium?: InputMaybe<Scalars['String']['input']>;
  /** Рекламная система */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Ключевое слово, проинициировавшее показ объявления */
  term?: InputMaybe<Scalars['String']['input']>;
};

/** Информация о url */
export type UrlInfo = {
  __typename?: 'UrlInfo';
  /** Domain */
  domain: Scalars['Url']['output'];
  /** Полный URL */
  originalUrl: Scalars['Url']['output'];
  /** Path */
  path?: Maybe<Scalars['String']['output']>;
};

/** Информация по пользователю для самого пользователя */
export type User = UserForReferrerInterface & UserInterface & {
  __typename?: 'User';
  /** Аватар пользователя */
  avatar?: Maybe<UserAvatarImage>;
  /** Город */
  city?: Maybe<City>;
  /** Доверенный склад */
  clientStorage?: Maybe<StorageForUser>;
  /** Дата заключения контракта */
  contractCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Страна */
  country?: Maybe<Country>;
  /** Дата регистрации */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Список почты */
  emails?: Maybe<Array<Maybe<UserEmail>>>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Язык пользователя */
  locale?: Maybe<LanguageEnum>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Данные по mlm бинару пользователя */
  mlmBinar?: Maybe<UserDataMlmBinar>;
  /** Статистика по текущему периоду */
  mlmPeriodStat?: Maybe<UserDataMlmPeriodStat>;
  /** Максимальный ранг пользователя за всю историю */
  mlmRank?: Maybe<MlmRank>;
  /** Данные по личной активности */
  mlmUserActivity?: Maybe<UserDataMlmActivity>;
  /** Данные по лидерской активности */
  mlmUserLeaderActivity?: Maybe<UserDataMlmUserLeaderActivity>;
  /** Подписки пользователя на каналы уведомлений */
  notificationSettings?: Maybe<Array<Maybe<UserNotificationSetting>>>;
  /** Parents пользователя */
  parents?: Maybe<UserParentWithReferrerAndSponsor>;
  /** Список телефонов */
  phones?: Maybe<Array<Maybe<UserPhone>>>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
  /** Баллов BPV с заказов всего за всю историю */
  pointBpv?: Maybe<Scalars['Float']['output']>;
  /** Дополнительные данные по пользователю */
  profile?: Maybe<UserProfile>;
  /** Ссылки на социальные сети пользователя */
  social?: Maybe<UserSocial>;
  /** Статистика для родительских наставник/спонсор */
  statsForParent?: Maybe<UserStatsForParent>;
  /** Временная зона */
  timezone?: Maybe<TimezoneEnum>;
};

/** Разрешено ли пользователю выполнять те или иные действия */
export type UserAccess = {
  __typename?: 'UserAccess';
  /** Вывод средств */
  isAllowContractorWithdraw: Scalars['Boolean']['output'];
  /** Редактирование настроек пользователя по размещению в бинаре */
  isUserMlmBinarSettingReferral: Scalars['Boolean']['output'];
};

export type UserAccessQueryPayload = {
  __typename?: 'UserAccessQueryPayload';
  errors?: Maybe<Array<UserAccessQueryProblem>>;
  userAccess?: Maybe<UserAccess>;
};

export type UserAccessQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Информация об ответах на пользовательские соглашения */
export type UserAgreementSetting = {
  __typename?: 'UserAgreementSetting';
  /** Ответ */
  answerConst?: Maybe<UserAgreementSettingAnswerEnum>;
  /** Тип соглашения */
  typeConst?: Maybe<UserAgreementSettingTypeEnum>;
};

/** Ответ */
export enum UserAgreementSettingAnswerEnum {
  No = 'NO',
  Yes = 'YES'
}

/** Тип пользовательского соглашения */
export enum UserAgreementSettingTypeEnum {
  PartnerAgreement = 'PARTNER_AGREEMENT'
}

export type UserAgreementSettingUpdateInput = {
  /** Ответ */
  answerConst?: InputMaybe<UserAgreementSettingAnswerEnum>;
  /** Тип соглашения */
  typeConst?: InputMaybe<UserAgreementSettingTypeEnum>;
};

/** Информация об ответах на пользовательские соглашения */
export type UserAgreementSettingUpdatePayload = {
  __typename?: 'UserAgreementSettingUpdatePayload';
  errors?: Maybe<Array<UserAgreementSettingUpdateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
  userAgreementSetting?: Maybe<UserAgreementSetting>;
};

export type UserAgreementSettingUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Аватар пользователя */
export type UserAvatarImage = {
  __typename?: 'UserAvatarImage';
  /** Id файла */
  id?: Maybe<Scalars['Int']['output']>;
  /** Изображение */
  image?: Maybe<Image>;
};

/** Данные для создания аватара пользователя */
export type UserAvatarImageCreateInput = {
  /** Файл аватара пользователя */
  file?: InputMaybe<Scalars['Upload']['input']>;
};

/** Информация добавления файла аватара пользователя */
export type UserAvatarImageCreatePayload = {
  __typename?: 'UserAvatarImageCreatePayload';
  errors?: Maybe<Array<UserAvatarImageCreateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
  /** Пользователь */
  user?: Maybe<User>;
};

export type UserAvatarImageCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Информация удаления аватара пользователя */
export type UserAvatarImageDeletePayload = {
  __typename?: 'UserAvatarImageDeletePayload';
  errors?: Maybe<Array<UserAvatarImageDeleteProblem>>;
  fileId?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type UserAvatarImageDeleteProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Сообщение в ЛК */
export type UserCabinetMessage = {
  __typename?: 'UserCabinetMessage';
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Шаблон сообщения */
  messageTemplate?: Maybe<MailingMessageTemplate>;
  /** Родитель */
  parent?: Maybe<UserCabinetMessage>;
  /** Дата публикации */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Источник */
  sourceConst?: Maybe<UserCabinetMessageSourceEnum>;
  /** Статус */
  statusConst?: Maybe<UserCabinetMessageStatusEnum>;
  /** Пользователь */
  user?: Maybe<User>;
};

export type UserCabinetMessagePagination = {
  __typename?: 'UserCabinetMessagePagination';
  /** List of items on the current page */
  data?: Maybe<Array<UserCabinetMessage>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр */
export type UserCabinetMessageQueryFilter = {
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type UserCabinetMessageQueryPayload = {
  __typename?: 'UserCabinetMessageQueryPayload';
  errors?: Maybe<Array<UserCabinetMessageQueryProblem>>;
  userCabinetMessage?: Maybe<UserCabinetMessage>;
};

export type UserCabinetMessageQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Источники */
export enum UserCabinetMessageSourceEnum {
  Mass = 'MASS',
  Personal = 'PERSONAL'
}

/** Статусы */
export enum UserCabinetMessageStatusEnum {
  New = 'NEW',
  Viewed = 'VIEWED'
}

export type UserCabinetMessageUpdateInput = {
  /** Повторная отправка сообщения через 24 часа */
  isResend?: InputMaybe<Scalars['Boolean']['input']>;
  /** Статус */
  statusConst?: InputMaybe<UserCabinetMessageStatusEnum>;
};

/** Информация о состоянии сообщения в лк пользователя */
export type UserCabinetMessageUpdatePayload = {
  __typename?: 'UserCabinetMessageUpdatePayload';
  errors?: Maybe<Array<UserCabinetMessageUpdateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type UserCabinetMessageUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type UserCabinetMessagesQueryFilter = {
  /** Статус */
  statusConsts?: InputMaybe<Array<UserCabinetMessageStatusEnum>>;
};

export type UserCabinetMessagesQueryPayload = {
  __typename?: 'UserCabinetMessagesQueryPayload';
  errors?: Maybe<Array<UserCabinetMessagesQueryProblem>>;
  stats?: Maybe<UserCabinetMessagesQueryStat>;
  userCabinetMessages?: Maybe<UserCabinetMessagePagination>;
};

export type UserCabinetMessagesQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Статистика */
export type UserCabinetMessagesQueryStat = {
  __typename?: 'UserCabinetMessagesQueryStat';
  /** Число непрочитанных сообщений */
  newCount?: Maybe<Scalars['Int']['output']>;
};

/** Договор контрагента */
export type UserContract = {
  __typename?: 'UserContract';
  /** Контрагент компании */
  companyContractor?: Maybe<Contractor>;
  /** Номер договора */
  contractNumber?: Maybe<Scalars['String']['output']>;
  /** Дата подписания договора */
  contractSignedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Файлы */
  files?: Maybe<Array<Maybe<UserContractFile>>>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Статус */
  statusConst?: Maybe<UserContractStatusEnum>;
  /** Тип */
  typeConst?: Maybe<UserContractTypeEnum>;
  /** Контрагент партнера */
  userContractor?: Maybe<Contractor>;
  /** Кошелек контрагента партнера */
  userContractorWallet?: Maybe<ContractorWallet>;
};

export type UserContractCreateInput = {
  /** IDs прикрепляемых файлов */
  fileIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Тип договора контрагента */
  typeConst?: InputMaybe<UserContractTypeEnum>;
  /** ID контрагента */
  userContractorId?: InputMaybe<Scalars['Int']['input']>;
  /** ID кошелька контрагента */
  userContractorWalletId?: InputMaybe<Scalars['Int']['input']>;
};

/** Информация создания договора контрагента */
export type UserContractCreatePayload = {
  __typename?: 'UserContractCreatePayload';
  errors?: Maybe<Array<UserContractCreateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
  userContract?: Maybe<UserContract>;
};

export type UserContractCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Информация удаления договора контрагента */
export type UserContractDeletePayload = {
  __typename?: 'UserContractDeletePayload';
  errors?: Maybe<Array<UserContractDeleteProblem>>;
  /** ID удаленного договора контрагента */
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type UserContractDeleteProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Файл договора контрагента */
export type UserContractFile = {
  __typename?: 'UserContractFile';
  /** Ссылка на файл */
  file?: Maybe<Scalars['String']['output']>;
  /** Id файла */
  id?: Maybe<Scalars['Int']['output']>;
  /** mime type файла */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** Размер файла в байтах */
  size?: Maybe<Scalars['Int']['output']>;
  /** Статус файла */
  statusConst?: Maybe<ContractorFileStatusEnum>;
  /** Название файла */
  title?: Maybe<Scalars['String']['output']>;
  /** Тип файла */
  typeConst?: Maybe<ContractorFileTypeEnum>;
};

/** Добавление и удаление файлов контрагента к договору */
export type UserContractFileIdsUpdateInput = {
  /** Добавление файлов контрагента к договору */
  add?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Удаление файлов контрагента от договора */
  delete?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserContractPagination = {
  __typename?: 'UserContractPagination';
  /** List of items on the current page */
  data?: Maybe<Array<UserContract>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над договорами контрагента */
export type UserContractPayload = {
  __typename?: 'UserContractPayload';
  /** Создание договора контрагента */
  Create?: Maybe<UserContractCreatePayload>;
  /** Удаление договора контрагента */
  Delete?: Maybe<UserContractDeletePayload>;
  /** Редактирование договора контрагента */
  Update?: Maybe<UserContractUpdatePayload>;
};


/** Операции над договорами контрагента */
export type UserContractPayloadCreateArgs = {
  UserContractCreateInput?: InputMaybe<UserContractCreateInput>;
};


/** Операции над договорами контрагента */
export type UserContractPayloadDeleteArgs = {
  id: Scalars['Int']['input'];
};


/** Операции над договорами контрагента */
export type UserContractPayloadUpdateArgs = {
  UserContractUpdateInput?: InputMaybe<UserContractUpdateInput>;
  id: Scalars['Int']['input'];
};

/** Фильтр */
export type UserContractQueryFilter = {
  /** ID договора контрагента */
  id: Scalars['Int']['input'];
};

export type UserContractQueryPayload = {
  __typename?: 'UserContractQueryPayload';
  errors?: Maybe<Array<UserContractQueryProblem>>;
  userContract?: Maybe<UserContract>;
};

export type UserContractQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Статус договора контрагента */
export enum UserContractStatusEnum {
  Moderation = 'MODERATION',
  New = 'NEW',
  Rejected = 'REJECTED',
  Verified = 'VERIFIED'
}

/** Тип договора контрагента */
export enum UserContractTypeEnum {
  ContractorWithdraw = 'CONTRACTOR_WITHDRAW',
  StorageOpening = 'STORAGE_OPENING'
}

export type UserContractUpdateInput = {
  /** IDs связанных файлов контрагента */
  fileIds?: InputMaybe<UserContractFileIdsUpdateInput>;
  /** Статус */
  statusConst?: InputMaybe<UserContractStatusEnum>;
  /** ID прикрепляемого кошелька контрагента */
  userContractorWalletId?: InputMaybe<Scalars['Int']['input']>;
};

/** Информация изменения договора контрагента */
export type UserContractUpdatePayload = {
  __typename?: 'UserContractUpdatePayload';
  errors?: Maybe<Array<UserContractUpdateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
  userContract?: Maybe<UserContract>;
};

export type UserContractUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type UserContractsQueryFilter = {
  /** IDs */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Статус */
  statusConsts?: InputMaybe<Array<UserContractStatusEnum>>;
  /** Тип */
  typeConsts?: InputMaybe<Array<UserContractTypeEnum>>;
  /** Ids контрагентов пользователя */
  userContractorIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserContractsQueryPayload = {
  __typename?: 'UserContractsQueryPayload';
  errors?: Maybe<Array<ContractorsQueryProblem>>;
  userContracts?: Maybe<UserContractPagination>;
};

export type UserContractsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Активность пользователя */
export type UserDataMlmActivity = {
  __typename?: 'UserDataMlmActivity';
  /** Есть личная активность */
  isMlmActivity?: Maybe<Scalars['Boolean']['output']>;
  /** Начало активности */
  startAt?: Maybe<Scalars['DateTime']['output']>;
  /** Статистика по личной активности пользователя */
  stats?: Maybe<UserDataMlmActivityStats>;
  /** Окончание активности */
  stopAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Статистика по личной активности пользователя */
export type UserDataMlmActivityStats = {
  __typename?: 'UserDataMlmActivityStats';
  /** Баллы */
  personalVolume?: Maybe<Scalars['Float']['output']>;
};

/** Данные о бинаре пользователя */
export type UserDataMlmBinar = {
  __typename?: 'UserDataMlmBinar';
  /** Бинарная активность пользователя */
  mlmBinarActivity?: Maybe<UserDataMlmBinarActivity>;
  /** Данные по ячейке в бинаре */
  mlmBinarCellStats?: Maybe<UserDataMlmBinarCellStat>;
  /** Данные бинарного статуса пользователя */
  mlmBinarUserStatus?: Maybe<UserDataMlmBinarUserStatus>;
};

/** Бинарная активность пользователя */
export type UserDataMlmBinarActivity = {
  __typename?: 'UserDataMlmBinarActivity';
  /** Начало бинарной активности */
  startAt?: Maybe<Scalars['DateTime']['output']>;
  /** Есть личная активность */
  statusConst?: Maybe<UserMlmBinarActivityStatusEnum>;
  /** Окончание бинарной активности */
  stopAt?: Maybe<Scalars['DateTime']['output']>;
  /** Пользовтаель слева */
  userLeft?: Maybe<User>;
  /** Пользователь справа */
  userRight?: Maybe<User>;
};

/** Статистика ячейки в бинаре пользователя */
export type UserDataMlmBinarCellStat = {
  __typename?: 'UserDataMlmBinarCellStat';
  /** Групповой объем бинара за классический период c безлимитом по бинарному статусу */
  groupVolumeUnlimitedByMlmPeriod?: Maybe<Scalars['Float']['output']>;
  /** Боковой объем бинара за классический период c безлимитом по бинарному статусу */
  sideVolumeUnlimitedByMlmPeriod?: Maybe<Scalars['Float']['output']>;
};

/** Бинарный статус пользователя */
export type UserDataMlmBinarUserStatus = {
  __typename?: 'UserDataMlmBinarUserStatus';
  /** Начало бинарного статуса */
  startAt?: Maybe<Scalars['DateTime']['output']>;
  /** Окончание бинарного статуса */
  stopAt?: Maybe<Scalars['DateTime']['output']>;
  /** Тип бинарного статуса */
  typeStatusConst?: Maybe<UserMlmBinarUserStatusTypeConstEnum>;
};

/** Статистика по периоду пользователя */
export type UserDataMlmPeriodStat = {
  __typename?: 'UserDataMlmPeriodStat';
  /** Статистика классического маркетинга */
  classic?: Maybe<UserDataMlmPeriodStatClassic>;
  /** Данные по рангу в текущем периоде (квалификация) */
  mlmRank?: Maybe<UserDataMlmRank>;
};

/** Статистика классического маркетинга по периоду */
export type UserDataMlmPeriodStatClassic = {
  __typename?: 'UserDataMlmPeriodStatClassic';
  /** Групповой объем по дереву наставника */
  groupVolumeReferrer?: Maybe<Scalars['Float']['output']>;
  /** Групповой объем по дереву спонсора */
  groupVolumeSponsor?: Maybe<Scalars['Float']['output']>;
  /** Личный объем */
  personalVolume?: Maybe<Scalars['Float']['output']>;
  /** BPV в первой линии по дереву наставника */
  pointBpvSumLevel1Referrer?: Maybe<Scalars['Float']['output']>;
  /** Боковой объем по дереву наставника */
  sideVolumeReferrer?: Maybe<Scalars['Float']['output']>;
  /** Боковой объем по дереву спонсора */
  sideVolumeSponsor?: Maybe<Scalars['Float']['output']>;
};

/** Информация по рангу пользователя */
export type UserDataMlmRank = {
  __typename?: 'UserDataMlmRank';
  /** Ранг в текущем месячном периоде */
  mlmRank?: Maybe<MlmRank>;
  /** Следующий ранг в текущем месячном периоде */
  mlmRankNext?: Maybe<UserDataMlmRankNext>;
};

/** Следующий ранг в текущем месячном периоде */
export type UserDataMlmRankNext = {
  __typename?: 'UserDataMlmRankNext';
  /** Условия получения ранга */
  conditionsOriginalText?: Maybe<Scalars['String']['output']>;
  /** Осталось выполнить для получения ранга */
  conditionsUserText?: Maybe<Scalars['String']['output']>;
  /** Ранг */
  mlmRank?: Maybe<MlmRank>;
};

/** Лидерская пользователя */
export type UserDataMlmUserLeaderActivity = {
  __typename?: 'UserDataMlmUserLeaderActivity';
  /** Есть лидерская активность */
  isActivity?: Maybe<Scalars['Boolean']['output']>;
  /** Начало активности */
  startAt?: Maybe<Scalars['DateTime']['output']>;
  /** Окончание активности */
  stopAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Пользовательский профиль доставки */
export type UserDeliveryProfile = {
  __typename?: 'UserDeliveryProfile';
  /** Адрес */
  address?: Maybe<Address>;
  /** Автор */
  author?: Maybe<User>;
  /** Добавлено */
  createdAt: Scalars['DateTime']['output'];
  /** Точка доставки */
  deliveryPoint?: Maybe<DeliveryPoint>;
  /** ID */
  id: Scalars['Int']['output'];
  /** Стоимость */
  price?: Maybe<Price>;
  /** Статус доступности доставки */
  statusConst?: Maybe<UserDeliveryProfileStatusEnum>;
  /** Склад */
  storage: Storage;
  /** Профиль доставки */
  storageDelivery: StorageDelivery;
  /** Обновлено */
  updatedAt: Scalars['DateTime']['output'];
  /** Покупатель */
  user?: Maybe<User>;
};

export type UserDeliveryProfileDeletePayload = {
  __typename?: 'UserDeliveryProfileDeletePayload';
  errors?: Maybe<Array<UserDeliveryProfileDeleteProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type UserDeliveryProfileDeleteProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type UserDeliveryProfilePagination = {
  __typename?: 'UserDeliveryProfilePagination';
  /** List of items on the current page */
  data?: Maybe<Array<UserDeliveryProfile>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над пользовательскими профилями доставки */
export type UserDeliveryProfilePayload = {
  __typename?: 'UserDeliveryProfilePayload';
  Delete?: Maybe<UserDeliveryProfileDeletePayload>;
  Update?: Maybe<UserDeliveryProfileUpdatePayload>;
};


/** Операции над пользовательскими профилями доставки */
export type UserDeliveryProfilePayloadDeleteArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Операции над пользовательскими профилями доставки */
export type UserDeliveryProfilePayloadUpdateArgs = {
  UserDeliveryProfileUpdateInput?: InputMaybe<UserDeliveryProfileUpdateInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** Фильтр */
export type UserDeliveryProfileQueryFilter = {
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type UserDeliveryProfileQueryPayload = {
  __typename?: 'UserDeliveryProfileQueryPayload';
  errors?: Maybe<Array<UserDeliveryProfileQueryProblem>>;
  userDeliveryProfile?: Maybe<UserDeliveryProfile>;
};

export type UserDeliveryProfileQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Статус */
export enum UserDeliveryProfileStatusEnum {
  Available = 'AVAILABLE',
  Impossible = 'IMPOSSIBLE',
  PartiallyAvailable = 'PARTIALLY_AVAILABLE',
  StorageClosed = 'STORAGE_CLOSED'
}

export type UserDeliveryProfileUpdateInput = {
  /** ID адреса пользователя */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** ID точки доставки */
  deliveryPointId?: InputMaybe<Scalars['Int']['input']>;
  /** ID профиля доставки */
  storageDeliveryId?: InputMaybe<Scalars['Int']['input']>;
  /** ID склада */
  storageId?: InputMaybe<Scalars['Int']['input']>;
};

export type UserDeliveryProfileUpdatePayload = {
  __typename?: 'UserDeliveryProfileUpdatePayload';
  errors?: Maybe<Array<UserDeliveryProfileUpdateProblem>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  userDeliveryProfile?: Maybe<UserDeliveryProfile>;
};

export type UserDeliveryProfileUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type UserDeliveryProfilesQueryFilter = {
  /** ID города */
  cityId?: InputMaybe<Scalars['Int']['input']>;
  /** Код страны */
  countryCode?: InputMaybe<CountryEnum>;
  /** Код валюты */
  currencyCode?: InputMaybe<CurrencyEnum>;
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** ID склада */
  storageId?: InputMaybe<Scalars['Int']['input']>;
};

export type UserDeliveryProfilesQueryPayload = {
  __typename?: 'UserDeliveryProfilesQueryPayload';
  errors?: Maybe<Array<UserDeliveryProfilesQueryProblem>>;
  userDeliveryProfiles?: Maybe<UserDeliveryProfilePagination>;
};

export type UserDeliveryProfilesQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Email пользователя */
export type UserEmail = {
  __typename?: 'UserEmail';
  /** E-mail */
  email: Scalars['String']['output'];
  /** ID */
  id: Scalars['Int']['output'];
  /** Подтвержден */
  isConfirmed: Scalars['Boolean']['output'];
  /** Главный */
  isMain: Scalars['Boolean']['output'];
};

/** Подтверждение почты из личного кабинета */
export type UserEmailCodeSendInput = {
  /** Подтверждаемая почта */
  email: Scalars['Email']['input'];
};

export type UserEmailCodeSendPayload = {
  __typename?: 'UserEmailCodeSendPayload';
  errors?: Maybe<Array<UserEmailCodeSendProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type UserEmailCodeSendProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Данные для добавления почты пользователя */
export type UserEmailCreateInput = {
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
};

/** Данные для удаления почт пользователя */
export type UserEmailDeleteInput = {
  /** IDs почт */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserEmailInput = {
  /** Добавление почт пользователя */
  create?: InputMaybe<Array<InputMaybe<UserEmailCreateInput>>>;
  /** Удаление почт пользователя */
  delete?: InputMaybe<UserEmailDeleteInput>;
  /** Редактирование почт пользователя */
  update?: InputMaybe<Array<InputMaybe<UserEmailUpdateInput>>>;
};

/** Данные для редактирования почты пользователя */
export type UserEmailUpdateInput = {
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Основной */
  isMain?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Окружение пользователя */
export type UserEnvironment = {
  __typename?: 'UserEnvironment';
  /** Приложение */
  application: Application;
  /** Автор */
  author?: Maybe<User>;
  /** UUID Гостя */
  guestUuid?: Maybe<Scalars['UUID']['output']>;
  /** Покупатель */
  user?: Maybe<User>;
  /** Фильтр каталога */
  userStoreFilterParam?: Maybe<UserStoreFilterParam>;
  /** UUID */
  uuid: Scalars['UUID']['output'];
};

/** Фильтр */
export type UserEnvironmentQueryFilter = {
  /** UUID окружения пользователя */
  uuid: Scalars['UUID']['input'];
};

export type UserEnvironmentQueryPayload = {
  __typename?: 'UserEnvironmentQueryPayload';
  errors?: Maybe<Array<UserEnvironmentQueryProblem>>;
  /** Окружение пользователя */
  userEnvironment?: Maybe<UserEnvironment>;
};

export type UserEnvironmentQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Редактировать окружение пользователя */
export type UserEnvironmentUpdateInput = {
  /** Фильтр каталога */
  userStoreFilterParam?: InputMaybe<UserEnvironmentUpdateStoreFilterParamInput>;
};

export type UserEnvironmentUpdatePayload = {
  __typename?: 'UserEnvironmentUpdatePayload';
  errors?: Maybe<Array<UserEnvironmentUpdateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
  /** Окружение пользователя */
  userEnvironment?: Maybe<UserEnvironment>;
};

export type UserEnvironmentUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Каталог товаров, доступных для доставки по заданному городу с разных складов */
export type UserEnvironmentUpdateStoreCityDeliveryFilterInput = {
  /** ID города */
  cityId: Scalars['Int']['input'];
  /** Фильтр активен */
  isActive: Scalars['Boolean']['input'];
};

/** Каталог доверенного склада */
export type UserEnvironmentUpdateStoreClientStorageFilterInput = {
  /** ID города */
  cityId: Scalars['Int']['input'];
  /** Фильтр активен */
  isActive: Scalars['Boolean']['input'];
};

/** Фильтр каталога */
export type UserEnvironmentUpdateStoreFilterParamInput = {
  /** Каталог товаров, доступных для доставки по заданному городу с разных складов */
  cityDelivery?: InputMaybe<UserEnvironmentUpdateStoreCityDeliveryFilterInput>;
  /** Каталог доверенного склада */
  clientStorage?: InputMaybe<UserEnvironmentUpdateStoreClientStorageFilterInput>;
  /** Каталог пикап-центра, выбранного пользователем из числа складов с самовывозом из заданного  города */
  storage?: InputMaybe<UserEnvironmentUpdateStoreStorageFilterInput>;
  /** Каталог товаров одного склада, который подобран с наиболее широким ассортиментом с доставкой в заданный город */
  storageSelection?: InputMaybe<UserEnvironmentUpdateStoreStorageSelectionFilterInput>;
};

/** Каталог пикап-центра, выбранного пользователем из числа складов с самовывозом из заданного города */
export type UserEnvironmentUpdateStoreStorageFilterInput = {
  /** ID города */
  cityId: Scalars['Int']['input'];
  /** Фильтр активен */
  isActive: Scalars['Boolean']['input'];
  /** ID склада */
  storageId: Scalars['Int']['input'];
};

/** Каталог товаров одного склада, который подобран с наиболее широким ассортиментом с доставкой в заданный город */
export type UserEnvironmentUpdateStoreStorageSelectionFilterInput = {
  /** ID города */
  cityId: Scalars['Int']['input'];
  /** Фильтр активен */
  isActive: Scalars['Boolean']['input'];
};

/** Фильтр */
export type UserExistsCheckQueryFilter = {
  /** Email пользователя */
  email?: InputMaybe<Scalars['Email']['input']>;
  /** Телефон пользователя */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UserExistsCheckQueryPayload = {
  __typename?: 'UserExistsCheckQueryPayload';
  errors?: Maybe<Array<UserExistsCheckQueryProblem>>;
  userExistsCheck?: Maybe<UserExistsCheckType>;
};

export type UserExistsCheckQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Информация по наличию пользователя */
export type UserExistsCheckType = {
  __typename?: 'UserExistsCheckType';
  /** Пользователь найден по email */
  isFoundByEmail?: Maybe<Scalars['Boolean']['output']>;
  /** Пользователь найден по номеру телефона */
  isFoundByPhone?: Maybe<Scalars['Boolean']['output']>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
  /** Статус пользователя */
  statusConst?: Maybe<UserStatusEnum>;
};

export type UserFeedbackRequestCreateInput = {
  /** Описание */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Прикрепленное изображение */
  image?: InputMaybe<Scalars['Upload']['input']>;
  /** Ссылка страницы */
  pageLink?: InputMaybe<Scalars['Url']['input']>;
};

export type UserFeedbackRequestCreatePayload = {
  __typename?: 'UserFeedbackRequestCreatePayload';
  errors?: Maybe<Array<Maybe<UserFeedbackRequestCreateProblem>>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type UserFeedbackRequestCreateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Операции над заявками обратной связи */
export type UserFeedbackRequestPayload = {
  __typename?: 'UserFeedbackRequestPayload';
  Create?: Maybe<UserFeedbackRequestCreatePayload>;
};


/** Операции над заявками обратной связи */
export type UserFeedbackRequestPayloadCreateArgs = {
  UserFeedbackRequestCreateInput?: InputMaybe<UserFeedbackRequestCreateInput>;
};

/** Статус заявки обратной связи */
export enum UserFeedbackRequestStatusEnum {
  New = 'NEW',
  Processed = 'PROCESSED'
}

/** Информация по пользователю в ячейке бинара */
export type UserForMlmBinarCell = {
  __typename?: 'UserForMlmBinarCell';
  /** Аватар пользователя */
  avatar?: Maybe<UserAvatarImage>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Данные по mlm бинару пользователя */
  mlmBinar?: Maybe<UserDataMlmBinar>;
  /** Статистика по текущему периоду */
  mlmPeriodStat?: Maybe<UserDataMlmPeriodStat>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
};

/** Информация по пользователю в ячейке бинара в навигации */
export type UserForMlmBinarCellPath = {
  __typename?: 'UserForMlmBinarCellPath';
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Данные по личной активности */
  mlmUserActivity?: Maybe<UserDataMlmActivity>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
};

/** Информация по пользователю, находящемся ниже в бинарном дереве */
export type UserForParentBinar = UserInterface & {
  __typename?: 'UserForParentBinar';
  /** Аватар пользователя */
  avatar?: Maybe<UserAvatarImage>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Данные по mlm бинару пользователя */
  mlmBinar?: Maybe<UserDataMlmBinar>;
  /** Статистика по текущему периоду */
  mlmPeriodStat?: Maybe<UserDataMlmPeriodStat>;
  /** Данные по личной активности */
  mlmUserActivity?: Maybe<UserDataMlmActivity>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
};

/** Информация по пользователю публичная */
export type UserForPublic = UserInterface & {
  __typename?: 'UserForPublic';
  /** Аватар пользователя */
  avatar?: Maybe<UserAvatarImage>;
  /** Город */
  city?: Maybe<City>;
  /** Страна */
  country?: Maybe<Country>;
  /** Дата регистрации */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Данные по mlm бинару пользователя */
  mlmBinar?: Maybe<UserDataMlmBinar>;
  /** Максимальный ранг пользователя за всю историю */
  mlmRank?: Maybe<MlmRank>;
  /** Parents пользователя */
  parents?: Maybe<UserParentWithReferrerAndSponsor>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
  /** Дополнительные данные по пользователю */
  profile?: Maybe<UserProfile>;
};

/** Информация по пользователю для наставника */
export type UserForReferrer = UserForReferrerInterface & UserInterface & {
  __typename?: 'UserForReferrer';
  /** Аватар пользователя */
  avatar?: Maybe<UserAvatarImage>;
  /** Город */
  city?: Maybe<City>;
  /** Дата заключения контракта */
  contractCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Страна */
  country?: Maybe<Country>;
  /** Дата регистрации */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Список почты */
  emails?: Maybe<Array<Maybe<UserEmail>>>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Данные по mlm бинару пользователя */
  mlmBinar?: Maybe<UserDataMlmBinar>;
  /** Статистика по текущему периоду */
  mlmPeriodStat?: Maybe<UserDataMlmPeriodStat>;
  /** Максимальный ранг пользователя за всю историю */
  mlmRank?: Maybe<MlmRank>;
  /** Данные по личной активности */
  mlmUserActivity?: Maybe<UserDataMlmActivity>;
  /** Данные по лидерской активности */
  mlmUserLeaderActivity?: Maybe<UserDataMlmUserLeaderActivity>;
  /** Parents пользователя */
  parents?: Maybe<UserParentWithReferrerAndSponsor>;
  /** Список телефонов */
  phones?: Maybe<Array<Maybe<UserPhone>>>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
  /** Баллов BPV с заказов всего за всю историю */
  pointBpv?: Maybe<Scalars['Float']['output']>;
  /** Дополнительные данные по пользователю */
  profile?: Maybe<UserProfile>;
  /** Ссылки на социальные сети пользователя */
  social?: Maybe<UserSocial>;
  /** Статистика для родительских наставник/спонсор */
  statsForParent?: Maybe<UserStatsForParent>;
  /** Теги */
  tags?: Maybe<Array<Tag>>;
};

/** Пользователь который возвращается для наставника */
export type UserForReferrerInterface = {
  /** Город */
  city?: Maybe<City>;
  /** Страна */
  country?: Maybe<Country>;
  /** Дата регистрации */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Список почты */
  emails?: Maybe<Array<Maybe<UserEmail>>>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Максимальный ранг пользователя за всю историю */
  mlmRank?: Maybe<MlmRank>;
  /** Данные по личной активности */
  mlmUserActivity?: Maybe<UserDataMlmActivity>;
  /** Parents пользователя */
  parents?: Maybe<UserParentWithReferrerAndSponsor>;
  /** Список телефонов */
  phones?: Maybe<Array<Maybe<UserPhone>>>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
  /** Дополнительные данные по пользователю */
  profile?: Maybe<UserProfile>;
  /** Ссылки на социальные сети пользователя */
  social?: Maybe<UserSocial>;
  /** Статистика для родительских наставник/спонсор */
  statsForParent?: Maybe<UserStatsForParent>;
};

/** Информация по пользователю для спонсора */
export type UserForSponsor = UserInterface & {
  __typename?: 'UserForSponsor';
  /** Аватар пользователя */
  avatar?: Maybe<UserAvatarImage>;
  /** Город */
  city?: Maybe<City>;
  /** Страна */
  country?: Maybe<Country>;
  /** Дата регистрации */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Список почты */
  emails?: Maybe<Array<Maybe<UserEmail>>>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Данные по mlm бинару пользователя */
  mlmBinar?: Maybe<UserDataMlmBinar>;
  /** Статистика по текущему периоду */
  mlmPeriodStat?: Maybe<UserDataMlmPeriodStat>;
  /** Максимальный ранг пользователя за всю историю */
  mlmRank?: Maybe<MlmRank>;
  /** Данные по личной активности */
  mlmUserActivity?: Maybe<UserDataMlmActivity>;
  /** Parents пользователя */
  parents?: Maybe<UserParentWithReferrerAndSponsor>;
  /** Список телефонов */
  phones?: Maybe<Array<Maybe<UserPhone>>>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
  /** Баллов BPV с заказов всего за всю историю */
  pointBpv?: Maybe<Scalars['Float']['output']>;
  /** Дополнительные данные по пользователю */
  profile?: Maybe<UserProfile>;
  /** Ссылки на социальные сети пользователя */
  social?: Maybe<UserSocial>;
  /** Статистика для родительских наставник/спонсор */
  statsForParent?: Maybe<UserStatsForParent>;
  /** Теги */
  tags?: Maybe<Array<Tag>>;
};

/** Пол пользователя */
export enum UserGenderEnum {
  Female = 'FEMALE',
  Male = 'MALE',
  NotSet = 'NOT_SET'
}

export type UserGroupPayload = {
  __typename?: 'UserGroupPayload';
  /** Пользователь */
  User?: Maybe<UserQueryPayload>;
  /** Разрешения пользователя */
  UserAccess?: Maybe<UserAccessQueryPayload>;
  /** Сообщение пользователя в лк */
  UserCabinetMessage?: Maybe<UserCabinetMessageQueryPayload>;
  /** Сообщения пользователя в лк */
  UserCabinetMessages?: Maybe<UserCabinetMessagesQueryPayload>;
  /** Пользовательские профили доставки */
  UserDeliveryProfile?: Maybe<UserDeliveryProfileQueryPayload>;
  /** Пользовательские профили доставки */
  UserDeliveryProfiles?: Maybe<UserDeliveryProfilesQueryPayload>;
  /** Окружение пользователя */
  UserEnvironment?: Maybe<UserEnvironmentQueryPayload>;
  /** Проверка существования пользователя */
  UserExistsCheck?: Maybe<UserExistsCheckQueryPayload>;
  /** Нотификации в ЛК */
  UserNotifications?: Maybe<UserNotificationsQueryPayload>;
  /** Избранные товары */
  UserProductFavorites?: Maybe<UserProductFavoritesQueryPayload>;
  /** Реферальные ссылки пользователя */
  UserRefLinks?: Maybe<UserRefLinksQueryPayload>;
  /** Рефералы пользователя */
  UserReferrals?: Maybe<UserReferralsQueryPayload>;
  /** Путь до пользователя наставника/спонсора */
  UserReferralsPath?: Maybe<UserReferralsPathQueryPayload>;
  /** Поиск наставника */
  UserReferrerSearch?: Maybe<UserReferrerSearchQueryPayload>;
};


export type UserGroupPayloadUserArgs = {
  filter?: InputMaybe<UserQueryFilter>;
};


export type UserGroupPayloadUserCabinetMessageArgs = {
  filter?: InputMaybe<UserCabinetMessageQueryFilter>;
};


export type UserGroupPayloadUserCabinetMessagesArgs = {
  filter?: InputMaybe<UserCabinetMessagesQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type UserGroupPayloadUserDeliveryProfileArgs = {
  filter?: InputMaybe<UserDeliveryProfileQueryFilter>;
};


export type UserGroupPayloadUserDeliveryProfilesArgs = {
  filter?: InputMaybe<UserDeliveryProfilesQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type UserGroupPayloadUserEnvironmentArgs = {
  filter?: InputMaybe<UserEnvironmentQueryFilter>;
};


export type UserGroupPayloadUserExistsCheckArgs = {
  filter?: InputMaybe<UserExistsCheckQueryFilter>;
};


export type UserGroupPayloadUserNotificationsArgs = {
  filter?: InputMaybe<UserNotificationsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type UserGroupPayloadUserProductFavoritesArgs = {
  filter?: InputMaybe<UserProductFavoritesQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type UserGroupPayloadUserRefLinksArgs = {
  filter?: InputMaybe<UserRefLinksQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type UserGroupPayloadUserReferralsArgs = {
  filter?: InputMaybe<UserReferralsQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  sort?: InputMaybe<UserReferralsSortEnum>;
};


export type UserGroupPayloadUserReferralsPathArgs = {
  filter?: InputMaybe<UserReferralsPathQueryFilter>;
};


export type UserGroupPayloadUserReferrerSearchArgs = {
  filter?: InputMaybe<UserReferrerSearchQueryFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

/** Пользователь */
export type UserInput = {
  /** ID ref пользователя */
  idRef?: InputMaybe<Scalars['Int']['input']>;
  /** PK пользователя */
  pk?: InputMaybe<Scalars['Int']['input']>;
};

/** Интерфейс типов пользователя */
export type UserInterface = {
  /** PK пользователя */
  pk: Scalars['Int']['output'];
};

export type UserLoginByOneTimeTokenInput = {
  /** Одноразовый токен */
  token?: InputMaybe<Scalars['String']['input']>;
};

export type UserLoginInput = {
  /** Логин */
  login?: InputMaybe<Scalars['String']['input']>;
  /** Пароль */
  password?: InputMaybe<Scalars['String']['input']>;
};

/** Информация для входа */
export type UserLoginPayload = {
  __typename?: 'UserLoginPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<UserLoginProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
  tokenExpiredAt?: Maybe<Scalars['DateTime']['output']>;
  userTokenAccess?: Maybe<UserTokenAccess>;
  userTokenOneTime?: Maybe<UserTokenOneTime>;
};

export type UserLoginProblem = NotFoundError | ServerError | ValidateError;

/** Статусы бинарной активности */
export enum UserMlmBinarActivityStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Part = 'PART'
}

/** Настройки пользователя по размещению в бинаре */
export type UserMlmBinarSettingReferral = {
  __typename?: 'UserMlmBinarSettingReferral';
  /** Время отсрочки автоматического размещения */
  automaticTimerConst: UserMlmBinarSettingReferralAutomaticTimerEnum;
  /** Тип автоматического размещения */
  automaticTypeConst: UserMlmBinarSettingReferralAutomaticTypeEnum;
  /** Бизнес центр */
  businessCenterConst: UserMlmBinarSettingReferralBusinessCenterEnum;
  /** Дополнительные параметры */
  flags: UserMlmBinarSettingReferralFlag;
  /** Бизнес центр */
  id: Scalars['Int']['output'];
  /** Тип размещения */
  typeConst: UserMlmBinarSettingReferralTypeEnum;
};

/** Время отсрочки размещения */
export enum UserMlmBinarSettingReferralAutomaticTimerEnum {
  DayFive = 'DAY_FIVE',
  DayOne = 'DAY_ONE',
  DayThree = 'DAY_THREE',
  DayTwo = 'DAY_TWO',
  Instant = 'INSTANT'
}

/** Тип автоматического размещения */
export enum UserMlmBinarSettingReferralAutomaticTypeEnum {
  LeftLeg = 'LEFT_LEG',
  RightLeg = 'RIGHT_LEG',
  RotationEqualLeg = 'ROTATION_EQUAL_LEG',
  RotationWithManual = 'ROTATION_WITH_MANUAL',
  RotationWithOnlyAutomatic = 'ROTATION_WITH_ONLY_AUTOMATIC',
  StrongLeg = 'STRONG_LEG',
  WeakLeg = 'WEAK_LEG'
}

/** Бизнес центр */
export enum UserMlmBinarSettingReferralBusinessCenterEnum {
  One = 'ONE',
  Three = 'THREE',
  Two = 'TWO'
}

/** Дополнительные параметры размещения партнера в бинаре */
export type UserMlmBinarSettingReferralFlag = {
  __typename?: 'UserMlmBinarSettingReferralFlag';
  /** Удерживать бинарную активность */
  isKeepBinaryActivity?: Maybe<Scalars['Boolean']['output']>;
};

/** Дополнительные параметры автоматического размещения пользователей в бинаре */
export type UserMlmBinarSettingReferralFlagInput = {
  /** Удерживать бинарную активность */
  isKeepBinaryActivity: Scalars['Boolean']['input'];
};

export type UserMlmBinarSettingReferralPagination = {
  __typename?: 'UserMlmBinarSettingReferralPagination';
  /** List of items on the current page */
  data?: Maybe<Array<UserMlmBinarSettingReferral>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над настройками пользователя размещения рефералов в бинаре */
export type UserMlmBinarSettingReferralPayload = {
  __typename?: 'UserMlmBinarSettingReferralPayload';
  /** Редактирование настроек пользователя для размещение бинаров */
  Update?: Maybe<UserMlmBinarSettingReferralUpdatePayload>;
};


/** Операции над настройками пользователя размещения рефералов в бинаре */
export type UserMlmBinarSettingReferralPayloadUpdateArgs = {
  UserMlmBinarSettingReferralUpdateInput?: InputMaybe<UserMlmBinarSettingReferralUpdateInput>;
};

/** Фильтр */
export type UserMlmBinarSettingReferralQueryFilter = {
  /** ID */
  id: Scalars['Int']['input'];
};

export type UserMlmBinarSettingReferralQueryPayload = {
  __typename?: 'UserMlmBinarSettingReferralQueryPayload';
  errors?: Maybe<Array<UserMlmBinarSettingReferralQueryProblem>>;
  userMlmBinarSettingReferral?: Maybe<UserMlmBinarSettingReferral>;
};

export type UserMlmBinarSettingReferralQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Тип размещения */
export enum UserMlmBinarSettingReferralTypeEnum {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

/** Данные для редактирования настроек размещения пользователей в бинаре */
export type UserMlmBinarSettingReferralUpdateInput = {
  /** Время отсрочки автоматического размещения */
  automaticTimerConst?: InputMaybe<UserMlmBinarSettingReferralAutomaticTimerEnum>;
  /** Тип автоматического размещения */
  automaticTypeConst?: InputMaybe<UserMlmBinarSettingReferralAutomaticTypeEnum>;
  /** Дополнительные параметры */
  flags?: InputMaybe<UserMlmBinarSettingReferralFlagInput>;
  /** ID */
  id: Scalars['Int']['input'];
  /** Тип размещения */
  typeConst?: InputMaybe<UserMlmBinarSettingReferralTypeEnum>;
};

/** Информация о настройках пользователя по размещению рефералов в бинаре */
export type UserMlmBinarSettingReferralUpdatePayload = {
  __typename?: 'UserMlmBinarSettingReferralUpdatePayload';
  errors?: Maybe<Array<UserMlmBinarSettingReferralUpdateProblem>>;
  /** Id */
  id?: Maybe<Scalars['Int']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  userMlmBinarSettingReferral?: Maybe<UserMlmBinarSettingReferral>;
};

export type UserMlmBinarSettingReferralUpdateProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр */
export type UserMlmBinarSettingReferralsQueryFilter = {
  /** Бизнес центр */
  businessCenterConst?: InputMaybe<UserMlmBinarSettingReferralBusinessCenterEnum>;
  /** IDs */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserMlmBinarSettingReferralsQueryPayload = {
  __typename?: 'UserMlmBinarSettingReferralsQueryPayload';
  errors?: Maybe<Array<UserMlmBinarSettingReferralsQueryProblem>>;
  userMlmBinarSettingReferrals?: Maybe<UserMlmBinarSettingReferralPagination>;
};

export type UserMlmBinarSettingReferralsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Бинарные статусы пользователя */
export enum UserMlmBinarUserStatusTypeConstEnum {
  TypeStatusBronze = 'TYPE_STATUS_BRONZE',
  TypeStatusGold = 'TYPE_STATUS_GOLD',
  TypeStatusPlatinum = 'TYPE_STATUS_PLATINUM',
  TypeStatusSilver = 'TYPE_STATUS_SILVER',
  TypeStatusStart = 'TYPE_STATUS_START'
}

/** Нотификация в ЛК */
export type UserNotification = {
  __typename?: 'UserNotification';
  /** Категория */
  categoryConst: UserNotificationCategoryEnum;
  /** Сообщение */
  content?: Maybe<Scalars['String']['output']>;
  /** Событие */
  eventConst: UserNotificationEventEnum;
  /** Ссылка */
  href?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['Int']['output'];
  /** Родитель */
  parent?: Maybe<UserNotification>;
  /** Дата публикации */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Источник */
  sourceConst: UserNotificationSourceEnum;
  /** Статус */
  statusConst: UserNotificationStatusEnum;
  /** Заголовок */
  title?: Maybe<Scalars['String']['output']>;
  /** Тип */
  typeConst: UserNotificationTypeEnum;
  /** Пользователь */
  user: User;
};

/** Категории */
export enum UserNotificationCategoryEnum {
  BinaryStructure = 'BINARY_STRUCTURE',
  ClassicStructure = 'CLASSIC_STRUCTURE',
  Common = 'COMMON',
  Finance = 'FINANCE',
  Store = 'STORE'
}

/** Типы */
export enum UserNotificationChannelTypeEnum {
  Mail = 'MAIL',
  Sms = 'SMS',
  Telegram = 'TELEGRAM'
}

/** Типы */
export enum UserNotificationEventEnum {
  MlmUserActivityEnd = 'MLM_USER_ACTIVITY_END',
  MlmUserActivityWeekBeforeEnd = 'MLM_USER_ACTIVITY_WEEK_BEFORE_END',
  OrderCreated = 'ORDER_CREATED',
  Registered = 'REGISTERED',
  Test = 'TEST'
}

export type UserNotificationPagination = {
  __typename?: 'UserNotificationPagination';
  /** List of items on the current page */
  data?: Maybe<Array<UserNotification>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Информация по настройкам подписок пользователя на канал */
export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  /** Канал */
  channelConst: UserNotificationChannelTypeEnum;
  /** Активна ли подписка на канал на сервисе */
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  /** Состояние подписки на весь канал(включена/отключена) */
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Список категорий уведомлений на которые включена подписка на канале */
  notifications?: Maybe<Array<Maybe<UserNotificationCategoryEnum>>>;
};

export type UserNotificationSettingUpdateInput = {
  /** Канал */
  channelConst?: InputMaybe<UserNotificationChannelTypeEnum>;
  /** Состояние подписки на весь канал(включена/отключена) */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Список категорий уведомлений на которые включена подписка на канале */
  notifications?: InputMaybe<Array<InputMaybe<UserNotificationCategoryEnum>>>;
};

/** Информация о состоянии подписки пользователя на канал */
export type UserNotificationSettingUpdatePayload = {
  __typename?: 'UserNotificationSettingUpdatePayload';
  errors?: Maybe<Array<UserNotificationSettingUpdateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
  userNotificationSetting?: Maybe<UserNotificationSetting>;
};

export type UserNotificationSettingUpdateProblem = ServerError | ValidateError;

/** Источники */
export enum UserNotificationSourceEnum {
  Mass = 'MASS',
  Personal = 'PERSONAL'
}

/** Статусы */
export enum UserNotificationStatusEnum {
  New = 'NEW',
  Viewed = 'VIEWED'
}

/** Типы */
export enum UserNotificationTypeEnum {
  MlmUserActivityEnd = 'MLM_USER_ACTIVITY_END',
  MlmUserActivityFirst = 'MLM_USER_ACTIVITY_FIRST',
  MlmUserActivityWeekBeforeEnd = 'MLM_USER_ACTIVITY_WEEK_BEFORE_END',
  OrderFirstLess_25PointBpv = 'ORDER_FIRST_LESS_25_POINT_BPV',
  UserRegistrationEmail = 'USER_REGISTRATION_EMAIL',
  UserRegistrationPhone = 'USER_REGISTRATION_PHONE',
  UserRegistrationUnderReferrer = 'USER_REGISTRATION_UNDER_REFERRER'
}

export type UserNotificationUpdateInput = {
  /** Изменить состояние для всех уведомлений */
  isAllNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  /** Список уведомлений */
  notificationIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Статус */
  statusConst?: InputMaybe<UserNotificationStatusEnum>;
};

/** Информация о состоянии уведомлений пользователя */
export type UserNotificationUpdatePayload = {
  __typename?: 'UserNotificationUpdatePayload';
  errors?: Maybe<Array<UserNotificationUpdateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type UserNotificationUpdateProblem = ServerError | ValidateError;

/** Фильтр */
export type UserNotificationsQueryFilter = {
  /** Событие */
  eventConst?: InputMaybe<UserNotificationEventEnum>;
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Источник */
  sourceConst?: InputMaybe<UserNotificationSourceEnum>;
  /** Статус */
  statusConst?: InputMaybe<UserNotificationStatusEnum>;
  /** Тип */
  typeConst?: InputMaybe<UserNotificationTypeEnum>;
};

export type UserNotificationsQueryPayload = {
  __typename?: 'UserNotificationsQueryPayload';
  errors?: Maybe<Array<UserNotificationsQueryProblem>>;
  stats?: Maybe<UserNotificationsQueryStat>;
  userNotifications?: Maybe<UserNotificationPagination>;
};

export type UserNotificationsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Статистика */
export type UserNotificationsQueryStat = {
  __typename?: 'UserNotificationsQueryStat';
  /** Число непрочитанных нотификаций */
  newCount?: Maybe<Scalars['Int']['output']>;
};

/** Статистика по заказам */
export type UserOrderStat = {
  __typename?: 'UserOrderStat';
  /** Дата последнего заказа */
  lastOrderAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Информация о наставнике и спонсоре */
export type UserParentWithReferrerAndSponsor = {
  __typename?: 'UserParentWithReferrerAndSponsor';
  /** Наставник */
  referrer?: Maybe<UserReferrerInterface>;
  /** Спонсор */
  sponsor?: Maybe<UserSponsor>;
};

/** Восстановление пароля по почте */
export type UserPasswordRestoreByEmailInput = {
  /** Код подтверждения */
  code: Scalars['UUID']['input'];
  /** Новый пароль */
  password: Scalars['String']['input'];
};

/** Восстановление пароля по телефону */
export type UserPasswordRestoreByPhoneInput = {
  /** Код подтверждения */
  code: Scalars['String']['input'];
  /** Новый пароль */
  password: Scalars['String']['input'];
  /** Номер телефона пользователя */
  phone: Scalars['String']['input'];
};

/** Письмо восстановление пароля */
export type UserPasswordRestoreEmailCodeSendInput = {
  /** Приложение инициализатор */
  applicationConstId?: InputMaybe<ApplicationEnum>;
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
};

export type UserPasswordRestorePayload = {
  __typename?: 'UserPasswordRestorePayload';
  errors?: Maybe<Array<UserPasswordRestoreProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type UserPasswordRestorePhoneCodeSendInput = {
  /** Телефонный номер пользователя (для отправки смс) */
  phone: Scalars['String']['input'];
};

export type UserPasswordRestoreProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Операции над пользователями */
export type UserPayload = {
  __typename?: 'UserPayload';
  AgreementSettingUpdate?: Maybe<UserAgreementSettingUpdatePayload>;
  /** Отправка кода подтверждения на почту */
  EmailCodeSend?: Maybe<UserEmailCodeSendPayload>;
  /** Редактировать окружения пользователя */
  EnvironmentUpdate?: Maybe<UserEnvironmentUpdatePayload>;
  Login?: Maybe<UserLoginPayload>;
  NotificationSettingUpdate?: Maybe<UserNotificationSettingUpdatePayload>;
  NotificationUpdate?: Maybe<UserNotificationUpdatePayload>;
  /** Восстановление пароля пользователя путем отправки кода (полученного по смс) */
  PasswordRestore?: Maybe<UserPasswordRestorePayload>;
  /** Подтверждение телефонного номера путем отправки кода (полученного по смс) */
  PhoneCodeConfirm?: Maybe<UserPhoneCodeConfirmPayload>;
  /** Отправка кода подтверждения на телефонный номер */
  PhoneCodeSend?: Maybe<UserPhoneCodeSendPayload>;
  /** Регистрация для не зарегистрированных пользователей */
  Registration?: Maybe<UserRegistrationPayload>;
  Update?: Maybe<UserUpdatePayload>;
  /** Добавление аватара пользователя */
  UserAvatarImageCreate?: Maybe<UserAvatarImageCreatePayload>;
  /** Удаление аватара пользователя */
  UserAvatarImageDelete?: Maybe<UserAvatarImageDeletePayload>;
  UserCabinetMessageUpdate?: Maybe<UserCabinetMessageUpdatePayload>;
  UserTelegramSubscribe?: Maybe<UserTelegramSubscribePayload>;
};


/** Операции над пользователями */
export type UserPayloadAgreementSettingUpdateArgs = {
  UserAgreementSettingUpdateInput?: InputMaybe<UserAgreementSettingUpdateInput>;
};


/** Операции над пользователями */
export type UserPayloadEmailCodeSendArgs = {
  UserEmailCodeSendInput?: InputMaybe<UserEmailCodeSendInput>;
  UserPasswordRestoreEmailCodeSendInput?: InputMaybe<UserPasswordRestoreEmailCodeSendInput>;
  UserTemporaryEmailCodeSendInput?: InputMaybe<UserTemporaryEmailCodeSendInput>;
};


/** Операции над пользователями */
export type UserPayloadEnvironmentUpdateArgs = {
  UserEnvironmentUpdateInput?: InputMaybe<UserEnvironmentUpdateInput>;
};


/** Операции над пользователями */
export type UserPayloadLoginArgs = {
  UserLoginByOneTimeTokenInput?: InputMaybe<UserLoginByOneTimeTokenInput>;
  UserLoginInput?: InputMaybe<UserLoginInput>;
};


/** Операции над пользователями */
export type UserPayloadNotificationSettingUpdateArgs = {
  UserNotificationSettingUpdateInput?: InputMaybe<UserNotificationSettingUpdateInput>;
};


/** Операции над пользователями */
export type UserPayloadNotificationUpdateArgs = {
  UserNotificationUpdateInput?: InputMaybe<UserNotificationUpdateInput>;
};


/** Операции над пользователями */
export type UserPayloadPasswordRestoreArgs = {
  UserPasswordRestoreByEmailInput?: InputMaybe<UserPasswordRestoreByEmailInput>;
  UserPasswordRestoreByPhoneInput?: InputMaybe<UserPasswordRestoreByPhoneInput>;
};


/** Операции над пользователями */
export type UserPayloadPhoneCodeConfirmArgs = {
  UserPhoneCodeConfirmInput?: InputMaybe<UserPhoneCodeConfirmInput>;
  UserTemporaryPhoneCodeConfirmInput?: InputMaybe<UserTemporaryPhoneCodeConfirmInput>;
};


/** Операции над пользователями */
export type UserPayloadPhoneCodeSendArgs = {
  UserPasswordRestorePhoneCodeSendInput?: InputMaybe<UserPasswordRestorePhoneCodeSendInput>;
  UserPhoneCodeSendInput?: InputMaybe<UserPhoneCodeSendInput>;
  UserTemporaryPhoneCodeSendInput?: InputMaybe<UserTemporaryPhoneCodeSendInput>;
};


/** Операции над пользователями */
export type UserPayloadRegistrationArgs = {
  UserRegistrationInput?: InputMaybe<UserRegistrationInput>;
};


/** Операции над пользователями */
export type UserPayloadUpdateArgs = {
  UserUpdateInput?: InputMaybe<UserUpdateInput>;
};


/** Операции над пользователями */
export type UserPayloadUserAvatarImageCreateArgs = {
  UserAvatarImageCreateInput?: InputMaybe<UserAvatarImageCreateInput>;
};


/** Операции над пользователями */
export type UserPayloadUserCabinetMessageUpdateArgs = {
  UserCabinetMessageUpdateInput?: InputMaybe<UserCabinetMessageUpdateInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** Тип пользователя в ответе запроса */
export enum UserPayloadTypeEnum {
  ForParentBinar = 'FOR_PARENT_BINAR',
  ForReferrer = 'FOR_REFERRER',
  ForSponsor = 'FOR_SPONSOR'
}

/** Телефон пользователя */
export type UserPhone = {
  __typename?: 'UserPhone';
  /** К телефону подключен Viber */
  hasViber?: Maybe<Scalars['Boolean']['output']>;
  /** К телефону подключен Whatsapp */
  hasWhatsapp?: Maybe<Scalars['Boolean']['output']>;
  /** ID */
  id: Scalars['Int']['output'];
  /** Подтвержден */
  isConfirmed: Scalars['Boolean']['output'];
  /** Главный */
  isMain: Scalars['Boolean']['output'];
  /** Ссылки на мессенджеры пользователя */
  links?: Maybe<UserPhoneLink>;
  /** Телефон */
  phone: Scalars['Phone']['output'];
};

export type UserPhoneCodeConfirmInput = {
  /** Код подтверждения телефонного номера */
  code: Scalars['String']['input'];
  /** Подтверждаемый телефонный номер */
  phone: Scalars['String']['input'];
};

export type UserPhoneCodeConfirmPayload = {
  __typename?: 'UserPhoneCodeConfirmPayload';
  errors?: Maybe<Array<UserPhoneCodeConfirmProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type UserPhoneCodeConfirmProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type UserPhoneCodeSendInput = {
  /** Подтверждаемый телефонный номер (для отправки смс) */
  phone: Scalars['String']['input'];
};

export type UserPhoneCodeSendPayload = {
  __typename?: 'UserPhoneCodeSendPayload';
  errors?: Maybe<Array<UserPhoneCodeSendProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type UserPhoneCodeSendProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Данные для добавления телефона пользователя */
export type UserPhoneCreateInput = {
  /** Телефон */
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** Данные для удаления телефонов пользователя */
export type UserPhoneDeleteInput = {
  /** IDs телефонов */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserPhoneInput = {
  /** Добавление телефонов пользователя */
  create?: InputMaybe<Array<InputMaybe<UserPhoneCreateInput>>>;
  /** Удаление телефонов пользователя */
  delete?: InputMaybe<UserPhoneDeleteInput>;
  /** Редактирование телефонов пользователя */
  update?: InputMaybe<Array<InputMaybe<UserPhoneUpdateInput>>>;
};

/** Ссылки на мессенджеры */
export type UserPhoneLink = {
  __typename?: 'UserPhoneLink';
  /** Ссылка на Viber */
  viber?: Maybe<Scalars['Url']['output']>;
  /** Ссылка на Whatsapp */
  whatsapp?: Maybe<Scalars['Url']['output']>;
};

/** Данные для редактирования телефона пользователя */
export type UserPhoneUpdateInput = {
  /** К телефону подключен Viber */
  hasViber?: InputMaybe<Scalars['Boolean']['input']>;
  /** К телефону подключен Whatsapp */
  hasWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID телефона */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Основной */
  isMain?: InputMaybe<Scalars['Boolean']['input']>;
  /** Телефон */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UserProductFavoriteCreateInput = {
  /** ID товара */
  productId?: InputMaybe<Scalars['Int']['input']>;
};

export type UserProductFavoriteCreatePayload = {
  __typename?: 'UserProductFavoriteCreatePayload';
  errors?: Maybe<Array<UserProductFavoriteCreateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
  /** Избранный товар */
  userProductFavorite?: Maybe<UserProductFavoriteType>;
};

export type UserProductFavoriteCreateProblem = ForbiddenError | NotFoundError | ServerError | ValidateError;

export type UserProductFavoriteDeletePayload = {
  __typename?: 'UserProductFavoriteDeletePayload';
  errors?: Maybe<Array<UserProductFavoriteDeleteProblem>>;
  /** IDs товаров */
  ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type UserProductFavoriteDeleteProblem = ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Операции над избранными товарами пользователя */
export type UserProductFavoritePayload = {
  __typename?: 'UserProductFavoritePayload';
  /** Добавление товара в список избранных, пользователя */
  Create?: Maybe<UserProductFavoriteCreatePayload>;
  /** Удаление товаров из списка избранных пользователя */
  Delete?: Maybe<UserProductFavoriteDeletePayload>;
};


/** Операции над избранными товарами пользователя */
export type UserProductFavoritePayloadCreateArgs = {
  UserProductFavoriteCreateInput?: InputMaybe<UserProductFavoriteCreateInput>;
};


/** Операции над избранными товарами пользователя */
export type UserProductFavoritePayloadDeleteArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

/** Избранный товар */
export type UserProductFavoriteType = {
  __typename?: 'UserProductFavoriteType';
  /** ID */
  id: Scalars['Int']['output'];
  /** Товар */
  product?: Maybe<Product>;
};

export type UserProductFavoriteTypePagination = {
  __typename?: 'UserProductFavoriteTypePagination';
  /** List of items on the current page */
  data?: Maybe<Array<UserProductFavoriteType>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр */
export type UserProductFavoritesQueryFilter = {
  /** ID города */
  cityId?: InputMaybe<Scalars['Int']['input']>;
  /** Код страны */
  countryCode?: InputMaybe<CountryEnum>;
  /** IDs складов */
  storageIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserProductFavoritesQueryPayload = {
  __typename?: 'UserProductFavoritesQueryPayload';
  /** Валюта для цен товаров */
  currencyCode?: Maybe<CurrencyEnum>;
  errors?: Maybe<Array<UserProductFavoritesQueryProblem>>;
  userProductFavorites?: Maybe<UserProductFavoriteTypePagination>;
};

export type UserProductFavoritesQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Дополнительная информация по пользователю */
export type UserProfile = {
  __typename?: 'UserProfile';
  /** Дата рождения */
  birthdayAt?: Maybe<Scalars['Date']['output']>;
  /** Пол */
  genderConst?: Maybe<UserGenderEnum>;
};

export type UserProfileInput = {
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['Date']['input']>;
  /** Пол */
  genderConst?: InputMaybe<UserGenderEnum>;
};

/** Фильтр */
export type UserQueryFilter = {
  /** Тип пользователя в ответе запроса в приоритете */
  payloadTypeInPriority?: InputMaybe<UserPayloadTypeEnum>;
  /** PK пользователя */
  pk?: InputMaybe<Scalars['Int']['input']>;
};

export type UserQueryPayload = {
  __typename?: 'UserQueryPayload';
  errors?: Maybe<Array<UserQueryProblem>>;
  user?: Maybe<UserInterface>;
};

export type UserQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Ref-ссылка */
export type UserRefLink = {
  __typename?: 'UserRefLink';
  /** Приложение */
  application: Application;
  /** Комментарий */
  comment?: Maybe<Scalars['String']['output']>;
  /** Ссылка */
  link?: Maybe<Scalars['String']['output']>;
};

export type UserRefLinkLogInput = {
  /** Личный номер (реф.код) пользователя */
  idRef?: InputMaybe<Scalars['String']['input']>;
  /** Ссылка */
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Информация для редактирования */
export type UserRefLinkLogPayload = {
  __typename?: 'UserRefLinkLogPayload';
  errors?: Maybe<Array<UserRefLinkLogProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
};

export type UserRefLinkLogProblem = ServerError | ValidateError;

export type UserRefLinkPagination = {
  __typename?: 'UserRefLinkPagination';
  /** List of items on the current page */
  data?: Maybe<Array<UserRefLink>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Операции над реферальными ссылками пользователей */
export type UserRefLinkPayload = {
  __typename?: 'UserRefLinkPayload';
  Log?: Maybe<UserRefLinkLogPayload>;
};


/** Операции над реферальными ссылками пользователей */
export type UserRefLinkPayloadLogArgs = {
  UserRefLinkLogInput?: InputMaybe<UserRefLinkLogInput>;
};

/** Фильтр */
export type UserRefLinksQueryFilter = {
  /** ConstId приложения */
  applicationConstId?: InputMaybe<ApplicationEnum>;
};

export type UserRefLinksQueryPayload = {
  __typename?: 'UserRefLinksQueryPayload';
  errors?: Maybe<Array<UserRefLinksQueryProblem>>;
  userRefLinks?: Maybe<UserRefLinkPagination>;
};

export type UserRefLinksQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Данные о реферале пользователя */
export type UserReferral = {
  __typename?: 'UserReferral';
  /** Информация по бинару */
  binarInfo?: Maybe<UserReferralBinarInfo>;
  /** Линия абсолютная в дереве */
  depthAbsolute: Scalars['Int']['output'];
  /** Линия относительная в дереве */
  depthRelative: Scalars['Int']['output'];
  /** Данные о пользователе */
  user: UserInterface;
  /** PK пользовтеля */
  userPk: Scalars['Int']['output'];
};

/** Данные о бинаре реферала пользователя */
export type UserReferralBinarInfo = {
  __typename?: 'UserReferralBinarInfo';
  /** Размещен в бинаре */
  inBinar?: Maybe<Scalars['Boolean']['output']>;
  /** Позиция относительно запрашиваемого пользователя */
  sideRelativeConst?: Maybe<MlmBinarCellSideEnum>;
};

export type UserReferralPagination = {
  __typename?: 'UserReferralPagination';
  /** List of items on the current page */
  data?: Maybe<Array<UserReferral>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр по бинарному статусу пользователя */
export type UserReferralsMlmBinarUserStatusFilter = {
  /** Тип бинарного статуса */
  typeStatusConst?: InputMaybe<UserMlmBinarUserStatusTypeConstEnum>;
};

/** Пользователь из пути дерева наставник/спонсор */
export type UserReferralsPath = {
  __typename?: 'UserReferralsPath';
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
};

/** Фильтр по данным периода */
export type UserReferralsPathByMlmPeriodFilter = {
  /** Период */
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** Фильтр по данным пользователей */
export type UserReferralsPathQueryByUserFilter = {
  /** Id Ref пользователя */
  idRef?: InputMaybe<Scalars['Int']['input']>;
  /** PK пользователя */
  pk?: InputMaybe<Scalars['Int']['input']>;
};

/** Фильтр */
export type UserReferralsPathQueryFilter = {
  /** Пользователь до которого делаем просмотр структуры (начиная от pk авторизованного пользователя) */
  childUser?: InputMaybe<UserReferralsPathQueryByUserFilter>;
  /** Период */
  mlmPeriod?: InputMaybe<UserReferralsPathByMlmPeriodFilter>;
  /** Тип структуры */
  typeConst?: InputMaybe<UserReferralsPathQueryFilterTypeEnum>;
};

/** Тип структуры */
export enum UserReferralsPathQueryFilterTypeEnum {
  Referrer = 'REFERRER',
  Sponsor = 'SPONSOR'
}

export type UserReferralsPathQueryPayload = {
  __typename?: 'UserReferralsPathQueryPayload';
  errors?: Maybe<Array<UserReferralsPathQueryProblem>>;
  /** Дерево наставника/спонсора */
  userReferralsPath?: Maybe<Array<Maybe<UserReferralsPath>>>;
};

export type UserReferralsPathQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Фильтр по данным бинару */
export type UserReferralsQueryByBinarFilter = {
  /** Дата окончания бинарной активности от */
  activityStopFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата окончания бинарной активности до */
  activityStopTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Размещен в бинаре */
  inBinar?: InputMaybe<Scalars['Boolean']['input']>;
  /** Статус бинарной активности */
  isActivity?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Фильтр по данным периода */
export type UserReferralsQueryByMlmPeriodFilter = {
  /** Групповой объем по дереву спонсора за период от */
  groupVolumeSponsorFrom?: InputMaybe<Scalars['Float']['input']>;
  /** Групповой объем по дереву спонсора за период до */
  groupVolumeSponsorTo?: InputMaybe<Scalars['Float']['input']>;
  /** Период */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** BPV накопительное за период от */
  pointBpvFrom?: InputMaybe<Scalars['Float']['input']>;
  /** BPV накопительное за период до */
  pointBpvTo?: InputMaybe<Scalars['Float']['input']>;
  /** Боковой объем по дереву спонсора за период от */
  sideVolumeSponsorFrom?: InputMaybe<Scalars['Float']['input']>;
  /** Боковой объем по дереву спонсора за период до */
  sideVolumeSponsorTo?: InputMaybe<Scalars['Float']['input']>;
  /** Квалификация в месячном периоде от и выше */
  userRankConstIdFrom?: InputMaybe<MlmRankEnum>;
  /** Квалификация в месячном периоде */
  userRankConstIds?: InputMaybe<Array<MlmRankEnum>>;
};

/** Фильтр по личной активности */
export type UserReferralsQueryByMlmUserActivityFilter = {
  /** Проверить активность на дату */
  activityAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Личная активность */
  isActivity?: InputMaybe<Scalars['Boolean']['input']>;
  /** Дата окончания личной активности от */
  stopFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата окончания личной активности до */
  stopTo?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Фильтр по данным бинару */
export type UserReferralsQueryByParentUserFilter = {
  /** id Ref пользователя, родителя рефералов, чье дерево показать */
  idRef?: InputMaybe<Scalars['Int']['input']>;
  /** PK пользователя, родителя рефералов, чье дерево показать */
  pk?: InputMaybe<Scalars['Int']['input']>;
};

/** Фильтр по дереву */
export type UserReferralsQueryByTreeFilter = {
  /** Линия, уровень вложенности. Если указать null придут все линии */
  depthRelative?: InputMaybe<Scalars['Int']['input']>;
  /** Линия, уровень вложенности от */
  depthRelativeFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Линия, уровень вложенности до */
  depthRelativeTo?: InputMaybe<Scalars['Int']['input']>;
};

/** Фильтр по данным пользователей */
export type UserReferralsQueryByUserFilter = {
  /** ID города пользователя среди рефералов */
  cityId?: InputMaybe<Scalars['Int']['input']>;
  /** Дата подписания контракта физ лица пользователя среди рефералов от */
  contractCreatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата подписания контракта физ лица пользователя среди рефералов до */
  contractCreatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Код страны пользователя среди рефералов */
  countryCode?: InputMaybe<CountryEnum>;
  /** Регистрация пользователя среди рефералов от */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Регистрация пользователя среди рефералов до */
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Id Ref пользователя среди рефералов */
  idRef?: InputMaybe<Scalars['Int']['input']>;
  /** Контракт подписан у физ лица пользователя */
  isContractCreated?: InputMaybe<Scalars['Boolean']['input']>;
  /** Фамилия пользователя среди рефералов */
  lname?: InputMaybe<Scalars['String']['input']>;
  /** Квалификации */
  mlmUserRankConstIds?: InputMaybe<Array<MlmRankEnum>>;
  /** PK пользователя среди рефералов */
  pk?: InputMaybe<Scalars['Int']['input']>;
  /** BPV накопительное за всю историю от */
  pointBpvFrom?: InputMaybe<Scalars['Float']['input']>;
  /** BPV накопительное за всю историю до */
  pointBpvTo?: InputMaybe<Scalars['Float']['input']>;
  /** Дата рождения пользователя среди рефералов от */
  profileBirthdayAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата рождения пользователя среди рефералов до */
  profileBirthdayAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Id Ref наставника пользователя среди рефералов */
  referrerIdRef?: InputMaybe<Scalars['Int']['input']>;
  /** IDs тегов пользователей */
  tagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Фильтр */
export type UserReferralsQueryFilter = {
  /** Фильтр по данным бинара */
  binar?: InputMaybe<UserReferralsQueryByBinarFilter>;
  /** Фильтр по данным бинарного статуса пользователя */
  mlmBinarUserStatus?: InputMaybe<UserReferralsMlmBinarUserStatusFilter>;
  /** Фильтр по данным периода */
  mlmPeriod?: InputMaybe<UserReferralsQueryByMlmPeriodFilter>;
  /** PK пользователя, родителя рефералов, чье дерево показать */
  parentUser: UserReferralsQueryByParentUserFilter;
  /** Фильтр по данным пользователей */
  tree?: InputMaybe<UserReferralsQueryByTreeFilter>;
  /** Дерево */
  treeConst: MlmUserTreeFilterEnum;
  /** Фильтр по данным пользователей */
  user?: InputMaybe<UserReferralsQueryByUserFilter>;
  /** Фильтр по дереву */
  userMlmUserActivity?: InputMaybe<UserReferralsQueryByMlmUserActivityFilter>;
};

export type UserReferralsQueryPayload = {
  __typename?: 'UserReferralsQueryPayload';
  errors?: Maybe<Array<UserReferralsQueryProblem>>;
  mlmPeriod?: Maybe<MlmPeriod>;
  stats?: Maybe<UserReferralsQueryStat>;
  userReferrals?: Maybe<UserReferralPagination>;
};

export type UserReferralsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Статистика */
export type UserReferralsQueryStat = {
  __typename?: 'UserReferralsQueryStat';
  /** Число партнёров с личной активностью */
  countMlmUserActivity?: Maybe<Scalars['Int']['output']>;
  /** Число партнёров под пользователем в дереве  */
  countReferrals: Scalars['Int']['output'];
  /** Число партнёров с квалификацией выше граната в дереве спонсора */
  countReferralsAboveGarnet?: Maybe<Scalars['Int']['output']>;
  /** Количество рефералов, неразмещенных в бинаре */
  countReferralsNotInBinar: Scalars['Int']['output'];
};

/** Допустимые сортировки списка рефералов пользователя */
export enum UserReferralsSortEnum {
  MlmBinarActivityStopAtAsc = 'MLM_BINAR_ACTIVITY_STOP_AT_ASC',
  MlmBinarActivityStopAtDesc = 'MLM_BINAR_ACTIVITY_STOP_AT_DESC',
  MlmPeriodChildrenPointBpvSponsorAsc = 'MLM_PERIOD_CHILDREN_POINT_BPV_SPONSOR_ASC',
  MlmPeriodChildrenPointBpvSponsorDesc = 'MLM_PERIOD_CHILDREN_POINT_BPV_SPONSOR_DESC',
  MlmPeriodUserPointBpvAsc = 'MLM_PERIOD_USER_POINT_BPV_ASC',
  MlmPeriodUserPointBpvDesc = 'MLM_PERIOD_USER_POINT_BPV_DESC',
  MlmUserActivityStopAtAsc = 'MLM_USER_ACTIVITY_STOP_AT_ASC',
  MlmUserActivityStopAtDesc = 'MLM_USER_ACTIVITY_STOP_AT_DESC',
  ReferrerDepthAsc = 'REFERRER_DEPTH_ASC',
  ReferrerDepthDesc = 'REFERRER_DEPTH_DESC',
  SponsorDepthAsc = 'SPONSOR_DEPTH_ASC',
  SponsorDepthDesc = 'SPONSOR_DEPTH_DESC',
  UserBirthdayClosest = 'USER_BIRTHDAY_CLOSEST',
  UserCreatedAtAsc = 'USER_CREATED_AT_ASC',
  UserCreatedAtDesc = 'USER_CREATED_AT_DESC',
  UserMlmRankConstIdAsc = 'USER_MLM_RANK_CONST_ID_ASC',
  UserMlmRankConstIdDesc = 'USER_MLM_RANK_CONST_ID_DESC',
  UserPkAsc = 'USER_PK_ASC',
  UserPkDesc = 'USER_PK_DESC'
}

/** Информация о наставнике публичная */
export type UserReferrerForPublic = UserReferrerInterface & {
  __typename?: 'UserReferrerForPublic';
  /** Аватар пользователя */
  avatar?: Maybe<UserAvatarImage>;
  /** Дата регистрации */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Максимальный ранг пользователя за всю историю */
  mlmRank?: Maybe<MlmRank>;
  /** Parents пользователя */
  parents?: Maybe<UserParentWithReferrerAndSponsor>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
  /** Ссылки на социальные сети пользователя */
  social?: Maybe<UserSocial>;
};

/** Информация о наставнике для самого пользователя */
export type UserReferrerForUser = UserReferrerInterface & {
  __typename?: 'UserReferrerForUser';
  /** Аватар пользователя */
  avatar?: Maybe<UserAvatarImage>;
  /** Город */
  city?: Maybe<City>;
  /** Страна */
  country?: Maybe<Country>;
  /** Дата регистрации */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Список почты */
  emails?: Maybe<Array<Maybe<UserEmail>>>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Максимальный ранг пользователя за всю историю */
  mlmRank?: Maybe<MlmRank>;
  /** Parents пользователя */
  parents?: Maybe<UserParentWithReferrerAndSponsor>;
  /** Список телефонов */
  phones?: Maybe<Array<Maybe<UserPhone>>>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
  /** Ссылки на социальные сети пользователя */
  social?: Maybe<UserSocial>;
};

/** Минимальная информация по пользователю, которая видна всем */
export type UserReferrerInterface = {
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
};

/** Информация по найденному пользователю */
export type UserReferrerSearch = {
  __typename?: 'UserReferrerSearch';
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
};

export type UserReferrerSearchPagination = {
  __typename?: 'UserReferrerSearchPagination';
  /** List of items on the current page */
  data?: Maybe<Array<UserReferrerSearch>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр */
export type UserReferrerSearchQueryFilter = {
  /** Поиск по полям idRef, Генерируемый код, Телефон, E-mail */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserReferrerSearchQueryPayload = {
  __typename?: 'UserReferrerSearchQueryPayload';
  errors?: Maybe<Array<UserReferrerSearchQueryProblem>>;
  users?: Maybe<UserReferrerSearchPagination>;
};

export type UserReferrerSearchQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

export type UserRegistrationEmailCreateInput = {
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
};

export type UserRegistrationInput = {
  /** Приложение инициализатор */
  applicationConstId?: InputMaybe<ApplicationEnum>;
  /** Дата рождения */
  birthdayAt?: InputMaybe<Scalars['Date']['input']>;
  /** ID города */
  cityId?: InputMaybe<Scalars['Int']['input']>;
  /** Название города пользователя */
  cityName?: InputMaybe<Scalars['String']['input']>;
  /** Код страны */
  countryCode?: InputMaybe<CountryEnum>;
  /** Данные электронной почты пользователя */
  emailData?: InputMaybe<UserRegistrationEmailCreateInput>;
  /** Имя */
  firstName: Scalars['String']['input'];
  /** Фамилия */
  lastName: Scalars['String']['input'];
  /** Отчество */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Пароль */
  password: Scalars['String']['input'];
  /** Данные телефона пользователя */
  phoneData?: InputMaybe<UserRegistrationPhoneCreateInput>;
  /** Код ref. ссылки или ref. id */
  refCode?: InputMaybe<Scalars['String']['input']>;
};

/** Информация для регистрации */
export type UserRegistrationPayload = {
  __typename?: 'UserRegistrationPayload';
  errors?: Maybe<Array<UserRegistrationProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
  /** Token UUID */
  tokenUuid?: Maybe<Scalars['UUID']['output']>;
};

export type UserRegistrationPhoneCreateInput = {
  /** К телефону подключен Viber */
  hasViber?: InputMaybe<Scalars['Boolean']['input']>;
  /** К телефону подключен Whatsapp */
  hasWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
  /** Телефон */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UserRegistrationProblem = NotFoundError | ServerError | ValidateError;

/** Информация по пользователю-рецензенту */
export type UserReviewer = {
  __typename?: 'UserReviewer';
  /** Аватар пользователя */
  avatar?: Maybe<UserAvatarImage>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
};

/** Социальные сети пользователя */
export type UserSocial = {
  __typename?: 'UserSocial';
  /** Ссылка на Facebook */
  facebook?: Maybe<SocialNetworkLink>;
  /** Ссылка на Instagram */
  instagram?: Maybe<SocialNetworkLink>;
  /** Ссылка на Telegram */
  telegram?: Maybe<SocialNetworkLink>;
  /** Ссылка на TikTok */
  tiktok?: Maybe<SocialNetworkLink>;
  /** Ссылка на VK */
  vk?: Maybe<SocialNetworkLink>;
  /** Ссылка на YouTube */
  youtube?: Maybe<UrlInfo>;
};

/** Социальные сети пользователя */
export type UserSocialInput = {
  /** Facebook ссылка на профиль */
  facebook?: InputMaybe<Scalars['String']['input']>;
  /** Instagram username */
  instagram?: InputMaybe<Scalars['String']['input']>;
  /** Telegram username */
  telegram?: InputMaybe<Scalars['String']['input']>;
  /** TikTok username */
  tiktok?: InputMaybe<Scalars['String']['input']>;
  /** VK ссылка на профиль */
  vk?: InputMaybe<Scalars['String']['input']>;
  /** Youtube ссылка на профиль */
  youtube?: InputMaybe<Scalars['String']['input']>;
};

/** Информация о спонсоре */
export type UserSponsor = {
  __typename?: 'UserSponsor';
  /** Аватар пользователя */
  avatar?: Maybe<UserAvatarImage>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Личный номер (реф.код) */
  idRef: Scalars['Int']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** PK пользователя */
  pk: Scalars['Int']['output'];
  /** Ссылки на социальные сети пользователя */
  social?: Maybe<UserSocial>;
};

/** Статистика для родительских наставник/спонсор */
export type UserStatsForParent = {
  __typename?: 'UserStatsForParent';
  /** Статистика по заказам */
  orders?: Maybe<UserOrderStat>;
};

/** Статусы */
export enum UserStatusEnum {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Inactive = 'INACTIVE'
}

/** Фильтр каталога */
export type UserStoreFilterParam = {
  __typename?: 'UserStoreFilterParam';
  /** Город */
  city: City;
  /** Группа фильтра при запросе каталога товаров */
  filterParamTypeConst?: Maybe<UserStoreFilterParamFilterParamTypeEnum>;
  /** ID */
  id: Scalars['Int']['output'];
  /** Склад */
  storage?: Maybe<Storage>;
};

/**
 * Группы фильтров при запросе каталога товаров.
 *             <br>STORAGE - Фильтр по ID склада,
 *             <br>CLIENT_STORAGE - Фильтр по доверенному складу,
 *             <br>CITY_DELIVERY - Фильтр по городу складов,
 *             <br>STORAGE_SELECTION - Фильтр для подбора склада
 */
export enum UserStoreFilterParamFilterParamTypeEnum {
  CityDelivery = 'CITY_DELIVERY',
  ClientStorage = 'CLIENT_STORAGE',
  Storage = 'STORAGE',
  StorageSelection = 'STORAGE_SELECTION'
}

/** Ссылка для подписки на telegram канал */
export type UserTelegramSubscribePayload = {
  __typename?: 'UserTelegramSubscribePayload';
  errors?: Maybe<Array<UserTelegramSubscribeProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
  telegramSubscribeLink?: Maybe<Scalars['String']['output']>;
};

export type UserTelegramSubscribeProblem = NotFoundError | ServerError | ValidateError;

/** Повторная отправка письма подтверждения почты временного пользователя */
export type UserTemporaryEmailCodeSendInput = {
  /** Приложение инициализатор */
  applicationConstId?: InputMaybe<ApplicationEnum>;
  /** Токен пользователя */
  tokenUuid: Scalars['UUID']['input'];
};

export type UserTemporaryPhoneCodeConfirmInput = {
  /** Код подтверждения телефонного номера */
  code: Scalars['String']['input'];
  /** Подтверждаемый телефонный номер */
  phone: Scalars['String']['input'];
  /** Токен пользователя */
  tokenUuid: Scalars['UUID']['input'];
};

export type UserTemporaryPhoneCodeSendInput = {
  /** Подтверждаемый телефонный номер (для отправки смс) */
  phone: Scalars['String']['input'];
  /** Токен пользователя */
  tokenUuid: Scalars['UUID']['input'];
};

/** Токен авторизации */
export type UserTokenAccess = {
  __typename?: 'UserTokenAccess';
  /** Дата истечения */
  expiredAt: Scalars['DateTime']['output'];
  /** Токен */
  token: Scalars['String']['output'];
};

/** Одноразовый токен для авторизации */
export type UserTokenOneTime = {
  __typename?: 'UserTokenOneTime';
  /** Дата истечения */
  expiredAt: Scalars['DateTime']['output'];
  /** Токен */
  token: Scalars['String']['output'];
};

/** Ошибка доступа к редактирвоанию */
export type UserUpdateForbiddenError = Problem & {
  __typename?: 'UserUpdateForbiddenError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  inputName?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UserUpdateInput = {
  /** ID города */
  cityId?: InputMaybe<Scalars['Int']['input']>;
  /** ID доверенного склада */
  clientStorageId?: InputMaybe<Scalars['Int']['input']>;
  /** Код страны */
  countryCode?: InputMaybe<CountryEnum>;
  /** Emails */
  emails?: InputMaybe<UserEmailInput>;
  /** Имя пользователя */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Язык */
  lang?: InputMaybe<LanguageEnum>;
  /** Фамилия пользователя */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Отчество пользователя */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Новый пароль */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Телефоны */
  phones?: InputMaybe<UserPhoneInput>;
  /** Дополнительные данные о пользователе */
  profile?: InputMaybe<UserProfileInput>;
  /** Социальные сети пользователя */
  social?: InputMaybe<UserSocialInput>;
};

/** Информация для редактирования */
export type UserUpdatePayload = {
  __typename?: 'UserUpdatePayload';
  errors?: Maybe<Array<UserUpdateProblem>>;
  isSuccessful: Scalars['Boolean']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
};

export type UserUpdateProblem = NotFoundError | ServerError | UserUpdateForbiddenError | ValidateError;

/** Ошибка валидации */
export type ValidateError = Problem & {
  __typename?: 'ValidateError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  inputName?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** Вебинар */
export type Webinar = {
  __typename?: 'Webinar';
  /** Страны */
  countries?: Maybe<Array<Country>>;
  /** Описание */
  description?: Maybe<Scalars['String']['output']>;
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Изображения */
  images?: Maybe<Array<Maybe<WebinarImageMultiLangType>>>;
  /**
   * Ссылки на вебинар, по языкам<br>
   *         без парамтера languages - Отдаем на языке пользователя <br>
   *         languages: null - Отдаем во всех языка <br>
   *         languages: [RU, EN] - Отдаем в указанных языках <br>
   */
  link?: Maybe<Array<Maybe<TranslationField>>>;
  /** ЧПУ (Slug) */
  slug: Scalars['String']['output'];
  /** Описание спикера */
  speakerDescription?: Maybe<Scalars['String']['output']>;
  /** Полное имя спикера */
  speakerFullName?: Maybe<Scalars['String']['output']>;
  /** Изображение спикера */
  speakerImage?: Maybe<WebinarSpeakerImageType>;
  /** Дата и время начала */
  startAt?: Maybe<Scalars['DateTime']['output']>;
  /** Название */
  title?: Maybe<Scalars['String']['output']>;
};


/** Вебинар */
export type WebinarLinkArgs = {
  languages?: InputMaybe<Array<LanguageEnum>>;
};

/** Изображения вебинара */
export type WebinarImageMultiLangType = {
  __typename?: 'WebinarImageMultiLangType';
  /** Id файла */
  id?: Maybe<Scalars['Int']['output']>;
  /** Изображение */
  image?: Maybe<Image>;
  /** Название файла */
  title?: Maybe<Scalars['String']['output']>;
  /** Тип изображения */
  typeConst?: Maybe<WebinarImageTypeEnum>;
};

/** Типы изображений вебинара */
export enum WebinarImageTypeEnum {
  MainImageBig = 'MAIN_IMAGE_BIG',
  MainImageRetina = 'MAIN_IMAGE_RETINA',
  MainImageSmall = 'MAIN_IMAGE_SMALL'
}

export type WebinarPagination = {
  __typename?: 'WebinarPagination';
  /** List of items on the current page */
  data?: Maybe<Array<Webinar>>;
  /** List of items on the current page */
  pageInfo?: Maybe<PageInfo>;
};

/** Фильтр */
export type WebinarQueryFilter = {
  /** Slug */
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type WebinarQueryPayload = {
  __typename?: 'WebinarQueryPayload';
  errors?: Maybe<Array<WebinarQueryProblem>>;
  webinar?: Maybe<Webinar>;
};

export type WebinarQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

/** Изображение спикера вебинара */
export type WebinarSpeakerImageType = {
  __typename?: 'WebinarSpeakerImageType';
  /** Id файла */
  id?: Maybe<Scalars['Int']['output']>;
  /** Изображение */
  image?: Maybe<Image>;
};

/** Фильтр */
export type WebinarsQueryFilter = {
  /** Slug */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** Название спикера */
  speakerFullName?: InputMaybe<Scalars['String']['input']>;
  /** Дата и время начала от */
  startAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата и время начала до */
  startAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Название */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type WebinarsQueryPayload = {
  __typename?: 'WebinarsQueryPayload';
  errors?: Maybe<Array<WebinarsQueryProblem>>;
  webinars?: Maybe<WebinarPagination>;
};

export type WebinarsQueryProblem = FieldForbiddenError | ForbiddenError | NotFoundError | ServerError | ValidateError;

// tslint:disable-next-line:max-line-length
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from 'src/environments/environment';
import { LanguageService } from '@core/services/language.service';

export interface SocialServiceInterface {
  iconPrefix: string;
  name: { [key: string]: string };
  link?: string;
}

const services: SocialServiceInterface[] = [
  {
    iconPrefix: 'fb',
    name: {
      en: 'Facebook',
      ru: 'Facebook',
      cs: 'Facebook',
      bg: 'Facebook',
      es: 'Facebook',
      de: 'Facebook',
      tr: 'Facebook',
      kk: 'Facebook',
      hu: 'Facebook',
      mk: 'Facebook',
      sr: 'Facebook',
    },
    link: 'https://www.facebook.com/verteraorganic',
  },
  {
    iconPrefix: `vk`,
    name: {
      en: 'Vk.com',
      ru: 'ВКонтакте',
      cs: 'Vk.com',
      bg: 'Vk.com',
      es: 'Vk.com',
      de: 'Vk.com',
      tr: 'Vk.com',
      kk: 'Vk.com',
      hu: 'Vk.com',
      mk: 'Vk.com',
      sr: 'Vk.com',
    },
    link: 'https://vk.com/verteraorganic',
  },
  {
    iconPrefix: `inst`,
    name: {
      en: 'Instagram',
      ru: 'Инстаграм',
      cs: 'Instagram',
      bg: 'Instagram',
      es: 'Instagram',
      de: 'Instagram',
      tr: 'Instagram',
      kk: 'Instagram',
      hu: 'Instagram',
      mk: 'Instagram',
      sr: 'Instagram',
    },
    link: 'https://www.instagram.com/vertera_company/',
  },
  {
    iconPrefix: `youtube`,
    name: {
      en: 'YouTube',
      ru: 'YouTube',
      cs: 'YouTube',
      bg: 'YouTube',
      es: 'YouTube',
      de: 'YouTube',
      tr: 'YouTube',
      kk: 'YouTube',
      hu: 'YouTube',
      mk: 'YouTube',
      sr: 'YouTube',
    },
    link: 'https://www.youtube.com/user/verteraorganic',
  },
  {
    iconPrefix: 'ok',
    name: {
      en: 'Ok.ru',
      ru: 'Одноклассники',
      cs: 'Ok.ru',
      bg: 'Ok.ru',
      es: 'Ok.ru',
      de: 'Ok.ru',
      tr: 'Ok.ru',
      kk: 'Ok.ru',
      hu: 'Ok.ru',
      mk: 'Ok.ru',
      sr: 'Ok.ru',
      uz: 'Ok.ru',
    },
    link: 'https://ok.ru/verteraorganic',
  },
];

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss'],
})
export class ShareButtonComponent implements OnInit, OnDestroy {
  private readonly sub = new Subscription();
  language: string;
  @HostBinding('attr.role') role = 'button';
  @HostBinding('attr.tabindex') tabindex = 0;
  @Output() share = new EventEmitter<boolean>();
  href: string;
  @ViewChild('btn', { static: true }) _btn: ElementRef;
  @Input() sharedNews: boolean;
  @Input() imageUrl?: string;

  services: SocialServiceInterface[];

  _opened: boolean;

  constructor(
    @Optional() @Inject('LOCATION_HREF') private LOCATION_HREF: string,
    @Inject(PLATFORM_ID) private platform: any,
    private languageService: LanguageService,
  ) {}

  ngOnInit() {
    if (isPlatformServer(this.platform)) {
      // eslint-disable-next-line no-useless-escape
      const regExp = /\:\d{4}(.*)$/.exec(this.LOCATION_HREF);
      if (regExp) {
        const queryPrams = regExp[1];
        this.href = `${environment.host}${queryPrams}`;
      }
    } else if (location.href || isPlatformBrowser(this.platform)) {
      this.href = location.href.indexOf('?') > -1 ? location.href.split('?')[0] : location.href;
    }
    this.services = services;
    this.language = this.languageService.getLanguage();
  }

  onShare(): void {
    this.share.emit();
  }

  @HostListener('keyup.enter')
  onEnter() {
    if (this._btn) {
      this._btn.nativeElement.click();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  openMenu() {
    this._opened = !this._opened;
  }

  get okLink() {
    return `https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=${this.href}&st.imageUrl=${this.imageUrl}`;
  }
}

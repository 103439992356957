import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { flatMap, tap } from 'rxjs/operators';
import { AuthTokenService } from '../services/auth/auth-token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isAuth$ = new BehaviorSubject<boolean>(false);
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.injector.get(AuthTokenService);
    return token.getAuthorizationHeaderValue().pipe(
      flatMap((authHeader) => {
        if (authHeader) {
          this.isAuth$.next(true);
          const multiplyHeaders = req.headers.set('Authorization', authHeader as string);
          const authReq = req.clone({ headers: multiplyHeaders });
          return next.handle(authReq).pipe(
            tap((err: any) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status !== 401) {
                  return;
                }
                token.clearAuthHeaderValue();
              }
            }),
          );
        } else {
          return next.handle(req);
        }
      }),
    );
  }
}

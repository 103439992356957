<div class="dialog__content {{type}}">
  <div [innerHTML]="text"></div>
  <div class="dialog__buttons">
    <button class="dialog__btn" *ngIf="!accept"
            (click)="close(true)" i18n="@@common.yes">Yes</button>
    <button class="dialog__btn" *ngIf="accept"
            (click)="close(true)">{{ accept }}</button>
    <button class="dialog__btn" *ngIf="!deny"
            (click)="close(false)" i18n="@@common.no">No</button>
    <button class="dialog__btn" *ngIf="deny"
            (click)="close(false)">{{ deny }}</button>
  </div>
</div>

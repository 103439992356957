import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appInputNumber]'
})
export class InputNumberDirective {

  constructor(private el: ElementRef) {
  }

  @Input() appInputNumber!: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (this.appInputNumber) {
      // tslint:disable-next-line: deprecation
      const key = e.keyCode;
      if ([109, 107, 110, 190].indexOf(key) !== -1) {
        // prevent input the wrong symbols
      } else if ([46, 8, 9, 27, 13].indexOf(key) !== -1 ||
        // Allow: Ctrl+A
        (key === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (key === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V <-- Don't allow paste to the input
        // (key === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (key === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (key >= 35 && key <= 39)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (key < 48 || key > 57)) && (key < 96 || key > 105)) {
        e.preventDefault();
      }
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconModule } from '@ui';
import { TransparentButtonComponent } from './transparent-button/transparent-button.component';
import { GreenButtonComponent } from './green-button/green-button.component';
import { WhiteButtonComponent } from './white-button/white-button.component';
import { VerteraCommonModule } from '@vertera-common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddToCartComponent } from './add-to-cart/add-to-cart.component';
import { CartMessagesService } from './services/cart-messages.service';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, SvgIconModule, VerteraCommonModule],
  declarations: [GreenButtonComponent, TransparentButtonComponent, WhiteButtonComponent, AddToCartComponent],
  exports: [GreenButtonComponent, TransparentButtonComponent, WhiteButtonComponent, AddToCartComponent],
  providers: [CartMessagesService],
})
export class ButtonsModule {}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { AppStorage, CookieStorage } from './core/storage';
import { environment } from 'src/environments/environment';
import { BASE_URL } from '@core/lib/api.factory';
import { CoreModule } from '@core/core.module';
import { LayoutModule } from './layout/layout.module';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { NotificationModule } from './ui/notification/notification.module';
import { NgAnimateScrollService } from '@core/services';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule, GraphQLModule, HttpClientModule, CoreModule.forRoot(), LayoutModule,
    NotificationModule],
  providers: [
    {
      provide: AppStorage,
      useClass: CookieStorage,
    },
    {
      provide: BASE_URL,
      useValue: environment.baseUri,
    },
    {
      provide: REQUEST,
      useValue: {}
    },
    {
      provide: RESPONSE,
      useValue: {}
    },
    NgAnimateScrollService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { User } from '@generated';
import { UserService } from './user.service';
import { AuthTokenService } from './auth/auth-token.service';

@Injectable({
  providedIn: 'root'
})
export class UserHelperService {
  user$ = new ReplaySubject<User | null>(1);
  acceptCookies$ = new ReplaySubject<boolean>(1);

  constructor(private userService: UserService, private authTokenService: AuthTokenService) { }

  getUser() {
    this.userService.getUser().subscribe((user: User) => {
      // VERTERA_REFACTOR
      // if (user && user.hasOwnProperty('is_accepted_cookies')) {
      //   this.acceptCookies$.next(user.is_accepted_cookies);
      // }
      if (user && user.pk) {
        this.user$.next(user);
      } else {
        this.authTokenService.clearAuthHeaderValue();
        this.user$.next(null);
      }
    }, () => this.user$.next(null));
  }
}

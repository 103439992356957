<label class="check__container {{ type }}"
       [ngClass]="{'disabled': disabled, 'small': small, 'medium': medium}">
  <!-- (click)="onChange2()" -->
  <input #input
         (change)="onChange()"
         [checked]="checked"
         [disabled]="disabled"
         [name]="name"
         [value]="value"
         class="visuallyhidden"
         type="checkbox">

  <span class="check__checkmark {{ type }}" [ngClass]="{'indeterminate': indeterminate, 'disabled': disabled, 'small': small, 'medium': medium}"></span>
  <span class="check__content" [ngClass]="{'small': small, 'medium': medium}">
    <ng-content></ng-content>
  </span>
</label>


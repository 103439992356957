import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';

import {
  GQL_USER_PRODUCT_FAVORITE_CREATE_MUTATION,
  GQL_USER_PRODUCT_FAVORITE_DELETE_MUTATION,
  GQL_USER_PRODUCT_FAVORITES_QUERY,
  IEntityUpdateResponse,
  UserProductFavoritesQuery,
} from '@core/models';

import { UserProductFavoriteCreateInput, UserProductFavoritesQueryPayload } from '@generated';

@Injectable()
export class UserProductFavoriteApiService {
  constructor(private apollo: Apollo) {}

  public create(input: UserProductFavoriteCreateInput): Observable<IEntityUpdateResponse> {
    return this.apollo
      .mutate({
        mutation: GQL_USER_PRODUCT_FAVORITE_CREATE_MUTATION,
        variables: { input },
      })
      .pipe(map((res: any) => res.data.UserProductFavorite.Create));
  }

  public delete(id: number): Observable<IEntityUpdateResponse> {
    return this.apollo
      .mutate({
        mutation: GQL_USER_PRODUCT_FAVORITE_DELETE_MUTATION,
        variables: { ids: [id] },
      })
      .pipe(map((res: any) => res.data.UserProductFavorite.Delete));
  }

  public get(query: UserProductFavoritesQuery): Observable<UserProductFavoritesQueryPayload> {
    return this.apollo
      .query({
        query: GQL_USER_PRODUCT_FAVORITES_QUERY,
        fetchPolicy: 'no-cache',
        variables: query,
      })
      .pipe(map((res: any) => res.data.UserGroup.UserProductFavorites));
  }
}

import { Component, Input } from '@angular/core';
import SwiperCore, { A11y, Controller, FreeMode, Navigation, SwiperOptions, Thumbs} from 'swiper'; // import Swiper core and required modules

// install Swiper modules
SwiperCore.use([Thumbs, FreeMode, Controller, Navigation, A11y]);

@Component({
  selector: 'app-similar-products-slider',
  templateUrl: './similar-products-slider.component.html',
  styleUrls: ['./similar-products-slider.component.scss'],
})
export class SimilarProductsSliderComponent {
  @Input() similar: any;
  @Input() recommended!: boolean;
  @Input() buywithproduct!: boolean;

  config: SwiperOptions = {
    direction: 'horizontal',
    slidesPerView: 5,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
      },
      991: {
        slidesPerView: 3,
      },
      576: {
        slidesPerView: 2,
      },
      480: {
        slidesPerView: 2,
        // slidesPerColumn: 2,
      },
    },
  };
}

<div class="dropdown" [class._opened]="_opened" aria-haspopup="true" *ngIf="!sharedNews">
  <div class="share dropdown-toggle">
    <svg-icon name="ic--share" [size]="16"></svg-icon>
    <span (click)="openMenu()" i18n>Share</span>
  </div>
  <div class="dropdown-menu">
    <span class="title" i18n>Share with friends</span>
    <ul class="options">
      <li *ngFor="let service of services"
          class="option">
          <ng-container *ngIf="service.iconPrefix != 'whatsapp'">
            <ng-container *ngIf="language !=='ru'">
              <a href="{{service.link}}{{href}}" target="_blank" rel="nofollow">
                <svg-icon name="social--{{service.iconPrefix}}"></svg-icon>
                <span class="name">{{ service!.name[language] }}</span>
              </a>
            </ng-container>
            <ng-container *ngIf="language ==='ru' && service.iconPrefix !=='fb'">
              <a href="{{service.link}}{{href}}" target="_blank" rel="nofollow">
                <svg-icon name="social--{{service.iconPrefix}}"></svg-icon>
                <span class="name">{{ service!.name[language] }}</span>
              </a>
            </ng-container>
          </ng-container>
      </li>
    </ul>
  </div>
</div>
<div class="shared__news" *ngIf="sharedNews">
  <ng-container *ngFor="let service of services">
    <div class="shared__news--item" *ngIf="language !=='ru'">
        <a href="{{service.link}}" target="_blank" rel="nofollow" class="shared__news--item-link">
          <svg-icon name="social--{{service.iconPrefix}}" [size]="17"></svg-icon>
        </a>
    </div>

    <div class="shared__news--item" *ngIf="language ==='ru' && service.iconPrefix !=='fb' && service.iconPrefix !=='inst'">
        <a href="{{service.link}}" target="_blank" rel="nofollow" class="shared__news--item-link">
          <svg-icon name="social--{{service.iconPrefix}}" [size]="17"></svg-icon>
        </a>
    </div>
  </ng-container>
</div>


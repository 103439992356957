import { gql } from 'apollo-angular';
import { GQL_ERROR_FIELDS } from '../common.gql';

export const GQL_USER_LOGIN_SERVER_MUTATION = `
 mutation userLogin($input: UserLoginByOneTimeTokenInput) {
    User {
       Login(UserLoginByOneTimeTokenInput: $input) {
        isSuccessful
        accessToken
        ${GQL_ERROR_FIELDS}
       }  
    }
 }
`;

export const GQL_USER_SERVER_ENVIRONMENT_UPDATE_MUTATION = `
  mutation ($userEnvironmentUpdateInput: UserEnvironmentUpdateInput) {
    User {
      EnvironmentUpdate(UserEnvironmentUpdateInput: $userEnvironmentUpdateInput) {
        isSuccessful
        userEnvironment {
          uuid
        }
        ${GQL_ERROR_FIELDS}
      }
    }
  }
`;

export const GQL_USER_LOGIN_MUTATION = gql(GQL_USER_LOGIN_SERVER_MUTATION);

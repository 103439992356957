import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AuthTokenService } from './auth-token.service';
import { Guid } from '../../lib/utils/guid';
import { AppStorage } from '../../storage';

@Injectable()
export class AuthSessionService {
  private sessionKey = `${environment.appName}_session`;
  constructor(
    @Inject(AppStorage) private _appStorage: Storage,
    private authTokenService: AuthTokenService,
  ) {}

  getSessionHeaderValue(): Observable<any> {
    // X-Session-Token
    return of(this.getSessionHeader());
  }

  generateNewSessionToken(): string {
    const session_token = Guid.newGuid();
    this._appStorage.setItem(this.sessionKey, session_token);
    return session_token;
  }

  removeSessionToken() {
    this._appStorage.removeItem(this.sessionKey);
  }

  getSessionHeader(): string {
    // X-Session-Token
    let session_token = this._appStorage.getItem(this.sessionKey) as string;
    const authToken = this.authTokenService.getAuthHeader();
    if (authToken) {
      return '';
    }
    if (!session_token && !/^[\w\d-]{30,40}$/.test(session_token)) {
      session_token = this.generateNewSessionToken();
    }
    return session_token;
  }
}

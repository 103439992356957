import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers = request.headers.set('Accept-Language', this.locale);
    const langRequest = request.clone({ headers });

    return next.handle(langRequest);
  }
}

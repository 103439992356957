import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { LanguageService } from '@core/services/language.service';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent implements OnInit, AfterViewInit {
  language!: string;
  scrollHeader!: boolean;
  widthscreen!: number;

  constructor(
    private languageService: LanguageService,
    @Inject(PLATFORM_ID) private platformId: any,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.scrollHeader = false;
    this.language = this.languageService.getLanguage();

    if (isPlatformBrowser(this.platformId)) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add(this.language);
    }
  }

  ngAfterViewInit() {
    this.calcWidth();
    this.onScroll();
    this.cd.detectChanges();
  }

  @HostListener('window: resize')
  calcWidth() {
    if (isPlatformBrowser(this.platformId)) {
      this.widthscreen = window.innerWidth;
    }
  }

  @HostListener('window: scroll')
  onScroll() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.widthscreen < 992) {
        this.scrollHeader = window.pageYOffset >= 72;
      } else {
        this.scrollHeader = window.pageYOffset >= 121;
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { CitiesQuery, GQL_CITIES_QUERY, GQL_CITY_QUERY, PaginationResponse } from '@core/models';
import { City, CityQueryFilter } from '@generated';

@Injectable({ providedIn: 'root' })
export class CitiesApiService {
  constructor(private apollo: Apollo) {}

  public getCities(query: CitiesQuery, fields: string): Observable<PaginationResponse<City>> {
    return this.apollo
      .query({
        query: GQL_CITIES_QUERY(fields),
        variables: query,
      })
      .pipe(map((res: any) => res.data.GeoGroup.Cities.cities));
  }

  public getCity(filter: CityQueryFilter, fields: string): Observable<City> {
    return this.apollo
      .query({
        query: GQL_CITY_QUERY(fields),
        variables: { filter },
      })
      .pipe(map((res: any) => res.data.GeoGroup.City.city));
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// VERTERA_REFACTOR
// import { CartCondition } from '@core/models';

export interface NotiferData {
  title?: string;
  description: string;
}

export interface Notifer {
  component?: any;
  type?: 'to-cart'| 'to-fav' | 'from-fav' | 'literal' | 'stock' | 'withdraw' | 'top';
  data: NotiferData | any;
  entityType?: string;
  // VERTERA_REFACTOR
  // stockInfo? : CartCondition;
}


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notification$ = new Subject<Notifer>();
  constructor() {
  }
  openNotification(notifer: Notifer) {
    this.notification$.next(notifer);
  }
}


import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '@core/services/language.service';
import { OnInit, OnDestroy, Input, Directive } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface ILanguage {
  code: string;
  name: string;
}

export const languageList: ILanguage[] = [
  { code: 'en', name: 'English' },
  { code: 'ru', name: 'Русский' },
  { code: 'es', name: 'Español' },
  { code: 'cs', name: 'Čeština' },
  { code: 'bg', name: 'Български' },
  { code: 'de', name: 'Deutsch' },
  { code: 'hu', name: 'Magyar' },
  { code: 'kk', name: 'Қазақша' },

];

if (!environment.production) {
  languageList.push({ code: 'he', name: 'עברית' });
}

@Directive({
  selector: '[appLanguageSwitcherBase]',
})
export abstract class LanguageSwitcherBaseDirective implements OnInit, OnDestroy {
  currentLanguage!: ILanguage;
  url!: string;
  readonly sub$: Subscription = new Subscription();

  @Input() set supportedLanguages(langs: string[]) {
    if (!langs) {
      langs = ['en'];
    }
    this.languages = languageList.filter((lang) => langs.indexOf(lang.code) > -1);
  }

  languages: ILanguage[] = [
    { code: 'en', name: 'English' },
    // {code: 'ru', name: 'Русский'},
  ];

  protected constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected languageService: LanguageService,
  ) {}

  ngOnInit() {
    this.getUrl();
    this.getCurrentLanguage();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  getUrl() {
    this.sub$.add(
      this.route.queryParams.subscribe(() => {
        this.url = this.router.url;
      }),
    );
  }

  getCurrentLanguage() {
    this.currentLanguage = this.languageService.getCurrentLanguage(this.languages);
  }
}

import { Injectable } from '@angular/core';
import { GQL_CART_FULL_FIELDS, IEntityUpdateResponse } from '@core/models';
import { CartService } from '@core/services/cart/cart.service';
import { CurrencyService } from '@core/services/currency.service';
import { Cart, CartInfo, CartsQueryPayload, CurrencyEnum } from '@generated';
import { BehaviorSubject, Observable, ReplaySubject, map, mergeMap, of, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartViewService {
  cartItems$ = new ReplaySubject<Cart[]>(1);
  // cartRewars$ = new BehaviorSubject<CartReward[]>(null);
  cartItemAmountChanged$ = new BehaviorSubject<number>(0);
  cartItemsUpdate$ = new BehaviorSubject<any>(null);
  cartInfo$ = new BehaviorSubject<CartInfo | undefined>(undefined);
  cartItems: Cart[] = [];
  selectedCartItems: Cart[] = [];
  discount = 0;
  totalPrice = 0;
  totalCustomerPrice = 0;
  selectedTotalAmount = 0;
  totalAmount = 0;
  deliveryPrice = 0;
  markupPercent = 0; // Процент региональной наценки
  markupPrice = 0;
  markupCustomerPrice = 0;
  totalPriceWithDelivery = 0;
  totalCustomerPriceWithDelivery = 0;
  points = 0;
  // runCartUpdate$ = new BehaviorSubject(null);
  // stockConditionsUpdate$ = new BehaviorSubject<CartCondition[]>(null);
  // user: UserModel;
  // sub: Subscription = new Subscription();

  // get cartRewards(): CartReward[] {
  //   const cartRewards: CartReward[] = [];
  //   for (const cartItem of this.selectedCartItems) {
  //     if (cartItem.reward_products && cartItem.reward_products.length > 0) {
  //       for (const cartReward of cartItem.reward_products) {
  //         if (cartReward.amount > 0 ) {
  //           cartRewards.push(cartReward);
  //         }
  //       }
  //     }
  //   }
  //   return cartRewards;
  // }

  constructor(
    private cartService: CartService,
    private currencyService: CurrencyService,
  ) {
    // VERTERA_REFACTOR
    // this.subscribeToCityChange();
    // this.subscribeToUser();
  }

  //  subscribeToUser() {
  //    this.userService.user$.subscribe((user) => {
  //      if(user) {
  //       this.user = user;
  //       this.getStockConditions();
  //      }
  //     });
  //  }

  calculateTotal() {
    this.resetCalculatedValues();
    this.selectedCartItems.forEach((item) => {
      this.totalPrice += item.amount * item.product!.prices!.pricePartner!;
      this.totalCustomerPrice += item.amount * item.product!.prices!.priceGuest!;
      this.selectedTotalAmount += item.amount;
      if (item.product!.points && item.product!.points[0]) {
        this.points += item.product!.points[0].value! * item.amount;
      }
    });
    this.markupPrice = (this.totalPrice * this.markupPercent) / 100;
    this.markupCustomerPrice = (this.totalCustomerPrice * this.markupPercent) / 100;
    if (this.deliveryPrice) {
      this.totalPriceWithDelivery = this.totalPrice + +this.deliveryPrice + this.markupPrice;
      this.totalCustomerPriceWithDelivery = this.totalCustomerPrice + +this.deliveryPrice + this.markupCustomerPrice;
    }
    this.discount = this.totalCustomerPrice - this.totalPrice;
    this.calculateCartTotalAmount();
    this.fetchCartInfo();
  }

  fetchCartInfo() {
    this.cartService.getCartInfo().pipe(tap((res: CartsQueryPayload) => {
      this.cartInfo$.next(res.cartInfo);
    }), take(1)).subscribe();
  }

  selectCartItem(cartItem: Cart): Observable<IEntityUpdateResponse> {
    return this.cartService.selectCartItem(cartItem.id).pipe(tap((res: IEntityUpdateResponse) => {
      if (res.isSuccessful) {
        cartItem.isSelected = true;
        this.selectedCartItems.push(cartItem);
        this.calculateTotal();
      }
    }));
  }

  deselectCartItem(cartItem: Cart): Observable<IEntityUpdateResponse> {
    return this.cartService.deselectCartItem(cartItem.id).pipe(tap(() => {
      cartItem.isSelected = false;

      if (this.selectedCartItems) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.selectedCartItems.length; i++) {
          if (this.selectedCartItems[i].product?.slug === cartItem.product?.slug) {
            this.selectedCartItems.splice(i, 1);
            break;
          }
        }
      }

      this.calculateTotal();
    }));
  }

  selectAllCartItems(): Observable<IEntityUpdateResponse | null> {
    const availableCartItems: Cart[] = this.cartItems.filter((cartItem: Cart) => cartItem.isAvailableToOrder);
    if (availableCartItems && availableCartItems.length > 0) {
      const ids = availableCartItems.map((cartItem: Cart) => cartItem.id);
      return this.cartService.toggleCartItemsSelection(ids, true).pipe(
        tap((res: IEntityUpdateResponse) => {
          if (res.isSuccessful) {
            availableCartItems.map((cartItem) => (cartItem.isSelected = true));
            this.selectedCartItems = availableCartItems;
            this.calculateTotal();
          }
        }),
      );
    } else {
      return of(null);
    }
  }

  deselectAllCartItems(): Observable<IEntityUpdateResponse | null> {
    const availableCartItems: Cart[] = this.cartItems.filter((cartItem: Cart) => cartItem.isAvailableToOrder);
    if (availableCartItems && availableCartItems.length > 0) {
      const ids = availableCartItems.map((cartItem: Cart) => cartItem.id);
      return this.cartService.toggleCartItemsSelection(ids, false).pipe(
        tap((res: IEntityUpdateResponse) => {
          if (res.isSuccessful) {
            availableCartItems.map((cartItem) => {
              cartItem.isSelected = false;
            });
            this.selectedCartItems = [];
            this.calculateTotal();
          }
        }),
      );
    } else {
      return of(null);
    }
  }

  increment(id: number): Observable<IEntityUpdateResponse> {
    return this.cartService.incrementCartItem(id).pipe(
      tap((res: IEntityUpdateResponse) => {
        if (res.isSuccessful) {
          const cartItem = this.findCartItem(id);
          if (cartItem) {
            cartItem.amount++;
            this.calculateTotal();
            this.cartItemAmountChanged$.next(cartItem.id);
          }
        }
      }),
    );
  }

  decrement(id: number): Observable<IEntityUpdateResponse> {
    return this.cartService.decrementCartItem(id).pipe(
      tap((res: IEntityUpdateResponse) => {
        if (res.isSuccessful) {
          const cartItem = this.findCartItem(id);
          if (cartItem) {
            cartItem.amount--;
            this.calculateTotal();
            this.cartItemAmountChanged$.next(id);
          }
        }
      }),
    );
  }

  changeAmount(id: number, amount: number): Observable<IEntityUpdateResponse> {
    return this.cartService.changeCartItem(id, amount).pipe(
      tap((res: IEntityUpdateResponse) => {
        if (res.isSuccessful) {
          const cartItem = this.findCartItem(id);
          if (cartItem) {
            cartItem.amount = amount;
            this.calculateTotal();
            this.cartItemAmountChanged$.next(id);
          }
        }
      }),
    );
  }

  private findCartItem(id: number): Cart {
    return this.cartItems.find((cartItem: Cart) => cartItem.id === id) as Cart;
  }

  resetCalculatedValues() {
    this.discount = 0;
    this.totalPrice = 0;
    this.totalCustomerPrice = 0;
    this.selectedTotalAmount = 0;
    this.totalAmount = 0;
    this.points = 0;
  }

  calculateCartTotalAmount() {
    this.totalAmount = 0;
    this.cartItems?.forEach((item) => {
      this.totalAmount += item.amount;
    });
  }

  deleteCartItem(id: number): Observable<IEntityUpdateResponse> {
    return this.cartService.deleteCartItem(id).pipe(
      tap((res: IEntityUpdateResponse) => {
        if (res.isSuccessful) {
          const cartItem = this.findCartItem(id);
          if (cartItem) {
            // VERTERA_REFACTOR
            // this.deselectCartItem(cartItem);
            this.cartItems.splice(
              this.cartItems.findIndex((item) => item.id === cartItem.id),
              1,
            );
            this.cartItems$.next(this.cartItems);
            this.cartItemAmountChanged$.next(id);
          }
        }
      }),
    );
  }

  // getTotalWeight(): number {
  //   let weight = 0;
  //   this.selectedCartItems.forEach((cartItem: CartItem) => {
  //     weight += cartItem.amount * cartItem.product.weight_brutto;
  //   });
  //   this.cartRewards.forEach((cartReward: CartReward) => {
  //     weight += cartReward.amount * cartReward.product.weight_brutto;
  //   });
  //   return parseFloat((weight / 1000).toFixed(2));
  // }

  // getSelectedProductIds(): number[] {
  //  return this.selectedCartItems.map( (cartItem: CartItem) => cartItem.product.id );
  // }
  // getSelectedProductSlugs(): string[] {
  //   return this.selectedCartItems.map( (cartItem: CartItem) => cartItem.product.slug );
  //  }

  setDeliveryPrice(price = 0) {
    this.deliveryPrice = price;
  }

  // Региональная наценка
  setMarkupPercent(percent = 0) {
    this.markupPercent = percent;
  }

  reset() {
    this.cartItems = [];
    this.selectedCartItems = [];
    this.discount = 0;
    this.totalPrice = 0;
    this.totalCustomerPrice = 0;
    this.selectedTotalAmount = 0;
    this.totalAmount = 0;
    this.deliveryPrice = 0;
    this.totalPriceWithDelivery = 0;
    this.totalCustomerPriceWithDelivery = 0;
    this.points = 0;
    this.cartItems$.next([]);
  }

  getCart(): Observable<Cart[]> {
    return this.cartService.getCart(GQL_CART_FULL_FIELDS).pipe(
      tap((data: CartsQueryPayload) => this.handleRespone(data.carts)),
      map((data: CartsQueryPayload) => data.carts || []),
    );
  }

  private handleRespone(data?: Cart[]) {
    if (data) {
      this.cartItems$.next(data);
      this.cartItems = data;
      if (data && data.length > 0) {
        this.currencyService.setCurrency(data[0].currency?.code as CurrencyEnum);
      }
      this.calculateCartTotalAmount();
      this.selectedCartItems = this.cartItems.filter((item) => item.isSelected);
      // VERTERA_REFACTOR
      // this.getCartRewards();
      this.cartItemsUpdate$.next(true);
    }
  }

  // subscribeToCityChange() {
  //   this.cityService.cityChange$.subscribe((city) => {
  //     if (city) {
  //       if (this.cartItems.length > 0) {
  //         const query = new CartUpdateCityQuery();
  //         query.new_city_id = city.id;
  //         const sub = this.cartService.updateCartCity(query).subscribe(() => sub.unsubscribe());
  //       }
  //     }
  //   });

  // }

  // // checks if a cart should fully update
  // checkStockConditions(cartConditions: CartCondition[]) {
  //   // if we have any cart's stocks conditions
  //   if (cartConditions && cartConditions.length > 0) {
  //     // if a value required by condition is true (for example we have enought points to display cart gifts)
  //     if (cartConditions[0].params.current_bpv >= cartConditions[0].params.required_bpv) {
  //       // if there are no gifts in cart
  //       if (!this.isCartHasRewards()) {
  //         // fully update a cart in order to get gifts
  //         this.runCartUpdate$.next(true);
  //       }
  //     } else { // if a value required by condition is false (for example we don't have enought points to display cart gifts)
  //       if (this.isCartHasRewards()) { // if there are gifts in cart
  //         this.runCartUpdate$.next(true); // fully updates a cart to get rid of gifts
  //       }
  //     }
  //   } else {
  //     if (!this.isCartHasRewards()) {
  //       // fully update a cart in order to get gifts
  //       this.runCartUpdate$.next(true);
  //     }
  //   }
  // }

  // private isCartHasRewards() {
  //   for (const cartItem of this.cartItems) {
  //     if (cartItem.reward_products && cartItem.reward_products.length > 0) {
  //       return true;
  //     }
  //   }
  // }

  // getStockConditions() {
  //  if(this.user) {
  //    this.stockService.activeStock$.pipe(filter(stock => !!stock)).subscribe(() => {
  //     this.cartService.getStockConditions().subscribe((cartConditions: CartCondition[]) => {
  //       this.stockConditionsUpdate$.next(cartConditions);
  //      });
  //    })
  //  }
  // }

  // getCartRewards() {
  //   this.userService.user$.pipe(mergeMap((user: UserModel) => {
  //    return  user? this.cartService.getCartRewards(): of(null);
  //   })).subscribe((cartRewards: CartReward[]) => {
  //     if(cartRewards) {
  //       this.cartRewars$.next(cartRewards);
  //       this.cartItems.map(cartItem => cartItem.reward_products = null);
  //       this.cartService.setCartRewards(this.cartItems, cartRewards);
  //     }
  //     this.sub.unsubscribe();
  //   }, () => {
  //     this.sub.unsubscribe();
  //   });
  // }
  // isCartItemAvailable(cartItem: CartItem): boolean {
  //   return cartItem.is_delivery_available && cartItem.product.status.id === ProductStatuses.published;
  // }
}

import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CartsApiService } from '../api/CartGroup/carts-api.service';
import { UserEnvironmentService } from '../core/user-environment/user-environment.service';
import { CartCreateInput, CartDeliveryTypesQueryPayload, CartUpdatePayload, CartsQueryPayload, StorageDelivery } from '@generated';
import { GQL_CART_TOTAL_FIELDS, IEntityUpdateResponse } from '@core/models';
import { CartDeliveryTypesApiService } from '../api/CartGroup/cart-delivery-types-api.service';
import { GQL_CART_DELIVERY_TYPES_FIELDS } from '@core/models/CartGroup/cart-delivery-types.gql';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(
    private cartsApiService: CartsApiService,
    private userEnvironmentService: UserEnvironmentService,
    private cartDeliveryTypesApiService: CartDeliveryTypesApiService
  ) { }

  getCart(fields: string): Observable<CartsQueryPayload> {
    return this.cartsApiService.get(fields, this.userEnvironmentService.get());
  }

  addToCart(cartCreateInput: CartCreateInput): Observable<CartUpdatePayload> {
    return this.cartsApiService.create(cartCreateInput, this.userEnvironmentService.get());
  }

  incrementCartItem(id: number): Observable<IEntityUpdateResponse> {
    return this.cartsApiService.increment(id, this.userEnvironmentService.get());
  }

  decrementCartItem(id: number): Observable<IEntityUpdateResponse> {
    return this.cartsApiService.decrement(id, this.userEnvironmentService.get());
  }

  changeCartItem(id: number, amount: number): Observable<IEntityUpdateResponse> {
    return this.cartsApiService.update(id, { amount }, this.userEnvironmentService.get());
  }

  deleteCartItem(id: number): Observable<IEntityUpdateResponse> {
    return this.cartsApiService.delete([id], this.userEnvironmentService.get());
  }

  cleanCart(ids: number[]): Observable<IEntityUpdateResponse> {
    return this.cartsApiService.delete(ids, this.userEnvironmentService.get());
  }

  selectCartItem(id: number): Observable<IEntityUpdateResponse> {
    return this.toggleCartItemsSelection([id], true);
  }

  deselectCartItem(id: number): Observable<IEntityUpdateResponse> {
    return this.toggleCartItemsSelection([id], false);
  }

  toggleCartItemsSelection(ids: number[], isSelected): Observable<IEntityUpdateResponse> {
    return this.cartsApiService.multipleUpdate(ids, this.userEnvironmentService.get(), { isSelected });
  }

  getCartInfo(storageDeliveryId?: number): Observable<CartsQueryPayload> {
    return this.cartsApiService.getOrderInfo(GQL_CART_TOTAL_FIELDS, this.userEnvironmentService.get(), storageDeliveryId);
  }

  getCartDelivery(): Observable<StorageDelivery[] | undefined> {
    return this.cartDeliveryTypesApiService.get(this.userEnvironmentService.get(), GQL_CART_DELIVERY_TYPES_FIELDS).pipe(map((res: CartDeliveryTypesQueryPayload) => res.storageDeliveries));
  }
}

import { Pipe, PipeTransform } from '@angular/core';

export const CURRENCY_MATCH: any = {
  USD: '$',
  EUR: '€',
  RUB: '₽',
  BTC: '฿', // fix bitcoin
  KZT: '₸',
  CNY: '¥',
  UAH: '₴',
  BYN: 'Br',
  MDL: 'lei.',
  AZN: '₼',
  KGS: 'som.',
  AMD: '֏',
  TJS: 'somoni',
  UZS: 'soʻm.',
  TMT: 'm.',
  AED: 'د.إ',
  INR: '₹',
  TRY: '₺',
  CZK: 'Kč'
};
// $ € ₽ ₿ ₸ ¥ ₴ Br lei. ₼ som. ֏ somoni soʻm. m. ₹ ₺ د.إ

@Pipe({
  name: 'vrCurrency'
})
export class VRCurrencyPipe implements PipeTransform {

  transform(value: number, arg: string): any {
    if (value) {
      if (arg) {
        if (!CURRENCY_MATCH[arg]) {
          return arg;
        } else {
          return value + CURRENCY_MATCH[arg];
        }
      } else {
        return value + '%';
      }
    }
  }
}

@Pipe({
  name: 'vrCurrencySymbol'
})
export class VRCurrencySymbolPipe implements PipeTransform {
  transform(value: string): string {
    return CURRENCY_MATCH[value && value.toUpperCase()];
  }
}

import { LanguageService } from './language.service';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { filter, map, take, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { CityService } from './hdbk/city.service';
import { Params } from '@angular/router';
import { DynamicScriptLoaderService } from './script-loader.service';
import { City, CountryEnum } from '@generated';
import { CitiesQuery } from '@core/models';
import { LANG_ID_MAPS } from '@core/lib/const/langs';

declare let ymaps: any;

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  myCityName: string;
  myCity: City;

  defaultCities: any = {
    en: [
      { title: 'Moscow', code: CountryEnum.Ru},
      { title: 'Kiev', code: CountryEnum.Ua },
      { title: 'Prague', code: CountryEnum.Cz },
      { title: 'Sofia', code: CountryEnum.Bg },
      { title: 'Nur-Sultan', code: CountryEnum.Kz },
      { title: 'London', code: CountryEnum.Gb },
      { title: 'Yerevan', code: CountryEnum.Ar },
      { title: 'Rome', code: CountryEnum.It },
      { title: 'Vienna', code: CountryEnum.At },
      { title: 'Copenhagen', code: CountryEnum.Dk },
      { title: 'Tbilisi', code: CountryEnum.Ge },
      { title: 'Berlin', code: CountryEnum.De },
      { title: 'Paris', code: CountryEnum.Fr },
      { title: 'Helsinki', code: CountryEnum.Fi },
    ],
    ru: [
      { title: 'Москва', country: { code: CountryEnum.Ru } },
      { title: 'Санкт-Петербург', country: { code: CountryEnum.Ru } },
      { title: 'Новосибирск', country: { code: CountryEnum.Ru } },
      { title: 'Екатеринбург', country: { code: CountryEnum.Ru } },
      { title: 'Нижний Новгород', country: { code: CountryEnum.Ru } },
      { title: 'Краснодар', country: { code: CountryEnum.Ru } },
      { title: 'Челябинск', country: { code: CountryEnum.Ru } },
      { title: 'Кемерово', country: { code: CountryEnum.Ru } },
      { title: 'Тюмень', country: { code: CountryEnum.Ru } },
      { title: 'Красноярск', country: { code: CountryEnum.Ru } },
      { title: 'Казань', country: { code: CountryEnum.Ru } },
      { title: 'Пермь', country: { code: CountryEnum.Ru } },
    ]
  };

  previousUrl: string;
  previousQueryParams: Params;

  get myCityNameSelected(): boolean {
    return !!this.myCityName;
  }

  constructor(
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private cityService: CityService,
    private languageService: LanguageService,
    @Inject(PLATFORM_ID) private platform: any
  ) {
    this.getMyCityName();
  }

  getMyCityName(): string {
    if (!this.myCityName) {
      const city = this.cityService.getCity();
      this.myCityName = city ? city.title : '';
    }
    return this.myCityName;
  }

  getMyCity(): Observable<City> {
    if (this.myCity) {
      return of(this.myCity);
    } else {
      const query = new CitiesQuery();
      query.filter = {
        title: this.getMyCityName()
      };
      return this.cityService.getPage(query)
        .pipe(
          filter( data => !!data.data[0]),
          take(1),
          map( data => {
            this.myCity = data.data[0];
            this.myCity.country = data.data[0].country;
            return this.myCity;
          })
        );
    }
  }

  detectGeo(): Observable<{title: string} | undefined> {
    if (!isPlatformBrowser(this.platform)) {
      return of(undefined);
    }
    const geo = { title: this.getMyCityName()};

    if (!geo.title) {
      // tslint:disable-next-line: deprecation
      return Observable.create( observer => {
        this.loadDetectScript().then(() => {
          ymaps.ready(() => {
            this.cityService.setCity(ymaps.geolocation.city);
            observer.next({ title: ymaps.geolocation.city });
          });
        }).catch(error => console.error(error));

      });
    } else {
      return of(geo);
    }
  }
  // Загрузка только по необходимости, если не выбран город
  loadDetectScript(): Promise<any> {
    let langQuery = `&lang=ru-RU`;
    const property = LANG_ID_MAPS[this.languageService.getLanguage()];
    if (property) {
      langQuery = `&lang=${property}`;
    }
    return this.dynamicScriptLoader.loadScript('ymaps', langQuery);
  }

  // Moscow
  isInDefaultCity(): Observable<boolean> {
    return this.detectGeo().pipe( mergeMap((geo) => {
      if (geo?.title === this.defaultCities[this.languageService.getLanguage()]) {
        return of(true);
      } else {
        return of(false);
      }
    }));
  }
}

import { gql } from 'apollo-angular';

export const GQL_CART_DELIVERY_TYPES_QUERY = (fields: string) => gql`
  query cartDeliveryTypes($userEnvironmentUuid: UUID!) {
    CartGroup {
      CartDeliveryTypes(userEnvironmentUuid: $userEnvironmentUuid) {
        ${fields}
      }
    }
  }
`;

export const GQL_CART_DELIVERY_TYPES_FIELDS = `
  storageDeliveries {
    id
    deliveryType {
      title
      constId
    }
    price
    currency {
      code
    }
    storage {
      address {
        id
      }
    }
  }
`;

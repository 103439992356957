import { BaseDirective } from '@abstract';
import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { GQL_CART_PREVIEW_FIELDS } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { CartService } from '@core/services/cart/cart.service';
import { LanguageService } from '@core/services/language.service';
import { UserHelperService } from '@core/services/user-helper.service';
import { Cart, User } from '@generated';
import { Subscription } from 'rxjs';
import { CartViewService } from 'src/app/cart/services/cart-view.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseDirective implements OnInit, AfterViewInit {
  showAside = false;
  _opened = false; // For user menu click
  // For stock panel
  sticky = false;
  widthscreen!: number;
  stickyHeader = false;
  showCatalogMenu = false; // Для просмотра каталога в мобильнном меню
  isUserLoading = false;
  user: User;
  language: string;
  private readonly sub = new Subscription();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private cd: ChangeDetectorRef,
    public cartViewService: CartViewService,
    private cartService: CartService,
    private router: Router,
    private userHelperService: UserHelperService,
    private languageService: LanguageService,
    private authService: AuthService
  ) {
    super()
  }

  ngOnInit() {
    this.subscribeToCart();
    this.getCart();
    this.subscribeToCartAmount();
    this.subscribeToCartItemsUpdate();
    this.language = this.languageService.getLanguage();
    if (isPlatformBrowser(this.platformId)) {
      this.widthscreen = window.innerWidth;
    }
    this.subscribeToUser();

  }

  ngAfterViewInit() {
    this.calcWidth();
    this.onScroll();
  }

  toggleAside() {
    this.showAside = !this.showAside;
    this._opened = false;
  }

  // For Desktop Catalog
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickedOutsideCatalogue(event: any) {
    if (!event.target.closest('.header__button--catalog')) {
      this.showCatalogMenu = false;
    }
  }

  // For Mobile && Desctop Catalog
  toggleCatalogMenu() {
    this.showCatalogMenu = !this.showCatalogMenu;
  }

  closeCatalogMenu() {
    this.toggleAside();
  }

  @HostListener('window: scroll')
  onScroll() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.widthscreen < 992) {
        this.stickyHeader = window.pageYOffset >= 72;
        this.sticky = window.pageYOffset >= 72;
      } else {
        this.stickyHeader = window.pageYOffset >= 121;
        this.sticky = window.pageYOffset >= 121;
      }
    }
  }

  @HostListener('window: resize')
  calcWidth() {
    if (isPlatformBrowser(this.platformId)) {
      this.widthscreen = window.innerWidth;
      if (this.widthscreen >= 576) {
        this.showCatalogMenu = false; // Need for mobile catalog if resize
      }
    }
  }

  private getCart() {
    if (!this.router.url.includes('cart')) {
      const sub = this.cartService.getCart(GQL_CART_PREVIEW_FIELDS).subscribe((data) => {
        this.cartViewService.cartItems$.next(data.carts as Cart[]);
        this.cartViewService.cartItems = data.carts as Cart[];
        this.cartViewService.calculateCartTotalAmount();
        this.cd.detectChanges();
      });
      this.sub.add(sub);
    }
  }

  private subscribeToUser() {
    this.isUserLoading = true;
    this.userHelperService.getUser();
    this.userHelperService.user$.pipe(this.takeUntilDestroyed()).subscribe(user => {
      this.isUserLoading = false;
      if (user) {
        this.user = user;
        this.cd.markForCheck();
        // this.subscribeToFavorites();
      }
    });
  }

  private subscribeToCart() {
    const sub = this.cartViewService.cartItems$.subscribe(() => this.cd.detectChanges());
    this.sub.add(sub);
  }

  private subscribeToCartAmount() {
    const sub = this.cartViewService.cartItemAmountChanged$.subscribe(() => this.cd.detectChanges());
    this.sub.add(sub);
  }

  subscribeToCartItemsUpdate() {
    const sub = this.cartViewService.cartItemsUpdate$.subscribe(() => {
      this.cd.detectChanges();
    });
    this.sub.add(sub);
  }

  register() {
    this.authService.externalReg();
  }

  login() {
    this.authService.externalLogin();
  }

  logout() {
    this.authService.logOut();
  }

  toggleUserMenu() {
    this._opened = !this._opened;
  }
}
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AppStorage } from '../storage';
import { GeoData, GeoDataDefault } from '../models/ui/geoData.model';

@Injectable({
  providedIn: 'root',
})
export class GeoDataService {
  public readonly geoDataKey = `${environment.appName}_geoData`;
  private geoData$$ = new BehaviorSubject<GeoData>(GeoDataDefault);

  geoData$ = this.geoData$$.asObservable();

  constructor(@Inject(AppStorage) private appStorage: Storage) {
    const data = this.getGeoData();
    if (data) {
      this.geoData$$.next(data);
    }
  }

  setGeoData(data: GeoData): void {
    if (data.country?.code && data.city?.id) {
      this.appStorage.removeItem(this.geoDataKey);
      const monthMs = 2592000000;
      this.appStorage.setItemWithPeriod(this.geoDataKey, JSON.stringify(data), monthMs);
      this.geoData$$.next(data);
    }
  }

  getGeoData(): GeoData {
    const data = this.appStorage.getItem(this.geoDataKey);
    if (!data) {
      return GeoDataDefault;
    }
    return JSON.parse(data);
  }
}

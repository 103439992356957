<div class="card__labels"> <!-- || product.is_top -->
  <div class="card__labels--item" *ngIf="product.flags?.isHit">
    <app-label color="red">
      <span>Hit</span>
    </app-label>
  </div>
  <div class="card__labels--item" *ngIf="product.flags?.isNew" i18n-matTooltip='@@labels.tooltip.new'
    matTooltip="New product" matTooltipClass="mat-tooltip-label card" matTooltipPosition="below">
    <app-label color="blue">
      <span>New</span>
    </app-label>
  </div>
  <!--
    <div class="card__labels--item" *ngIf="product.is_top">
      <app-label color="orange">
        <span>Top</span>
      </app-label>
    </div>
  -->
  <div class="card__labels--item" *ngIf="product.couponInfo?.currentCoupon" i18n-matTooltip='@@labels.tooltip.present'
    matTooltip="The product is included in the promotion" matTooltipClass="mat-tooltip-label card"
    matTooltipPosition="below">
    <div class="card__labels--present label-svg">
      <svg-icon name="ic--gift-icon" [size]="15"></svg-icon>
    </div>
  </div>
  <div class="card__labels--item" *ngIf="product.flags?.isVegan" i18n-matTooltip='@@labels.tooltip.vegan'
    matTooltip="Meets international vegan standards" matTooltipClass="mat-tooltip-label card"
    matTooltipPosition="below">
    <div class="card__labels--vegan label-svg">
      <svg-icon name="ic--leaf" [size]="15"></svg-icon>
    </div>
  </div>
  <div class="card__labels--item" *ngIf="product.flags?.isPromax" i18n-matTooltip='@@labels.tooltip.pro-max'
    matTooltip="Made with the PRO-MAX technology" matTooltipClass="mat-tooltip-label card" matTooltipPosition="below">
    <div class="card__labels--pro-max label-svg">
      <svg-icon name="ic--pro-max" [size]="30"></svg-icon>
    </div>
  </div>
  <div class="card__labels--item" *ngIf="product.flags?.isHalal" i18n-matTooltip='@@labels.tooltip.halal'
    matTooltip="Compliant with HALAL standards" matTooltipClass="mat-tooltip-label card" matTooltipPosition="below">
    <div class="card__labels--halal label-svg">
      <svg-icon name="ic--halal" [size]="20"></svg-icon>
    </div>
  </div>
  <div class="card__labels--item" *ngIf="product.flags?.isKosher" i18n-matTooltip='@@labels.tooltip.kosher'
    matTooltip="Certified for kosher compliance" matTooltipClass="mat-tooltip-label card" matTooltipPosition="below">
    <div class="card__labels--kosher label-svg">
      <svg-icon name="ic--kosher" [size]="18"></svg-icon>
    </div>
  </div>
</div>
<div class="card" [routerLink]="['/product', product.id]" queryParamsHandling="merge">
  <div class="card__actions">
    <div class="actions">
      <app-to-fav [item]='product'></app-to-fav>
    </div>
  </div>
  <div class="card__image">
    <img
      src="{{ product.mainImage?.image?.size300 || 'https://static.dev.new.vertera.org/upload/fb/39/fb390818-2bb9-4836-8b8d-6bdbab907a15.png'}}"
      alt="{{ product.title }}" [routerLink]="['/product', product.id]">
  </div>
  <div class="card__price" *appIfAuth>
    <span [class.card__price--new]="product.prices?.priceGuest  !== product.prices?.pricePartner">
      {{ product.prices?.pricePartner }} <span class="vrCurrency">{{currency | vrCurrencySymbol}}</span>
    </span>
    <span class="card__price--old" *ngIf="product.prices?.priceGuest !== product.prices?.pricePartner">
      {{ product.prices?.priceGuest }} <span class="vrCurrency">{{currency | vrCurrencySymbol}}</span>
    </span>
  </div>
  <div class="card__price not-auth" *appIfAuth="'not'">
    <div>
      <span>
        {{ product.prices?.pricePartner }} <span class="vrCurrency">{{currency | vrCurrencySymbol}}</span>
      </span>
      <span>
        {{ product.prices?.priceGuest }} <span class="vrCurrency">{{currency | vrCurrencySymbol}}</span>
      </span>
    </div>
    <div class="card__price--note">
      <span i18n='@@common.price_after_reg'>Price after registration</span>
    </div>
  </div>
  <div class="card__points" *appIfAuth>
    <img appStatic="/assets/img/cart/y-points.svg" />
    <span>{{ product.points | points: CurrencyPointEnum.Bpv }}</span>
  </div>
  <!--
  <div class="card__raiting" *ngIf="product.avg_rating">
    <app-product-rating [stars]="5"
                        [activeStar]="product.avg_rating" [onlyIndication]="true" [size]="14"
                        [activeColor]="'#FFCC00'" [backColor]="'#E0E0E0'"></app-product-rating>
    <div class="card__review" *ngIf="product.count_reviews">
      ({{ product.count_reviews }})
    </div>
  </div>
  -->
  <div class="card__name">
    {{ product.title | cutWords: widthScreen < 576 ? 20 : 31 : 'hard' }} </div>
      <div class="cart-edit-loader" *ngIf='product.cart?.count  && isLoading'>
        <mat-spinner class='dialog-block-spinner' [diameter]='20'></mat-spinner>
      </div>
      <!-- VERTERA_REFACTOR -->
      <app-add-to-cart [product]="product"></app-add-to-cart>
  </div>
import {
  AfterViewInit, ChangeDetectorRef,
  Component, ElementRef, EventEmitter, Input, NgZone,
  OnInit, Output, ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


export interface RangeValue {
  min: number;
  max: number;
}

/*
 * TODO: add validation, errors
 */
@Component({
  selector: 'app-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss']
})
export class RangeComponent implements OnInit, AfterViewInit {

  @ViewChild('inputMin', {static: true}) inputMinElRef: ElementRef;
  @ViewChild('inputMax', {static: true}) inputMaxElRef: ElementRef;

  @Output() changeData = new EventEmitter();
  range: any[];
  disabled: boolean;

  rangeOutput: { min: number, max: number } = { min: 0, max: 1 };

  private _min: number;
  private _max: number;
  private _value: { min: number, max: number };
  @Input()
  set value(value: { min: number, max: number }) {
    this._setValue(value);
    this._value = Object.assign({}, value);
  }

  @Input()
  set min(value: number) {
    value = +value;
    if (value === this._max) {
      --value;
    }

    if (value < 0) {
      value = 0;
    }
    this._min = +value;
    this._updateValue();
  }

  @Input()
  set max(value: number) {
    value = +value;
    if (value === this._min) {
      value = this._min + 1;
    }
    this._max = value;
    this._updateValue();
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  get min() {
    return this._min;
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  get max() {
    return this._max;
  }

  constructor(private ngzone: NgZone,
              private cdref: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngzone.runOutsideAngular(() => {
      fromEvent(this.inputMaxElRef.nativeElement, 'keyup')
        .pipe(
          debounceTime(500)
        )
        .subscribe(_ => {
          this.onChangeMax(this.rangeOutput.max);
          this.cdref.detectChanges();
        });

      fromEvent(this.inputMinElRef.nativeElement, 'keyup')
        .pipe(
          debounceTime(500)
        )
        .subscribe(_ => {
          this.onChangeMin(this.rangeOutput.min);
          this.cdref.detectChanges();
        });
    });
  }

  onChangeSlider(event) {
    this.rangeOutput = {
      min: event[0],
      max: event[1]
    };
  }

  onChangeEmit() {
    this.changeData.emit(this.rangeOutput);
  }

  onChangeMin(min: number) {
    min < this.rangeOutput.max
      ? this.range = [min, null]
      : this.rangeOutput.min = this.rangeOutput.max - 1;
    this.onChangeEmit();
  }

  onChangeMax(max: number) {
    if (max > this.rangeOutput.min) {
      this.range = [null, max];
      this.onChangeEmit();
    }
  }

  onReset() {
    this.range = [this._min, this._max];
  }

  private _updateValue() {
    if (this._value) {
      this._setValue(this._value);
    }
    this.disabled = !!(this._min && this._max && this._min === this._max - 1);
  }

  private _setValue(value) {
    this._setRangeValue(value);
    // Хак. JS компонент ползунка не всегда отрабатывает.
    // Приходится повторно чере n времени его уведомить о новом значении.
    setTimeout(() => {
      this._setRangeValue(value);
      if (this.range && this.range.length === 2 && this.range[0] !== undefined && this.range[1] !== undefined) {
        this.cdref.detectChanges();
      }
    }, 100);
  }

  private _setRangeValue(value) {
    this.rangeOutput.min = value.min >= this.min ? value.min : this.min;
    this.rangeOutput.max = value.max <= this.max ? value.max : this.max;
    this.range = [this.rangeOutput.min, this.rangeOutput.max];
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';

import { Address, AddressCreateInput, AddressCreatePayload, AddressDeletePayload, AddressesQueryPayload, AddressQueryFilter, AddressUpdateInput, AddressUpdatePayload } from '@generated';
import { AddressesQuery } from '@core/models/GeoGroup/addresses';
import { GQL_ADDRESSES_QUERY, GQL_ADDRESS_CREATE_MUTATION, GQL_ADDRESS_DELETE_MUTATION, GQL_ADDRESS_QUERY, GQL_ADDRESS_UPDATE_MUTATION } from '@core/models/GeoGroup/addresses.gql';


@Injectable(
  {
    providedIn: 'root'
  }
)
export class AddressesApiService {
  constructor(private apollo: Apollo) { }

  public getAddress(filter: AddressQueryFilter, fields: string): Observable<Address | undefined> {
    return this.apollo
      .query({
        query: GQL_ADDRESS_QUERY(fields),
        fetchPolicy: 'no-cache',
        variables: { filter },
      })
      .pipe(map((res: any) => res.data.GeoGroup.Address.address));
  }

  public getAddresses(query: AddressesQuery, fields: string): Observable<AddressesQueryPayload> {
    return this.apollo
      .query({
        query: GQL_ADDRESSES_QUERY(fields),
        fetchPolicy: 'no-cache',
        variables: { ...query },
      })
      .pipe(map((res: any) => res.data.GeoGroup.Addresses));
  }

  public addressCreate(input: AddressCreateInput): Observable<AddressCreatePayload> {
    return this.apollo
      .mutate({
        mutation: GQL_ADDRESS_CREATE_MUTATION,
        variables: { input },
      })
      .pipe(map((res: any) => res.data.Address.Create));
  }

  public addressDelete(id: number): Observable<AddressDeletePayload> {
    return this.apollo
      .mutate({
        mutation: GQL_ADDRESS_DELETE_MUTATION,
        variables: { id },
      })
      .pipe(map((res: any) => res.data.Address.Delete));
  }

  public addressUpdate(id: number, input: AddressUpdateInput): Observable<AddressUpdatePayload> {
    return this.apollo
      .mutate({
        mutation: GQL_ADDRESS_UPDATE_MUTATION,
        variables: { id, input },
      })
      .pipe(map((res: any) => res.data.Address.Update));
  }
}

import { gql } from 'apollo-angular';

import {
  GQL_PRODUCT_CART_FIELDS,
  GQL_PRODUCT_FLAGS_BODY,
  GQL_PRODUCT_IMAGE_BODY,
  GQL_PRODUCT_POINTS_BODY,
  GQL_PRODUCT_PRICES_BODY,
} from '../ProductGroup/product.gql';

import { GQL_PAGE_INFO_MODEL } from '../common.gql';

export const GQL_USER_PRODUCT_FAVORITES_QUERY = gql`
  query userFavoriteProducts($page: Int!, $limit: Int!, $filter: UserProductFavoritesQueryFilter) {
    UserGroup {
      UserProductFavorites(page: $page, limit: $limit, filter: $filter) {
        userProductFavorites {
          data {
            product {
              id
              title
              statusConst
              slug
              isMyFavorite
              isAvailableToOrder
              unitConst

              ${GQL_PRODUCT_CART_FIELDS}
              ${GQL_PRODUCT_FLAGS_BODY}
              ${GQL_PRODUCT_PRICES_BODY}
              ${GQL_PRODUCT_POINTS_BODY}

              mainImage { ${GQL_PRODUCT_IMAGE_BODY} }
            }
          }
          pageInfo { ${GQL_PAGE_INFO_MODEL} }
        }
        currencyCode
      }
    }
  }
`;

export const GQL_USER_PRODUCT_FAVORITE_CREATE_MUTATION = gql`
  mutation createProductFavorite($input: UserProductFavoriteCreateInput) {
    UserProductFavorite {
      Create(UserProductFavoriteCreateInput: $input) {
        isSuccessful
      }
    }
  }
`;

export const GQL_USER_PRODUCT_FAVORITE_DELETE_MUTATION = gql`
  mutation ($ids: [Int]) {
    UserProductFavorite {
      Delete(ids: $ids) {
        isSuccessful
      }
    }
  }
`;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardComponent } from './product-card/product-card.component';
import { ProductRatingModule } from './product-rating/product-rating.module';
import { VerteraCommonModule } from '@vertera-common';
import { ButtonsModule } from '../buttons/buttons.module';

import { LabelModule } from '../label/label.module';
import { ToFavModule } from '../favorites/to-fav/to-fav.module';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
  imports: [
    CommonModule,
    ProductRatingModule,
    VerteraCommonModule,
    ButtonsModule,
    SvgIconModule,
    LabelModule,
    ToFavModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatTooltipModule,
  ],
  declarations: [ProductCardComponent],
  exports: [ProductCardComponent]
})
export class CardsModule { }

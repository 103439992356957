import { OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectorRef, Directive, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, from, Subscription, of, Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CityService } from '@core/services/hdbk/city.service';
import { CountryService } from '@core/services/hdbk/country.service';
import { debounceTime, filter, map, switchMap, catchError, take } from 'rxjs/operators';
import { GeoService } from '@core/services/geo.service';
import { LanguageService } from '@core/services/language.service';
import { MatDialog } from '@angular/material/dialog';
import { CountryRedirectService } from '@core/services/common/country-redirect.service';
import { City, Country, CountryEnum } from '@generated';
import { CitiesQuery, CountriesQuery, PaginationResponse } from '@core/models';
import { UserEnvironmentService } from '@core/services/core/user-environment/user-environment.service';

declare let ymaps: any;

export class GeoEntity {
  isLoading: boolean;
  entities = [];
  entitiesForDD: Country[] | City[] = [];
  public readonly allowSearch$ = new Subject<boolean>();
  public readonly search$ = new Subject<string>();
  public query: any;
  public selected?: Country | City;

  constructor(q) {
    this.query = q;
  }
}

@Directive({
  selector: '[appBaseDirective]',
})
export abstract class Geo implements OnInit, OnDestroy {
  readonly sub$: Subscription = new Subscription();
  submitted: boolean;
  isLoading: boolean;
  isUserEnvLoading = false;
  city: GeoEntity;
  country: GeoEntity;
  // set this property to true in child classes if you don't need geo detection
  protected skipGeoDetection: boolean;
  yaGeolocation: any;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onSubmit = new EventEmitter();

  @Input() productIds?: number;

  protected onCitySelected$ = new EventEmitter();
  protected onCountryPreselected$ = new EventEmitter();

  protected constructor(
    protected formBuilder: FormBuilder,
    protected cityService: CityService,
    protected countryService: CountryService,
    protected changeDetector: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected router: Router,
    protected geoService: GeoService,
    protected languageService: LanguageService,
    protected dialog: MatDialog,
    protected countryRedirectService: CountryRedirectService,
    protected zone: NgZone,
    protected userEnvironmentService: UserEnvironmentService,
  ) { }

  form: FormGroup = this.formBuilder.group({
    country_code: ['', [Validators.required]],
    city_id: ['', [Validators.required]],
    language: [''],
  });

  get formControls() {
    return this.form.controls;
  }

  ngOnInit() {
    this.city = new GeoEntity(new CitiesQuery());
    this.country = new GeoEntity(new CountriesQuery(1, 1000));
    this.subscribeToSearch(this.city);
    this.getCountries();
    this.preselectLanguage();
    this.subscribeToRouter();
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  onCitySelected(city: any) {
    this.formControls.city_id.setValue(city.id);
    this.city.selected = city;
    this.onCitySelected$.emit(city);

    if (/\/catalog\//.test(this.router.url)) {
      this.router.navigate([], {
        // trigger catalog filter
        queryParams: { cityId: city.id },
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        skipLocationChange: true,
      });
    }

    setTimeout(() => {
      if (this.router.url.includes('geo-lang')) {
        this.router.navigate([], {
          // trigger catalog filter
          queryParams: { cityId: city.id, countryCode: city.country.code },
          relativeTo: this.route,
          queryParamsHandling: 'merge',
          skipLocationChange: false,
        });
      }
      this.changeDetector.detectChanges();
    });
  }

  onCountrySelected(country: Country) {
    this._handleCountrySelection(country, true);
  }

  private _handleCountrySelection(country: Country, isManualSelect: boolean) {
    // selectFunc - ation that performs if country belog to this type of website
    const selectFunc = isManualSelect ? this._countrySelected.bind(this) : this.handleCountryPreselect.bind(this);
    if (country) {
      switch (country.code) {
        case CountryEnum.Tr:
          this.countryRedirectService.checkTurkey().subscribe((isRedirect) => this.handleCountryRedirectCancel(isRedirect));
          break;
        case CountryEnum.Az:
          this.countryRedirectService.checkAzerbaijan().subscribe((isRedirect) => this.handleCountryRedirectCancel(isRedirect));
          break;
        case CountryEnum.Mc:
          this.countryRedirectService.checkMacedonia().subscribe((isRedirect) => this.handleCountryRedirectCancel(isRedirect));
          break;
        case CountryEnum.Rs:
          this.countryRedirectService.checkSerbia().subscribe((isRedirect) => this.handleCountryRedirectCancel(isRedirect));
          break;
        case CountryEnum.Ru:
          selectFunc(country);
          break;
        case CountryEnum.By:
          selectFunc(country);
          break;
        case CountryEnum.Kz:
          selectFunc(country);
          break;
        case CountryEnum.Kg:
          selectFunc(country);
          break;
        case CountryEnum.Uz:
          selectFunc(country);
          break;
        default:
          this.countryRedirectService.checkEU(country).subscribe((isRedirect) => this.handleCountryRedirectCancel(isRedirect));
      }
    }
    setTimeout(() => {
      this.changeDetector.detectChanges();
    }, 100);
  }
  private _countrySelected(country: any) {
    this.formControls.country_code.setValue(country.code);
    this.country.selected = country;
    this.resetCity();
  }

  handleCountryRedirectCancel(isRedirect: boolean) {
    if (isRedirect) {
      this._countrySelected(this.country.selected);
      this.preselectCity(this.city.selected);
    }
  }

  submitFirstVisit() {
    if (this.form.valid && this.city.selected && (this.city.selected as City).id) {
      this.cityService.setCity(this.city.selected as City);
      setTimeout(() => {
        this.isUserEnvLoading = true;
        const sub = this.userEnvironmentService.updateUserEnvironment().subscribe({
          complete: () => {
            this.isUserEnvLoading = false;
            this.changeDetector.detectChanges();
            this.goBack();
          },
        });
        this.sub$.add(sub);
      });
    } else {
      this.goBack();
    }
  }

  searchCity(event) {
    this.search(event, this.city);
  }

  searchCountry(event) {
    this.search(event, this.country, false);
  }

  private search(event, geo, clearList = true) {
    if (clearList) {
      geo.entitiesForDD = [];
    }
    geo.allowSearch$.next(!!event.term);
    this.changeDetector.detectChanges();
  }

  subscribeToSearch(geo: GeoEntity) {
    // tslint:disable-next-line: deprecation
    const sub = combineLatest(geo.search$, geo.allowSearch$)
      .pipe(
        debounceTime(500),
        filter(([term, allow]) => allow && !!term),
        map(([term, allow]) => term),
        switchMap((term: string) => {
          geo.isLoading = true;
          geo.query.filter = {
            title: term,
          };
          if (this.formControls.country_code.value) {
            geo.query.filter.countryCode = this.formControls.country_code.value;
          }
          this.modifyQuery(geo.query);
          this.changeDetector.detectChanges();
          return this.cityService.getPage(geo.query);
        }),
        filter((res) => res && !!res.data),
        catchError((err) => of(err)),
        map((res) => {
          if (res instanceof Error) {
            // Maybe do some error handling here
            return null;
          }
          return res.data;
        }),
      )
      .subscribe(
        (res) => {
          geo.entitiesForDD = res;
          geo.isLoading = false;
          this.changeDetector.detectChanges();
        },
        (err) => {
          geo.isLoading = false;
          this.changeDetector.detectChanges();
        },
      );
    this.sub$.add(sub);
  }

  preselectCountry() {
    const city = this.cityService.getCity();
    this.formControls.country_code.patchValue(city && city.country && city.country.code ? city.country.code : this.cityService.CITY.moscow.country?.code);
    this._countrySelected(city ? city.country : this.cityService.CITY.moscow.country);
    if (!this.skipGeoDetection) {
      this.runGeoDetection();
    }
  }

  runGeoDetection() {
    if (!this.skipGeoDetection) {
      this.sub$.add(
        from(this.geoService.loadDetectScript()).subscribe(() => {
          ymaps.ready(() => {
            if (ymaps.geolocation) {
              this.ymapsReadyGeo(ymaps.geolocation);
            }
          });
        }),
      );
    }
  }

  private ymapsReadyGeo(geolocation: any) {
    this.yaGeolocation = geolocation;
    const query = new CountriesQuery();
    query.filter = {
      title: this.yaGeolocation.country,
    };

    const sub = this.countryService.getPage(query).subscribe(
      (_country) => {
        if (_country.data.length) {
          const country = _country.data[0];
          this.zone.run(() => {
            this._handleCountrySelection(country, false);
          });
        } else {
          this.selectDefaultCity();
        }
        this.changeDetector.detectChanges();
      },
      (err) => this.formControls.country_code.patchValue(this.route.snapshot.queryParams.country_code || this.cityService.CITY.moscow.country?.code),
    );
    this.sub$.add(sub);
  }

  private handleCountryPreselect(country: Country): void {
    this.formControls.country_code.patchValue(country.code);
    this.onCountryPreselected$.emit(country);
    this.resetCity();
    const cityQuery = new CitiesQuery();
    cityQuery.filter = {
      countryCode: country.code as CountryEnum,
      title: this.yaGeolocation.city,
    };
    const sub = this.cityService.getCities(cityQuery).subscribe((cities) => {
      let city = cities.find((item) => item.title.toLowerCase() === this.yaGeolocation.city.toLowerCase());
      if (!city) {
        city = cities.filter((item) => item.title.toLowerCase().includes(this.yaGeolocation.city.toLowerCase()))[0] || cities[0];
      }
      if (city) {
        this.preselectCity(city);
      }
    });
    this.sub$.add(sub);
  }

  private getCountries() {
    // tslint:disable-next-line: no-shadowed-variable
    const query = new CountriesQuery(1, 1000);
    this.sub$.add(
      this.countryService.getPage(query).subscribe((data: PaginationResponse<Country>) => {
        this.country.entitiesForDD = data.data;
        if (!this.isLoading) {
          this.preselectCountry();
          this.selectDefaultCity(); // TODO: fix this logic
        }
      }),
    );
  }

  private preselectLanguage() {
    this.formControls.language.patchValue(this.languageService.getBrowserLanguage());
  }

  preselectCity(exactCity: any = null) {
    const city = exactCity ? exactCity : this.cityService.getCity();
    if (city) {
      if (this.formControls.country_code.value === city.country.code) {
        this.city.entitiesForDD = [city];
        this.onCitySelected(city);
      }
    }
  }

  skipCitySelection(navigate = true) {
    const defaultCity = this.cityService.CITY.default;
    this.cityService.setCity(this.city.selected as City);
    if (navigate) {
      this.onCitySelected(defaultCity);
      this.goBack();
    }
  }

  goBack(): void {
    if (this.geoService.previousUrl) {
      if (this.geoService.previousQueryParams) {
        delete this.geoService.previousQueryParams.global;
      }
      this.router.navigate([this.geoService.previousUrl], this.geoService.previousQueryParams ? { queryParams: this.geoService.previousQueryParams } : {});
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  resetCity() {
    this.city.entitiesForDD = [];
    this.city.selected = undefined;
    this.form.controls.city_id.reset();
  }

  selectDefaultCity() {
    const city = this.cityService.getCity();
    if (!city && this.route.snapshot.queryParams.city) return;
    this.preselectCity(city ? city : this.cityService.CITY.moscow);
    this.changeDetector.detectChanges();
  }

  subscribeToCityChange() {
    const sub = this.cityService.cityChange$.subscribe((city: any) => {
      if (this.city.selected && city.id !== (this.city.selected as City).id) {
        this.formControls.country_code.patchValue(city ? city.country.code : this.cityService.CITY.moscow.country?.code);
        this.city.entitiesForDD = [city];
        this.formControls.city_id.setValue(city.id);
        this.city.selected = city;
        this.changeDetector.detectChanges();
      }
    });
    this.sub$.add(sub);
  }

  subscribeToRouter() {
    const sub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe((event: any) => {
        const urlWithoutParams = event.url.replace(/\?.*/, '');
        if (urlWithoutParams !== '/geo-lang') {
          this.geoService.previousUrl = urlWithoutParams;
          this.geoService.previousQueryParams = Object.assign({}, this.route.snapshot.queryParams);
        }
      });
    this.sub$.add(sub);
  }

  abstract modifyQuery(query);
}

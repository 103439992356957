<button class="pagination__more" *ngIf="page < totalPages" (click)="showMore()" id="show_more" i18n>
  Show more
</button>

<ul class="pagination" [class.small]="small_margin">
  <li *ngIf="page > 1"
      (click)="onPrev()"
      class="pagination--page prev">
    <svg-icon name="ic--arrow-t" [size]="10"></svg-icon>
  </li>

  <ng-container *ngIf="displayFirst()">
    <li
      [class.active]="isActive(1)"
      (click)="keep = false; page = 1;"
      class="pagination--page">
      <span>1</span>
    </li>

    <li *ngIf="pages.indexOf(2) === -1 && pages.length && pages[0] === 3"
        (click)="keep = false; page = 2;"
        [class.active]="isActive(2)"
        class="pagination--page">
      <span>2</span>
    </li>

    <li *ngIf="pages.length && pages[0] > 3"
        class="pagination--page dropdown">
      <span class="pagination--page-select dropdown-toggle">
        ...
      </span>
      <div class="pagination__select dropdown-menu">
        <input [(ngModel)]="manual"
               (keyup.enter)="setManual()">
        <button (click)="setManual()">Перейти</button>
      </div>
    </li>
  </ng-container>

  <li *ngFor="let p of pages"
      (click)="keep = false; page = p"
      [class.active]="isActive(p)"
      class="pagination--page">
    <span>{{p}}</span>
  </li>

  <ng-container *ngIf="displayLast()">
    <li *ngIf="displayLastDots()"
        class="pagination--page dropdown">
      <span class="pagination--page-select dropdown-toggle">
        ...
      </span>
      <div class="pagination__select dropdown-menu">
        <input [(ngModel)]="manual"
               (keyup.enter)="setManual()">
        <button (click)="setManual()">Перейти</button>
      </div>
    </li>
    <li *ngIf="!displayLastDots() && pages.indexOf(totalPages - 1) === -1"
        [class.active]="isActive(totalPages - 1)"
        (click)="page = totalPages - 1"
        class="pagination--page">
      <span>{{ totalPages - 1 }}</span>
    </li>

    <li (click)="page = totalPages;"
        [class.active]="isActive(totalPages)"
        class="pagination--page">
      <span>{{totalPages}}</span>
    </li>
  </ng-container>


  <li *ngIf="page < totalPages"
      (click)="onNext()"
      class="pagination--page next" >
    <svg-icon name="ic--arrow-t" [size]="10"></svg-icon>
  </li>
</ul>


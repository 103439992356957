export { SvgIconModule } from './svg-icon/svg-icon.module';
export { ButtonsModule } from './buttons/buttons.module';
export { LabelModule } from './label/label.module';
export * from './language-switcher/language-switcher.module';
export { SimilarProductsSliderModule } from './similar-products-slider/similar-products-slider.module';
export { CardsModule } from './cards/cards.module';
export { ShareButtonModule } from './share-button/share-button.module';
export { PaginationModule } from './pagination/pagination.module';
export * from './range/range.component';
export { CheckBoxModule } from './check-box/check-box.module';
export * from './check-box/check-event.interface';
import { Injectable } from '@angular/core';
import { GQL_USER_ENVIRONMENT_QUERY, GQL_USER_ENVIRONMENT_UPDATE_MUTATION } from '@core/models';
import { UserEnvironmentQueryFilter, UserEnvironmentQueryPayload, UserEnvironmentUpdateInput, UserEnvironmentUpdatePayload } from '@generated';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserEnvironmentApiService {
  constructor(private apollo: Apollo) {}

  public get(fields: string, filter?: UserEnvironmentQueryFilter): Observable<UserEnvironmentQueryPayload> {
    return this.apollo
      .query({
        query: GQL_USER_ENVIRONMENT_QUERY(fields),
        fetchPolicy: 'no-cache',
        variables: { filter },
      })
      .pipe(map((res: any) => res.data.UserGroup.UserEnvironment));
  }

  public mutate(userEnvironmentUpdateInput: UserEnvironmentUpdateInput): Observable<UserEnvironmentUpdatePayload> {
    return this.apollo
      .mutate({
        mutation: GQL_USER_ENVIRONMENT_UPDATE_MUTATION,
        variables: { userEnvironmentUpdateInput },
      })
      .pipe(map((res: any) => res.data.User.EnvironmentUpdate));
  }
}

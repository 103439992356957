import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CheckEventInterface } from './check-event.interface';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent implements OnInit {

  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() name: string;
  @Input() value: any;
  @Input() type: string;
  @Input() class: string;
  @Input() indeterminate: boolean;
  @Input() small: boolean;
  @Input() medium: boolean;

  @Output() valueChange = new EventEmitter<CheckEventInterface>();

  /** The native `<input type="checkbox">` element */
  @ViewChild('input', {static: false}) _inputElement: ElementRef;

  ngOnInit() {
  }

  onChange() {
    const isChecked = this._inputElement.nativeElement.checked;
    const value = this.value;
    const event: CheckEventInterface = { isChecked, value };
    this.valueChange.emit(event);
  }

  check() {
    this._inputElement.nativeElement.checked = true;
  }
  uncheck() {
    this._inputElement.nativeElement.checked = false;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@generated';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
  transform(address?: Address, isShort?: boolean): string {
    if (!address) {
      return '';
    }

    const prefix = `${address.country?.title}, ${address.city?.title} `;
    const street = address.street ? address.street : '';
    const houseNumber = address.houseNumber ? `${$localize`:@@address.house:д.`} ${address.houseNumber}` : '';
    const houseBlock = address.houseBlock ? `${$localize`:@@address.houseBlock:корп.`} ${address.houseBlock}` : '';
    const apartNumber = address.apartNumber ? `${$localize`:@@address.apartNumber:кв.`} ${address.apartNumber}` : '';
    const result = `${isShort ? '' : prefix} ${street} ${houseNumber} ${houseBlock} ${apartNumber}`;
    return result.replace(/\s+/g, ' ').trim();
  }
}

import { gql } from 'apollo-angular';

export const GQL_DELIVERY_POINTS_QUERY = (fields: string) => gql`
  query getDeliveryPoints($filter: DeliveryPointsQueryFilter, $userEnvironmentUuid: UUID!) {
    DeliveryGroup {
      DeliveryPoints(filter: $filter, userEnvironmentUuid: $userEnvironmentUuid) {
        deliveryPoints {
          ${fields}
        }
        errors {
          __typename
          ... on ValidateError {
            message
            field
          }
        }
      }
    }
  }
`;

export const GQL_DELIVERY_POINTS_FIELDS = `
  id
  deliveryTypeConst
  externalId
  city {
    id
    title
  }
  price {
    currency
    value
  }
  title
  description
  address
  phone
  contactPerson
  lat
  lng
  days
  cartInfo {
    statusConst
  }
`;

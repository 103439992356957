<app-green-button *ngIf="!inCart" height="h40" (click)="addToCart($event)">
  <span i18n="@@fav.to_cart">Add to cart</span>
  <div class="cart-loader" *ngIf="isLoading">
    <mat-spinner class="dialog-block-spinner" [diameter]="20"></mat-spinner>
  </div>
</app-green-button>
<div class="card__button actions" *ngIf="inCart">
  <span class="action minus" (click)="decrement($event)">-</span>
  <input type="number" [appInputNumber]="true" [value]="inCartCount" [appMaxChars]="3" (input)="changeAmount($event)" (click)="prevent($event)" />
  <span class="action plus" (click)="increment($event)">+</span>
</div>

import { gql } from 'apollo-angular';
import { GQL_ENTITY_ERROR_BODY } from '../common.gql';

export const GQL_MLM_PERIODS_QUERY = (fields: string) => gql`
 query getMlmPeriods($page: Int!, $limit: Int!, $filter: MlmPeriodsQueryFilter) {
    MlmGroup {
        MlmPeriods(limit: $limit,page: $page, filter: $filter
            ) {
             mlmPeriods {
                data {
                  ${fields}
                }
              }
              errors {
                __typename
                ... on ServerError {${GQL_ENTITY_ERROR_BODY}}
                ... on ValidateError {${GQL_ENTITY_ERROR_BODY}}
                ... on NotFoundError {${GQL_ENTITY_ERROR_BODY}}
                ... on ForbiddenError {${GQL_ENTITY_ERROR_BODY}}
              }
            }
          }
 }
`;

export const GQL_MLM_PERIODS_FIELDS = `
 id
 title
`;

import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { Notifer, NotificationService } from '@core/services/notification/notification.service';
import { fade } from '@vertera-common/animations/fade.animation';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [fade],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit, OnDestroy {
  isShow = false;
  template: any;
  notifer: Notifer;
  timer = timer(5000);
  sub: Subscription;
  literalServiceSub: Subscription;
  time$: Subscription | null;

  constructor(
    private service: NotificationService,
  ) { }

  ngOnInit() {
    this.subscribeNotification();
  }
  private subscribeNotification() {
    this.sub = this.service.notification$.subscribe(data => {
      this.show(data);
    });
  }
  private show(data: Notifer) {
    this.template = data.component;
    this.notifer = data;
    if (data.type === 'top') {
      // set 10 sec delay for top notification
      this.timer = timer(10000);
    }
    this.isShow = true;
    this.setNewTimer();
  }
  private setNewTimer() {
    if (this.time$) {
      this.time$.unsubscribe();
      this.time$ = null;
      this.subscribeTimer();
    } else {
      this.subscribeTimer();
    }
  }
  private subscribeTimer() {
    this.time$ = this.timer.subscribe(() => {
      this.hide();
    });
  }
  private notificationUnsubscribe() {
    if (this.literalServiceSub) {
      this.literalServiceSub.unsubscribe();
     }
  }
  hide() {
    // reset timer to default 5 sec
    this.timer = timer(5000);
    this.isShow = false;
    this.time$?.unsubscribe();
    this.time$ = null;
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  close() {
    this.isShow = false;
  }

}

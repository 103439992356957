import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent  {
  @Input() color: 'blue' | 'orange' | 'red' | 'yellow' | 'green' | 'grey' | 'pink' | 'default' = 'blue';
  @Input() square: boolean;
  @Input() status: boolean;

  constructor() { }


}

import { ProductsQueryFilter, ProductsSortEnum, ProductUnitEnum, CountryEnum, ProductQueryFilter } from '@generated';
import { PageFilter } from '../common';

export class ProductsQuery extends PageFilter {
  commonFilter?: ProductsQueryFilter;
  sort?: ProductsSortEnum;
  userEnvironmentUuid: string;
}

export class ProductQuery {
  filter?: ProductQueryFilter;
  userEnvironmentUuid: string;
}

export interface FilterCount {
  count: number;
}
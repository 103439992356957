import { gql } from 'apollo-angular';
import { GQL_ERROR_FIELDS } from '@core/models';

export const GQL_ADDRESS_BODY = `
  id
  lname
  fname
  isMain
  country {
    code
    title
  }
  city {
    id
    title
  }
  postcode
  street
  houseNumber
  houseBlock
  apartNumber
  phone
  email
`;

export const GQL_ADDRESS_QUERY = (fields: string) => gql`
  query addressQuery($filter: AddressQueryFilter) {
    GeoGroup {
      Address(filter: $filter) {
        address {
          ${fields}
        }
      }
    }
  }
`;

export const GQL_ADDRESSES_QUERY = (fields: string) => gql`
  query addressesQuery($page: Int!, $limit: Int!, $filter: AddressesQueryFilter) {
    GeoGroup {
      Addresses(page: $page, limit: $limit, filter: $filter) {
        addresses {
          data {
            ${fields}
          }
        }
        ${GQL_ERROR_FIELDS}
      }
    }
  }
`;

export const GQL_ADDRESS_CREATE_MUTATION = gql`
  mutation addressCreate($input: AddressCreateInput) {
    Address {
      Create(AddressCreateInput: $input) {
        isSuccessful
        address {
          ${GQL_ADDRESS_BODY}
        }
        ${GQL_ERROR_FIELDS}
      }
    }
  }
`;

export const GQL_ADDRESS_DELETE_MUTATION = gql`
  mutation addressDelete($id: Int!) {
    Address {
      Delete(id: $id) {
        isSuccessful
        ${GQL_ERROR_FIELDS}
      }
    }
  }
`;

export const GQL_ADDRESS_UPDATE_MUTATION = gql`
  mutation addressUpdate($id: Int!, $input: AddressUpdateInput) {
    Address {
      Update(id: $id, AddressUpdateInput: $input) {
        isSuccessful
        address {
          ${GQL_ADDRESS_BODY}
        }
        ${GQL_ERROR_FIELDS}
      }
    }
  }
`;

export const GQL_RECIPIENT_INFO = `
  id
  fname
  lname
  phone
  email
`;

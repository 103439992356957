import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appMaxChars]'
})
export class MaxCharsDirective {

  constructor(private el: ElementRef) {}

  @Input() appMaxChars!: number;
  allowKeysCode: number[] = [46, 8, 9, 27, 13, 110, 190, 37, 39];
  /**
   * Allow keypress if the input text is selected and it should replace the selected value
   * @typea {boolean}
   */
  selected = false;

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    // tslint:disable-next-line: deprecation
    const key = e.keyCode;
    if (this.el.nativeElement.value.toString().length < this.appMaxChars ||
      this.allowKeysCode.indexOf(key) !== -1 ||
      // Allow: Ctrl+A
      (key === 65 && (e.ctrlKey || e.metaKey)) || this.selected) {
      this.selected = false;
      return;
    }
    e.preventDefault();
    this.selected = false;
  }

  @HostListener('select') onSelect() {
    this.selected = true;
  }
}

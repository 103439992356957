import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AppTokenInterceptorService } from './interceptors/app-token-interceptor.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { SessionInterceptor } from './interceptors/session.interceptor';
import { AuthSessionService } from './services/auth/auth-session.service';
import { AuthTokenService } from './services/auth/auth-token.service';
import { AuthService } from './services/auth/auth.service';
import { RefService } from './services/user/ref.service';
import { FavoritesService, ProductService } from './services';
import { ProductApiService } from './services/api/ProductGroup/product-api.service';
import { UserProductFavoriteApiService } from './services/api/UserGroup/user-product-favorite-api.service';
import { FiltersApiService } from './services/api/ProductGroup/filters-api.service';

@NgModule({
  declarations: [
    // NotFoundComponent
  ],
  imports: [
    CommonModule,
    // TextDialogModule, MetaModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppTokenInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    AuthSessionService,
    ProductService,
    ProductApiService,
    FiltersApiService,
    UserProductFavoriteApiService,
    FavoritesService,
    AuthTokenService,
    RefService,
    AuthService,

    // Guards
    // AuthGuard,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule already loaded; import in root module only.');
    }
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return { ngModule: CoreModule };
  }
}

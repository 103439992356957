import { Component } from '@angular/core';

@Component({
  selector: 'app-language-switcher-black',
  templateUrl: './language-switcher-black.component.html',
  styleUrls: ['./language-switcher-black.component.scss']
})
export class LanguageSwitcherBlackComponent {

}

import { Pipe, PipeTransform } from '@angular/core';
import { orderStatusConstTranslates } from '@core/models/StoreGroup/orders';
import { OrderStatusEnum } from '@generated';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  transform(value: OrderStatusEnum): string {
    return orderStatusConstTranslates[value];
  }

}

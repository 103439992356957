import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DEFAULT_DATE_FORMAT_REGEX } from '@core/lib/const/date';

@Injectable({
  providedIn: 'root',
})
export class BaseFiltersService<QueryFilterType> {
  protected readonly arrayFields: string[] = [];
  protected readonly stringFields: string[] = []; //enforces field to be a string(skips convertions)
  protected allowedNullValuesFields: string[] = []; //allows to send null values in these fields

  constructor(protected router: Router, protected route: ActivatedRoute) {}


  public getFilter(params: Params): QueryFilterType {
    const filters = { ...params };
    if (filters.page) {
      delete filters.page;
    }
    if (filters.limit) {
      delete filters.limit;
    }
    if (filters.keep) {
      delete filters.keep;
    }
    if (filters.sort) {
      delete filters.sort;
    }

    if (filters.pk) {
      delete filters.pk;
    }

    if (filters.tableView) {
      delete filters.tableView;
    }

    if (filters.search) {
      delete filters.search;
    }

    if(filters.countryCode) {
      delete filters.countryCode;
    }

    if(filters.cityId) {
      delete filters.cityId;
    }

    this.parseFilterValues(filters);
    return filters as QueryFilterType;
  }


  private parseFilterValues(obj: any) {
    for (const prop in obj) {
      if (obj[prop] === 'false') {
        obj[prop] = false;
      } else if (obj[prop] === 'true') {
        obj[prop] = true;
      } else if (obj[prop] === 'null' && this.allowedNullValuesFields.includes(prop)) {
        // null values are allowed if it is an intentional use case
        obj[prop] = null;
      } else if (this.arrayFields.includes(prop)) {
        obj[prop] = obj[prop].split(',');
      }
      // checks if not date string
      if (!DEFAULT_DATE_FORMAT_REGEX.test(obj[prop]) && !Array.isArray(obj[prop])) {
        // tries to convert to numeric value
        const floatValue = parseFloat(obj[prop]);
        if (!isNaN(floatValue) && !this.stringFields.includes(prop)) {
          obj[prop] = floatValue;
        }
      }

      // if filter value is array value tries to convert values to number
      if (Array.isArray(obj[prop])) {
        obj[prop] = obj[prop].map((value: string) => {
          const floatValue = parseFloat(value);
          if (!isNaN(floatValue)) {
            return floatValue;
          } else {
            return value;
          }
        });
      }
    }
  }
}

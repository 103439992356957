import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppTokenInterceptorService implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const multiplyHeaders = req.headers.set('X-App-Token', environment.appToken);
    const authReq = req.clone({ headers: multiplyHeaders });
    return next.handle(authReq);
  }
}

<div class="rating" tabindex="0">
  <div class="star-container">
    <div class="star" *ngFor="let star of starObjs; let i = index" (click)="onStarClicked(i)">
      <svg [attr.width]="size" [attr.height]="size - 1" viewBox="0 0 13 12" version="1.1">
        <g id="Canvas" fill="none">
          <g id="Vector">
            <path d="M 6.03243 0L 7.84865 3.95455L 12 4.56818L 9.01622 7.63636L 9.72973 12L 6.03243 9.95455L 2.27027 12L 2.98378 7.63636L 0 4.56818L 4.15135 3.95455L 6.03243 0Z"
                  transform="translate(0.833984 0)"  [attr.fill]="star.color" />
          </g>
        </g>
      </svg>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CITIES } from './cities';
import { City, Country, CountryEnum } from '@generated';
import { CitiesApiService } from '../api/GeoGroup/cities-api.service';
import { CitiesQuery, GQL_CITIES_FIELDS, PaginationResponse } from '@core/models';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class CityService {
  readonly cityKey: string = `${environment.appName}_city`;
  readonly cityChange$ = new ReplaySubject<City | void>(1);
  allowFirstCityChange = true;

  public readonly CITY: { [key: string]: City } = {
    moscow: {
      id: 4442,
      title: 'Москва',
      country: {
        code: CountryEnum.Ru,
        title: 'Россия',
        alpha3: 'RU',
      },
    },
  };

  constructor(
    private cityApiService: CitiesApiService,
    private cookieService: CookieService
  ) {
    this.CITY = Object.assign(this.CITY, CITIES);
  }

  getPage(query: CitiesQuery): Observable<PaginationResponse<City>> {
    return this.cityApiService.getCities(query, GQL_CITIES_FIELDS);
  }

  getCities(query: CitiesQuery): Observable<City[]> {
    return this.cityApiService.getCities(query, GQL_CITIES_FIELDS).pipe(map((res) => res.data));
  }

  setCity(city: City) {
    const prevCityId = this.getCityId();
    if (city && city.country) {
      this.cookieService.set(this.cityKey, JSON.stringify(city));
      if (city.id !== prevCityId || this.allowFirstCityChange) {
        this.cityChange$.next(city);
        this.allowFirstCityChange = false;
      }
    }
  }

  getCity(): City {
    const city = this.cookieService.get(this.cityKey);
    return city ? JSON.parse(city) : null;
  }

  getCountry(): Country | undefined {
    const city = this.getCity();
    if (city && city.country) {
      return city.country;
    }
    return undefined;
  }

  removeCity() {
    this.cookieService.delete(this.cityKey);
  }

  getCityId(): number | null {
    const city = this.getCity();
    return city ? city.id : null;
  }

  getCountryCode(): string {
    const city = this.getCity();
    if (city && city.country && city.country.alpha3) {
      return city.country.alpha3;
    } else {
      return 'RU'; // Russia
    }
  }
}

import { Directive, HostListener, AfterViewInit, Output, EventEmitter, OnInit, Input, Inject, PLATFORM_ID, LOCALE_ID } from '@angular/core';
import { NgxIntlTelInputComponent, CountryISO } from 'ngx-intl-tel-input';
import { LanguageService } from '@core/services/language.service';
import { PHONE_ERROR } from '@core/lib/translations';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[appNgxTelInputCustomPlaceholder]'
})
export class NgxTelInputCustomPlaceholderDirective implements AfterViewInit {
  countryISO = CountryISO;

  constructor(private phonePlugin: NgxIntlTelInputComponent,
    @Inject(PLATFORM_ID) public platform: any, @Inject(LOCALE_ID) private locale: string) { }

  @Input() appNgxTelInputCustomPlaceholder: any;

  @Output() validateMessage = new EventEmitter<string>();
  validInputLength!: number;
  localNumber!: string;

  @HostListener('click', ['$event']) onClick($event: any): void {
    this.parsePlaceholder();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      this.detectCountry();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.parsePlaceholder();
    }, 1);
  }

  parsePlaceholder(): void {
    const countryCode = `+${this.phonePlugin.selectedCountry.dialCode}`;
    const originalPlaceHolder = this.phonePlugin.selectedCountry.placeHolder;
    this.localNumber = originalPlaceHolder.replace(`${countryCode} `, '');
    const placeholder = `${this.localNumber.replace(new RegExp(`[0-9]`, 'g'), 'X')}`;
    this.phonePlugin.customPlaceholder = placeholder;
    if (this.phonePlugin.phoneNumber) {
      this.phonePlugin.writeValue(this.phonePlugin.phoneNumber.replace(`+${this.phonePlugin.selectedCountry.dialCode}`, ''));
    }
    this.validInputLength = this.localNumber.replace(/[^0-9]/g, '').length;
    this.generateErrorText();
  }

  private generateErrorText(): void {
    if (this.phonePlugin.phoneNumber) {
      const phone = this.phonePlugin.phoneNumber.replace(/[^0-9]/g, '');
      if (phone && phone.length === this.validInputLength) {
        this.validateMessage.emit('');
      } else if (PHONE_ERROR[this.locale]) {
        const errorText = PHONE_ERROR[this.locale].replace('{n}', `${this.validInputLength}`);
        this.validateMessage.emit(errorText);
      }
    }


  }
  detectCountry() {
    // if (!this.phonePlugin.selectedCountryISO && this.appNgxTelInputCustomPlaceholder) {
    //   debugger;
    //   const phone = this.appNgxTelInputCustomPlaceholder;
    //   if (phone?.phone.replace('+', '').startsWith('7')) {
    //     this.phonePlugin.selectedCountryISO = CountryISO.Russia;
    //   } else {
    //     for (const country of this.phonePlugin.allCountries) {
    //       if (phone?.phone.startsWith(country.dialCode)) {
    //         this.phonePlugin.selectedCountryISO = country.iso2 as CountryISO;
    //         break;
    //       }
    //     }
    //   }
    //   // this.phonePlugin.writeValue(null);
    // } else {
    //   this.phonePlugin.selectedCountryISO = CountryISO.Russia;
    // }
  }
}

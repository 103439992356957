import { Component, Input, HostListener, OnInit } from '@angular/core';
import { CurrencyService } from '@core/services/currency.service';
import { CurrencyPointEnum, Product } from '@generated';


@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['../cards.component.scss']
})

export class ProductCardComponent implements OnInit {
  @Input() product: Product;
  isLoading: boolean;
  public widthScreen: number;
  CurrencyPointEnum = CurrencyPointEnum;
  currency: string;

  constructor(private currencyService: CurrencyService) {
  }

  ngOnInit() {
    this.widthScreen = window.innerWidth;
    this.currency = this.currencyService.getCurrency();
  }

  @HostListener('window: resize')
  calcWidth() {
    this.widthScreen = window.innerWidth;
  }

}

import { ChangeDetectionStrategy, Component, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormComponent {
  showSearchForm!: boolean;
  public search$ = new EventEmitter<string>();
  isLoading!: boolean;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleSearch() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSearchCatalog() {}
}

import { Directive, Input, HostListener } from '@angular/core';
import { VerteraAnalyticsService } from '../services/vertera-analytics.service';

export interface MetricProperties {
  category: string;
  label?: string;
  value?: string;
}

@Directive({
  selector: '[appVerteraAnalytics]'
})
export class VerteraAnalyticsDirective {
  @Input() appVerteraAnalytics!: string; // Action
  @Input() metricProps!: MetricProperties;

  constructor(private verteraAnalytictsService: VerteraAnalyticsService) { }

  @HostListener('click') click() {
    if (this.appVerteraAnalytics) {
      this.verteraAnalytictsService.facebookTrack(this.appVerteraAnalytics);
    }

    if (this.metricProps) {
      if (this.metricProps.label)  {
        this.verteraAnalytictsService.yandexMetrikaTrack(this.metricProps.label);
        this.verteraAnalytictsService.googleAnalyticsTrack(this.metricProps.label);
      }
    }
  }
}

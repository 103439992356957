import { PageInfo } from '@generated';

export class PaginationResponse<T> {
  data: T[];
  pageInfo: PageInfo;
}

export class PageFilter {
  limit: number;
  page: number;
  constructor(page = 1, limit = 20) {
    this.limit = limit;
    this.page = page;
  }
}

export class TypeModel {
  id: number;
  name: string;
}

export interface IEntityUpdateResponse {
  isSuccessful: boolean;
  errors: IServerError[];
}

export interface IServerError extends IFieldError {
  code: string;
  __typename?: string;
}

export interface IFieldError {
  field: string;
  message: string;
}
import { Injectable } from '@angular/core';
import { FiltersQuery } from '@core/models/ProductGroup/filters';
import { GQL_FILTERS_QUERY } from '@core/models/ProductGroup/filters.gql';
import { ProductFilters } from '@generated';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

@Injectable()
export class FiltersApiService {
  constructor(private apollo: Apollo) {}

  public getFilters(query: FiltersQuery, fields: string): Observable<ProductFilters> {
    return this.apollo
      .query({
        query: GQL_FILTERS_QUERY(fields),
        fetchPolicy: 'no-cache',
        variables: query,
      })
      .pipe(
        map((res: any) => {
          return res.data.ProductGroup.Filters.filters;
        }),
      );
  }
}

import { gql } from 'apollo-angular';
import { GQL_ERROR_FIELDS } from '../common.gql';

export const GQL_PAYMENT_METHODS_QUERY = (fields: string) => gql`
  query getPayments($filter: PaymentMethodsQueryFilter) {
    FinanceGroup {
        PaymentMethods(filter: $filter) {
            paymentMethods {
                paymentUrl
                paymentSystem {
                    ${fields}
                }
            }
            ${GQL_ERROR_FIELDS}
        }
    }
  }
`;

export const GQL_PAYMENT_SYSTEM_FIELDS = `
  title
  code
  paymentConsts
`;
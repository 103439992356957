<svg-icon *ngIf="!withText" (click)="onClick($event)"
          [name]="item.isMyFavorite ? 'ic--heart-red' : 'ic--heart'" [size]="17"
          class="actions--icon"></svg-icon>

<app-transparent-button class="add-to-fav" *ngIf="withText" (click)="onClick($event)">
  <span class="favorite" [class.is_favorite]="item.isMyFavorite">
    <svg-icon [name]="item.isMyFavorite? 'ic--heart-red' : 'ic--heart'" [size]="20"></svg-icon>
  </span>
  <span *ngIf="!item.isMyFavorite" class="favorite--text" i18n='@@to_fav.to_favorites'>Add to Favourites</span>
  <span *ngIf="item.isMyFavorite" class="favorite--text" i18n='@@to_fav.in_favorites'>The item is in Favourites</span>
</app-transparent-button>

import { Component, EventEmitter, Output } from '@angular/core';
import { LanguageSwitcherBaseDirective } from '@core/components/languageSwitcherBase';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '@core/services/language.service';

@Component({
  selector: 'app-language-switcher-select',
  templateUrl: './language-switcher-select.component.html',
  styleUrls: ['./language-switcher-select.component.scss'],
})
export class LanguageSwitcherSelectComponent extends LanguageSwitcherBaseDirective {
  noChangeSelect = false;
  opened!: boolean;
  selectedLanguage: any;

  @Output() public languageToGeo = new EventEmitter();

  constructor(
    protected override route: ActivatedRoute,
    protected override router: Router,
    protected override languageService: LanguageService,
  ) {
    super(route, router, languageService);
  }

  onSelectLanguage() {
    this.noChangeSelect = true;
    this.languageToGeo.emit(this.selectedLanguage);
  }

  onOpenLanguage() {
    this.opened = !this.opened;
  }
}

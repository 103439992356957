import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalGeoComponent } from '@core/dialogs/geo-dialog/modal-geo.component';
// VERTERA_REFACTOR
// import { ModalGeoComponent } from '@core/dialogs/geo-dialog/modal-geo.component';
import { TextDialogComponent } from '@core/dialogs/text-dialog/text-dialog.component';
import { PRODUCT_WARNING_MSG } from '@core/lib/const/messages';
import { CityService } from '@core/services/hdbk/city.service';
import { LanguageService } from '@core/services/language.service';
import { CountryEnum } from '@generated';
import { CartViewService } from 'src/app/cart/services/cart-view.service';


@Injectable()
export class CartMessagesService {

  productsToCheck = [
    'cardio-mix',
    'respira-mix',
    'arthro-mix',
    'endo-mix',
    'nervo-mix',
    'immuno-mix',
    'visio-mix',
    'gastro-mix',
    'hepa-mix',
    'antihelm-mix',
    'reno-mix',
    'mens-formula',
    'beauty-formula',
    'womens-formula',
    'weight-control-mix'];

  countriesToCheck = [
    CountryEnum.Ru, CountryEnum.By, CountryEnum.Kz, CountryEnum.Kg, CountryEnum.Az,
    CountryEnum.Uz
  ];

  constructor(private cityService: CityService, private dialog: MatDialog, private langugeService: LanguageService,
    private cartViewService: CartViewService) { }

  showProductMessage(slug: string) {
    const country = this.cityService.getCountry();
    if (this.productsToCheck.indexOf(slug) !== -1 && this.countriesToCheck.indexOf(country?.code as CountryEnum) !== -1) {
      const message = PRODUCT_WARNING_MSG[this.langugeService.getLanguage()] ?
        PRODUCT_WARNING_MSG[this.langugeService.getLanguage()] : PRODUCT_WARNING_MSG.en;
      this.dialog.open(TextDialogComponent, {
        width: '300px',
        panelClass: 'modal-form-error',
        data: message
     });
    }
  }

  showGeoMessage() {
    // VERTERA_REFACTOR
    if (!this.cartViewService.cartItems || this.cartViewService.cartItems.length === 0) {
      this.dialog.open(ModalGeoComponent, {
        panelClass: 'geo-modal',
      });
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPointEnum, ProductPoint } from '@generated';

@Pipe({
  name: 'points',
})
export class PointsPipe implements PipeTransform {
  transform(points: ProductPoint[] | undefined, code: CurrencyPointEnum): any {
    return (
      points?.find((point: ProductPoint) => point.code === code)?.value ?? '0'
    );
  }
}

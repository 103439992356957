export const GQL_PAGE_INFO_MODEL = `
  total
  perPage
  currentPage
  from
  to
  lastPage
  hasMorePages
`;

export const GQL_IMAGE = `
  original
  size150
  size300
  size600
`;

export const GQL_LINK = `
  originalUrl
  domain
  path
`;

export const GQL_ENTITY_ERROR_BODY = `
  message
  code
  field
`;

const GQL_ENTITY_UPDATE_ERROR_BODY = `
  message
  inputName
  code
  field
`;

export const GQL_ERROR_FIELDS = `
  errors {
    __typename
    ... on ValidateError { ${GQL_ENTITY_ERROR_BODY} }
    ... on NotFoundError { ${GQL_ENTITY_ERROR_BODY} }
    ... on ServerError { ${GQL_ENTITY_ERROR_BODY} }
  }
`;

export const GQL_ENTITY_UPDATE_BODY = `
  isSuccessful
  errors {
    ...on ServerError { ${GQL_ENTITY_UPDATE_ERROR_BODY} }
    ...on ValidateError { ${GQL_ENTITY_UPDATE_ERROR_BODY} }
    ...on NotFoundError { ${GQL_ENTITY_UPDATE_ERROR_BODY} }
  }
`;

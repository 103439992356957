import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserEnvironmentService } from '@core/services';
import { CountryRedirectService } from '@core/services/common/country-redirect.service';
import { GeoService } from '@core/services/geo.service';
import { CityService } from '@core/services/hdbk/city.service';
import { CountryService } from '@core/services/hdbk/country.service';
import { LanguageService } from '@core/services/language.service';
import { City } from '@generated';
import { Geo } from '@vertera-common/abstract/geo';


@Component({
  selector: 'app-modal-geo',
  templateUrl: './modal-geo.component.html',
  styleUrls: ['./modal-geo.component.scss']
})
export class ModalGeoComponent extends Geo implements OnInit, AfterViewInit   {
  selectedCity: string;

  constructor(
    public dialogRef: MatDialogRef<any>,
    protected override formBuilder: FormBuilder,
    protected override cityService: CityService,
    protected override countryService: CountryService,
    protected override changeDetector: ChangeDetectorRef,
    protected override route: ActivatedRoute,
    protected override router: Router,
    protected override geoService: GeoService,
    protected override languageService: LanguageService,
    protected override dialog: MatDialog,
    protected override countryRedirectService: CountryRedirectService,
    protected cd: ChangeDetectorRef,
    protected override zone: NgZone,
    protected override userEnvironmentService: UserEnvironmentService,
  ) {
    super(formBuilder, cityService, countryService, changeDetector, route, router, geoService, languageService,
      dialog, countryRedirectService, zone, userEnvironmentService);
  }

  override ngOnInit() {
    const city = this.cityService.getCity();
    if (city && city.country && city.country.code) {
      this.skipGeoDetection = true;
    }
    super.ngOnInit();
    this.subsribeToCitySelection();
    this.subscribeToCityChange();
  }

  ngAfterViewInit() {
    this.selectedCity = this.city.selected?.title as string;
    this.cd.detectChanges();
  }

  override onCitySelected(city) {
    this.formControls.city_id.setValue(city.id);
    this.city.selected = city;
  }

  save() {
    this.selectedCity = this.city.selected?.title as string;
    this.onCitySelected$.emit(this.city.selected);
    this.isUserEnvLoading = true;
    const sub = this.userEnvironmentService.updateUserEnvironment().subscribe({
      complete: () => {
        this.isUserEnvLoading = false;
        this.clickClose();
      }
    });
    this.sub$.add(sub);
  }

  clickClose(): void {
    this.dialogRef.close();
  }

  modifyQuery(query) {
    console.log(query);
  }

  subsribeToCitySelection() {
    const sub = this.onCitySelected$.subscribe(() => {
      this.cityService.setCity(this.city.selected as City);
      this.changeDetector.detectChanges();
    });
    this.sub$.add(sub);
  }

}

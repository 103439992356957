import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent {
  /**
   * Base path for SVG sprite
   */
  public readonly basePath = '/assets/icon';

  /**
   * Icon size
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('size') size = 16;

  /**
   * Symbol ID value from SVG sprite
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('name') name: string | undefined;
}

import { PageFilter } from '@core/models';
import { CountryEnum, CurrencyEnum, OrdersQueryFilter, OrderStatusEnum } from '@generated';

export class OrdersQuery extends PageFilter {
  filter?: OrdersQueryFilter;
}

export const orderStatusConstTranslates = {
  [OrderStatusEnum.New]: $localize`:@@order.status_new:Новый`,
  [OrderStatusEnum.Paid]: $localize`:@@order.status_paid:Оплачен`,
  [OrderStatusEnum.Collecting]: $localize`:@@order.status_collecting:На сборке`,
  [OrderStatusEnum.InTransit]: $localize`:@@order.status_in_transit:В пути`,
  [OrderStatusEnum.PartiallyCompleted]: $localize`:@@order.status_partially_completed:Частично выдан`,
  [OrderStatusEnum.ReadyToIssue]: $localize`:@@order.status_ready_to_issue:Готов к выдаче`,
  [OrderStatusEnum.Completed]: $localize`:@@order.status_completed:Выполнен`,
  [OrderStatusEnum.Finished]: $localize`:@@order.status_finished:Завершен`,
  [OrderStatusEnum.Canceled]: $localize`:@@order.status_cancelled:Отменен`,
};

export const orderStatusConstOptions = [
  {
    value: OrderStatusEnum.New,
    name: $localize`:@@order.status_new:Новый`,
  },
  {
    value: OrderStatusEnum.Paid,
    name: $localize`:@@order.status_paid:Оплачен`,
  },
  {
    value: OrderStatusEnum.Collecting,
    name: $localize`:@@order.status_collecting:На сборке`,
  },
  {
    value: OrderStatusEnum.InTransit,
    name: $localize`:@@order.status_in_transit:В пути`,
  },
  {
    value: OrderStatusEnum.ReadyToIssue,
    name: $localize`:@@order.status_ready_to_issue:Готов к выдаче`,
  },
  {
    value: OrderStatusEnum.PartiallyCompleted,
    name: $localize`:@@order.status_partially_completed:Частично выдан`,
  },
  {
    value: OrderStatusEnum.Completed,
    name: $localize`:@@order.status_completed:Выполнен`,
  },
  {
    value: OrderStatusEnum.Finished,
    name: $localize`:@@order.status_finished:Завершен`,
  },
  {
    value: OrderStatusEnum.Canceled,
    name: $localize`:@@order.status_cancelled:Отменен`,
  },
];

export interface OrderInfo {
  currencyCode: CurrencyEnum;

  user: {
    fname: string;
    lname: string;
    email: string;
    phone: string;
  };

  delivery: {
    countryCode: CountryEnum;
    cityId: number;
    address: string;
    isPickupType: boolean;
    storageDeliveryId: number;
    addressId: number;
    pointId?: number;
  };
}

export enum OrderType {
  SelfOrder = 1,
  CompanyOrder,
  PartnerOrder,
}

export interface OrderStatus {
  value: OrderStatusEnum,
  name: string
}
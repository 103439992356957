import { Injectable } from '@angular/core';
import { CityService } from '@core/services/hdbk/city.service';
import { UserEnvironmentUpdateInput } from '@generated';

@Injectable({
  providedIn: 'root',
})
export class UserEnvironmentQueryFactoryService {
  constructor(private cityService: CityService) {}

  public getQuery(): UserEnvironmentUpdateInput {
    const cityId = this.cityService.getCityId() || this.cityService.CITY.moscow.id;
    return {
      userStoreFilterParam: {
        cityDelivery: {
          cityId,
          isActive: true,
        },
      },
    };
  }
}

import { Component, Input } from '@angular/core';
import { CheckBoxComponent } from '../check-box.component';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent extends CheckBoxComponent {

  @Input() green: boolean;
  @Input() lable: boolean;
  constructor() {
    super();
  }
}

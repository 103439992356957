import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnDestroy {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected changeDetector: ChangeDetectorRef,
  ) {}
  @Input() small_margin: boolean;
  @Output() pageChange = new EventEmitter();

  /* should keep prev items or should remove them? */
  public keep = false;
  private currentPage = 1;

  @Input()
  get page(): number {
    return +this.currentPage;
  }

  set page(num: number) {
    if (num === this.page) {
      return;
    }
    if (!this.keep) {
      this.pageChange.emit();
    }
    this.manual = num;
    this.currentPage = num;
    this.setPages();
    this.changeDetector.detectChanges();
    this.navigate();
  }

  private currentPerPage = 3;

  @Input()
  get perPage(): number {
    return this.currentPerPage;
  }

  set perPage(num: number) {
    if (num === this.currentPerPage) {
      return;
    }
    this.currentPerPage = num;
    this.navigate();
  }

  @Input()
  set total(v: number) {
    if (this.total === v) {
      return;
    }
    this._total = v;
    this.ngOnInit();
  }

  get total(): number {
    return this._total;
  }

  private _total: number;

  @Input()
  pagesToDisplay = 4;

  private _perPageSet: number[] = [16, 32, 64];
  @Input()
  set perPageSet(v: number[]) {
    this._perPageSet = v;
    const perPage = v.find((pp) => pp === this.perPage);
    if (!perPage && v.length) {
      this.perPage = v[0];
    }
  }

  get perPageSet(): number[] {
    return this._perPageSet;
  }

  /*@ViewChild(PopupMenuComponent)
  menu: PopupMenuComponent;*/

  manual: number;
  totalPages: number;
  pages: number[] = [];
  step: number;

  private readonly sub = new Subscription();

  ngOnInit() {
    this.reset();
    this.subscribeToQueryParams();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  reset() {
    this.resetParams();
    this.step = this.pagesToDisplay - 1;
    this.manual = this.page;
    this.totalPages = Math.ceil(this.total / this.perPage);
    this.setPages();
  }

  resetParams() {
    this.page = +this.route.snapshot.queryParams.page || this.page;
    this.perPage = +this.route.snapshot.queryParams.per__page || this.perPage;
    this.keep = this.route.snapshot.queryParams.keep;
  }

  setPerPage(): void {
    this.totalPages = Math.ceil(this.total / this.perPage);
    this.setPages();
  }

  setPages(): void {
    this.pages = [];
    if (this.page > this.totalPages) {
      this.page = this.totalPages;
      return;
    } else if (this.page < 1) {
      this.page = 1;
      return;
    }
    if (this.page <= this.step) {
      const numberOfPages = this.totalPages <= this.pagesToDisplay ? this.totalPages : this.pagesToDisplay;
      for (let i = 1; i <= numberOfPages; i++) {
        this.pages.push(i);
      }
    } else if (this.page > this.totalPages - this.step) {
      for (let i = this.totalPages - this.step; i <= this.totalPages; i++) {
        this.pages.push(i);
      }
    } else {
      let tail = this.pagesToDisplay - 2;

      if (this.page === this.totalPages - this.step) {
        while (tail > 0) {
          this.pages.push(this.page - tail--);
        }
        this.pages.push(this.page);
        this.pages.push(this.page + 1);
      } else {
        this.pages.push(this.page - 1);
        this.pages.push(this.page);
        let i = 1;
        while (i <= tail) {
          this.pages.push(this.page + i++);
        }
      }
    }
  }

  onPrev(): void {
    this.page = --this.page;
  }

  onNext(): void {
    this.page = ++this.page;
  }

  isActive(page: number): boolean {
    return this.page === page;
  }

  onManual(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // this.menu.open();
    this.router.navigate([], { queryParams: { keep: null } });
  }

  displayFirst(): boolean {
    const alreadyHas = -1 !== this.pages.indexOf(1);
    return !alreadyHas && this.page > this.step;
  }

  displayLast(): boolean {
    const alreadyHas = -1 !== this.pages.indexOf(this.totalPages);
    return !alreadyHas;
  }

  displayLastDots(): boolean {
    const last = this.pages[this.pages.length - 1];
    return last < this.totalPages - 2;
  }

  setManual() {
    // this.menu.close();
    if (this.manual === this.page) {
      return;
    }
    this.manual = Number(this.manual);
    if (this.manual <= 0) {
      this.manual = 1;
    } else if (this.manual > this.totalPages) {
      this.manual = this.totalPages;
    }
    this.page = this.manual;
    if (!isFinite(this.manual)) {
      this.manual = 1;
    }
  }

  showMore() {
    this.keep = true;
    this.onNext();
  }

  navigate() {
    const skipLocationChange = !this.route.snapshot.queryParams.page && !this.route.snapshot.queryParams.per__page;
    const extras: NavigationExtras = {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      skipLocationChange,
      queryParams: {
        page: this.page,
        per__page: this.perPage,
      },
    };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    extras!.queryParams!.keep = this.keep ? 'true' : undefined;
    this.keep = false;
    this.router.navigate([], extras);
  }

  private subscribeToQueryParams() {
    const sub = this.route.queryParams.subscribe((params) => {
      const page = +this.route.snapshot.queryParams.page;
      const newPage = +page > 0 ? +page : 1;
      const prevPage = this.page;
      if (newPage !== prevPage) {
        this.page = newPage || 1;
        this.reset();
      }
    });
    this.sub.add(sub);
  }
}

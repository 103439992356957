import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MonoTypeOperatorFunction } from 'rxjs/internal/types';

@Directive({
  selector: '[appBaseDirective]',
})
export abstract class BaseDirective implements OnDestroy {
  private willBeDestroyed = new Subject<void>();

  takeUntilDestroyed<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this.willBeDestroyed);
  }

  ngOnDestroy() {
    this.willBeDestroyed.next();
    this.willBeDestroyed.complete();
  }
}

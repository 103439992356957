import { GQL_PRODUCT_FLAGS_BODY, GQL_PRODUCT_IMAGE_BODY, GQL_PRODUCT_POINTS_BODY, GQL_PRODUCT_PRICES_BODY } from '../ProductGroup/product.gql';
import { GQL_ENTITY_ERROR_BODY, GQL_IMAGE } from '../common.gql';
import { gql } from 'apollo-angular';

export const GQL_CART_QUERY = (fields: string) => gql`
  query cart($filter: CartsInfoQueryFilter, $userEnvironmentUuid: UUID!) {
    CartGroup {
      Carts(infoFilter: $filter, userEnvironmentUuid: $userEnvironmentUuid) {
        ${fields}
      }
    }
  }
`;

export const GQL_CART_IDS_FIELDS = `
  carts {
    id
  }
`;

export const GQL_CART_PREVIEW_FIELDS = `
  carts {
    id
    amount
    product {
      id
    }
  }
`;

export const GQL_CART_FULL_FIELDS = `
  carts {
    id
    amount
    isAvailableToOrder
    isSelected
    currency {
      code
    }
    product {
        id
        title
        slug
        mainImage {
            image {
                ${GQL_IMAGE}
            }
        }
        prices {
            pricePartner
            priceGuest
            price
          }
        points {
            code
            value
          }
        typeAttributes {
            ...on ProductProductType {
              netWeight
            }
        }
    }
  }
`;

export const GQL_CART_TOTAL_FIELDS = `
  cartInfo {
    productQuantity
    rewardProductQuantity
    totalQuantity
    totalDeliveryPrice
    totalWeight
    totalGuestPrices {
      currency
      value
    }

    totalPrices {
      currency
      value
    }

    totalDiscounts {
      currency
      value
    }
  }
`;

export const GQL_CART_INFO_CHECKOUT_FIELDS = `
  cartInfo {
    totalWeight
  }
`;

export const GQL_CART_CHECKOUT_FIELDS = `
  carts {
    id
    amount
    isAvailableToOrder
    isSelected
    product {
        id
        title
        mainImage {
            image {
                ${GQL_IMAGE}
            }
        }
        prices {
            price
          }
        points {
            code
            value
        }
        typeAttributes {
            ...on ProductProductType {
              netWeight
            }
        }

    }
  }
`;

export const GQL_CART_CREATE_MUTATION = gql`
  mutation ($input: CartCreateInput, $userEnvironmentUuid: UUID!) {
    Cart {
      Create(CartCreateInput: $input, userEnvironmentUuid: $userEnvironmentUuid) {
        isSuccessful
        id
        cart {
          id
          amount
          product {
            id
            slug
          }
        }
        errors {
          ...on ServerError { ${GQL_ENTITY_ERROR_BODY} }
          ...on ValidateError { ${GQL_ENTITY_ERROR_BODY} }
        }
      }
    }
  }
`;

export const GQL_CART_UPDATE_MUTATION = gql`
  mutation ($id: Int!, $input: CartUpdateInput, $userEnvironmentUuid: UUID!) {
    Cart {
      Update(id: $id, CartUpdateInput: $input, userEnvironmentUuid: $userEnvironmentUuid) {
        isSuccessful
        errors {
          ...on ServerError { ${GQL_ENTITY_ERROR_BODY} }
          ...on ValidateError { ${GQL_ENTITY_ERROR_BODY} }
        }
      }
    }
  }
`;

export const GQL_CART_DELETE_MUTATION = gql`
  mutation ($ids: [Int!], $userEnvironmentUuid: UUID!) {
    Cart {
      Delete(ids: $ids, userEnvironmentUuid: $userEnvironmentUuid) {
        isSuccessful
        errors {
          ...on ServerError { ${GQL_ENTITY_ERROR_BODY} }
          ...on ValidateError { ${GQL_ENTITY_ERROR_BODY} }
        }
      }
    }
  }
`;

export const GQL_CART_INCREMENT_MUTATION = gql`
  mutation ($id: Int!, $userEnvironmentUuid: UUID!) {
    Cart {
      Inc(id: $id, userEnvironmentUuid: $userEnvironmentUuid) {
        isSuccessful
        errors {
          ...on ServerError { ${GQL_ENTITY_ERROR_BODY} }
          ...on ValidateError { ${GQL_ENTITY_ERROR_BODY} }
        }
      }
    }
  }
`;

export const GQL_CART_DECREMENT_MUTATION = gql`
  mutation ($id: Int!, $userEnvironmentUuid: UUID!) {
    Cart {
      Dec(id: $id, userEnvironmentUuid: $userEnvironmentUuid) {
        isSuccessful
        errors {
          ...on ServerError { ${GQL_ENTITY_ERROR_BODY} }
          ...on ValidateError { ${GQL_ENTITY_ERROR_BODY} }
        }
      }
    }
  }
`;

export const GQL_CART_MULTIPLE_UPDATE = gql`
  mutation($ids: [Int!], $input: CartMultipleUpdateInput, $userEnvironmentUuid: UUID!) {
    Cart {
      MultipleUpdate(ids: $ids, CartMultipleUpdateInput: $input, userEnvironmentUuid: $userEnvironmentUuid) {
        isSuccessful
        errors {
          ...on ServerError { ${GQL_ENTITY_ERROR_BODY} }
          ...on ValidateError { ${GQL_ENTITY_ERROR_BODY} }
        }
      }
    }
  }
`;

export const GQL_CART_NOTIFICATION_FIELDS = `
  cartInfo {
    totalPrices {
      currency
      value
    }
  }
`;

<label class="check__container radio"
       [ngClass]="{'disabled': disabled }">
  <!-- (click)="onChange2()" -->
  <input #input
         (change)="onChange()"
         [checked]="checked"
         [disabled]="disabled"
         [name]="name"
         [value]="value"
         class="visuallyhidden"
         type="checkbox">
  <span class="check__checkmark radio" [ngClass]="{'green': green}"></span>
  <span class="check__content radio" *ngIf="lable">
     <ng-content></ng-content>
  </span>
</label>

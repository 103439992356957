<div class="modal-geo">
  <svg-icon class="close" name="control--close" [size]="12" (click)="clickClose()"></svg-icon>
  <p class="modal-geo__title" i18n="@@modal-geo.title">Thank you for choosing Vertera products!</p>
  <p class="modal-geo__subtitle" i18n="@@modal-geo.subtitle">You collect a basket with delivery to</p>
  <form [formGroup]="form" class="modal-geo__actions">
    <div class="actions-select">
      <label class="actions-select__title" i18n="@@common.country">Country</label>
      <ng-select i18n-placeholder placeholder="Country" id="country_id"
        [items]="country.entitiesForDD"
        appendTo="body" class="select-in-tabs"
        [loading]="country.isLoading"
        loadingText='Loading...' i18n-loadingText="@@ng_select.loading"
        notFoundText='Country is not found' i18n-notFoundText="@@ng_select.country_not_found"
        bindLabel="title" bindValue="code"
        (search)="searchCountry($event)"
        (change)="onCountrySelected($event)"
        formControlName="country_code">
      </ng-select>
    </div>
    <div class="actions-select">
      <label class="actions-select__title" i18n="@@common.city">City</label>
      <ng-select i18n-placeholder placeholder="City" id="city_id" class="form__select hidden-arrow"
        appendTo="body" class="select-in-tabs"
        [ngClass]="{error: submitted && !city.selected}"
        [items]="city.entitiesForDD"
        [loading]="city.isLoading"
        typeToSearchText="Type to search" i18n-typeToSearchText="@@ng_select.type_to_search"
        loadingText='Loading...' i18n-loadingText="@@ng_select.loading"
        notFoundText='City is not found' i18n-notFoundText="@@ng_select.city_not_found"
        bindLabel="title" bindValue="id"
        (search)="searchCity($event)"
        [typeahead]="city.search$"
        formControlName="city_id"
        (change)="onCitySelected($event)">
      </ng-select>
    </div>
  </form>
  <div class="modal-geo__warning">
    <img appStatic="/assets/icons/ic/attention.svg"/>
    <p class="modal-geo__warning-text" i18n="@@modal-geo.attention">
      <span>Attention!</span> Changing the country and city at later stages of the order can lead to the unavailability
      of
      the part of
      the basket and the dumping of gifts
    </p>
  </div>
  <div class="modal-geo__buttons">
    <app-green-button [disabled]="isUserEnvLoading" height="h48" i18n="@@common.confirm" (click)='save()'>Confirm</app-green-button>
  </div>
</div>

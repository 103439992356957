import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { UserApiService } from './api/UserGroup/user-api.service';
import { Address, AddressCreateInput, AddressCreatePayload, AddressDeletePayload, AddressQueryFilter, AddressUpdateInput, AddressUpdatePayload, User } from '@generated';
import { GQL_USER_COMMON_FIELDS } from '@core/models/UserGroup/user.gql';
import { AddressesApiService } from './api/GeoGroup/addresses-api.service';
import { AddressesQuery } from '@core/models/GeoGroup/addresses';
import { GQL_ADDRESS_BODY } from '@core/models/GeoGroup/addresses.gql';


@Injectable(
  {
    providedIn: 'root'
  }
)
export class UserService {
  user: User;

  constructor(private userApiService: UserApiService, private addressesApiService: AddressesApiService) { }

  getUser(): Observable<User> {
    return this.userApiService.getUser({}, GQL_USER_COMMON_FIELDS).pipe(tap((user: User) => this.user = user));
  }

  setAddress(input: AddressCreateInput): Observable<AddressCreatePayload> {
    return this.addressesApiService.addressCreate(input);
  }

  editAddress(id: number, input: AddressUpdateInput): Observable<AddressUpdatePayload> {
    return this.addressesApiService.addressUpdate(id, input);
  }

  removeAddress(id: number): Observable<AddressDeletePayload> {
    return this.addressesApiService.addressDelete(id);
  }

  getAddresses(query: AddressesQuery): Observable<Address[] | undefined> {
    return this.addressesApiService.getAddresses(query, GQL_ADDRESS_BODY).pipe(map((res) => res.addresses?.data))
  }

  getAddress(filter: AddressQueryFilter): Observable<Address | undefined> {
    return this.addressesApiService.getAddress(filter, GQL_ADDRESS_BODY);
  }
}

import { GQL_IMAGE } from '../common.gql';

export const GQL_USER_COMMON_FIELDS = `
... on User { 
  pk
  idRef
  lastName
  firstName
  middleName
  avatar {
    image {
      ${GQL_IMAGE}
    }
  }
  phones {
    id
    isMain
    phone
  }
  emails {
    id
    isMain
    email
  }
}
`;


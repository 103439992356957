import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { GQL_PAGE_INFO_MODEL, GQL_PRODUCT_QUERY, ProductQuery, ProductsQuery } from '@core/models';
import { ProductsQueryPayload, ProductQueryFilter, ProductQueryPayload } from '@generated';

@Injectable()
export class ProductApiService {
  constructor(private apollo: Apollo) {}

  getProducts(query: ProductsQuery, fields: string): Observable<ProductsQueryPayload> {
    return this.apollo
      .query({
        query: gql`
        query products($limit: Int!, $page: Int!, $commonFilter: ProductsQueryFilter, $sort: ProductsSortEnum, $userEnvironmentUuid: UUID!) {
          ProductGroup {
            Products(limit: $limit, page: $page, commonFilter: $commonFilter, sort: $sort, userEnvironmentUuid: $userEnvironmentUuid) {
              products {
                data {
                 ${fields}
                }
                pageInfo { ${GQL_PAGE_INFO_MODEL} }
              }
              currencyCode
            }
          }
        }`,
        variables: query,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res: any) => res.data.ProductGroup.Products));
  }

  getProduct(query: ProductQuery, fields: string): Observable<ProductQueryPayload> {
    return this.apollo
      .query({
        query: GQL_PRODUCT_QUERY(fields),
        variables: query,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res: any) => res.data.ProductGroup.Product));
  }
}
